import { gql } from "@apollo/client";

export const CREAR_TIPO_ACTIVIDAD = gql`
  mutation CrearTipoActividad($idEmpresa: ID!, $input: tipoActividadInput) {
    crearTipoActividad(idEmpresa: $idEmpresa, input: $input) {
      message
    }
  }
`;
export const ACTUALIZAR_TIPO_ACTIVIDAD = gql`
  mutation ActualizarTipoActividad(
    $idEmpresa: ID!
    $idTipoActividad: ID!
    $input: tipoActividadInput
  ) {
    actualizarTipoActividad(
      idEmpresa: $idEmpresa
      idTipoActividad: $idTipoActividad
      input: $input
    ) {
      message
    }
  }
`;
export const ELIMINAR_TIPO_ACTIVIDAD = gql`
  mutation EliminarTipoActividad($idTipoActividad: ID!) {
    eliminarTipoActividad(idTipoActividad: $idTipoActividad) {
      message
    }
  }
`;
