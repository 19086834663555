import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import { ArrowBack, PersonSearch } from "@mui/icons-material";
import { TratosContext } from "../../Context/TratosContext";
import ContactoEdit from "./Contacto/ContactoEdit";
import TratoEdit from "./TratoEdit";
import EliminarTrato from "../EliminarTrato";
import { DialogContent, Stack } from "@mui/material";

export default function TratoDetails({ data, children }) {
  const { setTrato, initial_state_trato, setVisiblePara } = React.useContext(
    TratosContext
  );
  const [open, setOpen] = React.useState(false);

  const handleOpenDetail = (e) => {
    e.stopPropagation();
    setOpen(true);
    setTrato(data);
    setVisiblePara(data.visiblePara);
  };

  const handleClose = (event) => {
    const { type, key } = event;
    if (type === "keydown" && (key === "Tab" || key === "Shift")) return;
    setOpen(false);
    setTrato(initial_state_trato);
    setVisiblePara([]);
  };

  return (
    <div onClick={handleOpenDetail}>
      {children}
      <Drawer
        anchor={"right"}
        open={open}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
        ModalProps={{
          componentsProps: {
            backdrop: {
              sx: { background: "none" /* backdropFilter: "blur(2px)" */ },
            },
          },
        }}
        PaperProps={{
          elevation: 1,
          sx: { width: { xs: "100vw", md: "80vw" }, overflowY: "auto" },
        }}
      >
        <Toolbar variant="dense" />
        <DialogTitle component="div">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <IconButton onClick={handleClose}>
                <ArrowBack />
              </IconButton>
              <Typography variant="h6" color="gray">
                Detalles Oportunidad
              </Typography>
            </Stack>
            <EliminarTrato data={data} handleCloseDetail={handleClose} />
          </Box>
        </DialogTitle>
        <DialogContent sx={{ position: "relative" }}>
          <Grid container>
            <Grid
              item
              md={4}
              xs={12}
              sx={{
                borderRight: { xs: 0, md: 1 },
                borderColor: { xs: null, md: "divider" },
                p: 1,
              }}
            >
              {!data?.contactoPopulated?.length ? (
                <Box
                  sx={{
                    mt: 3,
                    display: "grid",
                    direction: "column",
                    placeItems: "center",
                    position: { xs: "initial", md: "sticky" },
                    top: 0,
                  }}
                >
                  <PersonSearch color="action" sx={{ fontSize: 50 }} />
                  <Typography color="GrayText">
                    No se encontro este contacto
                  </Typography>
                </Box>
              ) : (
                <ContactoEdit trato={data} />
              )}
            </Grid>
            <Grid item md={8} xs={12}>
              <TratoEdit />
            </Grid>
          </Grid>
        </DialogContent>
      </Drawer>
    </div>
  );
}
