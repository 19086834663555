import React from "react";
import { Paper, Typography, useTheme, Box, Button } from "@mui/material";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { DashboardContext } from "../Context/DashboardContext";
import { Label } from "@mui/icons-material";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Box
        bgcolor="background.paper"
        sx={{ p: 1, borderRadius: 2, opacity: 0.7 }}
      >
        <Typography variant="caption">
          <b>{label}</b>
        </Typography>
        {payload.map(({ fill, name, value }, index) => {
          return (
            <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  height: 12,
                  width: 12,
                  backgroundColor: fill,
                  borderRadius: 1,
                  mr: 0.5,
                }}
              />
              <Typography variant="caption">{`${name}: ${value}`}</Typography>
            </Box>
          );
        })}
      </Box>
    );
  }

  return null;
};

export default function GanadosPerdidos() {
  const { dashQuery, table, handleSetTable } = React.useContext(
    DashboardContext
  );
  const theme = useTheme();
  const { byFase } = dashQuery.data.obtenerDataDashboard.tratos;

  const dataGraph = [
    { name: "En trato", value: "enTrato", color: theme.palette.primary.light },
    { name: "Perdidos", value: "perdidos", color: theme.palette.error.light },
    {
      name: "Olvidados",
      value: "olvidadas",
      color: theme.palette.warning.light,
    },
    { name: "Ganados", value: "ganados", color: theme.palette.secondary.light },
  ];

  return (
    <Paper variant="outlined">
      <Button
        fullWidth
        disableElevation
        size="small"
        sx={{ textTransform: "none" }}
        startIcon={<Label />}
        onClick={() => handleSetTable("byFase")}
        variant={table === "byFase" ? "contained" : "text"}
      >
        Tratos por fase
      </Button>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "35vh",
        }}
      >
        <ResponsiveContainer width="100%" height={230}>
          <ComposedChart
            layout="vertical"
            data={byFase}
            margin={{
              top: 0,
              right: 10,
              left: 30,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" />
            <YAxis dataKey="fase" type="category" fontSize={11} />
            <Tooltip content={<CustomTooltip />} />
            <Bar
              dataKey="tratosDashboard[0].byGroup.enTrato"
              name="En trato"
              stackId="a"
              barSize={30}
              fill={dataGraph[0].color}
            />
            <Bar
              dataKey="tratosDashboard[0].byGroup.perdidos"
              name="Perdidos"
              stackId="a"
              barSize={30}
              fill={dataGraph[1].color}
            />
            <Bar
              dataKey="tratosDashboard[0].byGroup.olvidadas"
              name="Olvidados"
              stackId="a"
              barSize={30}
              fill={dataGraph[2].color}
            />
            <Bar
              dataKey="tratosDashboard[0].byGroup.ganados"
              name="Ganados"
              stackId="a"
              barSize={30}
              fill={dataGraph[3].color}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </Paper>
  );
}
