import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { ColorLens } from "@mui/icons-material";
import { Box } from "@mui/material";
import { StatusContext } from "../Context/StatusActividadContext";
import { TwitterPicker } from "react-color";
import { getContrastColor } from "../../../../../Config/reuserFunctions";

const colors = [
  "#FF6900",
  "#FF9800",
  "#FCB900",
  "#FFE17D",
  "#C2FF99",
  "#7BDCB5",
  "#00D084",
  "#8ED1FC",
  "#0693E3",
  "#5EA9FF",
  "#ABB8C3",
  "#EB144C",
  "#FF6961",
  "#FF8787",
  "#F78DA7",
  "#9900EF",
  "#C0A0C3"
];

export default function ColorSelect() {
  const { newItem, setNewItem } = React.useContext(StatusContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeComplete = (color, event) => {
    setNewItem((st) => ({ ...st, color: color.hex }));
  };

  return (
    <div>
      <Button
        size="small"
        startIcon={<ColorLens />}
        onClick={handleClick}
        sx={{
          bgcolor: newItem.color,
          color: getContrastColor(newItem.color),
          ":hover": { opacity: 0.8, bgcolor: newItem.color },
        }}
      >
        {newItem.color}
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box sx={{ p: 1 }}>
          <TwitterPicker
            onChangeComplete={handleChangeComplete}
            triangle="hide"
            colors={colors}
          />
        </Box>
      </Menu>
    </div>
  );
}
