import { gql } from "@apollo/client";

export const CREAR_CAMPAIGN = gql`
  mutation CrearCampaign($idEmpresa: ID!, $input: campaignInput) {
    crearCampaign(idEmpresa: $idEmpresa, input: $input) {
      message
    }
  }
`;
export const ACTUALIZAR_CAMPAIGN = gql`
  mutation ActualizarCampaign($idEmpresa: ID!, $idCampaign: ID!, $input: campaignInput) {
    actualizarCampaign(idEmpresa: $idEmpresa, idCampaign: $idCampaign, input: $input) {
      message
    }
  }
`;
export const ELIMINAR_CAMPAIGN = gql`
  mutation EliminarCampaign($idCampaign: ID!) {
    eliminarCampaign(idCampaign: $idCampaign) {
      message
    }
  }
`;
