import { gql } from "@apollo/client";

export const CREAR_ACTIVIDAD = gql`
  mutation CrearActividad($idEmpresa: ID!, $input: crearActividadInput) {
    crearActividad(idEmpresa: $idEmpresa, input: $input) {
      _id
      actividad
      tipoActividad
      idTipoActividad
      tipoActividadPopulated {
        _id
        tipoActividad
        accion
        icono
      }
      contactoPopulated {
        _id
        tipoContacto
        idTipoContacto
        nombre
        organizacion
        etiqueta
        idEtiqueta
        telefono
        correo
        propietario
        idPropietario
        formaAtraccion
        idFormaAtraccion
        campaign
        idCampaign
        recomendadoPor
        statusContacto
        idStatusContacto
        statusContactoPopulated {
          _id
          status
          factor
          color
        }
        fechaRegistro
        customFields {
          nombre
          contenido
        }
      }
      isDate
      fechaInicio
      fechaFin
      notas
      contacto
      idContacto
      trato
      idTrato
      ubicacion
      descripcionUbicacion
      usuario
      idUsuario
      prioridad
      status
      statusData {
        _id
        status
        color
        static
      }
      idStatus
      expires
      resultado
      fechaRegistro
      idResultado
    }
  }
`;
export const ACTUALIZAR_ACTIVIDAD = gql`
  mutation ActualizarActividad($idActividad: ID!, $input: crearActividadInput) {
    actualizarActividad(idActividad: $idActividad, input: $input) {
      message
    }
  }
`;
export const ACTUALIZAR_STATUS_ACTIVIDAD = gql`
  mutation UpdateStatusActividad(
    $idActividad: ID!
    $idStatus: ID!
    $status: String
    $resultado: String
  ) {
    updateStatusActividad(
      idActividad: $idActividad
      idStatus: $idStatus
      status: $status
      resultado: $resultado
    ) {
      message
    }
  }
`;
export const ACTUALIZAR_PRIORIDAD_ACTIVIDAD = gql`
  mutation UpdatePrioridadActividad($idActividad: ID!, $prioridad: String) {
    updatePrioridadActividad(idActividad: $idActividad, prioridad: $prioridad) {
      message
    }
  }
`;
export const ELIMINAR_ACTIVIDAD = gql`
  mutation EliminarActividad($idActividad: ID!) {
    eliminarActividad(idActividad: $idActividad) {
      message
    }
  }
`;
export const ELIMINAR_ACTIVIDADES = gql`
  mutation EliminarActividades($actividadesToDelete: [ID!]!) {
    eliminarActividades(actividadesToDelete: $actividadesToDelete) {
      message
    }
  }
`;
