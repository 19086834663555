import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Grid from "@mui/material/Grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircularProgress from "@mui/material/CircularProgress";
import EditableTextInput from "../../../../../Components/InputsForms/EditableTextInput";
import EditableAutoComplete from "../../../../../Components/InputsForms/EditableAutoComlete";
import { Done } from "@mui/icons-material";
import ChipStatusContacto from "../Actions/ChipStatusContacto";
import { ACTUALIZAR_CONTACTO } from "../graphql/mutations";
import { useMutation } from "@apollo/client";
import { MainContext } from "../../../../../Context/MainCtx";
import { ContactosContext } from "../Context/ContactoContext";
import NuevoTipoC from "../../TipoContacto/Actions/NuevoTipoC";
import NuevaEtiqueta from "../../Etiquetas/Actions/NuevaEtiqueta";

export default function ContactoEdit() {
  const { contacto, setContacto, queryCats, queryRefetch } = React.useContext(
    ContactosContext
  );
  const { snackMessage } = React.useContext(MainContext);
  const [loading, setLoading] = React.useState(false);
  const [actualizarContacto] = useMutation(ACTUALIZAR_CONTACTO);

  const autocompleteChange = (values) => {
    const { name, value, id, idName } = values;
    let dataUpdated = {
      ...contacto,
      [name]: value,
    };
    if (id && idName) {
      dataUpdated = {
        ...contacto,
        [name]: value,
        [idName]: id,
      };
    }
    setContacto(dataUpdated);
    handleSaveContacto(dataUpdated);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContacto((state) => ({
      ...state,
      [name]: name === "valor" ? parseFloat(value) : value,
    }));
  };

  const handleSaveContacto = async (dataUpdated = contacto) => {
    try {
      if (loading) return;
      setLoading(true);
      let {
        _id,
        fechaRegistro,
        idEmpresa,
        actividades,
        statusContactoPopulated,
        tratos,
        ...input
      } = dataUpdated;

      await actualizarContacto({
        variables: {
          idContacto: contacto._id,
          input,
        },
      });
      queryRefetch();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <Accordion
      elevation={0}
      sx={{
        "& .MuiAccordionSummary-root": {
          backgroundColor: (theme) =>
            `${theme.palette.background.paper}!important`,
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        component="div"
      >
        <Box onClick={(e) => e.stopPropagation()}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" color="gray">
                Nombre
              </Typography>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSaveContacto();
                }}
              >
                <EditableTextInput
                  tabIndex={1}
                  fullWidth
                  required
                  placeholder="Agregar nombre de contacto"
                  name="nombre"
                  value={contacto.nombre}
                  loading={loading}
                  onChange={handleChange}
                  actionButton={
                    <IconButton size="small" color="primary" type="submit">
                      {loading ? (
                        <CircularProgress size={20} color="primary" />
                      ) : (
                        <Done />
                      )}
                    </IconButton>
                  }
                />
              </form>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="body2" color="gray">
                Telefono
              </Typography>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSaveContacto();
                }}
              >
                <EditableTextInput
                  tabIndex={2}
                  fullWidth
                  required
                  placeholder="Agregar número de telefono"
                  name="telefono"
                  value={contacto.telefono}
                  loading={loading}
                  onChange={handleChange}
                  actionButton={
                    <IconButton size="small" color="primary" type="submit">
                      {loading ? (
                        <CircularProgress size={20} color="primary" />
                      ) : (
                        <Done />
                      )}
                    </IconButton>
                  }
                />
              </form>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography variant="body2" color="gray">
                Status
              </Typography>
              <ChipStatusContacto
                contacto={contacto}
                setContacto={setContacto}
                refetch={queryRefetch}
                query={queryCats.data?.statusContactos}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" color="gray">
                Tipo de contacto
              </Typography>
              <EditableAutoComplete
                tabIndex={3}
                placeholder="Añadir tipo de contacto"
                renderData={queryCats.data?.tiposContactos}
                value={contacto.tipoContacto}
                loading={loading}
                loadingQuery={queryCats.loading}
                errorQuery={queryCats.error}
                required
                addButton={
                  <NuevoTipoC
                    external={true}
                    refetchExternal={queryCats.refetch}
                  />
                }
                loadingIcon={<CircularProgress size={20} color="primary" />}
                onChange={(_, option) =>
                  autocompleteChange({
                    name: "tipoContacto",
                    idName: "idTipoContacto",
                    value: option?.tipoContacto || "",
                    id: option?._id || "",
                  })
                }
                isOptionEqualToValue={({ tipoContacto }, value) =>
                  tipoContacto === value
                }
                getOptionLabel={(option) =>
                  option.tipoContacto || option || null
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" color="gray">
                Etiqueta
              </Typography>
              <EditableAutoComplete
                tabIndex={4}
                placeholder="Añadir etiqueta"
                renderData={queryCats.data?.etiquetas}
                value={contacto.etiqueta}
                loading={loading}
                loadingQuery={queryCats.loading}
                errorQuery={queryCats.error}
                required
                addButton={
                  <NuevaEtiqueta
                    external={true}
                    refetchExternal={queryCats.refetch}
                  />
                }
                loadingIcon={<CircularProgress size={20} color="primary" />}
                onChange={(_, option) =>
                  autocompleteChange({
                    name: "etiqueta",
                    idName: "idEtiqueta",
                    value: option?.etiqueta || "",
                    id: option?._id || "",
                  })
                }
                isOptionEqualToValue={({ etiqueta }, value) =>
                  etiqueta === value
                }
                getOptionLabel={(option) => option.etiqueta || option || null}
              />
            </Grid>
          </Grid>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1} sx={{ mb: 1.5 }}>
          <Grid item xs={12} md={6}>
            <Typography variant="body2" color="gray">
              Correo
            </Typography>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSaveContacto();
              }}
            >
              <EditableTextInput
                tabIndex={5}
                fullWidth
                placeholder="Agregar correo electrónico"
                name="correo"
                value={contacto.correo}
                loading={loading}
                onChange={handleChange}
                actionButton={
                  <IconButton size="small" color="primary" type="submit">
                    {loading ? (
                      <CircularProgress size={20} color="primary" />
                    ) : (
                      <Done />
                    )}
                  </IconButton>
                }
              />
            </form>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body2" color="gray">
              Organización
            </Typography>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSaveContacto();
              }}
            >
              <EditableTextInput
                tabIndex={6}
                fullWidth
                placeholder="Agregar organización"
                name="organizacion"
                value={contacto.organizacion}
                loading={loading}
                onChange={handleChange}
                actionButton={
                  <IconButton size="small" color="primary" type="submit">
                    {loading ? (
                      <CircularProgress size={20} color="primary" />
                    ) : (
                      <Done />
                    )}
                  </IconButton>
                }
              />
            </form>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body2" color="gray">
              Forma de Atracción
            </Typography>
            <EditableAutoComplete
              tabIndex={7}
              placeholder="Añadir forma de atracción"
              renderData={queryCats.data?.formasAtraccion}
              value={contacto.formaAtraccion}
              loading={loading}
              loadingQuery={queryCats.loading}
              errorQuery={queryCats.error}
              addButton={
                <NuevoTipoC
                  external={true}
                  refetchExternal={queryCats.refetch}
                />
              }
              loadingIcon={<CircularProgress size={20} color="primary" />}
              onChange={(_, option) =>
                autocompleteChange({
                  name: "formaAtraccion",
                  idName: "idFormaAtraccion",
                  value: option?.formaAtraccion || "",
                  id: option?._id || "",
                })
              }
              isOptionEqualToValue={({ formaAtraccion }, value) =>
                formaAtraccion === value
              }
              getOptionLabel={(option) =>
                option.formaAtraccion || option || null
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body2" color="gray">
              Campaña
            </Typography>
            <EditableAutoComplete
              tabIndex={8}
              placeholder="Añadir campaña"
              renderData={queryCats.data?.campaigns}
              value={contacto.campaign}
              loading={loading}
              loadingQuery={queryCats.loading}
              errorQuery={queryCats.error}
              addButton={
                <NuevoTipoC
                  external={true}
                  refetchExternal={queryCats.refetch}
                />
              }
              loadingIcon={<CircularProgress size={20} color="primary" />}
              onChange={(_, option) =>
                autocompleteChange({
                  name: "campaign",
                  idName: "idCampaign",
                  value: option?.nombre || "",
                  id: option?._id || "",
                })
              }
              isOptionEqualToValue={({ nombre }, value) => nombre === value}
              getOptionLabel={(option) => option.nombre || option || null}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body2" color="gray">
              Propietario
            </Typography>
            <EditableAutoComplete
              tabIndex={9}
              placeholder="Asignar propietario"
              renderData={queryCats.data?.usuarios}
              value={contacto.propietario}
              loading={loading}
              loadingQuery={queryCats.loading}
              errorQuery={queryCats.error}
              addButton={
                <NuevoTipoC
                  external={true}
                  refetchExternal={queryCats.refetch}
                />
              }
              loadingIcon={<CircularProgress size={20} color="primary" />}
              onChange={(_, option) =>
                autocompleteChange({
                  name: "propietario",
                  idName: "idPropietario",
                  value: option?.nombreCompleto || "",
                  id: option?._id || "",
                })
              }
              isOptionEqualToValue={({ nombreCompleto }, value) =>
                nombreCompleto === value
              }
              getOptionLabel={(option) =>
                option.nombreCompleto || option || null
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body2" color="gray">
              Recomendado por
            </Typography>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSaveContacto();
              }}
            >
              <EditableTextInput
                tabIndex={10}
                fullWidth
                placeholder="Agregar nombre de quién recomendó"
                name="recomendadoPor"
                value={contacto.recomendadoPor}
                loading={loading}
                onChange={handleChange}
                actionButton={
                  <IconButton size="small" color="primary" type="submit">
                    {loading ? (
                      <CircularProgress size={20} color="primary" />
                    ) : (
                      <Done />
                    )}
                  </IconButton>
                }
              />
            </form>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
