import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { RouterProvider } from "react-router-dom";
import { router } from "./Config/routes";
import MainCtxProvider from "./Context/MainCtx";
import { dark, light } from "./Config/colors";
import { useSelector } from "react-redux";
import client from "./Config/apollo";
import { ApolloProvider } from "@apollo/client";

function App() {
  const theming = useSelector((state) => state.theming.mode);
  const theme = createTheme(theming === "dark" ? dark : light);

  return (
    <div className="App">
      <SnackbarProvider maxSnack={3} autoHideDuration={2500}>
        <MainCtxProvider>
          <ApolloProvider client={client}>
            <ThemeProvider theme={theme}>
              <RouterProvider router={router} />
            </ThemeProvider>
          </ApolloProvider>
        </MainCtxProvider>
      </SnackbarProvider>
    </div>
  );
}

export default App;
