import { gql } from "@apollo/client";

export const CREAR_ETIQUETA = gql`
  mutation CrearEtiqueta($idEmpresa: ID!, $etiqueta: String) {
  crearEtiqueta(idEmpresa: $idEmpresa, etiqueta: $etiqueta) {
    message
  }
}
`;
export const ACTUALIZAR_ETIQUETA = gql`
  mutation ActualizarEtiqueta($idEmpresa: ID!, $idEtiqueta: ID!, $etiqueta: String) {
  actualizarEtiqueta(idEmpresa: $idEmpresa, idEtiqueta: $idEtiqueta, etiqueta: $etiqueta) {
    message
  }
}
`;
export const ELIMINAR_ETIQUETA = gql`
  mutation EliminarEtiqueta($idEtiqueta: ID!) {
  eliminarEtiqueta(idEtiqueta: $idEtiqueta) {
    message
  }
}
`;
