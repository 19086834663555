import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import Done from "@mui/icons-material/Done";
import CircularProgress from "@mui/material/CircularProgress";
import { Add, ArrowBack } from "@mui/icons-material";
import ActividadDetail from "./ActividadDetail";
import { Button, DialogContent, Stack, Typography } from "@mui/material";
import { CREAR_ACTIVIDAD } from "../../graphql/mutations";
import { MainContext } from "../../../../../Context/MainCtx";
import { ActividadContext } from "../../Context/ActividadContext";
import { useMutation } from "@apollo/client";
import { isMobile } from "../../../../../Config/constantes";

export default function NuevaActividad({
  external = false,
  tratoData = null,
  refetchExternal = null,
  setToArray = null,
  fromContacto = null
}) {
  const { snackMessage, tokenDecoded } = React.useContext(MainContext);
  const {
    queryRefetch,
    initial_actividad,
    actividad,
    setActividad,
  } = React.useContext(ActividadContext);
  const [loading, setLoading] = React.useState(false);
  const [crearActividad] = useMutation(CREAR_ACTIVIDAD);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
    if (external && tratoData) {
      setActividad((st) => ({
        ...st,
        trato: tratoData.nombreTrato,
        idTrato: tratoData._id,
        contacto: tratoData.contacto,
        idContacto: tratoData.idContacto,
      }));
    }
    if(fromContacto){
      setActividad((st) => ({
        ...st,
        contacto: fromContacto.nombre,
        idContacto: fromContacto._id,
      }));
    }
  };

  const handleClose = () => {
    setOpen(false);
    setActividad(initial_actividad);
  };

  const saveActividad = async (e) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      if (loading) return;
      if (!actividad.status) {
        snackMessage({
          message: "Selecciona un Status a tu actividad",
          variant: "warning",
        });
        return;
      }
      setLoading(true);
      let {
        _id,
        fechaRegistro,
        idEmpresa,
        contactoPopulated,
        tipoActividadPopulated,
        statusData,
        color,
        fromCalendar,
        ...input
      } = actividad;
      delete input.new;
      const res = await crearActividad({
        variables: {
          idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
          input,
        },
      });
      snackMessage({
        message: "Actividad creada",
        variant: "success",
      });
      if(setToArray) setToArray(res.data.crearActividad);
      if (refetchExternal) refetchExternal();
      if (queryRefetch) queryRefetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  React.useEffect(() => {
    if (actividad && actividad.fromCalendar && actividad.new) {
      setOpen(true);
    }
  }, [actividad]);

  return (
    <div>
      <Button
        onClick={handleOpen}
        color="primary"
        variant={external ? "text" : "contained"}
        size={external ? "medium": "small"}
        sx={{ textTransform: "none", minWidth: 40 }}
        startIcon={ isMobile ? null : <Add />}
        disableElevation
      >
        {isMobile ? <Add fontSize="small" /> : "Nuevo"}
      </Button>
      <Drawer
        anchor={"right"}
        open={open}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
        ModalProps={{
          componentsProps: {
            backdrop: {
              sx: { background: "none" },
            },
          },
        }}
        PaperProps={{
          elevation: 1,
          sx: { width: { xs: "100vw", md: "50vw" }, overflowY: "auto" },
        }}
      >
        <Toolbar variant="dense" />
        <DialogTitle component="div">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <IconButton onClick={handleClose}>
                <ArrowBack />
              </IconButton>
              <Typography variant="h6" color="gray">
                Nueva Actividad
              </Typography>
            </Stack>
            <Button
              variant="contained"
              size="small"
              disableElevation
              sx={{ textTransform: "none" }}
              type="submit"
              form="formActividad"
              startIcon={
                loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <Done />
                )
              }
            >
              Guardar
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          {actividad ? (
            <form id="formActividad" onSubmit={saveActividad}>
              <ActividadDetail />
            </form>
          ) : null}
        </DialogContent>
      </Drawer>
    </div>
  );
}
