import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CustomLoader from "../../../../../Components/CustomLoader";
import ErrorMessage from "../../../../../Components/ErrorMessage";
import EliminarRedSocial from "./EliminarRedSocial";
import NuevaRedSocial from "../Actions/NuevaRedSocial";
import { useTheme } from "@mui/material";
import { getBicolorTable } from "../../../../../Config/reuserFunctions";

export default function RedesSocialesTable({ redesQuery }) {
  const { loading, data, error, refetch } = redesQuery;
  const theme = useTheme();

  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage />;

  return (
    <Paper variant="outlined" sx={{ overflow: "hidden" }}>
      <TableContainer
        sx={{
          transition: "width .3s",
          height: { xs: `calc(100vh - 190px)`, sm: `calc(100vh - 120px)` },
          overflowX: "scroll",
        }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>Red social</TableCell>
              <TableCell>Enlace</TableCell>
              <TableCell>Unidad de negocio</TableCell>
              <TableCell padding="checkbox" />
              <TableCell padding="checkbox" />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.obtenerRedSociales.map((data, index) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={`red-${data._id}`}
                  style={getBicolorTable(theme, index)}
                >
                  <TableCell>{data.redSocial}</TableCell>
                  <TableCell>{data.enlace}</TableCell>
                  <TableCell>{data.unidadNegocio}</TableCell>
                  <TableCell padding="checkbox">
                    <NuevaRedSocial edit={true} data={data} refetch={refetch} />
                  </TableCell>
                  <TableCell padding="checkbox">
                    <EliminarRedSocial data={data} refetch={refetch} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
