import * as React from "react";
import { ActividadContext } from "../Context/ActividadContext";
import CustomLoader from "../../../../Components/CustomLoader";
import ErrorMessage from "../../../../Components/ErrorMessage";
import ActividadesCalendar from "./ActividadesCalendar";
import ActividadesTable from "./TableMode";

export default function ActividadesIndex() {
  const { queryError, queryLoading, mode } = React.useContext(ActividadContext);

  if (queryLoading) return <CustomLoader />;
  if (queryError) return <ErrorMessage />;

  return mode === "calendar" ? <ActividadesCalendar /> : <ActividadesTable />;
}
