import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CustomLoader from "../../../../../Components/CustomLoader";
import ErrorMessage from "../../../../../Components/ErrorMessage";
import EliminarEtiqueta from "./EliminarEtiqueta";
import NuevaEtiqueta from "../Actions/NuevaEtiqueta";
import { useTheme } from "@mui/material";
import { getBicolorTable } from "../../../../../Config/reuserFunctions";

export default function EtiquetaTable({ etiquetaQuery }) {
  const { loading, data, error, refetch } = etiquetaQuery;
  const theme = useTheme();

  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage />;

  return (
    <Paper variant="outlined" sx={{ overflow: "hidden" }}>
      <TableContainer
        sx={{
          height: { xs: `calc(100vh - 232px)`, sm: `calc(100vh - 124px)` },
        }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>Etiqueta</TableCell>
              <TableCell padding="checkbox" />
              <TableCell padding="checkbox" />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.obtenerEtiquetas.map((data, index) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={`etiqueta-${data._id}`}
                  style={getBicolorTable(theme, index)}
                  sx={{ minWidth: "48px" }}
                >
                  <TableCell>{data.etiqueta}</TableCell>
                  <TableCell padding="checkbox">
                    <NuevaEtiqueta edit={true} data={data} refetch={refetch} />
                  </TableCell>
                  <TableCell padding="checkbox">
                    <EliminarEtiqueta data={data} refetch={refetch} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
