import React, { createContext } from "react";
import { useDebounce } from "use-debounce";
import { useQuery } from "@apollo/client";
import { OBTENER_STATUS_ACTIVIDAD } from "../graphql/queries";
import { MainContext } from "../../../../../Context/MainCtx";

export const StatusContext = createContext();

const initial_status = {
  status: "",
  color: "#5EA9FF",
  new: true,
};

const StatusActividadCtxProvider = ({ children }) => {
  const { tokenDecoded } = React.useContext(MainContext);
  const [filter, setFilter] = React.useState("");
  const [itemsSelected, setItemsSelected] = React.useState([]);
  const [loadingSave, setLoadingSave] = React.useState(false);
  const [newItem, setNewItem] = React.useState(null);
  const [indeterminated, setIndeterminated] = React.useState(false);
  const [FILTER] = useDebounce(filter, 500);

  const query = useQuery(OBTENER_STATUS_ACTIVIDAD, {
    variables: {
      filter: FILTER,
      idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
    },
    fetchPolicy: "network-only",
  });

  return (
    <StatusContext.Provider
      value={{
        filter,
        setFilter,
        query,
        initial_status,
        itemsSelected,
        setItemsSelected,
        newItem,
        setNewItem,
        loadingSave,
        setLoadingSave,
        indeterminated,
        setIndeterminated,
      }}
    >
      {children}
    </StatusContext.Provider>
  );
};

export default StatusActividadCtxProvider;
