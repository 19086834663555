import * as React from "react";
import TableBody from "@mui/material/TableBody";
import RenderTratos from "./RenderTratos";
import EditableRowTrato from "./EditableRow";
import { TratosContext } from "../../Context/TratosContext";
import { isMobile } from "../../../../../Config/constantes";

export default function TableBodyTratos({ shadows, tratos, handleSelect }) {
  const { newItem } = React.useContext(TratosContext);

  return (
    <TableBody>
      {newItem && newItem.new
        ? [newItem].map((_, index) => {
            return <EditableRowTrato key={`new-item-${index}`} />;
          })
        : null}
      {tratos.map((trato, index) => {
        if (newItem && !newItem.new && newItem._id === trato._id) {
          return <EditableRowTrato key={`new-item-${index}`} />;
        } else {
          return (
            <RenderTratos
              key={trato._id}
              trato={trato}
              shadows={shadows}
              index={index}
              handleSelect={handleSelect}
              isMobile={isMobile}
            />
          );
        }
      })}
    </TableBody>
  );
}
