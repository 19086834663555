import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { DashboardContext } from "../Context/DashboardContext";
import { Box, Chip, Typography, useTheme } from "@mui/material";
import { getBicolorTable, getWidth } from "../../../../Config/reuserFunctions";

export default function TableProspectos() {
  const { dashQuery } = React.useContext(DashboardContext);
  const { prospectos } = dashQuery.data.obtenerDataDashboard;
  const theme = useTheme();

  return (
    <Paper variant="outlined" sx={{
      my: 2,
      //borderColor: theme.palette.primary.main,
      position: "relative",
    }}
  >
    <Box
      sx={{
        position: "absolute",
        backgroundColor: theme.palette.primary.main,
        zIndex: 1,
        top: -25,
        left: 0,
        px: 2,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
      }}
    >
      <Typography variant="subtitle1" color="white">
        <b>Prospectos por forma de Atracción</b>
      </Typography>
    </Box>
      <TableContainer sx={{ height: "60vh" }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: 150 }}>Nombre Contacto</TableCell>
              <TableCell padding="checkbox" sx={{ minWidth: 120 }}>
                Telefono
              </TableCell>
              <TableCell sx={{ minWidth: 110 }}>Correo</TableCell>
              <TableCell padding="checkbox" align="center">
                Status
              </TableCell>
              <TableCell sx={{ minWidth: 140 }}>Forma de Atracción</TableCell>
              <TableCell sx={{ minWidth: 140 }}>Campaña</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {prospectos.map((forma) => (
              <React.Fragment key={forma._id}>
                <TableRow>
                  <TableCell
                    colSpan={17}
                    sx={{
                      position: "relative",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        position: "sticky",
                        left: 15,
                        width: getWidth(`${forma.formaAtraccion}`),
                      }}
                    >
                      <strong>{`${forma.formaAtraccion}`}</strong>
                    </Typography>
                  </TableCell>
                </TableRow>
                {forma.contactos.map((contacto, index) => (
                  <TableRow key={contacto._id} style={getBicolorTable(theme, index)}>
                    <TableCell sx={{ minWidth: getWidth(contacto.nombre) }}>
                      {contacto.nombre}
                    </TableCell>
                    <TableCell sx={{ minWidth: getWidth(contacto.telefono) }}>
                      {contacto.telefono}
                    </TableCell>
                    <TableCell sx={{ minWidth: getWidth(contacto.correo) }}>
                      {contacto.correo}
                    </TableCell>
                    <TableCell align="center">
                      <Chip
                        label={contacto.statusContacto}
                        //color={getColorcontacto("prioridad", contacto.prioridad)}
                        size="small"
                      />
                    </TableCell>
                    <TableCell
                      sx={{ minWidth: getWidth(contacto.formaAtraccion) }}
                    >
                      {contacto.formaAtraccion}
                    </TableCell>
                    <TableCell sx={{ minWidth: getWidth(contacto.campaign) }}>
                      {contacto.campaign}
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
