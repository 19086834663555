import { gql } from "@apollo/client";

export const OBTENER_EMBUDOS = gql`
  query ObtenerEmbudo($idEmpresa: ID!) {
    obtenerEmbudo(idEmpresa: $idEmpresa) {
      _id
      fase
      posicion
      idEmpresa
    }
  }
`;
