import { gql } from "@apollo/client";

export const CREAR_TIPO_CONTACTO = gql`
  mutation CrearTipoContacto($idEmpresa: ID!, $tipoContacto: String!) {
    crearTipoContacto(idEmpresa: $idEmpresa, tipoContacto: $tipoContacto) {
      message
    }
  }
`;
export const ACTUALIZAR_TIPO_CONTACTO = gql`
  mutation ActualizarTipoContacto(
    $idEmpresa: ID!
    $idTipoContacto: ID!
    $tipoContacto: String!
  ) {
    actualizarTipoContacto(
      idEmpresa: $idEmpresa
      idTipoContacto: $idTipoContacto
      tipoContacto: $tipoContacto
    ) {
      message
    }
  }
`;
export const ELIMINAR_TIPO_CONTACTO = gql`
  mutation EliminarTipoContacto($idTipoContacto: ID!) {
    eliminarTipoContacto(idTipoContacto: $idTipoContacto) {
      message
    }
  }
`;
