import React from "react";
import TableTratos from "./TableTratos";
import TableByFase from "./TableByFase";
import TableProspectos from "./TableProspectos";
import { Box, Typography } from "@mui/material";
import { DashboardContext } from "../Context/DashboardContext";

export default function HistorialTratos() {
  const { table } = React.useContext(DashboardContext);

  switch (table) {
    case "tratos":
      return <TableTratos />;
    case "byFase":
      return <TableByFase />;
    case "prospectos":
      return <TableProspectos />;

    default:
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "20vh",
          }}
        >
          <Typography color="GrayText">No hay datos seleccionados</Typography>
        </Box>
      );
  }
}
