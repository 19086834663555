import React from "react";
import { Box, Button, Paper, Typography, useTheme } from "@mui/material";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { DashboardContext } from "../Context/DashboardContext";
import { RocketLaunch } from "@mui/icons-material";

const renderCustomizedLabel = (getValues) => {
  const { x, y, percent } = getValues;
  return (
    <text
      x={x}
      y={y}
      fill={"#9e9e9e"}
      //TypographyAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Box
        bgcolor="background.paper"
        sx={{ p: 1, borderRadius: 2, opacity: 0.7 }}
      >
        {payload.map(({ name, value }, index) => {
          return (
            <Typography key={index} variant="caption">
              <b>{`${name}: ${value}`}</b>
            </Typography>
          );
        })}
      </Box>
    );
  }
  return null;
};

export default function GanadosPerdidos() {
  const { dashQuery, handleSetTable, table } = React.useContext(
    DashboardContext
  );
  const theme = useTheme();
  const { tratos } = dashQuery.data.obtenerDataDashboard;
  const { byGroup } = tratos;

  const dataGraph = [
    {
      name: "En trato",
      value: byGroup?.enTrato,
      color: theme.palette.primary.light,
    },
    {
      name: "Perdidos",
      value: byGroup?.perdidos,
      color: theme.palette.error.light,
    },
    {
      name: "Olvidados",
      value: byGroup?.olvidadas,
      color: theme.palette.warning.light,
    },
    {
      name: "Ganados",
      value: byGroup?.ganados,
      color: theme.palette.secondary.light,
    },
  ];

  return (
    <Paper variant="outlined">
      <Button
        fullWidth
        disableElevation
        size="small"
        sx={{ textTransform: "none" }}
        startIcon={<RocketLaunch />}
        variant={table === "tratos" ? "contained" : "text"}
        onClick={() => handleSetTable("tratos")}
      >
        Ganados y perdidos
      </Button>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "35vh",
          cursor: "pointer",
        }}
      >
        <ResponsiveContainer width="100%" height={230}>
          <PieChart>
            <Pie
              data={dataGraph}
              innerRadius={50}
              outerRadius={85}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
              label={renderCustomizedLabel}
            >
              {dataGraph.map(({ color }, index) => (
                <Cell key={`cell-${index}`} fill={color} />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
          </PieChart>
        </ResponsiveContainer>
      </Box>
    </Paper>
  );
}
