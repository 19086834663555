import * as React from "react";
import Chip from "@mui/material/Chip";
import TableCell from "@mui/material/TableCell";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Rating from "@mui/material/Rating";
import TableRow from "@mui/material/TableRow";
import {
  formatoFechaCorta,
  formatoMexico,
  getBicolorTable,
  getWidth,
} from "../../../../../Config/reuserFunctions";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import RenderActividades from "./RenderActividades";
import ActividadCtxProvider from "../../../Actividades/Context/ActividadContext";
import { Box, Checkbox, Typography } from "@mui/material";
import { TratosContext } from "../../Context/TratosContext";
import ChipUsuario from "../../../../../Components/Avatar/ChipUsuario";
import CustomProgress from "../CustomProgress";
import { DriveFileRenameOutline } from "@mui/icons-material";
import TratoDetails from "../Details";
import { SOCIAL_NETWORKS } from "../../../../../Config/constantes";
import { SocialIcon } from "react-social-icons";
import ChipStatus from "../BoardMode/ChipStatus";
import ChipPrioridad from "../BoardMode/ChipPrioridad";

export default function RenderTratos({
  trato,
  shadows,
  index,
  handleSelect,
  isMobile,
}) {
  const {
    setNewItem,
    itemsSelected,
    setVisiblePara,
    queryRefetch,
  } = React.useContext(TratosContext);
  const [open, setOpen] = React.useState(false);
  const checked = itemsSelected.find((res) => res._id === trato._id);

  const onClickRow = (e) => {
    const { target } = e;
    const focusOn = target.attributes["idonfocus"]?.value;
    setNewItem({ ...trato, focusOn });
    setVisiblePara(trato.visiblePara);
  };

  const getFormaContacto = (value) => {
    const forma = SOCIAL_NETWORKS.filter((social) => social.name === value);

    if (!forma.length) return null;
    const { name, icon } = forma[0];

    return (
      <Chip
        label={
          <Typography
            idonfocus="formaContacto-trato-texfield"
            sx={{ fontSize: 12, fontWeight: "500" }}
          >
            {name}
          </Typography>
        }
        size="small"
        icon={
          icon ? (
            <SocialIcon
              network={icon}
              style={{
                width: 24,
                height: 24,
              }}
            />
          ) : null
        }
        sx={{
          backgroundColor: "transparent",
        }}
      />
    );
  };

  return (
    <>
      <TableRow
        selected={open}
        onDoubleClick={(e) => onClickRow(e)}
        sx={{
          height: "48px",
          bgcolor: (theme) => getBicolorTable(theme, index),
        }}
      >
        <TableCell
          padding="checkbox"
          sx={{
            position: "sticky",
            left: 0,
            zIndex: 2,
            bgcolor: (theme) => getBicolorTable(theme, index),
          }}
        >
          <Checkbox
            onChange={(e) => handleSelect(e.target.checked, trato)}
            onClick={(e) => e.stopPropagation()}
            checked={checked ? true : false}
          />
        </TableCell>
        <TableCell
          className={isMobile ? shadows.start : ""}
          padding="checkbox"
          sx={{
            position: "sticky",
            left: 52,
            zIndex: 2,
            bgcolor: (theme) => getBicolorTable(theme, index),
          }}
        >
          <TratoDetails data={trato}>
            <IconButton size="small">
              <DriveFileRenameOutline />
            </IconButton>
          </TratoDetails>
        </TableCell>
        <TableCell
          idonfocus="nombreTrato-trato-texfield"
          className={!isMobile ? shadows.start : ""}
          sx={{
            position: { xs: "static", sm: "sticky" },
            left: 114,
            zIndex: 1,
            //minWidth: getWidth(trato.nombreTrato),
            padding: 0,
            bgcolor: (theme) => getBicolorTable(theme, index),
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                bgcolor: (theme) => getBicolorTable(theme, index),
              }}
            >
              <IconButton
                idonfocus="expand row"
                size="small"
                sx={{ mr: 1 }}
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
              {trato.nombreTrato}
            </Box>
          </Box>
        </TableCell>
        <TableCell
          idonfocus="producto-trato-texfield"
          sx={{ minWidth: getWidth(trato.producto) }}
        >
          {trato.producto}
        </TableCell>
        <TableCell
          idonfocus="contacto-trato-texfield"
          sx={{ minWidth: getWidth(trato.contacto) }}
        >
          {trato.contacto}
        </TableCell>
        <TableCell
          idonfocus="embudo-trato-texfield"
          sx={{ minWidth: getWidth(trato.embudo) }}
        >
          {trato.embudo}
        </TableCell>
        <TableCell idonfocus="probabilidadCierre-trato-texfield">
          <CustomProgress value={trato.probabilidadCierre} />
        </TableCell>
        <TableCell idonfocus="prioridad-trato-texfield" align="center">
          <ChipPrioridad trato={trato} refetch={queryRefetch} />
        </TableCell>
        <TableCell idonfocus="status-trato-texfield" align="center">
          {trato.status ? (
            <ChipStatus trato={trato} refetch={queryRefetch} />
          ) : null}
        </TableCell>
        <TableCell
          idonfocus="formaContacto-trato-texfield"
          sx={{ minWidth: getWidth(trato.formaContacto) }}
        >
          {getFormaContacto(trato.formaContacto)}
        </TableCell>
        <TableCell
          idonfocus="valor-trato-texfield"
          sx={{
            minWidth: getWidth(`$${formatoMexico(trato.valor)}`),
          }}
        >{`$${formatoMexico(trato.valor)}`}</TableCell>
        <TableCell
          idonfocus="moneda-trato-texfield"
          sx={{
            minWidth: getWidth(`${trato.moneda}`),
          }}
        >{`${trato.moneda}`}</TableCell>
        <TableCell idonfocus="interes-trato-texfield">
          <Rating size="small" value={trato.interes} readOnly />
        </TableCell>
        <TableCell
          idonfocus="campaign-trato-texfield"
          sx={{ minWidth: getWidth(trato.campaign) }}
        >
          {trato.campaign}
        </TableCell>
        <TableCell
          idonfocus="unidadNegocio-trato-texfield"
          sx={{ minWidth: getWidth(trato.unidadNegocio) }}
        >
          {trato.unidadNegocio}
        </TableCell>
        <TableCell
          idonfocus="propietario-trato-texfield"
          sx={{ minWidth: getWidth(trato.propietario) }}
        >
          {trato.propietario}
        </TableCell>
        <TableCell
          idonfocus="usuarioCierre-trato-texfield"
          sx={{ minWidth: getWidth(trato.usuarioCierre) }}
        >
          {trato.usuarioCierre}
        </TableCell>
        <TableCell
          idonfocus="fechaEstimadaCierre-trato-texfield"
          sx={{ minWidth: getWidth(trato.fechaEstimadaCierre) }}
        >
          {formatoFechaCorta(trato.fechaEstimadaCierre)}
        </TableCell>
        <TableCell idonfocus="fechaTrato-trato-texfield">
          {formatoFechaCorta(trato.fechaTrato)}
        </TableCell>
        <TableCell idonfocus="visiblePara-trato-texfield">
          <Box sx={{ display: "flex" }}>
            {trato.visiblePara.map(({ nombreCompleto }, index) => (
              <ChipUsuario
                key={index}
                label={nombreCompleto}
                size="small"
                idonfocus="visiblePara-trato-texfield"
              />
            ))}
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          sx={{
            paddingBottom: 0,
            paddingTop: 0,
            left: 0,
            border: "none",
          }}
          colSpan={19}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <ActividadCtxProvider>
              <RenderActividades trato={trato} />
            </ActividadCtxProvider>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
