import React from "react";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import ListItemButton from "@mui/material/ListItemButton";
import { MainContext } from "../../../../../Context/MainCtx";
import { useMutation } from "@apollo/client";
import { getContrastColor } from "../../../../../Config/reuserFunctions";

import {
  Box,
  CircularProgress,
  List,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { ACTUALIZAR_CONTACTO } from "../graphql/mutations";

export default function ChipStatusContacto({
  contacto,
  setContacto,
  refetch,
  query = [],
  isOnchange = false,
}) {
  const { snackMessage, tokenDecoded } = React.useContext(MainContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(null);
  const [status, setStatus] = React.useState(
    contacto?.statusContactoPopulated
      ? contacto?.statusContactoPopulated[0]
      : null
  );
  const open = Boolean(anchorEl);
  const [actualizarContacto] = useMutation(ACTUALIZAR_CONTACTO);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateStatus = async (status) => {
    try {
      handleClose();
      if (setContacto) {
        setContacto((st) => ({
          ...st,
          statusContacto: status.status,
          idStatusContacto: status._id,
        }));
      }
      setStatus((st) => ({
        ...st,
        status: status.status,
        color: status.color,
      }));
      if (isOnchange) return;
      setLoading(true);
      const {
        statusContactoPopulated,
        _id,
        idEmpresa,
        fechaRegistro,
        actividades,
        tratos,
        ...input
      } = contacto;
      await actualizarContacto({
        variables: {
          idContacto: contacto._id,
          idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
          input: {
            ...input,
            statusContacto: status.status,
            idStatusContacto: status._id,
          },
        },
      });
      refetch();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
      console.log(err.networkError?.result);
    }
  };

  return (
    <>
      <Chip
        id="custom-chip-contacto"
        aria-controls={open ? "contacto-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        component={"span"}
        label={status?.status || "Selecciona uno"}
        size="small"
        icon={
          loading ? (
            <CircularProgress sx={{ color: "white!important" }} size={16} />
          ) : null
        }
        sx={{
          fontWeight: "600",
          bgcolor: status?.color,
          color: getContrastColor(status?.color),
          ":hover": { opacity: 0.8, bgcolor: status?.color },
        }}
        onClick={handleClick}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.3))",
              mt: 1.5,
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                left: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
      >
        <List disablePadding dense sx={{ minWidth: 150 }}>
          {query.map((res, index) => (
            <ListItemButton
              key={index}
              onClick={() => updateStatus(res)}
              selected={res.status === status?.status}
            >
              <ListItemIcon sx={{ minWidth: 20, mr: 1 }}>
                <Box
                  sx={{
                    bgcolor: res.color,
                    height: 15,
                    width: 15,
                    borderRadius: 1,
                  }}
                />
              </ListItemIcon>
              <ListItemText>{res.status}</ListItemText>
            </ListItemButton>
          ))}
        </List>
      </Menu>
    </>
  );
}
