import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import LoadingAutocomplete from "./LoadingAutocomplete";
import ErrorAutocomplete from "./ErrorAutocomplete";

export default function AutoCompleteInput({
  renderData = [],
  value = "",
  label = "",
  icon = null,
  loading = false,
  error = null,
  required = false,
  disabled = false,
  addButton = null,
  onChange = null,
  isOptionEqualToValue = null,
  getOptionLabel = null,
}) {
  const [open, setOpen] = React.useState(false);
  if (loading) return <LoadingAutocomplete label={label} />;
  if (error) return <ErrorAutocomplete label={label} />;

  return (
    <Autocomplete
      autoHighlight
      openOnFocus
      onBlur={() => setOpen(false)}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      options={renderData}
      disabled={disabled}
      value={value || null}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      renderInput={(params) => (
        <TextField
          {...params}
          margin="dense"
          size="small"
          label={label}
          required={required}
          InputProps={{
            ...params.InputProps,
            startAdornment: icon ? (
              <InputAdornment position="start">{icon}</InputAdornment>
            ) : null,
            endAdornment: (
              <React.Fragment>
                {addButton ? addButton : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(props) => (
        <li {...props} style={{ fontSize: 13 }}>
          {props.key}
        </li>
      )}
      onChange={onChange}
    />
  );
}
