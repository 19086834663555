import React from "react";
import { Box, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import SearchContacto from "./Actions/SearchContacto";
import ContactosComponent from "./Contactos";
import { ViewColumn, ViewList } from "@mui/icons-material";
import ContactosCtxProvider, {
  ContactosContext,
} from "./Context/ContactoContext";
import DeleteManyContacto from "./Actions/DeleteMany";
import AddNewItem from "./Actions/AddNewItem";

export default function Contactos() {
  return (
    <Box>
      <ContactosCtxProvider>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ mb: 1, position: { sm: "absolute" }, top: 60, right: 24 }}
          bgcolor="background.paper"
        >
          <ChangeModeView />
          <SearchContacto />
          <AddNewItem />
          <DeleteManyContacto />
        </Stack>
        <ContactosComponent />
      </ContactosCtxProvider>
    </Box>
  );
}

const ChangeModeView = () => {
  const {
    mode,
    setMode,
    newItem,
    setNewItem,
    setItemsSelected,
    setIndeterminated,
  } = React.useContext(ContactosContext);

  const handleSetMode = () => {
    setMode(mode === "table" ? "board" : "table");
    if (newItem) setNewItem(null);
    setItemsSelected([]);
    setIndeterminated(false);
  };
  return (
    <Button
      color="inherit"
      size="small"
      variant="outlined"
      disableElevation
      onClick={handleSetMode}
      sx={{
        minWidth: 40,
        color: (theme) => theme.palette.action.active,
        borderColor: (theme) => theme.palette.action.disabled,
      }}
    >
      {mode === "table" ? <ViewColumn /> : <ViewList />}
    </Button>
  );
};
