import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Checkbox, TableSortLabel } from "@mui/material";
import { StatusContext } from "../Context/StatusActividadContext";

const headCells = [
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
    sortable: true,
  },
  {
    id: "color",
    numeric: false,
    disablePadding: true,
    label: "Color",
    sortable: false,
  },
];

export default function TableHeadStatus({
  handleCheckAll,
  handleSort,
  order,
  orderBy,
}) {
  const { itemsSelected, indeterminated } = React.useContext(StatusContext);

  return (
    <TableHead sx={{ height: 42 }}>
      <TableRow>
        <TableCell padding="checkbox" align="left">
          <Checkbox
            indeterminate={indeterminated}
            checked={itemsSelected.length > 0 && !indeterminated}
            onChange={(e) => handleCheckAll(e.target.checked)}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={{ fontWeight: "bold", }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => handleSort(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
        <TableCell padding="checkbox" />
      </TableRow>
    </TableHead>
  );
}
