import React from "react";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import ListItemButton from "@mui/material/ListItemButton";
import { MainContext } from "../../../../../Context/MainCtx";
import { ACTUALIZAR_STATUS_TRATO } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { getColorTratoStatus } from "../../../../../Config/reuserFunctions";
import { Handshake, ThumbDown, ThumbUp } from "@mui/icons-material";
import { STATUS_TRATO } from "../../../../../Config/constantes";
import {
  CircularProgress,
  List,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

export default function ChipStatus({
  trato,
  setTrato = null,
  refetch,
  onchange = null,
  tabIndex = null,
}) {
  const { snackMessage, tokenDecoded } = React.useContext(MainContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(null);
  const open = Boolean(anchorEl);
  const [actualizarStatusTrato] = useMutation(ACTUALIZAR_STATUS_TRATO);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateStatus = async (status) => {
    try {
      handleClose();
      setLoading(true);
      if (setTrato) setTrato((st) => ({ ...st, status }));
      await actualizarStatusTrato({
        variables: {
          idTrato: trato._id,
          input: {
            status,
            usuarioCierre: tokenDecoded.nombreCompleto,
            idUsuarioCierre: tokenDecoded._id
          },
        },
      });
      refetch();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
      console.log(err.networkError?.result);
    }
  };

  const getIconTrato = ({ status, sx = null }) => {
    switch (status) {
      case "EN TRATO":
        return (
          <Handshake
            color={status === trato.status ? "primary" : "inherit"}
            sx={sx}
          />
        );
      case "GANADO":
        return (
          <ThumbUp
            color={status === trato.status ? "primary" : "inherit"}
            sx={sx}
          />
        );
      case "PERDIDO":
        return (
          <ThumbDown
            color={status === trato.status ? "primary" : "inherit"}
            sx={sx}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Chip
        tabIndex={tabIndex}
        id="custom-chip"
        aria-controls={open ? "status-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        component={"span"}
        label={trato.status}
        size="small"
        icon={
          loading ? (
            <CircularProgress sx={{ color: "white!important" }} size={16} />
          ) : (
            getIconTrato({ status: trato.status, sx: { color: "white" } })
          )
        }
        sx={{
          mr: 0.5,
          //height: 20,
          fontWeight: "600",
          bgcolor: (theme) => getColorTratoStatus(trato.status, theme),
          ":hover": {
            bgcolor: (theme) => getColorTratoStatus(trato.status, theme),
          },
          color: "white",
        }}
        onClick={handleClick}
      />
      <Menu
        id="status-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "custom-chip",
        }}
      >
        <List disablePadding dense>
          {STATUS_TRATO.map((status, index) => {
            if (STATUS_TRATO.length === index + 1) return null;
            return (
              <ListItemButton
                key={index}
                onClick={() =>
                  onchange ? onchange("status", status) : updateStatus(status)
                }
                selected={status === trato.status}
              >
                <ListItemIcon>{getIconTrato({ status })}</ListItemIcon>
                <ListItemText>{status}</ListItemText>
              </ListItemButton>
            );
          })}
        </List>
      </Menu>
    </>
  );
}
