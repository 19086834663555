import { useTheme } from "@mui/material";
import React from "react";

const ComodyIcon = ({
  size = 5,
  color = "default",
  className = "",
  style = {},
}) => {
  let hexcolor1;
  /* let hexcolor2; */
  const theme = useTheme();

  switch (color) {
    case "primary":
      hexcolor1 = theme.palette.primary.main;
      /* hexcolor2 = theme.palette.primary.main; */
      break;
    case "secondary":
      hexcolor1 = theme.palette.secondary.main;
      /* hexcolor2 = theme.palette.secondary.main; */
      break;
    case "comodyBlue":
      hexcolor1 = theme.palette.comodyBlue;
      /* hexcolor2 = theme.palette.comodyBlue; */
      break;
    case "twoColor":
      hexcolor1 = theme.palette.primary.main;
      /* hexcolor2 = theme.palette.secondary.main; */
      break;
    default:
      hexcolor1 = "#FFFFFF";
      /* hexcolor2 = "#FFFFFF"; */
      break;
  }

  return (
    <svg
      style={style}
      height="100%"
      strokeMiterlimit="10"
      version="1.1"
      viewBox="0 0 530.02 530.02"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsvectornator="http://vectornator.io"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={`${size}rem`}
      className={className}
    >
      <defs />
      <clipPath id="ArtboardFrame3">
        <rect height="530.02" width="530.02" x="0" y="0" />
      </clipPath>
      <g
        clipPath="url(#ArtboardFrame3)"
        id="Untitled-3"
        vectornatorlayername="Untitled-3"
      >
        <g opacity="1" vectornatorlayername="path">
          <clipPath clipRule="nonzero" id="ClipPath3">
            <path d="M0 1.95313e-05L2690.45 1.95313e-05L2690.45 530.02L0 530.02L0 1.95313e-05Z" />
          </clipPath>
          <g clipPath="url(#ClipPath3)">
            <path
              d="M443.296 318.168L366.496 296.971C360.938 295.437 355.158 298.4 353.087 303.781C338.208 342.423 300.823 369.898 256.972 370.114C199.942 370.396 153.119 323.964 152.937 266.933C152.754 209.668 199.084 163.153 256.323 163.081C299.891 163.025 337.202 189.896 352.524 227.986C354.658 233.292 360.508 236.074 365.995 234.465L442.445 212.038C448.623 210.226 451.976 203.586 449.81 197.522C421.733 118.933 347.12 62.482 259.191 61.335C146.623 59.867 52.3066 151.927 51.1836 264.499C50.8166 301.367 60.1806 336.007 76.8436 366.033L52.0436 440.751C46.0116 458.922 63.3146 476.18 81.4696 470.103L155.457 445.336C185.351 462.267 219.903 471.925 256.715 471.879C346.936 471.764 423.478 413.46 450.909 332.518C452.969 326.439 449.483 319.875 443.296 318.168"
              fill={hexcolor1}
              fillRule="nonzero"
              opacity="1"
              stroke="none"
              vectornatorlayername="path"
            />
            <path
              d="M294.49 266.55C294.517 287.643 277.439 304.764 256.347 304.79C235.254 304.817 218.134 287.739 218.107 266.647C218.08 245.554 235.157 228.434 256.25 228.406C277.343 228.38 294.463 245.457 294.49 266.55"
              fill={hexcolor1}
              fillRule="nonzero"
              opacity="1"
              stroke="none"
              vectornatorlayername="path"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ComodyIcon ;
