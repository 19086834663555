import { gql } from "@apollo/client";

export const OBTENER_TIPO_ACTIVIDAD = gql`
  query ObtenerTipoActividades($idEmpresa: ID!) {
    obtenerTipoActividades(idEmpresa: $idEmpresa) {
      _id
      tipoActividad
      accion
      icono
      idEmpresa
    }
  }
`;
