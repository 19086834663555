import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import DialogTitle from "@mui/material/DialogTitle";
import { Add, ArrowBack } from "@mui/icons-material";
import EditableTextInput from "../../../../../Components/InputsForms/EditableTextInput";
import EditableAutoComplete from "../../../../../Components/InputsForms/EditableAutoComlete";
import { Done } from "@mui/icons-material";
import ChipStatusContacto from "../Actions/ChipStatusContacto";
import { CREAR_CONTACTO } from "../graphql/mutations";
import { useMutation } from "@apollo/client";
import { MainContext } from "../../../../../Context/MainCtx";
import { ContactosContext } from "../Context/ContactoContext";
import NuevoTipoC from "../../TipoContacto/Actions/NuevoTipoC";
import NuevaEtiqueta from "../../Etiquetas/Actions/NuevaEtiqueta";
import { isMobile } from "../../../../../Config/constantes";

export default function NuevoContacto({
  external = false,
  refetchExternal = null,
  externalClose = null,
}) {
  const {
    contacto,
    setContacto,
    queryCats,
    queryRefetch,
    initial_state_contacto,
  } = React.useContext(ContactosContext);
  const { snackMessage, tokenDecoded } = React.useContext(MainContext);
  const [loading, setLoading] = React.useState(false);
  const [crearContacto] = useMutation(CREAR_CONTACTO);
  const [open, setOpen] = React.useState(false);

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
    setContacto((st) => ({
      ...st,
      propietario: tokenDecoded.nombreCompleto,
      idPropietario: tokenDecoded._id,
    }));
  };

  const handleClose = (event) => {
    setOpen(false);
    setContacto(initial_state_contacto);
    if (externalClose) externalClose(event);
  };

  const autocompleteChange = (values) => {
    const { name, value, id, idName } = values;
    let dataUpdated = {
      ...contacto,
      [name]: value,
    };
    if (id && idName) {
      dataUpdated = {
        ...contacto,
        [name]: value,
        [idName]: id,
      };
    }
    setContacto(dataUpdated);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContacto((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleSaveContacto = async (e) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      if (loading) return;
      if (!contacto.statusContacto) {
        snackMessage({
          message: "Selecciona un STATUS para el contacto",
          variant: "warning",
        });
        return;
      }
      setLoading(true);
      let { ...input } = contacto;
      delete input.new;
      await crearContacto({
        variables: {
          idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
          input,
        },
      });
      if (external && refetchExternal) refetchExternal();
      queryRefetch();
      setLoading(false);
      handleClose(e);
    } catch (err) {
      console.log(err);
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      {external ? (
        <IconButton onClick={handleOpen} size="small" color="primary">
          <Add />
        </IconButton>
      ) : (
        <Button
          onClick={handleOpen}
          color="primary"
          variant="contained"
          startIcon={ isMobile ? null : <Add />}
          size="small"
          disableElevation
          sx={{ textTransform: "none", minWidth: 40 }}
        >
          {isMobile ? <Add fontSize="small" /> : "Nuevo"}
        </Button>
      )}
      <Drawer
        anchor={"right"}
        open={open}
        onClose={handleClose}
        ModalProps={{
          componentsProps: {
            backdrop: {
              sx: { background: "none" },
            },
          },
        }}
        sx={{
          zIndex: 1200,
        }}
        PaperProps={{
          elevation: 1,
          sx: { width: { xs: "100vw", md: "50vw" }, overflowY: "auto" },
        }}
      >
        <Toolbar variant="dense" />
        <DialogTitle component="div">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <IconButton onClick={handleClose}>
                <ArrowBack />
              </IconButton>
              <Typography variant="h6" color="gray">
                Nuevo Contacto
              </Typography>
            </Stack>
            <Button
              size="small"
              variant="contained"
              disableElevation
              sx={{ textTransform: "none" }}
              startIcon={
                loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <Done />
                )
              }
              type="submit"
              form="formContacto"
            >
              Guardar
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ height: "80vh" }}>
            <form id="formContacto" onSubmit={handleSaveContacto}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <Typography variant="body2" color="gray">
                    <span style={{ color: "red" }}>*</span>Nombre
                  </Typography>
                  <EditableTextInput
                    tabIndex={1}
                    fullWidth
                    required
                    placeholder="Agregar nombre de contacto"
                    name="nombre"
                    value={contacto.nombre}
                    loading={loading}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body2" color="gray">
                    <span style={{ color: "red" }}>*</span>Telefono
                  </Typography>
                  <EditableTextInput
                    tabIndex={2}
                    fullWidth
                    required
                    placeholder="Agregar telefono"
                    name="telefono"
                    value={contacto.telefono}
                    loading={loading}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body2" color="gray">
                    Correo
                  </Typography>
                  <EditableTextInput
                    tabIndex={3}
                    fullWidth
                    placeholder="Agregar correo electrónico"
                    name="correo"
                    value={contacto.correo}
                    loading={loading}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body2" color="gray">
                    <span style={{ color: "red" }}>*</span>Status
                  </Typography>
                  <ChipStatusContacto
                    contacto={contacto}
                    setContacto={setContacto}
                    refetch={queryRefetch}
                    query={queryCats.data?.statusContactos}
                    isOnchange
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" color="gray">
                    <span style={{ color: "red" }}>*</span>Tipo de contacto
                  </Typography>
                  <EditableAutoComplete
                    tabIndex={4}
                    placeholder="Añadir tipo de contacto"
                    renderData={queryCats.data?.tiposContactos}
                    value={contacto.tipoContacto}
                    loading={loading}
                    loadingQuery={queryCats.loading}
                    errorQuery={queryCats.error}
                    required
                    addButton={
                      <NuevoTipoC
                        external={true}
                        refetchExternal={queryCats.refetch}
                      />
                    }
                    loadingIcon={<CircularProgress size={20} color="primary" />}
                    onChange={(_, option) =>
                      autocompleteChange({
                        name: "tipoContacto",
                        idName: "idTipoContacto",
                        value: option?.tipoContacto || "",
                        id: option?._id || "",
                      })
                    }
                    isOptionEqualToValue={({ tipoContacto }, value) =>
                      tipoContacto === value
                    }
                    getOptionLabel={(option) =>
                      option.tipoContacto || option || null
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" color="gray">
                    <span style={{ color: "red" }}>*</span>Etiqueta
                  </Typography>
                  <EditableAutoComplete
                    tabIndex={5}
                    placeholder="Añadir etiqueta"
                    renderData={queryCats.data?.etiquetas}
                    value={contacto.etiqueta}
                    loading={loading}
                    loadingQuery={queryCats.loading}
                    errorQuery={queryCats.error}
                    required
                    addButton={
                      <NuevaEtiqueta
                        external={true}
                        refetchExternal={queryCats.refetch}
                      />
                    }
                    loadingIcon={<CircularProgress size={20} color="primary" />}
                    onChange={(_, option) =>
                      autocompleteChange({
                        name: "etiqueta",
                        idName: "idEtiqueta",
                        value: option?.etiqueta || "",
                        id: option?._id || "",
                      })
                    }
                    isOptionEqualToValue={({ etiqueta }, value) =>
                      etiqueta === value
                    }
                    getOptionLabel={(option) =>
                      option.etiqueta || option || null
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" color="gray">
                    Organización
                  </Typography>
                  <EditableTextInput
                    tabIndex={6}
                    fullWidth
                    placeholder="Agregar organización"
                    name="organizacion"
                    value={contacto.organizacion}
                    loading={loading}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body2" color="gray">
                    Forma de Atracción
                  </Typography>
                  <EditableAutoComplete
                    tabIndex={7}
                    placeholder="Añadir forma de atracción"
                    renderData={queryCats.data?.formasAtraccion}
                    value={contacto.formaAtraccion}
                    loading={loading}
                    loadingQuery={queryCats.loading}
                    errorQuery={queryCats.error}
                    addButton={
                      <NuevoTipoC
                        external={true}
                        refetchExternal={queryCats.refetch}
                      />
                    }
                    loadingIcon={<CircularProgress size={20} color="primary" />}
                    onChange={(_, option) =>
                      autocompleteChange({
                        name: "formaAtraccion",
                        idName: "idFormaAtraccion",
                        value: option?.formaAtraccion || "",
                        id: option?._id || "",
                      })
                    }
                    isOptionEqualToValue={({ formaAtraccion }, value) =>
                      formaAtraccion === value
                    }
                    getOptionLabel={(option) =>
                      option.formaAtraccion || option || null
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body2" color="gray">
                    Campaña
                  </Typography>
                  <EditableAutoComplete
                    tabIndex={8}
                    placeholder="Añadir campaña"
                    renderData={queryCats.data?.campaigns}
                    value={contacto.campaign}
                    loading={loading}
                    loadingQuery={queryCats.loading}
                    errorQuery={queryCats.error}
                    addButton={
                      <NuevoTipoC
                        external={true}
                        refetchExternal={queryCats.refetch}
                      />
                    }
                    loadingIcon={<CircularProgress size={20} color="primary" />}
                    onChange={(_, option) =>
                      autocompleteChange({
                        name: "campaign",
                        idName: "idCampaign",
                        value: option?.nombre || "",
                        id: option?._id || "",
                      })
                    }
                    isOptionEqualToValue={({ nombre }, value) =>
                      nombre === value
                    }
                    getOptionLabel={(option) => option.nombre || option || null}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" color="gray">
                    Recomendado por
                  </Typography>
                  <EditableTextInput
                    tabIndex={9}
                    fullWidth
                    placeholder="Agregar nombre de quién recomendó"
                    name="recomendadoPor"
                    value={contacto.recomendadoPor}
                    loading={loading}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </form>
          </Box>
        </DialogContent>
      </Drawer>
    </div>
  );
}
