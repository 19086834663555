import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Divider, Grid, Toolbar } from "@mui/material";
import { listPrimary, listSecondary } from "../listItems";
import CustomIconComponent from "../../../../Components/CustomIconComponent";

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  margin: theme.spacing(2),
  backgroundColor: theme.palette.divider,
  borderRadius: 3,
}));

export default function ListMenuResponsive({
  open,
  setOpen,
  pathname,
  handleClickSecodanry,
}) {
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
      slotProps={{
        root: {
          style: { zIndex: 1000 },
        },
      }}
      PaperProps={{
        sx: {
          height: `70%`,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          zIndex: 0,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Puller />
      </Box>
      <Box
        sx={{
          px: 2,
          pb: 2,
          mb: 2,
          height: "100%",
          overflow: "auto",
        }}
      >
        <Grid container spacing={1}>
          {listPrimary.map(({ title, url, icon }, index) => (
            <Grid key={`primary-${index}`} item xs={4}>
              <Button
                fullWidth
                sx={{
                  textTransform: "none",
                  color: (theme) =>
                    url === pathname
                      ? theme.palette.primary
                      : theme.palette.action.active,
                }}
                onClick={() => handleClickSecodanry({ title, url })}
              >
                <Box>
                  <CustomIconComponent iconName={icon} />
                  <Typography>{title}</Typography>
                </Box>
              </Button>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ py: 2 }}>
          <Divider />
        </Box>
        <List>
          {listSecondary.map(({ title, url, icon }, index) => (
            <ListItem key={`subitems-${index}`} disablePadding>
              <ListItemButton
                selected={url === pathname}
                onClick={() => handleClickSecodanry({ title, url })}
              >
                <ListItemIcon>
                  <CustomIconComponent
                    iconName={icon}
                    color={url === pathname ? "primary" : "inherit"}
                  />
                </ListItemIcon>
                <ListItemText primary={title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
      <Toolbar />
    </SwipeableDrawer>
  );
}
