import React from "react";
import { ProductoContext } from "../Context/ProductoContext";
import { CustomSearchTextField } from "../../../../../Components/CustomMuiComponents";
import { InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";

export default function SearchTratos() {
  const { filter, setFilter } = React.useContext(ProductoContext);

  const onChangeSearch = (search) => {
    setFilter((st) => ({ ...st, search }));
  };
  
  return (
    <CustomSearchTextField
      placeholder="Buscar por nombre o código"
      size="small"
      fullWidth
      variant="outlined"
      value={filter.search}
      onChange={(e) => onChangeSearch(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
}
