const listItems = [
  {
    title: "Contactos",
    url: "/contactos",
    icon: "PermContactCalendar", //"ContactPhone",
    expand: false, //true
    subItems: [
      /* {
        title: "Contactos",
        url: "/contactos/lista",
        icon: "PermContactCalendar",
      },
      {
        title: "Tipos",
        url: "/contactos/tipos",
        icon: "SupervisedUserCircle",
      },
      {
        title: "Status",
        url: "/contactos/status",
        icon: "HowToReg",
      },
      {
        title: "Etiquetas",
        url: "/contactos/etiquetas",
        icon: "Label",
      }, */
    ],
  },
  /* {
      title: "WhatsApp Chat",
      url: "/chat",
      icon: "WhatsApp",
      expand: false,
      subItems: [],
    }, */
  { title: "Tratos", url: "/tratos", icon: "Handshake" },
  {
    title: "Actividades",
    url: "/actividades",
    icon: "Event",
  },
  {
    title: "Reuniones",
    url: "/reuniones",
    icon: "Videocam",
  },
  {
    title: "Ventas",
    url: "/ventas",
    icon: "Paid",
    expand: false,
    subItems: [],
  },
  {
    title: "Dashboard",
    url: "/dashboard",
    icon: "TrendingUpOutlined",
    expand: false,
    subItems: [],
  },
  {
    title: "Configuraciones",
    url: "/settings",
    icon: "Settings",
    expand: true,
    subItems: [
      { title: "Productos", url: "/settings/productos", icon: "Stars" },
      /* {
          title: "Áreas",
          url: "/settings/areas",
          icon: "SupervisedUserCircle",
        }, */
      /* {
          title: "Status Actividad",
          url: "/settings/status-actividad",
          icon: "EditCalendar",
        }, */
      /* {
          title: "Embudos",
          url: "/settings/embudos",
          icon: "Label",
        }, */
      {
        title: "Formas de atracción",
        url: "/settings/forma-atraccion",
        icon: "AdUnits",
      },
      {
        title: "Formas de contacto",
        url: "/settings/forma-contacto",
        icon: "Forum",
      },
      {
        title: "Monedas",
        url: "/settings/monedas",
        icon: "CurrencyExchange",
      },
      {
        title: "Redes sociales",
        url: "/settings/redes-sociales",
        icon: "Interests",
      },
      {
        title: "Campañas",
        url: "/settings/campaigns",
        icon: "Campaign",
      },
      /* {
          title: "Tipo de actividades",
          url: "/settings/tipo-actividades",
          icon: "ManageHistory",
        }, */
      {
        title: "Unidades de negocio",
        url: "/settings/unidades-negocio",
        icon: "Store",
      },
      /* {
          title: "Tipos de usuarios",
          url: "/settings/tipos_usuario",
          icon: "Badge",
        }, */
      {
        title: "Usuarios",
        url: "/settings/usuarios",
        icon: "AccountCircle",
      },
      //{ title: "Visitas", url: "/settings/visitas", icon: "Handshake" },
    ],
  },
];

const responsiveListTab = (link = "/#") => { 
  let url = link;
  if (url === "/contactos" || url === "/tratos" || url === "/actividades") {
    url = "";
  }

  return [
    {
      title: "Contactos",
      url: "/contactos",
      icon: "PermContactCalendar", //"ContactPhone",
    },
    { title: "Tratos", url: "/tratos", icon: "Handshake" },
    {
      title: "Actividades",
      url: "/actividades",
      icon: "Event",
    },
    {
      title: "Más",
      url,
      icon: "Apps",
      noTab: true,
    },
  ];
};
const listPrimary = [
  {
    title: "Reuniones",
    url: "/reuniones",
    icon: "Videocam",
    main: true
  },
  {
    title: "Ventas",
    url: "/ventas",
    icon: "Paid",
    expand: false,
    main: true
  },
  {
    title: "Dashboard",
    url: "/dashboard",
    icon: "TrendingUpOutlined",
    expand: false,
    main: true
  },
];
const listSecondary = [
  { title: "Productos", url: "/settings/productos", icon: "Stars" },
  {
    title: "Formas de atracción",
    url: "/settings/forma-atraccion",
    icon: "AdUnits",
  },
  {
    title: "Formas de contacto",
    url: "/settings/forma-contacto",
    icon: "Forum",
  },
  {
    title: "Monedas",
    url: "/settings/monedas",
    icon: "CurrencyExchange",
  },
  {
    title: "Redes sociales",
    url: "/settings/redes-sociales",
    icon: "Interests",
  },
  {
    title: "Campañas",
    url: "/settings/campaigns",
    icon: "Campaign",
  },
  {
    title: "Unidades de negocio",
    url: "/settings/unidades-negocio",
    icon: "Store",
  },
  {
    title: "Usuarios",
    url: "/settings/usuarios",
    icon: "AccountCircle",
  },
];

export { listItems, responsiveListTab, listPrimary, listSecondary };
