import React from "react";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable } from "../../../../../Config/StrictModeDroppable";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Checkbox, Chip, ListItemSecondaryAction, alpha } from "@mui/material";
import { MainContext } from "../../../../../Context/MainCtx";
import { ACTUALIZAR_TIPO_CONTACTO } from "../graphql/mutations";
import { useMutation } from "@apollo/client";
import HorizontalScrollDrag from "./HorizontalScrollDrag";
import { ContactosContext } from "../Context/ContactoContext";
import ContactoDetails from "../Details";
import CustomAvatar from "../../../../../Components/Avatar/CustomAvatar";
import { getContrastColor } from "../../../../../Config/reuserFunctions";
import MenuOptionsContacto from "./ListOptions";

export default function ContactosBoard() {
  const { snackMessage } = React.useContext(MainContext);
  const [actualizarTipoEnContacto] = useMutation(ACTUALIZAR_TIPO_CONTACTO);
  const { tipos, setTipos, queryRefetch } = React.useContext(ContactosContext);
  const [dragginID, setDragginID] = React.useState("");

  const onDragStart = (result) => {
    setDragginID(result.destination?.droppableId);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const { source, destination, draggableId } = result;
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    const TIPOS = [...tipos];
    const sourceFase = TIPOS.filter((fase) => fase._id === source.droppableId);
    const destinFase = TIPOS.filter(
      (fase) => fase._id === destination.droppableId
    );

    if (!sourceFase.length && !destinFase.length) return;

    const draggedItem = sourceFase[0].contactos.find(
      (item) => item._id === draggableId
    );

    // Move within the same list
    if (source.droppableId !== destination.droppableId) {
      sourceFase[0].contactos.splice(source.index, 1);
      destinFase[0].contactos.splice(destination.index, 0, draggedItem);

      /* el siguiente codigo si funciona, pero sin el tambien, por si llega a falla
      descomentar, es para remplazar los arrays

      TIPOS.splice(sourceFase[0].posicion-1, 1, sourceFase[0]);
      TIPOS.splice(destinFase[0].posicion-1, 1, destinFase[0]); */
      setDragginID("");
      setTipos(TIPOS);
      updateList(draggedItem._id, destinFase[0]._id);
    }
  };

  const updateList = async (idContacto, idTipoContacto) => {
    try {
      await actualizarTipoEnContacto({
        variables: {
          idContacto,
          idTipoContacto,
        },
      });
      queryRefetch();
    } catch (err) {
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragStart}>
      <HorizontalScrollDrag>
        {tipos.map((tipo, k) => {
          let divContainer = document.getElementById(`contenedor-${k}`);
          const actHeight = divContainer?.offsetHeight || 60;
          const minHeight = dragginID === tipo._id ? actHeight + 80 : 60;
          return (
            <StrictModeDroppable
              key={`${tipo._id}-${k}`}
              droppableId={tipo._id}
            >
              {(provided) => (
                <Box sx={{ height: 80 }}>
                  <Box sx={{ mx: 1, width: "320px" }}>
                    <Box
                      sx={{
                        maxHeight: "100%",
                        borderRadius: 3,
                        bgcolor: (theme) =>
                          alpha(theme.palette.comodyBlue, 0.07),
                      }}
                    >
                      <Typography variant="body2" sx={{ px: 2, pt: 1 }}>
                        <b>{tipo.tipoContacto}</b>
                      </Typography>
                      <List
                        dense
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        <Box
                          sx={{
                            px: 1.5,
                            minHeight,
                            transition: ".3s",
                            maxHeight: "72vh",
                            overflowY: "auto",
                          }}
                          id={`contenedor-${k}`}
                        >
                          {tipo.contactos.map((contacto, index) => {
                            return (
                              <Draggable
                                key={`${contacto._id}-${index}`}
                                draggableId={contacto._id}
                                index={index}
                              >
                                {(provided) => (
                                  <Box
                                    {...provided.draggableProps}
                                    ref={provided.innerRef}
                                    sx={{
                                      bgcolor: "background.paper",
                                      borderRadius: 2,
                                      mb: 1.5,
                                      cursor: "grab",
                                    }}
                                    component={Paper}
                                    variant="outlined"
                                  >
                                    <ListItemsComponent
                                      contacto={contacto}
                                      provided={provided}
                                    />
                                  </Box>
                                )}
                              </Draggable>
                            );
                          })}
                        </Box>
                      </List>
                    </Box>
                    {provided.placeholder}
                  </Box>
                </Box>
              )}
            </StrictModeDroppable>
          );
        })}
      </HorizontalScrollDrag>
    </DragDropContext>
  );
}

const ListItemsComponent = ({ contacto, provided }) => {
  const [expanded, setExpanded] = React.useState(false);
  const {
    isSelecting,
    itemsSelected,
    setItemsSelected,
    setIndeterminated,
    contactos,
  } = React.useContext(ContactosContext);
  const checked = itemsSelected.find((res) => res._id === contacto._id);

  const handleExpandClick = (event) => {
    event.stopPropagation();
    setExpanded(!expanded);
  };

  const handleSelect = (checked, item) => {
    const items = [...itemsSelected];
    if (checked) items.push(item);
    if (!checked) {
      const indx = items.findIndex((elm) => elm._id === item._id);
      items.splice(indx, 1);
    }
    setItemsSelected(items);
    //revisar el checkAll
    if (items.length > 0 && items.length !== contactos.length) {
      setIndeterminated(true);
      return;
    }
    setIndeterminated(false);
  };

  return (
    <>
      <ContactoDetails data={contacto}>
        <ListItem sx={{ py: 1, px: 1 }}>
          <ListItemIcon sx={{ minWidth: "30px", mr: 1 }}>
            {isSelecting ? (
              <Checkbox
                onChange={(e) => handleSelect(e.target.checked, contacto)}
                onClick={(e) => e.stopPropagation()}
                checked={checked ? true : false}
                size="small"
                sx={{ p: 0.3 }}
              />
            ) : null}
            <CustomAvatar size={30} fontSize={18} name={contacto.nombre} />
          </ListItemIcon>
          <ListItemText
            {...provided.dragHandleProps}
            primary={
              <Box
                component="span"
                sx={{
                  display: "inline-block",
                  wordWrap: "break-word",
                  pb: 0.5,
                  whiteSpace: "normal",
                  width: "100%",
                }}
              >
                <Typography>{contacto.nombre}</Typography>
              </Box>
            }
            secondary={
              <span style={{ display: "flex", alignItems: "center" }}>
                <Chip
                  component="span"
                  label={contacto.statusContacto}
                  size="small"
                  sx={{
                    mr: 0.5,
                    height: 13,
                    fontSize: 10,
                    fontWeight: "600",
                    backgroundColor: contacto.statusContactoPopulated[0]?.color,
                    color: getContrastColor(
                      contacto.statusContactoPopulated[0]?.color
                    ),
                  }}
                />
                {contacto.telefono}
              </span>
            }
          />
          <ListItemSecondaryAction>
            <IconButton onClick={handleExpandClick}>
              <MoreVertIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </ContactoDetails>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <MenuOptionsContacto contacto={contacto} handleSelect={handleSelect} />
      </Collapse>
    </>
  );
};
