import { gql } from "@apollo/client";

export const CREAR_STATUS_ACTIVIDAD = gql`
  mutation CrearStatusActividad($idEmpresa: ID!, $input: StatusActInput) {
    crearStatusActividad(idEmpresa: $idEmpresa, input: $input) {
      message
    }
  }
`;
export const ACTUALIZAR_STATUS_ACTIVIDAD = gql`
  mutation ActualizarStatusActividad($idEmpresa: ID!, $idStatus: ID!, $input: StatusActInput) {
    actualizarStatusActividad(idEmpresa: $idEmpresa, idStatus: $idStatus, input: $input) {
      message
    }
  }
`;
export const ELIMINAR_STATUS_ACTIVIDAD = gql`
  mutation EliminarStatusActividad($idStatus: ID!) {
    eliminarStatusActividad(idStatus: $idStatus) {
      message
    }
  }
`;
export const ELIMINAR_STATUS_ACTIVIDADES = gql`
  mutation EliminarStatusActividades($status: [ID!]!) {
    eliminarStatusActividades(status: $status) {
      message
    }
  }
`;