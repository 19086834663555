import { createSlice } from "@reduxjs/toolkit";

export const themingReducer = createSlice({
  name: "theming",
  initialState: {
    mode: localStorage.getItem("CRMtheming"),
  },
  reducers: {
    changeTheme: (state, action) => {
      state.mode = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeTheme } = themingReducer.actions;
export default themingReducer.reducer;
