import React from "react";
import NuevoUsuario from "./Actions/NuevoUsuario";
import SearchUsuarios from "./Actions/Search";
import TableUsuarios from "./Table/TableUsuarios";
import { Stack } from "@mui/material";
import { useQuery } from "@apollo/client";
import { MainContext } from "../../../../Context/MainCtx";
import { useDebounce } from "use-debounce";
import { OBTENER_USUARIOS } from "./graphql/queries";

export default function Usuarios() {
  const [filter, setFilter] = React.useState("");
  const { tokenDecoded } = React.useContext(MainContext);
  const [FILTER] = useDebounce(filter, 800);

  const usuariosQuery = useQuery(OBTENER_USUARIOS, {
    variables: {
      filter: FILTER,
      idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
    },
    fetchPolicy: "network-only",
  });

  return (
    <div>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="flex-end"
        alignItems="center"
        sx={{
          mb: 1,
          position: { sm: "absolute" },
          top: 60,
          right: 24,
          width: { xs: "100%", md: "auto" }
        }}
      >
        <SearchUsuarios filter={filter} setFilter={setFilter} />
        <NuevoUsuario refetch={usuariosQuery.refetch} />
      </Stack>
      <TableUsuarios usuariosQuery={usuariosQuery} />
    </div>
  );
}
