import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ContactosContext } from "../Context/ContactoContext";
import { Checkbox, TableSortLabel } from "@mui/material";

const headCells = [
  {
    id: "nombre",
    padding: "normal",
    align: "left",
    label: "Nombre",
    sortable: true,
    sx: {
      minWidth: 250,
    },
  },
  {
    id: "telefono",
    padding: "normal",
    align: "left",
    label: "Telefono",
    sortable: false,
    sx: {
      minWidth: 170,
    },
  },
  {
    id: "correo",
    padding: "normal",
    align: "left",
    label: "Correo",
    sortable: true,
    sx: {
      minWidth: 170,
    },
  },
  {
    id: "statusContacto",
    padding: "checkbox",
    align: "left",
    label: "Status",
    sortable: false,
    sx: {
      minWidth: 150
    },
  },
  {
    id: "etiqueta",
    padding: "normal",
    align: "left",
    label: "Etiqueta",
    sortable: false,
    sx: {
      minWidth: 150
    },
  },
  {
    id: "tipoContacto",
    padding: "normal",
    align: "left",
    label: "Tipo",
    sortable: true,
    sx: {
      minWidth: 250,
    },
  },
  {
    id: "propietario",
    padding: "normal",
    align: "left",
    label: "Propietario",
    sortable: true,
    sx: {
      minWidth: 230,
    },
  },
  {
    id: "organizacion",
    padding: "normal",
    align: "left",
    label: "Organización",
    sortable: true,
    sx: {
      minWidth: 200,
    },
  },
  {
    id: "formaAtraccion",
    padding: "normal",
    align: "left",
    label: "Forma Atracción",
    sortable: true,
    sx: {
      minWidth: 250,
    },
  },
  {
    id: "campaign",
    padding: "normal",
    align: "left",
    label: "Campaña",
    sortable: true,
    sx: {
      minWidth: 250,
    },
  },
  {
    id: "fechaRegistro",
    padding: "normal",
    align: "left",
    label: "Fecha registro",
    sortable: true,
    sx: {
      minWidth: 150,
    },
  },
];

export default function ContactosTableHead({
  handleSort,
  order,
  orderBy,
  handleCheckAll,
}) {
  const { itemsSelected, indeterminated } = React.useContext(ContactosContext);

  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          sx={{
            position: { xs: "relative", sm: "sticky" },
            left: 0,
            zIndex: 3,
          }}
        >
          <Checkbox
            indeterminate={indeterminated}
            checked={itemsSelected.length > 0 && !indeterminated}
            onChange={(e) => handleCheckAll(e.target.checked)}
          />
        </TableCell>
        <TableCell />
        <TableCell />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={headCell.sx}
            sortDirection={orderBy === headCell.id ? order : false}
            padding={headCell.padding}
            align={headCell.align}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => handleSort(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
