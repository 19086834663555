import { gql } from "@apollo/client";

export const CREAR_FORMA_CONTACTO = gql`
  mutation CrearFormaContacto($idEmpresa: ID!, $formaContacto: String!) {
    crearFormaContacto(idEmpresa: $idEmpresa, formaContacto: $formaContacto) {
      message
    }
  }
`;
export const ACTUALIZAR_FORMA_CONTACTO = gql`
  mutation ActualizarFormaContacto(
    $idEmpresa: ID!
    $idForma: ID!
    $formaContacto: String!
  ) {
    actualizarFormaContacto(
      idEmpresa: $idEmpresa
      idForma: $idForma
      formaContacto: $formaContacto
    ) {
      message
    }
  }
`;
export const ELIMINAR_FORMA_CONTACTO = gql`
  mutation EliminarFormaContacto($idForma: ID!) {
    eliminarFormaContacto(idForma: $idForma) {
      message
    }
  }
`;
