import * as React from "react";
import LoadingAutocomplete from "./LoadingAutocomplete";
import ErrorAutocomplete from "./ErrorAutocomplete";
import {
  CustomTableAutocomplete,
  CustomTableTextField,
} from "../CustomMuiComponents";

export default function AutoCompleteInputTable({
  renderData = [],
  value = "",
  id = null,
  sx = null,
  loading = false,
  error = null,
  required = false,
  disabled = false,
  addButton = null,
  onChange = null,
  isOptionEqualToValue = null,
  getOptionLabel = null,
  hidden = false
}) {
  const [open, setOpen] = React.useState(false);
  if (loading) return <LoadingAutocomplete table />;
  if (error) return <ErrorAutocomplete table />;

  return (
    <CustomTableAutocomplete
      id={id}
      autoHighlight
      openOnFocus
      fullWidth
      onBlur={() => setOpen(false)}
      hidden={hidden}
      sx={{
        ...sx,
        "& .MuiAutocomplete-inputRoot": {
          padding: "0px 4px 2px 4px!important",
        },
      }}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      options={renderData}
      disabled={disabled}
      size="small"
      forcePopupIcon={false}
      value={value || null}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      renderInput={(params) => (
        <CustomTableTextField
          {...params}
          required={required}
          size="small"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <span style={{ color: "red", fontWeight: "bold" }}>
                {required && "*"}
              </span>
            ),
            endAdornment: (
              <React.Fragment>
                {addButton ? addButton : null}
                {/*  {params.InputProps.endAdornment} */}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option._id} style={{ fontSize: 13 }}>
          {props.key}
        </li>
      )}
      onChange={onChange}
    />
  );
}
