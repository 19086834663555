import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { Add, ArrowBack, Done } from "@mui/icons-material";
import { DialogContent, Divider, Rating } from "@mui/material";
import { TratosContext } from "../../Context/TratosContext";
import EditableTextInput from "../../../../../Components/InputsForms/EditableTextInput";
import EditableDateInput from "../../../../../Components/InputsForms/EditableDateInput";
import EditableAutoComplete from "../../../../../Components/InputsForms/EditableAutoComlete";
import EditableMultipleAutocomplete from "../../../../../Components/InputsForms/EditableMultipleAutocomplete";
import moment from "moment-timezone";

import NuevoEmbudo from "../../../Configuraciones/Embudos/Actions/NuevoEmbudo";
import NuevoContacto from "../../../Contactos/Contactos/Actions/NuevoContacto";
import NuevaFormaContacto from "../../../Configuraciones/FormasContacto/Actions/NuevaFormaContacto";
import NuevoUsuario from "../../../Configuraciones/Usuarios/Actions/NuevoUsuario";
import NuevaCampaign from "../../../Configuraciones/Campaigns/Actions/NuevaCampaign";
import NuevaUnidadN from "../../../Configuraciones/UnidadesNegocios/Actions/NuevaUnidadN";
import NuevaMoneda from "../../../Configuraciones/Monedas/Actions/NuevaMoneda";
import ChipStatus from "../../CardFases/BoardMode/ChipStatus";
import ChipPrioridad from "../../CardFases/BoardMode/ChipPrioridad";
import ChipUsuario from "../../../../../Components/Avatar/ChipUsuario";
import { MainContext } from "../../../../../Context/MainCtx";
import { CREAR_TRATO } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import NuevoProducto from "../../../Configuraciones/Productos/Actions/NuevoProducto";
import ProductoCtxProvider from "../../../Configuraciones/Productos/Context/ProductoContext";
import ContactosCtxProvider from "../../../Contactos/Contactos/Context/ContactoContext";
import { isMobile } from "../../../../../Config/constantes";

export default function NuevoTrato({
  external = false,
  refetchExternal = null,
  fromDetail = false,
  fromContacto = null,
  setToArray = null,
}) {
  const {
    trato,
    setTrato,
    queryCats,
    visiblePara,
    setVisiblePara,
    queryRefetch,
    initial_state_trato,
  } = React.useContext(TratosContext);
  const { snackMessage, tokenDecoded } = React.useContext(MainContext);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [crearTrato] = useMutation(CREAR_TRATO);

  const handleOpen = () => {
    setOpen(true);
    let data = {
      ...trato,
      propietario: tokenDecoded.nombreCompleto,
      idPropietario: tokenDecoded._id,
    };
    if (fromContacto) {
      data.contacto = fromContacto.nombre;
      data.idContacto = fromContacto._id;
    }
    setTrato(data);
  };

  const handleClose = () => {
    setOpen(false);
    setTrato(initial_state_trato);
    setVisiblePara([]);
  };

  const handleChangeDate = (name, value) => {
    setTrato((state) => ({
      ...state,
      [name]: moment(value).tz("America/Mexico_City").format(),
    }));
  };

  const onChangeChip = (name, value) => {
    setTrato((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const autocompleteChange = (values) => {
    const { name, value, id, idName } = values;
    if (id && idName) {
      setTrato((st) => ({
        ...st,
        [name]: value,
        [idName]: id,
      }));
    } else {
      setTrato((st) => ({
        ...st,
        [name]: value,
      }));
    }
  };

  const autocompleteMultipleChange = (values) => {
    const array = values.map((res) => ({
      idUsuario: res._id,
      nombreCompleto: res.nombreCompleto,
    }));
    setVisiblePara(values);
    setTrato((st) => ({
      ...st,
      visiblePara: array,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTrato((state) => ({
      ...state,
      [name]: name === "valor" ? parseFloat(value) : value,
    }));
  };

  const handleChangeInteres = (e, interes) => {
    setTrato((st) => ({
      ...st,
      interes,
    }));
  };

  const handleSaveTrato = async (e) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      if (loading) return;
      setLoading(true);
      let input = { ...trato };
      input.valor = parseFloat(input.valor);
      input.probabilidadCierre = parseFloat(input.probabilidadCierre);
      delete input.new;
      const res = await crearTrato({
        variables: {
          idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
          input,
        },
      });
      snackMessage({
        message: "Trato creado",
        variant: "success",
      });
      if (setToArray) setToArray(res.data.crearTrato);
      if (refetchExternal) refetchExternal();
      queryRefetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      {external && !fromDetail ? (
        <IconButton onClick={handleOpen} size="small" color="primary">
          <Add />
        </IconButton>
      ) : (
        <Button
          onClick={handleOpen}
          color="primary"
          variant={fromDetail ? "text" : "contained"}
          startIcon={ isMobile ? null : <Add />}
          size={fromDetail ? "medium" : "small"}
          disableElevation
          sx={{ textTransform: "none", minWidth: 40 }}
        >
          {isMobile ? <Add fontSize="small" /> : "Nuevo"}
        </Button>
      )}

      <Drawer
        anchor={"right"}
        open={open}
        onClose={handleClose}
        ModalProps={{
          componentsProps: {
            backdrop: {
              sx: { background: "none" /* backdropFilter: "blur(2px)" */ },
            },
          },
        }}
        PaperProps={{
          elevation: 1,
          sx: { width: { xs: "100vw", md: "50vw" }, overflowY: "auto" },
        }}
      >
        <Toolbar variant="dense" />
        <DialogTitle component="div">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <IconButton onClick={handleClose}>
                <ArrowBack />
              </IconButton>
              <Typography variant="h6" color="gray">
                Nueva Oportunidad
              </Typography>
            </Stack>
            <Button
              size="small"
              variant="contained"
              disableElevation
              sx={{ textTransform: "none" }}
              startIcon={
                loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <Done />
                )
              }
              type="submit"
              form="formTratoDetail"
            >
              Guardar
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ height: "80vh" }}>
            <form id="formTratoDetail" onSubmit={handleSaveTrato}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <Typography variant="body2" color="gray">
                    <span
                      style={{ color: !trato.nombreTrato ? "red" : "gray" }}
                    >
                      *
                    </span>
                    Trato
                  </Typography>
                  <EditableTextInput
                    tabIndex={1}
                    fullWidth
                    placeholder="Nombre del trato"
                    name="nombreTrato"
                    value={trato.nombreTrato}
                    required
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body2" color="gray">
                    <span
                      style={{ color: !trato.nombreTrato ? "red" : "gray" }}
                    >
                      *
                    </span>
                    Producto
                  </Typography>
                  <EditableAutoComplete
                    tabIndex={2}
                    fullWidth
                    placeholder="Añadir producto"
                    renderData={queryCats.data?.productos}
                    value={trato.producto}
                    loadingQuery={queryCats.loading}
                    errorQuery={queryCats.error}
                    required
                    addButton={
                      <ProductoCtxProvider>
                        <NuevoProducto
                          external
                          refetchExternal={queryCats.refetch}
                        />
                      </ProductoCtxProvider>
                    }
                    onChange={(_, option) =>
                      autocompleteChange({
                        name: "producto",
                        idName: "idProducto",
                        value: option?.nombre || "",
                        id: option?._id || "",
                      })
                    }
                    isOptionEqualToValue={({ nombre }, value) =>
                      nombre === value
                    }
                    getOptionLabel={(option) => option.nombre || option || null}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" color="gray">
                    <span
                      style={{ color: !trato.nombreTrato ? "red" : "gray" }}
                    >
                      *
                    </span>
                    Fase del embudo
                  </Typography>
                  <EditableAutoComplete
                    tabIndex={3}
                    placeholder="Añadir fase de embudo"
                    renderData={queryCats.data?.embudos}
                    value={trato.embudo}
                    loadingQuery={queryCats.loading}
                    errorQuery={queryCats.error}
                    required
                    addButton={
                      <NuevoEmbudo
                        external
                        refetchExternal={queryCats.refetch}
                      />
                    }
                    onChange={(_, option) =>
                      autocompleteChange({
                        name: "embudo",
                        idName: "idEmbudo",
                        value: option?.fase || "",
                        id: option?._id || "",
                      })
                    }
                    isOptionEqualToValue={({ fase }, value) => fase === value}
                    getOptionLabel={(option) => option.fase || option || null}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" color="gray">
                    <span
                      style={{ color: !trato.nombreTrato ? "red" : "gray" }}
                    >
                      *
                    </span>
                    Propietario
                  </Typography>
                  <EditableAutoComplete
                    tabIndex={4}
                    placeholder="Añadir propietario"
                    renderData={queryCats.data?.usuarios}
                    value={trato.propietario}
                    loadingQuery={queryCats.loading}
                    errorQuery={queryCats.error}
                    required
                    addButton={
                      <NuevoUsuario
                        external
                        refetchExternal={queryCats.refetch}
                      />
                    }
                    onChange={(_, option) =>
                      autocompleteChange({
                        name: "propietario",
                        idName: "idPropietario",
                        value: option?.nombreCompleto || "",
                        id: option?._id || "",
                      })
                    }
                    isOptionEqualToValue={({ nombreCompleto }, value) =>
                      nombreCompleto === value
                    }
                    getOptionLabel={(option) =>
                      option.nombreCompleto || option || null
                    }
                  />
                </Grid>
                <Grid item md={6}>
                  <Typography variant="body2" color="gray">
                    <span
                      style={{ color: !trato.nombreTrato ? "red" : "gray" }}
                    >
                      *
                    </span>
                    Contacto
                  </Typography>
                  <EditableAutoComplete
                    tabIndex={5}
                    placeholder="Añadir contacto"
                    renderData={queryCats.data?.contactos}
                    value={trato.contacto}
                    loadingQuery={queryCats.loading}
                    errorQuery={queryCats.error}
                    required
                    addButton={
                      <ContactosCtxProvider>
                        <NuevoContacto
                          external
                          refetchExternal={queryCats.refetch}
                        />
                      </ContactosCtxProvider>
                    }
                    onChange={(_, option) =>
                      autocompleteChange({
                        name: "contacto",
                        idName: "idContacto",
                        value: option?.nombre || "",
                        id: option?._id || "",
                      })
                    }
                    isOptionEqualToValue={({ nombre }, value) =>
                      nombre === value
                    }
                    getOptionLabel={(option) => option.nombre || option || null}
                  />
                </Grid>
                <Grid item md={6}>
                  <Typography variant="body2" color="gray">
                    <span
                      style={{ color: !trato.nombreTrato ? "red" : "gray" }}
                    >
                      *
                    </span>
                    ¿Cómo se contactó?
                  </Typography>
                  <EditableAutoComplete
                    tabIndex={6}
                    placeholder="Añadir forma de contacto"
                    renderData={queryCats.data?.formasContacto}
                    value={trato.formaContacto}
                    loadingQuery={queryCats.loading}
                    errorQuery={queryCats.error}
                    required
                    addButton={
                      <NuevaFormaContacto
                        external
                        refetchExternal={queryCats.refetch}
                      />
                    }
                    onChange={(_, option) =>
                      autocompleteChange({
                        name: "formaContacto",
                        idName: "idFormaContacto",
                        value: option?.formaContacto || "",
                        id: option?._id || "",
                      })
                    }
                    isOptionEqualToValue={({ formaContacto }, value) =>
                      formaContacto === value
                    }
                    getOptionLabel={(option) =>
                      option.formaContacto || option || null
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4} sx={{ pt: 1 }}>
                <Grid item>
                  <Typography variant="body2" color="gray">
                    Status
                  </Typography>
                  <ChipStatus
                    tabIndex={7}
                    trato={trato}
                    refetch={queryRefetch}
                    onchange={onChangeChip}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="body2" color="gray">
                    Prioridad
                  </Typography>
                  <ChipPrioridad
                    tabIndex={8}
                    trato={trato}
                    refetch={queryRefetch}
                    onchange={onChangeChip}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="body2" color="gray">
                    Interés
                  </Typography>
                  <Rating
                    tabIndex={9}
                    size="medium"
                    value={trato.interes}
                    onChange={handleChangeInteres}
                  />
                </Grid>
              </Grid>
              <Divider sx={{ my: 2 }} />
              <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                  <Typography variant="body2" color="gray">
                    Unidad de negocio
                  </Typography>
                  <EditableAutoComplete
                    tabIndex={11}
                    placeholder="Añadir unidad de negocio"
                    renderData={queryCats.data?.unidades}
                    value={trato.unidadNegocio}
                    loadingQuery={queryCats.loading}
                    errorQuery={queryCats.error}
                    addButton={
                      <NuevaUnidadN
                        external
                        refetchExternal={queryCats.refetch}
                      />
                    }
                    onChange={(_, option) =>
                      autocompleteChange({
                        name: "unidadNegocio",
                        idName: "idUnidadNegocio",
                        value: option?.nombreUnidad || "",
                        id: option?._id || "",
                      })
                    }
                    isOptionEqualToValue={({ nombreUnidad }, value) =>
                      nombreUnidad === value
                    }
                    getOptionLabel={(option) =>
                      option.nombreUnidad || option || null
                    }
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Typography variant="body2" color="gray">
                    <span
                      style={{ color: !trato.nombreTrato ? "red" : "gray" }}
                    >
                      *
                    </span>
                    Valor del trato
                  </Typography>
                  <EditableTextInput
                    tabIndex={12}
                    fullWidth
                    placeholder="Añadir valor"
                    name="valor"
                    value={trato.valor}
                    onChange={handleChange}
                    startAdorment="$"
                    required
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Typography variant="body2" color="gray">
                    <span
                      style={{ color: !trato.nombreTrato ? "red" : "gray" }}
                    >
                      *
                    </span>
                    Moneda
                  </Typography>
                  <EditableAutoComplete
                    tabIndex={13}
                    fullWidth
                    placeholder="Añadir moneda"
                    renderData={queryCats.data?.monedas}
                    value={trato.moneda}
                    loadingQuery={queryCats.loading}
                    errorQuery={queryCats.error}
                    required
                    addButton={
                      <NuevaMoneda
                        external
                        refetchExternal={queryCats.refetch}
                      />
                    }
                    onChange={(_, option) =>
                      autocompleteChange({
                        name: "moneda",
                        idName: "idMoneda",
                        value: option?.moneda || "",
                        id: option?._id || "",
                      })
                    }
                    isOptionEqualToValue={({ moneda }, value) =>
                      moneda === value
                    }
                    getOptionLabel={(option) => option.moneda || option || null}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography variant="body2" color="gray">
                    Campaña
                  </Typography>
                  <EditableAutoComplete
                    tabIndex={10}
                    placeholder="Añadir campaña"
                    renderData={queryCats.data?.campaigns}
                    value={trato.campaign}
                    loadingQuery={queryCats.loading}
                    errorQuery={queryCats.error}
                    addButton={
                      <NuevaCampaign
                        external
                        refetchExternal={queryCats.refetch}
                      />
                    }
                    onChange={(_, option) =>
                      autocompleteChange({
                        name: "campaign",
                        idName: "idCampaign",
                        value: option?.nombre || "",
                        id: option?._id || "",
                      })
                    }
                    isOptionEqualToValue={({ nombre }, value) =>
                      nombre === value
                    }
                    getOptionLabel={(option) => option.nombre || option || null}
                  />
                </Grid>
              </Grid>
              <Divider sx={{ my: 2 }} />
              <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                  <Typography variant="body2" color="gray">
                    Probabilidad de cierre
                  </Typography>
                  <EditableTextInput
                    tabIndex={14}
                    fullWidth
                    placeholder="Añadir probabilidad"
                    name="probabilidadCierre"
                    value={trato.probabilidadCierre}
                    onChange={handleChange}
                    startAdorment="%"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="body2" color="gray">
                    Fecha estimada
                  </Typography>
                  <EditableDateInput
                    tabIndex={15}
                    name="fechaEstimadaCierre"
                    value={trato.fechaEstimadaCierre}
                    onChange={handleChangeDate}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="body2" color="gray">
                    Fecha
                  </Typography>
                  <EditableDateInput
                    tabIndex={16}
                    name="fechaTrato"
                    value={trato.fechaTrato}
                    onChange={handleChangeDate}
                  />
                </Grid>
              </Grid>
              <Divider sx={{ my: 2 }} />
              <Typography variant="body2" color="gray">
                ¿Quién puede ver este trato?
              </Typography>
              <EditableMultipleAutocomplete
                tabIndex={17}
                placeholder="Añadir usuarios"
                renderData={queryCats.data?.usuarios}
                value={visiblePara}
                loadingQuery={queryCats.loading}
                errorQuery={queryCats.error}
                addButton={
                  <NuevoUsuario external refetchExternal={queryCats.refetch} />
                }
                onChange={autocompleteMultipleChange}
                isOptionEqualToValue={({ nombreCompleto }, value) =>
                  nombreCompleto === value.nombreCompleto
                }
                getOptionLabel={(option) =>
                  option.nombreCompleto || option || null
                }
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <ChipUsuario
                      key={index}
                      label={option.nombreCompleto}
                      size="small"
                      onDelete={() => {
                        const newSelectedOptions = [...visiblePara];
                        newSelectedOptions.splice(index, 1);
                        autocompleteMultipleChange(newSelectedOptions);
                      }}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </form>
          </Box>
        </DialogContent>
      </Drawer>
    </div>
  );
}
