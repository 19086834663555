import * as React from "react";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { AreaContext } from "../Context/AreaContext";
import { Close } from "@mui/icons-material";
import { CustomTableTextField } from "../../../../../Components/CustomMuiComponents";
import { MainContext } from "../../../../../Context/MainCtx";
import { ACTUALIZAR_AREA, CREAR_AREA } from "../graphql/mutations";
import { useMutation } from "@apollo/client";

export default function EditableRowArea({ edit = false }) {
  const {
    setNewItem,
    newItem,
    setLoadingSave,
    query,
    loadingSave,
  } = React.useContext(AreaContext);
  const { snackMessage, tokenDecoded } = React.useContext(MainContext);
  const [crearArea] = useMutation(CREAR_AREA);
  const [actualizarArea] = useMutation(ACTUALIZAR_AREA);

  const handleSaveArea = async (e) => {
    try {
      e.preventDefault();
      if (!newItem.area) return;
      setLoadingSave(true);
      if (!edit) {
        await crearArea({
          variables: {
            idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
            area: newItem.area,
          },
        });
      } else {
        await actualizarArea({
          variables: {
            idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
            idArea: newItem._id,
            area: newItem.area,
          },
        });
      }
      query.refetch();
      setLoadingSave(false);
      setNewItem(null);
    } catch (err) {
      console.log(err.networkError?.result)
      setLoadingSave(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  const handleChange = (area) => {
    setNewItem((st) => ({ ...st, area }));
  };

  const handleRemove = () => {
    setNewItem(null);
  };

  return (
    <TableRow role="checkbox" tabIndex={-1}>
      <TableCell padding="checkbox" />
      <TableCell>
        <form id="form-area-table" onSubmit={(e) => handleSaveArea(e)}>
          <CustomTableTextField
            variant="outlined"
            size="small"
            fullWidth
            value={newItem.area}
            onChange={(e) => handleChange(e.target.value)}
            disabled={loadingSave}
            autoFocus
          />
        </form>
      </TableCell>
      <TableCell padding="checkbox">
        <IconButton onClick={() => handleRemove()}>
          <Close />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
