import * as React from "react";
import {
  CustomTableAutocomplete,
  CustomTableTextField,
} from "../../../../../Components/CustomMuiComponents";
import NuevoUsuario from "../../../Configuraciones/Usuarios/Actions/NuevoUsuario";
import LoadingAutocomplete from "../../../../../Components/InputsForms/LoadingAutocomplete";
import ErrorAutocomplete from "../../../../../Components/InputsForms/ErrorAutocomplete";

export default function UsuariosMultipleTable({
  value,
  handleChange,
  query,
  disabled = false,
  id = "usuario-multiple-autocomplete",
}) {
  const { data, loading, error, refetch } = query;

  if (loading) return <LoadingAutocomplete table />;
  if (error) return <ErrorAutocomplete table />;

  const { usuarios = [] } = data;

  const totalNameLength = value.reduce(
    (acc, tag) => acc + tag.nombreCompleto.length,
    0
  );

  return (
    <CustomTableAutocomplete
      multiple
      autoHighlight
      openOnFocus
      id={id}
      options={usuarios}
      getOptionLabel={(option) =>
        (option.nombreCompleto ? option.nombreCompleto : option) || null
      }
      isOptionEqualToValue={({ nombreCompleto }, value) =>
        nombreCompleto === value.nombreCompleto
      }
      sx={{
        width: `${250 + totalNameLength * 8}px`, // Ajusta el ancho del Autocomplete
        transition: "width 0.3s", // Agrega una transición suave
      }}
      value={value}
      filterSelectedOptions
      disabled={disabled}
      renderInput={(params) => (
        <CustomTableTextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                <NuevoUsuario
                  external={true}
                  refetchExternal={refetch}
                />
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} style={{ fontSize: 13 }}>
          {option.nombreCompleto}
        </li>
      )}
      onChange={(_, option) => handleChange(option)}
    />
  );
}
