import { gql } from "@apollo/client";

export const CREAR_CONTACTO = gql`
  mutation CrearContacto($idEmpresa: ID!, $input: crearContactoInput) {
    crearContacto(idEmpresa: $idEmpresa, input: $input) {
      message
    }
  }
`;
export const ACTUALIZAR_CONTACTO = gql`
  mutation ActualizarContacto(
    $idContacto: ID!
    $input: actualizarContactoInput
  ) {
    actualizarContacto(idContacto: $idContacto, input: $input) {
      message
    }
  }
`;
export const ACTUALIZAR_TIPO_CONTACTO = gql`
  mutation ActualizarTipoEnContacto($idContacto: ID!, $idTipoContacto: ID!) {
    actualizarTipoEnContacto(
      idContacto: $idContacto
      idTipoContacto: $idTipoContacto
    ) {
      message
    }
  }
`;
export const ACTUALIZAR_STATUS_CONTACTO = gql`
  mutation ActualizarStatusDeContacto(
    $idContacto: ID!
    $input: updateStatusContacto
  ) {
    actualizarStatusDeContacto(idContacto: $idContacto, input: $input) {
      message
    }
  }
`;
export const ELIMINAR_CONTACTO = gql`
  mutation EliminarContacto($idContacto: ID!) {
    eliminarContacto(idContacto: $idContacto) {
      message
    }
  }
`;
export const ELIMINAR_CONTACTOS = gql`
  mutation EliminarContactos($contactosToDelete: [ID!]!) {
    eliminarContactos(contactosToDelete: $contactosToDelete) {
      message
    }
  }
`;
