import React, { createContext } from "react";
import { MainContext } from "../../../../../Context/MainCtx";
import { useDebounce } from "use-debounce";
import { useQuery } from "@apollo/client";
import { OBTENER_PRODUCTOS } from "../graphql/queries";

export const ProductoContext = createContext();

const initial_state = {
  skuCodigo: "",
  nombre: "",
  unidadMedida: "",
  descripcion: "",
  costo: 0,
  precio: 0,
  promocion: 0,
  tipoDescuento: "",
  valorDescuento: 0,
  precioFinal: 0,
  categoria: "",
  imagenes: [],
};

const initial_filter = {
  search: "",
  categoria: "",
  unidadMedida: ""
}
const units = ["PZ", "CAJA", "KG"];

const ProductoCtxProvider = ({ children }) => {
  const { tokenDecoded } = React.useContext(MainContext);
  const [filter, setFilter] = React.useState(initial_filter);
  const [page, setPage] = React.useState(0);
  const [productData, setProductData] = React.useState(initial_state);
  const [imagenes, setImagenes] = React.useState([]);
  const [FILTER] = useDebounce(filter, 500);

  const productoQuery = useQuery(OBTENER_PRODUCTOS, {
    variables: {
      filter: FILTER,
      page,
      limit: 20,
      idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
    },
    fetchPolicy: "network-only",
  });
  const { data, loading, error, refetch } = productoQuery;
  return (
    <ProductoContext.Provider
      value={{
        filter,
        setFilter,
        initial_filter,
        page,
        setPage,
        queryData: data,
        queryLoading: loading,
        queryError: error,
        queryRefetch: refetch,
        productData,
        setProductData,
        initial_state,
        imagenes,
        setImagenes,
        units
      }}
    >
      {children}
    </ProductoContext.Provider>
  );
};

export default ProductoCtxProvider;
