import { gql } from "@apollo/client";

export const OBTENER_TIPOS_USUARIO = gql`
  query ObtenerTipoUsuarios($idEmpresa: ID!, $filter: String) {
    obtenerTipoUsuarios(idEmpresa: $idEmpresa, filter: $filter) {
      _id
      tipoUsuario
      idEmpresa
    }
  }
`;
