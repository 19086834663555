import { gql } from "@apollo/client";

export const OBTENER_TIPOS_CONTACTO = gql`
  query ObtenerTipoContactos($idEmpresa: ID!) {
    obtenerTipoContactos(idEmpresa: $idEmpresa) {
      _id
      tipoContacto
      idEmpresa
    }
  }
`;
