import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Add, ArrowBack, Done, Edit } from "@mui/icons-material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import Select from "@mui/material/Select";
import { AdUnits } from "@mui/icons-material";
import { ACTUALIZAR_FORMA, CREAR_FORMA } from "../graphql/mutations";
import { useMutation } from "@apollo/client";
import { MainContext } from "../../../../../Context/MainCtx";
import { CircularProgress, Divider, Stack } from "@mui/material";
import { SOCIAL_NETWORKS, isMobile } from "../../../../../Config/constantes";

export default function NuevaForma({
  edit,
  data,
  refetch,
  external = false,
  refetchExternal = null,
}) {
  const [open, setOpen] = React.useState(false);
  const [forma, setForma] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const { snackMessage, tokenDecoded } = React.useContext(MainContext);
  const [crearForma] = useMutation(CREAR_FORMA);
  const [actualizarForma] = useMutation(ACTUALIZAR_FORMA);

  const handleOpen = () => {
    setOpen(true);
    if (edit) setForma(data.formaAtraccion);
  };
  const handleClose = () => {
    setOpen(false);
    setForma("");
  };

  const handleSaveForma = async (e) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      if (!forma) return;
      if (edit) handleClose();
      setLoading(true);
      if (!edit) {
        //si es nuevo
        const res = await crearForma({
          variables: {
            token: "",
            idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
            formaAtraccion: forma,
          },
        });
        snackMessage({
          message: res.data.crearForma.message,
          variant: "success",
        });
      } else {
        //si va a actualizar
        const res = await actualizarForma({
          variables: {
            token: "",
            idForma: data._id,
            idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
            formaAtraccion: forma,
          },
        });
        snackMessage({
          message: res.data.actualizarForma.message,
          variant: "success",
        });
      }
      if (external && refetchExternal) refetchExternal();
      if (refetch) refetch();
      setLoading(false);
      if (!edit) handleClose();
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      {edit ? (
        <IconButton onClick={handleOpen}>
          {loading ? <CircularProgress size={20} color="inherit" /> : <Edit />}
        </IconButton>
      ) : external ? (
        <IconButton onClick={handleOpen} size="small" color="primary">
          <Add />
        </IconButton>
      ) : (
        <Button
          startIcon={isMobile ? null : <Add />}
          color="primary"
          variant="contained"
          onClick={handleOpen}
          size="small"
          disableElevation
          sx={{ textTransform: "none", minWidth: 40 }}
        >
          {isMobile ? <Add fontSize="small" /> : "Nuevo"}
        </Button>
      )}

      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        ModalProps={{
          componentsProps: {
            backdrop: {
              sx: { background: "none" },
            },
          },
        }}
        PaperProps={{
          elevation: 1,
          sx: { width: { xs: "100vw", md: "40vw" } },
        }}
      >
        <Toolbar variant="dense" />
        <DialogTitle component="div">
          <Stack spacing={1} direction="row" alignItems="center">
            <IconButton onClick={() => handleClose()}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h6">
              {edit ? `Editar` : "Nueva forma"}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              startIcon={
                loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <Done />
                )
              }
              variant="contained"
              disableElevation
              sx={{ textTransform: "none" }}
              size="small"
              type="submit"
              form="formForma"
            >
              Guardar
            </Button>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ py: 0 }}>
          <form id="formForma" onSubmit={handleSaveForma}>
            <FormControl fullWidth size="small" margin="dense" required>
              <InputLabel id={`label-selec-formaAtrac`}>
                Seleccione una red social
              </InputLabel>
              <Select
                labelId={`label-selec-formaAtrac`}
                siza="small"
                onChange={(e) => setForma(e.target.value)}
                value={forma}
                label="Seleccione una red social"
                required
                startAdornment={
                  <InputAdornment position="start">
                    <AdUnits />
                  </InputAdornment>
                }
              >
                <MenuItem value="">Seleccione uno</MenuItem>
                {SOCIAL_NETWORKS.map((res) => (
                  <MenuItem key={res.name} value={res.name}>
                    {res.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Divider textAlign="left">Otro</Divider>
            <TextField
              label="Otro (especifique)"
              size="small"
              variant="outlined"
              margin="dense"
              fullWidth
              onChange={(e) => setForma(e.target.value)}
              value={forma}
              required
            />
          </form>
        </DialogContent>
      </Drawer>
    </div>
  );
}
