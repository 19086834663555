import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { CustomTableTextField } from "../../../../../Components/CustomMuiComponents";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { ActividadContext } from "../../Context/ActividadContext";
import FechaInputTable from "../../../../../Components/InputsForms/FechaTableInput";
import AutoCompleteInputTable from "../../../../../Components/InputsForms/AutocompleteInputTable";
import NuevoTipoActividad from "../../../Configuraciones/TiposActividades/Actions/NuevoTipoActividad";
import NuevoContacto from "../../../Contactos/Contactos/Actions/NuevoContacto";
import NuevoTrato from "../../../Tratos/Actions/NuevoTrato";
import TratosCtxProvider from "../../../Tratos/Context/TratosContext";
import NuevoUsuario from "../../../Configuraciones/Usuarios/Actions/NuevoUsuario";
import { formatoFechaCorta } from "../../../../../Config/reuserFunctions";
import ContactosCtxProvider from "../../../Contactos/Contactos/Context/ContactoContext";

export default function EditableRowActividad() {
  const { setNewItem, newItem, loadingSave, queryCats } = React.useContext(
    ActividadContext
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewItem((st) => ({ ...st, [name]: value }));
  };

  const handleSetFecha = (name, value) => {
    setNewItem((st) => ({ ...st, [name]: value }));
  };

  const autocompleteChange = (values) => {
    const { name, value, id, idName } = values;
    if (id && idName) {
      setNewItem((st) => ({
        ...st,
        [name]: value,
        [idName]: id,
      }));
    } else {
      setNewItem((st) => ({
        ...st,
        [name]: value,
      }));
    }
  };

  setTimeout(() => {
    document.getElementById(newItem.focusOn)?.focus();
  }, 500);

  return (
    <TableRow role="checkbox" tabIndex={-1}>
      <TableCell padding="checkbox" />
      <TableCell padding="checkbox" />
      <TableCell>
        <CustomTableTextField
          id="actividad-actividad-texfield"
          name="actividad"
          size="small"
          variant="outlined"
          fullWidth
          onChange={handleChange}
          value={newItem.actividad}
          disabled={loadingSave}
          required
          InputProps={{ startAdornment: "*" }}
        />
      </TableCell>
      <TableCell>
        <AutoCompleteInputTable
          id="prioridad-actividad-texfield"
          renderData={["BAJA", "MEDIA", "ALTA"]}
          value={newItem.prioridad}
          disabled={loadingSave}
          onChange={(_, option) =>
            autocompleteChange({
              name: "prioridad",
              value: option || "",
            })
          }
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option) => option || option || null}
        />
      </TableCell>
      <TableCell>
        <AutoCompleteInputTable
          id="contacto-actividad-texfield"
          renderData={queryCats.data?.contactos}
          value={newItem.contacto}
          loading={queryCats.loading}
          error={queryCats.error}
          required={true}
          disabled={loadingSave}
          addButton={
            <ContactosCtxProvider>
              <NuevoContacto
                external={true}
                refetchExternal={queryCats.refetch}
              />
            </ContactosCtxProvider>
          }
          onChange={(_, option) =>
            autocompleteChange({
              name: "contacto",
              idName: "idContacto",
              value: option?.nombre || "",
              id: option?._id || "",
            })
          }
          isOptionEqualToValue={({ nombre }, value) => nombre === value}
          getOptionLabel={(option) => option.nombre || option || null}
        />
      </TableCell>
      <TableCell>
        <AutoCompleteInputTable
          id="status-actividad-texfield"
          renderData={queryCats.data.statusActividad}
          value={newItem.status}
          loading={queryCats.loading}
          error={queryCats.error}
          required={true}
          disabled={loadingSave}
          onChange={(_, option) =>
            autocompleteChange({
              name: "status",
              value: option?.status || "",
              id: option?._id || "",
              idName: "idStatus",
            })
          }
          isOptionEqualToValue={({ status }, value) => status === value}
          getOptionLabel={(option) => option.status || option || null}
        />
      </TableCell>
      <TableCell>
        <Box sx={{ display: "flex" }}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                sx={{ p: 0 }}
                onChange={(e) => handleSetFecha("isDate", e.target.checked)}
                checked={newItem.isDate}
              />
            }
            label={
              <Typography sx={{ ml: 1 }}>
                {newItem.isDate ? "" : "Asignar Fecha"}
              </Typography>
            }
            sx={{ m: 0 }}
          />
          <FechaInputTable
            id="fechaInicio-actividad-texfield"
            name="fechaInicio"
            value={newItem.fechaInicio}
            disabled={loadingSave || !newItem.isDate}
            setState={setNewItem}
            hidden={!newItem.isDate}
          />
        </Box>
      </TableCell>
      <TableCell>
        <Box sx={{ display: "flex" }}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                sx={{ p: 0 }}
                onChange={(e) => handleSetFecha("expires", e.target.checked)}
                checked={newItem.expires}
              />
            }
            label={
              <Typography sx={{ ml: 1 }}>
                {newItem.expires ? "" : "Asignar Fecha"}
              </Typography>
            }
            sx={{ m: 0 }}
          />
          <FechaInputTable
            id="fechaFin-actividad-texfield"
            name="fechaFin"
            value={newItem.fechaFin}
            disabled={loadingSave || !newItem.expires}
            setState={setNewItem}
            hidden={!newItem.expires}
          />
        </Box>
      </TableCell>
      <TableCell>
        <AutoCompleteInputTable
          id="trato-actividad-texfield"
          renderData={queryCats.data?.tratos}
          value={newItem.trato}
          loading={queryCats.loading}
          error={queryCats.error}
          required={true}
          disabled={loadingSave}
          addButton={
            <TratosCtxProvider>
              <NuevoTrato external={true} refetchExternal={queryCats.refetch} />
            </TratosCtxProvider>
          }
          onChange={(_, option) =>
            autocompleteChange({
              name: "trato",
              idName: "idTrato",
              value: option?.nombreTrato || "",
              id: option?._id || "",
            })
          }
          isOptionEqualToValue={({ nombreTrato }, value) =>
            nombreTrato === value
          }
          getOptionLabel={(option) => option.nombreTrato || option || null}
        />
      </TableCell>
      <TableCell>
        <AutoCompleteInputTable
          id="tipoActividad-actividad-texfield"
          renderData={queryCats.data?.tipoActividades}
          value={newItem.tipoActividad}
          loading={queryCats.loading}
          error={queryCats.error}
          required={true}
          addButton={
            <NuevoTipoActividad
              external={true}
              refetchExternal={queryCats.refetch}
            />
          }
          onChange={(_, option) =>
            autocompleteChange({
              name: "tipoActividad",
              idName: "idTipoActividad",
              value: option?.tipoActividad || "",
              id: option?._id || "",
            })
          }
          isOptionEqualToValue={({ tipoActividad }, value) =>
            tipoActividad === value
          }
          getOptionLabel={(option) => option.tipoActividad || option || null}
        />
      </TableCell>
      <TableCell>
        <CustomTableTextField
          id="notas-actividad-texfield"
          name="notas"
          size="small"
          variant="outlined"
          fullWidth
          onChange={handleChange}
          value={newItem.notas}
          disabled={loadingSave}
        />
      </TableCell>
      <TableCell>
        <CustomTableTextField
          id="resultado-actividad-texfield"
          name="resultado"
          size="small"
          variant="outlined"
          fullWidth
          onChange={handleChange}
          value={newItem.resultado}
          disabled={loadingSave}
          sx={{
            display: !newItem.resultado ? "none" : "block",
          }}
        />
      </TableCell>
      <TableCell>
        <AutoCompleteInputTable
          id="usuario-actividad-texfield"
          renderData={queryCats.data?.usuarios}
          value={newItem.usuario}
          loading={queryCats.loading}
          error={queryCats.error}
          required={true}
          disabled={loadingSave}
          addButton={
            <NuevoUsuario external={true} refetchExternal={queryCats.refetch} />
          }
          onChange={(_, option) =>
            autocompleteChange({
              name: "usuario",
              idName: "idUsuario",
              value: option?.nombreCompleto || "",
              id: option?._id || "",
            })
          }
          isOptionEqualToValue={({ nombreCompleto }, value) =>
            nombreCompleto === value
          }
          getOptionLabel={(option) => option.nombreCompleto || option || null}
        />
      </TableCell>
      <TableCell>
        <CustomTableTextField
          id="ubicacion-actividad-texfield"
          name="ubicacion"
          size="small"
          variant="outlined"
          fullWidth
          onChange={handleChange}
          value={newItem.ubicacion}
          disabled={loadingSave}
        />
      </TableCell>
      <TableCell>
        <CustomTableTextField
          id="descripcionU-actividad-texfield"
          name="descripcionUbicacion"
          size="small"
          variant="outlined"
          fullWidth
          onChange={handleChange}
          value={newItem.descripcionUbicacion}
          disabled={loadingSave}
        />
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {formatoFechaCorta(newItem.fechaRegistro)}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
