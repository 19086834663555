import { gql } from "@apollo/client";

export const OBTENER_ACTIVIDADES = gql`
  query ObtenerActividades($idEmpresa: ID!, $filter: ActividadesFiltrosInput) {
    obtenerActividades(idEmpresa: $idEmpresa, filter: $filter) {
      _id
      tipoActividad
      idTipoActividad
      actividad
      isDate
      fechaInicio
      fechaFin
      notas
      contacto
      idContacto
      trato
      idTrato
      ubicacion
      descripcionUbicacion
      usuario
      idUsuario
      status
      statusData {
        _id
        status
        color
      }
      prioridad
      idStatus
      expires
      resultado
      fechaRegistro
      idResultado
      tipoActividadPopulated {
        _id
        accion
        icono
        tipoActividad
      }
      contactoPopulated {
        _id
        correo
        etiqueta
        nombre
        organizacion
        propietario
        statusContacto
        telefono
        tipoContacto
        campaign
        formaAtraccion
        recomendadoPor
        idStatusContacto
        idTipoContacto
        idEtiqueta
        statusContactoPopulated {
          _id
          status
          factor
          color
        }
      }
    }
  }
`;

export const OBTENER_QUERIES_ACTIVIDAD = gql`
  query ObtenerCatalogosActividades($idEmpresa: ID!) {
    obtenerCatalogosActividades(idEmpresa: $idEmpresa) {
      contactos {
        _id
        nombre
        telefono
      }
      tipoActividades {
        _id
        tipoActividad
        icono
        accion
      }
      tratos {
        _id
        nombreTrato
        contacto
      }
      usuarios {
        _id
        foto
        nombreCompleto
        nombreUsuario
      }
      statusActividad {
        _id
        status
        static
        color
      }
      statusContactos {
        _id
        status
        color
      }
    }
  }
`;

export const OBTENER_STATUS_GROUPED = gql`
  query GetStatusGroup($idEmpresa: ID!) {
    getStatusGroup(idEmpresa: $idEmpresa) {
      status
      color
      total
    }
  }
`;
