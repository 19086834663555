import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Add, Done } from "@mui/icons-material";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
} from "@mui/material";
import EditableTextInput from "../../../../../Components/InputsForms/EditableTextInput";
import { useMutation } from "@apollo/client";
import { MainContext } from "../../../../../Context/MainCtx";
import EditableAutoComplete from "../../../../../Components/InputsForms/EditableAutoComlete";
import { ActividadContext } from "../../../Actividades/Context/ActividadContext";
import NuevoTipoActividad from "../../../Configuraciones/TiposActividades/Actions/NuevoTipoActividad";
import moment from "moment-timezone";
import NuevoUsuario from "../../../Configuraciones/Usuarios/Actions/NuevoUsuario";
import { ACTUALIZAR_ACTIVIDAD } from "../../../Actividades/graphql/mutations";
import ChipStatusAct from "../../../Actividades/Actions/ChipStatusAct";
import EditableDateTimeInput from "../../../../../Components/InputsForms/EditableDateTimeInput";
import { formatoFechaCorta } from "../../../../../Config/reuserFunctions";
import ChipPrioridadActividad from "../../../Actividades/Actions/ChipPrioridadAct";

export default function ExtCompActForm({ data, refetch }) {
  const { snackMessage, tokenDecoded } = React.useContext(MainContext);
  const { queryCats } = React.useContext(ActividadContext);
  const [loading, setLoading] = React.useState(false);
  const [actividad, setActividad] = React.useState({ ...data });
  const [actualizarActividad] = useMutation(ACTUALIZAR_ACTIVIDAD);

  const handleChangeActividad = (e) => {
    const { name, value } = e.target;
    setActividad((state) => ({ ...state, [name]: value }));
  };

  const handleSetFecha = (name, value) => {
    let dataUpdated = {
      ...actividad,
      [name]: value,
    };
    if (name === "isDate") {
      dataUpdated.expires = false;
    }
    setActividad(dataUpdated);
    updateActividad(dataUpdated);
  };
  const handleChangeDate = async (name, value) => {
    const dataUpdated = {
      ...actividad,
      [name]: moment(value).tz("America/Mexico_City").format(),
    };
    setActividad(dataUpdated);
    await updateActividad(dataUpdated);
  };
  const autocompleteChange = (values) => {
    const { name, value, id, idName } = values;
    let dataUpdated = {
      ...actividad,
      [name]: value,
    };
    if (id && idName) {
      dataUpdated = {
        ...actividad,
        [name]: value,
        [idName]: id,
      };
    }
    setActividad(dataUpdated);
    updateActividad(dataUpdated);
  };
  const updateActividad = async (act = actividad) => {
    try {
      setLoading(true);
      const {
        _id,
        tipoActividadPopulated,
        contactoPopulated,
        statusData,
        fechaRegistro,
        ...input
      } = act;
      await actualizarActividad({
        variables: {
          idActividad: _id,
          idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
          input,
        },
      });
      refetch();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
      console.log(err.networkError?.result);
    }
  };

  return (
    <Accordion
      elevation={0}
      sx={{
        "& .MuiAccordionSummary-root": {
          backgroundColor: (theme) =>
            `${theme.palette.background.paper}!important`,
        },
        "& .MuiAccordionSummary-content": {
          my: 0,
        },
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} component="div">
        <Box onClick={(e) => e.stopPropagation()} sx={{ width: "100%" }}>
          <Grid container spacing={1}>
            <Grid item>
              <Typography variant="body2" color="gray">
                Status
              </Typography>
              <ChipStatusAct
                actividad={actividad}
                setActividad={setActividad}
                refetch={refetch}
                statusQuery={queryCats.data?.statusActividad}
              />
            </Grid>
            <Grid item sx={{ flexGrow: 1 }}>
              <Typography variant="body2" color="gray">
                Actividad
              </Typography>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  updateActividad();
                }}
              >
                <EditableTextInput
                  fullWidth
                  placeholder="Agregar nombre a la actividad"
                  name="actividad"
                  value={actividad.actividad}
                  icon={<Add />}
                  loading={loading}
                  onChange={handleChangeActividad}
                  actionButton={
                    <IconButton size="small" color="primary" type="submit">
                      {loading ? (
                        <CircularProgress size={20} color="primary" />
                      ) : (
                        <Done />
                      )}
                    </IconButton>
                  }
                />
              </form>
            </Grid>
            <Grid item md={2.5} hidden={!actividad.isDate}>
              <Typography variant="body2" color="gray">
                Recordatorio
              </Typography>
              <Typography>
                {formatoFechaCorta(actividad.fechaInicio)}
              </Typography>
            </Grid>
            <Grid item md={2.5} hidden={!actividad.expires}>
              <Typography variant="body2" color="gray">
                Vencimineto
              </Typography>
              <Typography>{formatoFechaCorta(actividad.fechaFin)}</Typography>
            </Grid>
          </Grid>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <Typography variant="body2" color="gray">
              Tipo Actividad
            </Typography>
            <EditableAutoComplete
              placeholder="Añadir tipo actividad"
              renderData={queryCats.data?.tipoActividades}
              value={actividad.tipoActividad}
              loading={loading}
              loadingQuery={queryCats.loading}
              errorQuery={queryCats.error}
              required={true}
              addButton={
                <NuevoTipoActividad
                  external={true}
                  refetchExternal={queryCats.refetch}
                />
              }
              loadingIcon={<CircularProgress size={20} color="primary" />}
              onChange={(_, option) =>
                autocompleteChange({
                  name: "tipoActividad",
                  idName: "idTipoActividad",
                  value: option?.tipoActividad || "",
                  id: option?._id || "",
                })
              }
              isOptionEqualToValue={({ tipoActividad }, value) =>
                tipoActividad === value
              }
              getOptionLabel={(option) =>
                option.tipoActividad || option || null
              }
            />
          </Grid>
          <Grid item md={4}>
            <Typography variant="body2" color="gray">
              Fecha Recordatorio
            </Typography>
            <Box sx={{ display: "flex" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    sx={{ p: 0 }}
                    onChange={(e) => handleSetFecha("isDate", e.target.checked)}
                    checked={actividad.isDate}
                  />
                }
                label={
                  <Typography sx={{ ml: 1 }}>
                    {actividad.isDate ? "" : "Asignar Fecha"}
                  </Typography>
                }
                sx={{ m: 0 }}
              />
              <Box hidden={!actividad.isDate}>
                <EditableDateTimeInput
                  name="fechaInicio"
                  value={actividad.fechaInicio}
                  onChange={handleChangeDate}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item md={4}>
            <Typography variant="body2" color="gray">
              Vence el
            </Typography>
            <Box sx={{ display: "flex" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    sx={{ p: 0 }}
                    onChange={(e) =>
                      handleSetFecha("expires", e.target.checked)
                    }
                    checked={actividad.expires}
                  />
                }
                label={
                  <Typography sx={{ ml: 1 }}>
                    {actividad.expires ? "" : "Asignar Fecha"}
                  </Typography>
                }
                sx={{ m: 0 }}
              />
              <Box hidden={!actividad.expires}>
                <EditableDateTimeInput
                  name="fechaFin"
                  value={actividad.fechaFin}
                  onChange={handleChangeDate}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="body2" color="gray">
              Usuario asignado
            </Typography>
            <EditableAutoComplete
              placeholder="Añadir usuario asignado"
              renderData={queryCats.data?.usuarios}
              value={actividad.usuario}
              loading={loading}
              loadingQuery={queryCats.loading}
              errorQuery={queryCats.error}
              required={true}
              addButton={
                <NuevoUsuario
                  external={true}
                  refetchExternal={queryCats.refetch}
                />
              }
              loadingIcon={<CircularProgress size={20} color="primary" />}
              onChange={(_, option) =>
                autocompleteChange({
                  name: "usuario",
                  idName: "idUsuario",
                  value: option?.nombreCompleto || "",
                  id: option?._id || "",
                })
              }
              isOptionEqualToValue={({ nombreCompleto }, value) =>
                nombreCompleto === value
              }
              getOptionLabel={(option) =>
                option.nombreCompleto || option || null
              }
            />
          </Grid>
          <Grid item md={4}>
            <Typography variant="body2" color="gray">
              Importancia
            </Typography>
            <ChipPrioridadActividad
              actividad={actividad}
              setActividad={setActividad}
              refetch={refetch}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="gray">
              Notas
            </Typography>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                updateActividad();
              }}
            >
              <EditableTextInput
                fullWidth
                placeholder="Agregar una nota"
                name="notas"
                value={actividad.notas}
                icon={<Add />}
                loading={loading}
                onChange={handleChangeActividad}
                actionButton={
                  <IconButton size="small" color="primary" type="submit">
                    {loading ? (
                      <CircularProgress size={20} color="primary" />
                    ) : (
                      <Done />
                    )}
                  </IconButton>
                }
              />
            </form>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
