import { gql } from "@apollo/client";

export const CREAR_STATUS = gql`
  mutation CrearStatusContacto(
    $idEmpresa: ID!
    $input: StatusInput
  ) {
    crearStatusContacto(
      idEmpresa: $idEmpresa
      input: $input
    ) {
      message
    }
  }
`;
export const ACTUALIZAR_STATUS = gql`
  mutation ActualizarStatusContacto(
    $idEmpresa: ID!
    $idStatusContacto: ID!
    $input: StatusInput
  ) {
    actualizarStatusContacto(
      idEmpresa: $idEmpresa
      idStatusContacto: $idStatusContacto
      input: $input
    ) {
      message
    }
  }
`;
export const ELIMINAR_STATUS = gql`
  mutation EliminarStatusContacto($idStatusContacto: ID!) {
    eliminarStatusContacto(idStatusContacto: $idStatusContacto) {
      message
    }
  }
`;
