import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
  FilterAlt,
  FilterAltOff,
  ManageHistory,
  AccountCircle,
} from "@mui/icons-material";
import { ArrowBack, Close, Done } from "@mui/icons-material";
import { ButtonGroup, InputAdornment, Stack } from "@mui/material";
import { ActividadContext } from "../Context/ActividadContext";
import AutoCompleteInput from "../../../../Components/InputsForms/AutocompleteInput";
import StatusChipInput from "../../../../Components/InputsForms/StatusChips";
import { isMobile } from "../../../../Config/constantes";

export default function FilterActividad() {
  const [open, setOpen] = React.useState(false);
  const [countFilter, setCountFilter] = React.useState(0);
  const { setFilter, initial_filter, queryCats } = React.useContext(
    ActividadContext
  );
  const [localFilter, setLocalFilter] = React.useState(initial_filter);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const onFilterChange = (e) => {
    const { name, value } = e.target;
    setLocalFilter((st) => ({ ...st, [name]: value }));
  };

  const autocompleteChange = (values) => {
    const { name, value } = values;
    setLocalFilter((st) => ({
      ...st,
      [name]: value,
    }));
  };

  const handleFilterOk = (e) => {
    e.preventDefault();
    setFilter((st) => ({
      ...st,
      ...localFilter,
    }));
    const arrValues = Object.values(localFilter);
    let count = 0;
    arrValues.forEach((res) => (res ? count++ : null));
    setCountFilter(count);
    handleClose();
  };

  const clearFilter = () => {
    const { searchFilter, ...initial } = initial_filter;
    setLocalFilter(initial);
    setFilter((st) => ({
      ...st,
      ...initial,
    }));
    setCountFilter(0);
    handleClose();
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        color="inherit"
        size="small"
        disableElevation
      >
        <Button
          size="small"
          startIcon={
            isMobile ? null : countFilter ? (
              countFilter
            ) : (
              <FilterAlt color="action" />
            )
          }
          onClick={handleOpen}
          sx={{ textTransform: "none" }}
        >
          {isMobile ? (
            countFilter ? (
              countFilter
            ) : (
              <FilterAlt fontSize="small" color="action" />
            )
          ) : (
            "Filtrar"
          )}
        </Button>
        {countFilter ? (
          <Button
            size="small"
            onClick={clearFilter}
            disabled={!countFilter}
            sx={{ minWidth: "25px!important", padding: 0 }}
          >
            <Close sx={{ fontSize: 16 }} />
          </Button>
        ) : null}
      </ButtonGroup>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        ModalProps={{
          componentsProps: {
            backdrop: {
              sx: { background: "none" },
            },
          },
        }}
        PaperProps={{
          elevation: 1,
          sx: { width: { xs: "100vw", md: "30vw" } },
        }}
      >
        <Toolbar />
        <DialogTitle sx={{ py: 0 }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton onClick={() => handleClose()}>
              <ArrowBack />
            </IconButton>
            <div style={{ flexGrow: 1 }} />
            <Button
              startIcon={<FilterAltOff />}
              size="small"
              disableElevation
              onClick={clearFilter}
            >
              Limpiar
            </Button>
            <Button
              startIcon={<Done />}
              size="small"
              variant="contained"
              disableElevation
              type="submit"
              form="filter-actividades"
            >
              Filtrar
            </Button>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <form id="filter-actividades" onSubmit={handleFilterOk}>
            <Typography>Por fecha:</Typography>
            <TextField
              size="small"
              variant="outlined"
              margin="dense"
              name="fechaStart"
              value={localFilter.fechaStart}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">De: </InputAdornment>
                ),
              }}
              onChange={onFilterChange}
              fullWidth
              type="date"
            />
            <TextField
              size="small"
              variant="outlined"
              margin="dense"
              name="fechaEnd"
              value={localFilter.fechaEnd}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">A: </InputAdornment>
                ),
              }}
              onChange={onFilterChange}
              fullWidth
              type="date"
            />
            <AutoCompleteInput
              renderData={queryCats.data?.tipoActividades}
              value={localFilter.tipoActividad}
              label="Tipo actividad"
              icon={<ManageHistory />}
              loading={queryCats.loading}
              error={queryCats.error}
              onChange={(_, option) =>
                autocompleteChange({
                  name: "tipoActividad",
                  value: option?.tipoActividad || "",
                })
              }
              isOptionEqualToValue={({ tipoActividad }, value) =>
                tipoActividad === value
              }
              getOptionLabel={(option) =>
                option.tipoActividad || option || null
              }
            />
            <AutoCompleteInput
              renderData={queryCats.data?.usuarios}
              value={localFilter.usuario}
              label="Usuario asignado"
              icon={<AccountCircle />}
              loading={queryCats.loading}
              error={queryCats.error}
              onChange={(_, option) =>
                autocompleteChange({
                  name: "usuario",
                  value: option?.nombreCompleto || "",
                })
              }
              isOptionEqualToValue={({ nombreCompleto }, value) =>
                nombreCompleto === value
              }
              getOptionLabel={(option) =>
                option.nombreCompleto || option || null
              }
            />
            <Box sx={{ my: 1 }}>
              <Typography>Status</Typography>
              <StatusChipInput
                data={queryCats.data?.statusActividad}
                value={localFilter.status}
                setState={setLocalFilter}
              />
            </Box>
          </form>
        </DialogContent>
      </Drawer>
    </>
  );
}
