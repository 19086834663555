import { Stack } from "@mui/material";
import React from "react";
import FilterProducto from "./Actions/FilterProducto";
import NuevoProducto from "./Actions/NuevoProducto";
import SearchProducto from "./Actions/Search";
import ProductosTable from "./Table/ProductosTable";
import ProductoCtxProvider from "./Context/ProductoContext";

export default function Productos() {
  return (
    <ProductoCtxProvider>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ mb: 1, mt: 2, width: {xs: "100%", md: "50%"} }}
        bgcolor="background.paper"
      >
        <SearchProducto />
        <FilterProducto />
        <NuevoProducto />
      </Stack>
      <ProductosTable />
    </ProductoCtxProvider>
  );
}
