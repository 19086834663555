import * as React from "react";
import IconButton from "@mui/material/IconButton";
import DriveFileMoveOutlined from "@mui/icons-material/DriveFileMoveOutlined";
import { Event } from "@mui/icons-material";
import SelectingOption from "./Seleccting";
import DeleteOption from "./EliminarOption";
import { Box, Stack } from "@mui/material";

export default function MenuOptionsTrato({ trato, handleSelect }) {
  return (
    <Box sx={{ mx: 1, py: 1, borderTop: "1px solid", borderColor: "divider" }}>
      <Stack direction="row" justifyContent="space-evenly">
        <SelectingOption trato={trato} handleSelect={handleSelect} />
        <IconButton size="small">
          <Event fontSize="small" />
        </IconButton>
        <IconButton size="small">
          <DriveFileMoveOutlined fontSize="small" />
        </IconButton>
        <DeleteOption trato={trato} />
      </Stack>
    </Box>
  );
}
