import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { CustomTableTextField } from "../../../../../Components/CustomMuiComponents";
import { Typography } from "@mui/material";
import { ContactosContext } from "../Context/ContactoContext";
import AutoCompleteInputTable from "../../../../../Components/InputsForms/AutocompleteInputTable";
import NuevoUsuario from "../../../Configuraciones/Usuarios/Actions/NuevoUsuario";
import { formatoFechaCorta } from "../../../../../Config/reuserFunctions";
import NuevoStatus from "../../StatusContacto/Actions/NuevoStatus";
import NuevaEtiqueta from "../../Etiquetas/Actions/NuevaEtiqueta";
import NuevoTipoC from "../../TipoContacto/Actions/NuevoTipoC";
import NuevaForma from "../../../Configuraciones/FormasAtraccion/Actions/NuevaForma";
import NuevaCampaign from "../../../Configuraciones/Campaigns/Actions/NuevaCampaign";

export default function EditableRowContacto() {
  const { setNewItem, newItem, loadingSave, queryCats } = React.useContext(
    ContactosContext
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewItem((st) => ({ ...st, [name]: value }));
  };

  const autocompleteChange = (values) => {
    const { name, value, id, idName } = values;
    if (id && idName) {
      setNewItem((st) => ({
        ...st,
        [name]: value,
        [idName]: id,
      }));
    } else {
      setNewItem((st) => ({
        ...st,
        [name]: value,
      }));
    }
  };

  setTimeout(() => {
    document.getElementById(newItem.focusOn)?.focus();
  }, 500);

  return (
    <TableRow role="checkbox" tabIndex={-1}>
      <TableCell padding="checkbox" />
      <TableCell padding="checkbox" />
      <TableCell padding="checkbox" />
      <TableCell>
        <CustomTableTextField
          id="nombre-contacto-texfield"
          name="nombre"
          size="small"
          variant="outlined"
          fullWidth
          onChange={handleChange}
          value={newItem.nombre}
          disabled={loadingSave}
          required
          InputProps={{ startAdornment: "*" }}
        />
      </TableCell>
      <TableCell>
        <CustomTableTextField
          id="telefono-contacto-texfield"
          name="telefono"
          size="small"
          variant="outlined"
          fullWidth
          onChange={handleChange}
          value={newItem.telefono}
          disabled={loadingSave}
          required
          InputProps={{ startAdornment: "*" }}
        />
      </TableCell>
      <TableCell>
        <CustomTableTextField
          id="correo-contacto-texfield"
          name="correo"
          size="small"
          variant="outlined"
          fullWidth
          onChange={handleChange}
          value={newItem.correo}
          disabled={loadingSave}
        />
      </TableCell>
      <TableCell>
        <AutoCompleteInputTable
          id="statusContacto-contacto-texfield"
          renderData={queryCats.data?.statusContactos}
          value={newItem.statusContacto}
          loading={queryCats.loading}
          error={queryCats.error}
          required={true}
          disabled={loadingSave}
          addButton={
            <NuevoStatus external={true} refetchExternal={queryCats.refetch} />
          }
          onChange={(_, option) =>
            autocompleteChange({
              name: "statusContacto",
              idName: "idStatusContacto",
              value: option?.status || "",
              id: option?._id || "",
            })
          }
          isOptionEqualToValue={({ status }, value) => status === value}
          getOptionLabel={(option) => option.status || option || null}
        />
      </TableCell>
      <TableCell>
        <AutoCompleteInputTable
          id="etiqueta-contacto-texfield"
          renderData={queryCats.data?.etiquetas}
          value={newItem.etiqueta}
          loading={queryCats.loading}
          error={queryCats.error}
          required={true}
          disabled={loadingSave}
          addButton={
            <NuevaEtiqueta
              external={true}
              refetchExternal={queryCats.refetch}
            />
          }
          onChange={(_, option) =>
            autocompleteChange({
              name: "etiqueta",
              idName: "idEtiqueta",
              value: option?.etiqueta || "",
              id: option?._id || "",
            })
          }
          isOptionEqualToValue={({ etiqueta }, value) => etiqueta === value}
          getOptionLabel={(option) => option.etiqueta || option || null}
        />
      </TableCell>
      <TableCell>
        <AutoCompleteInputTable
          id="tipoContacto-contacto-texfield"
          renderData={queryCats.data?.tiposContactos}
          value={newItem.tipoContacto}
          loading={queryCats.loading}
          error={queryCats.error}
          required={true}
          disabled={loadingSave}
          addButton={
            <NuevoTipoC external={true} refetchExternal={queryCats.refetch} />
          }
          onChange={(_, option) =>
            autocompleteChange({
              name: "tipoContacto",
              idName: "idTipoContacto",
              value: option?.tipoContacto || "",
              id: option?._id || "",
            })
          }
          isOptionEqualToValue={({ tipoContacto }, value) =>
            tipoContacto === value
          }
          getOptionLabel={(option) => option.tipoContacto || option || null}
        />
      </TableCell>
      <TableCell>
        <AutoCompleteInputTable
          id="propietario-contacto-texfield"
          renderData={queryCats.data?.usuarios}
          value={newItem.propietario}
          loading={queryCats.loading}
          error={queryCats.error}
          required={true}
          disabled={loadingSave}
          addButton={
            <NuevoUsuario external={true} refetchExternal={queryCats.refetch} />
          }
          onChange={(_, option) =>
            autocompleteChange({
              name: "propietario",
              idName: "idPropietario",
              value: option?.nombreCompleto || "",
              id: option?._id || "",
            })
          }
          isOptionEqualToValue={({ nombreCompleto }, value) =>
            nombreCompleto === value
          }
          getOptionLabel={(option) => option.nombreCompleto || option || null}
        />
      </TableCell>
      <TableCell>
        <CustomTableTextField
          id="organizacion-contacto-texfield"
          name="organizacion"
          size="small"
          variant="outlined"
          fullWidth
          onChange={handleChange}
          value={newItem.organizacion}
          disabled={loadingSave}
        />
      </TableCell>
      <TableCell>
        <AutoCompleteInputTable
          id="formaAtraccion-contacto-texfield"
          renderData={queryCats.data?.formasAtraccion}
          value={newItem.formaAtraccion}
          loading={queryCats.loading}
          error={queryCats.error}
          disabled={loadingSave}
          addButton={
            <NuevaForma external={true} refetchExternal={queryCats.refetch} />
          }
          onChange={(_, option) =>
            autocompleteChange({
              name: "formaAtraccion",
              idName: "idFormaAtraccion",
              value: option?.formaAtraccion || "",
              id: option?._id || "",
            })
          }
          isOptionEqualToValue={({ formaAtraccion }, value) =>
            formaAtraccion === value
          }
          getOptionLabel={(option) => option.formaAtraccion || option || null}
        />
      </TableCell>
      <TableCell>
        <AutoCompleteInputTable
          id="campaign-contacto-texfield"
          renderData={queryCats.data?.campaigns}
          value={newItem.campaign}
          loading={queryCats.loading}
          error={queryCats.error}
          disabled={loadingSave}
          addButton={
            <NuevaCampaign external={true} refetchExternal={queryCats.refetch} />
          }
          onChange={(_, option) =>
            autocompleteChange({
              name: "campaign",
              idName: "idCampaign",
              value: option?.nombre || "",
              id: option?._id || "",
            })
          }
          isOptionEqualToValue={({ nombre }, value) =>
            nombre === value
          }
          getOptionLabel={(option) => option.nombre || option || null}
        />
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {formatoFechaCorta(newItem.fechaRegistro)}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
