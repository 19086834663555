import { gql } from "@apollo/client";

export const OBTENER_UNIDADES_NEGOCIO = gql`
  query ObtenerUnidadNegocios($idEmpresa: ID!, $filter: String) {
    obtenerUnidadNegocios(idEmpresa: $idEmpresa, filter: $filter) {
      _id
      nombreUnidad
      idEmpresa
    }
  }
`;
