import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {
  CustomEditableTextField,
  CustomTableAutocomplete,
  CustomTableTextField,
} from "../CustomMuiComponents";
import { InfoOutlined } from "@mui/icons-material";

export default function ErrorAutocomplete({ table, editable, label, value = "" }) {
  if (table) {
    return (
      <CustomTableAutocomplete
        disabled
        renderInput={(params) => (
          <CustomTableTextField
            {...params}
            label={label}
            InputProps={{
              ...params.InputProps,
              startAdornment: "Error al cargar",
            }}
          />
        )}
      />
    );
  } else if (editable) {
    return (
      <Autocomplete
        freeSolo
        value={value}
        options={[]}
        readOnly
        renderInput={(params) => (
          <CustomEditableTextField
            {...params}
            size="small"
            InputProps={{
              ...params.InputProps,
              startAdornment: <InfoOutlined color="disabled" size={18} sx={{ mx: 1 }} />,
              sx: {
                "&.Mui-focused fieldset": {
                  border: "none!important",
                },
              },
            }}
          />
        )}
      />
    );
  } else {
    return (
      <Autocomplete
        disabled
        renderInput={(params) => (
          <TextField
            {...params}
            margin="dense"
            size="small"
            label={label}
            InputProps={{
              ...params.InputProps,
              startAdornment: "Error al cargar",
            }}
          />
        )}
      />
    );
  }
}
