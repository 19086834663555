import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Delete } from "@mui/icons-material";
import { ELIMINAR_ACTIVIDAD } from "../graphql/mutations";
import { useMutation } from "@apollo/client";
import { CircularProgress, IconButton } from "@mui/material";
import { MainContext } from "../../../../Context/MainCtx";
import { ActividadContext } from "../Context/ActividadContext";

export default function EliminarActividad({
  data,
  refetchTratos,
  externalHandleClose = null,
  size="small",
  sxButton = {p: 0}
}) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { snackMessage } = React.useContext(MainContext);
  const { queryRefetch } = React.useContext(ActividadContext);
  const [eliminarActividad] = useMutation(ELIMINAR_ACTIVIDAD);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteActivity = async () => {
    try {
      handleClose();
      setLoading(true);
      await eliminarActividad({
        variables: {
          idActividad: data._id,
        },
      });
      if (refetchTratos) {
        refetchTratos();
      } else {
        queryRefetch();
      }
      if (externalHandleClose) externalHandleClose();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      <IconButton size={size} onClick={() => handleClickOpen()} sx={sxButton}>
        {loading ? <CircularProgress size={20} color="inherit" /> : <Delete />}
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{`Eliminar ${data.actividad}`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Se va a eliminar este elemento, ¿Quiere continuar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus color="inherit">
            No, cancelar
          </Button>
          <Button
            onClick={() => handleDeleteActivity()}
            color="error"
            variant="contained"
            disableElevation
          >
            Si, eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
