import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Add from "@mui/icons-material/Add";
import EditableTextInput from "../../../../../Components/InputsForms/EditableTextInput";
import EditableAutoComplete from "../../../../../Components/InputsForms/EditableAutoComlete";
import NuevoUsuario from "../../../Configuraciones/Usuarios/Actions/NuevoUsuario";
import NuevoTipoActividad from "../../../Configuraciones/TiposActividades/Actions/NuevoTipoActividad";
import { ActividadContext } from "../../Context/ActividadContext";
import moment from "moment-timezone";
import ChipStatusAct from "../ChipStatusAct";
import ChipPrioridadActividad from "../ChipPrioridadAct";
import TratosCtxProvider from "../../../Tratos/Context/TratosContext";
import NuevoTrato from "../../../Tratos/Actions/NuevoTrato";
import EditableDateTimeInput from "../../../../../Components/InputsForms/EditableDateTimeInput";
import NuevoContacto from "../../../Contactos/Contactos/Actions/NuevoContacto";
import ContactosCtxProvider from "../../../Contactos/Contactos/Context/ContactoContext";

export default function ActividadDetail() {
  const { queryCats, queryRefetch, actividad, setActividad } = React.useContext(
    ActividadContext
  );

  const handleChangeActividad = (e) => {
    const { name, value } = e.target;
    setActividad((state) => ({ ...state, [name]: value }));
  };
  const handleSetFecha = (name, value) => {
    let dataUpdated = {
      ...actividad,
      [name]: value,
    };
    if (name === "isDate") {
      dataUpdated.expires = false;
    }
    setActividad(dataUpdated);
  };
  const handleChangeDate = (name, value) => {
    setActividad((st) => ({
      ...st,
      [name]: moment(value).tz("America/Mexico_City").format(),
    }));
  };
  const autocompleteChange = (values) => {
    const { name, value, id, idName } = values;
    let dataUpdated = {
      ...actividad,
      [name]: value,
    };
    if (id && idName) {
      dataUpdated = {
        ...actividad,
        [name]: value,
        [idName]: id,
      };
    }
    if (values.color) dataUpdated.color = values.color;
    setActividad(dataUpdated);
  };

  return (
    <Box sx={{ height: "80vh" }}>
      <Grid container spacing={1} onClick={(e) => e.stopPropagation()}>
        <Grid item xs={12} md={7}>
          <Typography variant="body2" color="gray">
            <span style={{ color: "red" }}>*</span>
            Tipo Actividad
          </Typography>
          <EditableAutoComplete
            tabIndex={1}
            placeholder="Añadir tipo actividad"
            renderData={queryCats.data?.tipoActividades}
            value={actividad.tipoActividad}
            loadingQuery={queryCats.loading}
            errorQuery={queryCats.error}
            required
            addButton={
              <NuevoTipoActividad
                external={true}
                refetchExternal={queryCats.refetch}
              />
            }
            onChange={(_, option) =>
              autocompleteChange({
                name: "tipoActividad",
                idName: "idTipoActividad",
                value: option?.tipoActividad || "",
                id: option?._id || "",
              })
            }
            isOptionEqualToValue={({ tipoActividad }, value) =>
              tipoActividad === value
            }
            getOptionLabel={(option) => option.tipoActividad || option || null}
          />
        </Grid>
        <Grid item xs={6} md={2.5}>
          <Typography variant="body2" color="gray">
            <span style={{ color: "red" }}>*</span>Status
          </Typography>
          <ChipStatusAct
            tabIndex={2}
            actividad={actividad}
            setActividad={setActividad}
            refetch={queryRefetch}
            statusQuery={queryCats.data?.statusActividad}
          />
        </Grid>
        <Grid item xs={6} md={2.5}>
          <Typography variant="body2" color="gray">
            Importancia
          </Typography>
          <ChipPrioridadActividad
            tabIndex={3}
            actividad={actividad}
            setActividad={setActividad}
            refetch={queryRefetch}
            onchange={autocompleteChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="gray">
            <span style={{ color: "red" }}>*</span>Actividad
          </Typography>
          <EditableTextInput
            tabIndex={4}
            fullWidth
            placeholder="Agregar nombre a la actividad"
            name="actividad"
            value={actividad.actividad}
            icon={<Add />}
            onChange={handleChangeActividad}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="gray">
            <span style={{ color: "red" }}>*</span>Trato
          </Typography>
          <EditableAutoComplete
            tabIndex={5}
            placeholder="Asignar trato"
            renderData={queryCats.data?.tratos}
            value={actividad.trato}
            loadingQuery={queryCats.loading}
            errorQuery={queryCats.error}
            required
            addButton={
              <TratosCtxProvider>
                <NuevoTrato
                  external={true}
                  refetchExternal={queryCats.refetch}
                />
              </TratosCtxProvider>
            }
            onChange={(_, option) =>
              autocompleteChange({
                name: "trato",
                idName: "idTrato",
                value: option?.nombreTrato || "",
                id: option?._id || "",
              })
            }
            isOptionEqualToValue={({ nombreTrato }, value) =>
              nombreTrato === value
            }
            getOptionLabel={(option) => option.nombreTrato || option || null}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="gray">
            <span style={{ color: "red" }}>*</span>Contacto
          </Typography>
          <EditableAutoComplete
            tabIndex={6}
            placeholder="Asignar contacto"
            renderData={queryCats.data?.contactos}
            value={
              actividad?.contactoPopulated?.length > 0
                ? actividad.contactoPopulated[0].nombre
                : actividad.contacto
            }
            loadingQuery={queryCats.loading}
            errorQuery={queryCats.error}
            required
            addButton={
              <ContactosCtxProvider>
                <NuevoContacto
                  external={true}
                  refetchExternal={queryCats.refetch}
                />
              </ContactosCtxProvider>
            }
            onChange={(_, option) =>
              autocompleteChange({
                name: "contacto",
                idName: "idContacto",
                value: option?.nombre || "",
                id: option?._id || "",
              })
            }
            isOptionEqualToValue={({ nombre }, value) => nombre === value}
            getOptionLabel={(option) => option.nombre || option || null}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body2" color="gray">
            Fecha Recordatorio
          </Typography>
          <Box sx={{ display: "flex" }}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  sx={{ p: 0 }}
                  onChange={(e) => handleSetFecha("isDate", e.target.checked)}
                  checked={actividad.isDate}
                />
              }
              label={
                <Typography sx={{ ml: 1 }}>
                  {actividad.isDate ? "" : "Asignar Fecha"}
                </Typography>
              }
              sx={{ m: 0 }}
            />
            <Box hidden={!actividad.isDate}>
              <EditableDateTimeInput
                tabIndex={7}
                name="fechaInicio"
                value={actividad.fechaInicio}
                onChange={handleChangeDate}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body2" color="gray">
            Vence el
          </Typography>
          <Box sx={{ display: "flex" }}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  sx={{ p: 0 }}
                  onChange={(e) => handleSetFecha("expires", e.target.checked)}
                  checked={actividad.expires}
                />
              }
              label={
                <Typography sx={{ ml: 1 }}>
                  {actividad.expires ? "" : "Asignar Fecha"}
                </Typography>
              }
              sx={{ m: 0 }}
            />
            <Box hidden={!actividad.expires}>
              <EditableDateTimeInput
                tabIndex={8}
                name="fechaFin"
                value={actividad.fechaFin}
                onChange={handleChangeDate}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="gray">
            <span style={{ color: "red" }}>*</span>Usuario asignado
          </Typography>
          <EditableAutoComplete
            tabIndex={10}
            placeholder="Añadir usuario asignado"
            renderData={queryCats.data?.usuarios}
            value={actividad.usuario}
            loadingQuery={queryCats.loading}
            errorQuery={queryCats.error}
            required
            addButton={
              <NuevoUsuario
                external={true}
                refetchExternal={queryCats.refetch}
              />
            }
            onChange={(_, option) =>
              autocompleteChange({
                name: "usuario",
                idName: "idUsuario",
                value: option?.nombreCompleto || "",
                id: option?._id || "",
              })
            }
            isOptionEqualToValue={({ nombreCompleto }, value) =>
              nombreCompleto === value
            }
            getOptionLabel={(option) => option.nombreCompleto || option || null}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="gray">
            Notas
          </Typography>
          <EditableTextInput
            tabIndex={11}
            fullWidth
            placeholder="Agregar una nota"
            name="notas"
            value={actividad.notas}
            icon={<Add />}
            onChange={handleChangeActividad}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
