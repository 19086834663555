import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import { getContrastColor } from "../../Config/reuserFunctions";

export default function StatusChipInput({
  data,
  value,
  setState,
  needsId = false,
  name = "status",
  idName = "idStatus",
}) {
  const handleClick = (status, id) => {
    if (needsId) {
      setState((state) => ({
        ...state,
        [name]: status,
        [idName]: id,
      }));
      return;
    }
    setState((state) => ({
      ...state,
      [name]: status,
    }));
  };

  return (
    <Grid container spacing={1}>
      {data.map(({ _id, status, color }) => (
        <Grid item md={4} key={_id}>
          <Chip
            label={status}
            clickable
            onClick={() => handleClick(status, _id)}
            icon={
              <Checkbox
                sx={{
                  m: 0,
                  p: 0,
                  color: `${getContrastColor(color)}!important`,
                }}
                checked={value === status ? true : false}
              />
            }
            sx={{
              width: "100%",
              fontWeight: "bold",
              backgroundColor: color,
              color: getContrastColor(color),
              ":hover": { opacity: 0.8, bgcolor: color },
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
}
