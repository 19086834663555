import * as React from "react";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { Abc, Category, Notes, QrCode, Straighten } from "@mui/icons-material";
import ImagenesProducto from "./Imagenes";
import Descuento from "./Descuentos";
import { ProductoContext } from "../../Context/ProductoContext";

export default function FormProducto({ handleSaveProducto }) {
  const { productData, setProductData, units } = React.useContext(
    ProductoContext
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData((st) => ({
      ...st,
      [name]:
        name === "costo" ||
        name === "precio" ||
        name === "promocion" ||
        name === "valorDescuento"
          ? parseFloat(value)
          : value.toString(),
    }));
  };

  return (
    <form id="producto-form" onSubmit={handleSaveProducto}>
      <ImagenesProducto />
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <QrCode />
            </InputAdornment>
          ),
        }}
        name="skuCodigo"
        value={productData.skuCodigo}
        onChange={handleChange}
        label="Código"
        size="small"
        variant="outlined"
        margin="dense"
        fullWidth
        required
      />
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Abc />
            </InputAdornment>
          ),
        }}
        name="nombre"
        value={productData.nombre}
        onChange={handleChange}
        label="Nombre"
        size="small"
        variant="outlined"
        margin="dense"
        fullWidth
        required
      />
      <FormControl fullWidth size="small" margin="dense">
        <InputLabel id="label-unidad">Unidad</InputLabel>
        <Select
          labelId="label-unidad"
          siza="small"
          name="unidadMedida"
          value={productData.unidadMedida ? productData.unidadMedida : ""}
          onChange={handleChange}
          label="Unidad"
          startAdornment={
            <InputAdornment position="start">
              <Straighten />
            </InputAdornment>
          }
        >
          <MenuItem value="">Seleccione uno</MenuItem>
          {units.map((res, index) => (
            <MenuItem key={`${res}-${index}`} value={res}>
              {res}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Notes />
            </InputAdornment>
          ),
        }}
        name="descripcion"
        value={productData.descripcion}
        onChange={handleChange}
        label="Descripción"
        size="small"
        variant="outlined"
        margin="dense"
        fullWidth
      />
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Category />
            </InputAdornment>
          ),
        }}
        name="categoria"
        value={productData.categoria}
        onChange={handleChange}
        label="Categoría"
        size="small"
        variant="outlined"
        margin="dense"
        fullWidth
      />
      <TextField
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        name="costo"
        value={productData.costo}
        onChange={handleChange}
        type="number"
        label="Costo"
        size="small"
        variant="outlined"
        margin="dense"
        fullWidth
      />
      <TextField
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        name="precio"
        value={productData.precio}
        onChange={handleChange}
        type="number"
        label="Precio"
        size="small"
        variant="outlined"
        margin="dense"
        fullWidth
        required
      />
      <Descuento productData={productData} setProductData={setProductData} />
    </form>
  );
}
