import { useTheme } from "@mui/material";
import React from "react";

const ComodyHorizontalIcon = ({
  size = 5,
  color = "default",
  className = "",
  style = {},
}) => {
  let hexcolor1;
  let hexcolor2;
  const theme = useTheme();

  switch (color) {
    case "primary":
      hexcolor1 = theme.palette.primary.main;
      hexcolor2 = theme.palette.primary.main;
      break;
    case "secondary":
      hexcolor1 = theme.palette.secondary.main;
      hexcolor2 = theme.palette.secondary.main;
      break;
    case "comodyBlue":
      hexcolor1 = theme.palette.comodyBlue;
      hexcolor2 = theme.palette.comodyBlue;
      break;
    case "twoColor":
      hexcolor1 = theme.palette.primary.main;
      hexcolor2 = theme.palette.secondary.main;
      break;
    default:
      hexcolor1 = "#FFFFFF";
      hexcolor2 = "#FFFFFF";
      break;
  }

  return (
    <svg
      style={style}
      height="100%"
      strokeMiterlimit="10"
      version="1.1"
      viewBox="0 0 2690.46 530.02"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsvectornator="http://vectornator.io"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={`${size}rem`}
      className={className}
    >
      <defs />
      <clipPath id="ArtboardFrame2">
        <rect height="530.02" width="2690.46" x="0" y="0" />
      </clipPath>
      <g
        clipPath="url(#ArtboardFrame2)"
        id="Untitled-2"
        vectornatorlayername="Untitled-2"
      >
        <g opacity="1" vectornatorlayername="path">
          <clipPath clipRule="nonzero" id="ClipPath2">
            <path d="M0 1.95313e-05L2690.45 1.95313e-05L2690.45 530.02L0 530.02L0 1.95313e-05Z" />
          </clipPath>
          <g clipPath="url(#ClipPath2)">
            <path
              d="M443.296 318.168L366.496 296.971C360.938 295.437 355.158 298.4 353.087 303.781C338.208 342.423 300.823 369.898 256.972 370.114C199.942 370.396 153.119 323.964 152.937 266.933C152.754 209.668 199.084 163.153 256.323 163.081C299.891 163.025 337.202 189.896 352.524 227.986C354.658 233.292 360.508 236.074 365.995 234.465L442.445 212.038C448.623 210.226 451.976 203.586 449.81 197.522C421.733 118.933 347.12 62.482 259.191 61.335C146.623 59.867 52.3066 151.927 51.1836 264.499C50.8166 301.367 60.1806 336.007 76.8436 366.033L52.0436 440.751C46.0116 458.922 63.3146 476.18 81.4696 470.103L155.457 445.336C185.351 462.267 219.903 471.925 256.715 471.879C346.936 471.764 423.478 413.46 450.909 332.518C452.969 326.439 449.483 319.875 443.296 318.168"
              fill={hexcolor1}
              fillRule="nonzero"
              opacity="1"
              stroke="none"
              vectornatorlayername="path"
            />
            <path
              d="M294.49 266.55C294.517 287.643 277.439 304.764 256.347 304.79C235.254 304.817 218.134 287.739 218.107 266.647C218.08 245.554 235.157 228.434 256.25 228.406C277.343 228.38 294.463 245.457 294.49 266.55"
              fill={hexcolor1}
              fillRule="nonzero"
              opacity="1"
              stroke="none"
              vectornatorlayername="path"
            />
            <path
              d="M822.362 302.512L768.225 287.57C764.307 286.489 760.233 288.577 758.773 292.37C748.285 319.61 721.932 338.978 691.021 339.129C650.82 339.327 617.814 306.597 617.686 266.396C617.557 226.03 650.215 193.241 690.563 193.19C721.275 193.151 747.575 212.092 758.376 238.943C759.881 242.683 764.004 244.644 767.872 243.509L821.762 227.7C826.117 226.423 828.481 221.742 826.953 217.468C807.162 162.07 754.567 122.278 692.585 121.469C613.235 120.434 546.751 185.327 545.96 264.68C545.7 290.669 552.301 315.086 564.048 336.252L546.566 388.921C542.314 401.731 554.51 413.896 567.308 409.612L619.462 392.154C640.535 404.088 664.891 410.897 690.84 410.864C754.437 410.783 808.392 369.684 827.728 312.627C829.18 308.342 826.724 303.716 822.362 302.512"
              fill={hexcolor2}
              fillRule="nonzero"
              opacity="1"
              stroke="none"
              vectornatorlayername="path"
            />
            <path
              d="M1000.91 339.773C960.482 339.825 927.666 307.091 927.615 266.662C927.563 226.231 960.297 193.415 1000.73 193.364C1041.16 193.312 1073.97 226.045 1074.02 266.475C1074.08 306.906 1041.34 339.722 1000.91 339.773M1000.64 121.399C920.461 121.501 855.549 186.578 855.65 266.752C855.752 346.927 920.829 411.839 1001 411.738C1081.18 411.635 1146.09 346.559 1145.99 266.384C1145.89 186.21 1080.81 121.297 1000.64 121.399"
              fill={hexcolor2}
              fillRule="nonzero"
              opacity="1"
              stroke="none"
              vectornatorlayername="path"
            />
            <path
              d="M1877.33 338.661C1836.9 338.713 1804.08 305.979 1804.03 265.55C1803.98 225.119 1836.71 192.303 1877.14 192.252C1917.57 192.2 1950.39 224.933 1950.44 265.363C1950.49 305.794 1917.76 338.61 1877.33 338.661M1877.05 120.287C1796.87 120.389 1731.96 185.466 1732.06 265.64C1732.17 345.815 1797.24 410.727 1877.42 410.626C1957.59 410.523 2022.5 345.447 2022.4 265.272C2022.3 185.098 1957.22 120.185 1877.05 120.287"
              fill={hexcolor2}
              fillRule="nonzero"
              opacity="1"
              stroke="none"
              vectornatorlayername="path"
            />
            <path
              d="M1620.95 140.397C1599.44 127.849 1574.42 120.672 1547.72 120.706C1521.02 120.74 1496.01 127.98 1474.54 140.583C1461.14 148.448 1449.11 158.395 1438.9 169.991C1428.51 158.248 1416.24 148.194 1402.57 140.308C1381.2 127.983 1356.41 120.949 1329.96 120.982C1303.26 121.016 1278.26 128.256 1256.78 140.859C1213.77 166.101 1184.91 212.859 1184.98 266.335L1185.15 401.679C1185.16 407.106 1189.56 411.5 1194.99 411.492L1247.3 411.425C1252.73 411.418 1257.12 407.014 1257.12 401.588L1256.94 266.245C1256.89 225.814 1289.63 192.998 1330.06 192.947C1350.62 192.92 1371.09 202.539 1385.07 217.468C1397.93 231.21 1402.32 247.181 1402.34 265.55C1402.38 295.865 1402.42 326.179 1402.46 356.494C1402.48 368.789 1402.68 389.379 1402.81 401.495C1402.87 406.885 1407.26 411.222 1412.65 411.213L1465.54 411.134C1470.97 411.126 1475.36 406.722 1475.35 401.297L1475.27 336.309C1475.24 313.62 1474.98 290.914 1475.3 268.226C1475.35 265.16 1475.19 262.103 1475.17 259.04C1474.92 221.891 1513.19 192.714 1547.81 192.67C1588.24 192.619 1621.06 225.352 1621.11 265.782L1621.28 401.126C1621.29 406.552 1625.69 410.946 1631.12 410.939L1683.43 410.873C1688.86 410.866 1693.25 406.46 1693.24 401.035L1693.07 265.691C1693 212.214 1664.03 165.53 1620.95 140.397"
              fill={hexcolor2}
              fillRule="nonzero"
              opacity="1"
              stroke="none"
              vectornatorlayername="path"
            />
            <path
              d="M2228.82 336.45C2221.37 338.122 2212.5 337.021 2204.86 337.031L2176.57 337.066C2164.63 337.082 2152.69 337.096 2140.76 337.112L2140.57 192.537L2203.08 192.458C2203.68 192.379 2204.28 192.31 2204.88 192.246C2209.91 191.706 2215.83 191.886 2220.86 192.277C2221.3 192.312 2221.74 192.351 2222.18 192.394C2258.14 195.951 2285.88 229.515 2285.93 264.937C2285.97 298.764 2261.86 329.042 2228.82 336.45M2212.54 119.861L2077.19 120.033C2071.77 120.04 2067.37 124.444 2067.38 129.871L2067.72 400.558C2067.73 405.985 2072.13 410.379 2077.56 410.372L2212.9 410.2C2266.64 410.132 2313.52 380.877 2338.56 337.456C2350.89 316.087 2357.92 291.287 2357.89 264.846C2357.85 233.976 2348.18 205.377 2331.73 181.873C2305.44 144.329 2261.84 119.799 2212.54 119.861"
              fill={hexcolor2}
              fillRule="nonzero"
              opacity="1"
              stroke="none"
              vectornatorlayername="path"
            />
            <path
              d="M2587.52 121.544L2519.18 221.215L2451.07 121.73C2450.14 120.373 2448.6 119.562 2446.95 119.565L2379.98 119.649C2376.02 119.654 2373.65 124.047 2375.82 127.356L2483.29 291.364L2483.42 400.651C2483.43 406.078 2487.84 410.472 2493.26 410.465L2545.58 410.398C2551 410.392 2555.4 405.986 2555.39 400.561L2555.25 288.791L2663.38 127.039C2665.59 123.73 2663.21 119.29 2659.23 119.295L2591.62 119.381C2589.98 119.383 2588.44 120.191 2587.52 121.544"
              fill={hexcolor2}
              fillRule="nonzero"
              opacity="1"
              stroke="none"
              vectornatorlayername="path"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ComodyHorizontalIcon;
