import React from "react";
import CustomLoader from "../../../../Components/CustomLoader";
import ErrorMessage from "../../../../Components/ErrorMessage";
import ContactosBoard from "./Board";
import ContactoTable from "./Table";
import { ContactosContext } from "./Context/ContactoContext";

export default function ContactosComponent() {
  const { mode, queryLoading, queryError } = React.useContext(ContactosContext);

  if (queryLoading) return <CustomLoader />;
  if (queryError) return <ErrorMessage />;

  return mode === "board" ? <ContactosBoard /> : <ContactoTable />;
}
