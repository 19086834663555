import { gql } from "@apollo/client";

export const OBTENER_STATUS_ACTIVIDAD = gql`
  query ObtenerStatusActividades($idEmpresa: ID!, $filter: String) {
    obtenerStatusActividades(idEmpresa: $idEmpresa, filter: $filter ) {
      _id
      status
      color
      static
      idEmpresa
    }
  }
`;
