import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { iconsMui } from "./listIcons";
import CustomIconComponent from "../../../../../Components/CustomIconComponent";
import { Add } from "@mui/icons-material";

export default function IconSelect({ tipoActividad, setTipoActividad }) {
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelected("");
  };

  const handleSelect = () => {
    setTipoActividad((st) => ({ ...st, icono: selected }));
    setOpen(false);
  };

  return (
    <div>
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "start" }}
      >
        {tipoActividad.icono ? (
          <CustomIconComponent
            iconName={tipoActividad.icono}
            fontSize={80}
          />
        ) : (
          <Typography sx={{ mt: 1 }}>Sin ícono</Typography>
        )}
        <IconButton variant="outlined" onClick={handleClickOpen}>
          <Add />
        </IconButton>
      </Box>
      <Dialog open={open} onClose={handleClose} aria-labelledby="dialog-icons">
        <DialogTitle id="dialog-icons">Selecciona un ícono</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {iconsMui.map((icon, index) => (
              <Grid key={index} item xs={2}>
                <Button
                  color={selected === icon ? "primary" : "inherit"}
                  onClick={() => setSelected(icon)}
                >
                  <CustomIconComponent
                    iconName={icon}
                    fontSize={30}
                  />
                </Button>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus color="inherit">
            Cancelar
          </Button>
          <Button onClick={handleSelect} variant="contained" disableElevation>
            Seleccionar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
