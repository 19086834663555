import React from "react";
import NuevaMoneda from "./Actions/NuevaMoneda";
import { Stack } from "@mui/material";
/* import SearchMoneda from './Actions/Search' */
import MonedasTable from "./Table/Table";
import { MainContext } from "../../../../Context/MainCtx";
import { useQuery } from "@apollo/client";
import { OBTENER_MONEDAS } from "./graphql/queries";

export default function Monedas() {
  const { tokenDecoded } = React.useContext(MainContext);

  const monedasQuery = useQuery(OBTENER_MONEDAS, {
    variables: {
      idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
    },
    fetchPolicy: "network-only",
  });

  return (
    <div>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ mb: 1, mt: 2, width: { xs: "100%", md: "50%" } }}
        bgcolor="background.paper"
      >
        {/* <SearchMoneda /> */}
        <NuevaMoneda refetch={monedasQuery.refetch} />
      </Stack>
      <MonedasTable monedasQuery={monedasQuery} />
    </div>
  );
}
