import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import LoginForm from "./LoginForm";
import ComodyHorizontalIcon from "../../Icons/ComodyHorizontalIcon";
import ComodyVerticalIcon from "../../Icons/ComodyVerticalIcon";

export default function LoginUser() {
  return (
    <Paper>
      <Grid container sx={{ height: "100vh" }}>
        <Grid
          item
          md={8}
          sx={{
            display: { xs: "none", md: "flex"},
            backgroundImage: `url(https://source.unsplash.com/random?administration)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover"
          }}
        >
          <Box
            sx={{
              p: 3,
              my: 5,
              color: "white",
              backdropFilter: "blur(10px)",
              backgroundColor: "rgb(0,0,0, 0.1)",
              height: "200px",
              width: "100%"
            }}
          >
            <Typography variant="h1" sx={{ WebkitTextStroke: "1px #eeeeee" }}>
              <ComodyHorizontalIcon size={30} />
            </Typography>
            <Typography variant="h5" sx={{ WebkitTextStroke: "1px #eeeeee" }}>
              The best CRM in the world, for your requirements, for your
              bussiness, for you.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <ComodyVerticalIcon size={10} color="twoColor" />
            <br />
            <LoginForm />
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}
