import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Add, ArrowBack, Done, Edit } from "@mui/icons-material";
import { MainContext } from "../../../../../Context/MainCtx";
import { ACTUALIZAR_MONEDA, CREAR_MONEDA } from "../graphql/mutations";
import { CircularProgress } from "@mui/material";
import { useMutation } from "@apollo/client";

export default function NuevaMoneda({
  edit,
  data,
  refetch,
  external = false,
  refetchExternal = null,
}) {
  const [open, setOpen] = React.useState(false);
  const [moneda, setMoneda] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const { snackMessage, tokenDecoded } = React.useContext(MainContext);
  const [crearMoneda] = useMutation(CREAR_MONEDA);
  const [actualizarMoneda] = useMutation(ACTUALIZAR_MONEDA);

  const handleOpen = () => {
    setOpen(true);
    if (edit) setMoneda(data.moneda);
  };
  const handleClose = () => {
    setOpen(false);
    setMoneda("");
  };

  const handleSaveMoneda = async (e) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      if (!moneda) return;
      setLoading(true);
      if (!edit) {
        //si es nuevo
        await crearMoneda({
          variables: {
            idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
            moneda,
          },
        });
      } else {
        //si va a actualizar
        await actualizarMoneda({
          variables: {
            idMoneda: data._id,
            idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
            moneda,
          },
        });
      }
      console.log(external, refetchExternal);
      if (external && refetchExternal) refetchExternal();
      if (refetch) refetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      {edit ? (
        <IconButton onClick={handleOpen}>
          {loading ? <CircularProgress size={20} color="inherit" /> : <Edit />}
        </IconButton>
      ) : external ? (
        <IconButton onClick={handleOpen} size="small" color="primary">
          <Add />
        </IconButton>
      ) : (
        <Button
          startIcon={<Add />}
          color="primary"
          variant="contained"
          onClick={handleOpen}
          size="small"
          disableElevation
          sx={{ textTransform: "none", minWidth: 40 }}
        >
          Nueva
        </Button>
      )}
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        ModalProps={{
          componentsProps: {
            backdrop: {
              sx: { background: "none" },
            },
          },
        }}
        PaperProps={{
          elevation: 1,
          sx: { width: { xs: "100vw", md: "40vw" } },
        }}
      >
        <Toolbar variant="dense" />
        <DialogTitle component="div">
          <Stack spacing={1} direction="row" alignItems="center">
            <IconButton onClick={() => handleClose()}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h6">
              {edit ? `Editar` : "Nueva"}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              startIcon={
                loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <Done />
                )
              }
              variant="contained"
              disableElevation
              sx={{ textTransform: "none" }}
              size="small"
              type="submit"
              form="moneda-form"
            >
              Guardar
            </Button>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ py: 0 }}>
          <form id="moneda-form" onSubmit={handleSaveMoneda}>
            <TextField
              label="Moneda"
              size="small"
              variant="outlined"
              margin="dense"
              fullWidth
              onChange={(e) => setMoneda(e.target.value)}
              value={moneda}
              required
            />
          </form>
        </DialogContent>
      </Drawer>
    </div>
  );
}
