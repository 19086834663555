import React from "react";
import { InputAdornment } from "@mui/material";
import { CustomSearchTextField } from "../../../../Components/CustomMuiComponents";
import { Search } from "@mui/icons-material";
import { ActividadContext } from "../Context/ActividadContext";

export default function SearchActividades() {
  const { filter, setFilter } = React.useContext(ActividadContext);

  const onSearch = (searchFilter) => {
    setFilter((st) => ({
      ...st,
      searchFilter,
    }));
  };

  return (
    <CustomSearchTextField
      placeholder="Buscar por actividades, tratos o contactos"
      size="small"
      variant="outlined"
      value={filter.searchFilter}
      onChange={(e) => onSearch(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
}
