import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import { ContactosContext } from "../Context/ContactoContext";
import {
  formatoFechaCorta,
  getBicolorTable,
  getWidth,
} from "../../../../../Config/reuserFunctions";
import { IconButton, useTheme } from "@mui/material";
import CustomAvatar from "../../../../../Components/Avatar/CustomAvatar";
import ContactoDetails from "../Details";
import { DriveFileRenameOutline } from "@mui/icons-material";
import ChipStatusContacto from "../Actions/ChipStatusContacto";
import EditableRowContacto from "./EditableRow";

export default function ContactosTableBody({
  shadows,
  contactos,
  handleSelect,
}) {
  const {
    itemsSelected,
    newItem,
    setNewItem,
    queryRefetch,
    queryCats,
  } = React.useContext(ContactosContext);
  const theme = useTheme();

  const onClickRow = (e, data) => {
    const { target } = e;
    const focusOn = target.attributes["idonfocuscontactos"]?.value;
    setNewItem({ ...data, focusOn });
  };

  return (
    <TableBody>
      {newItem && newItem.new
        ? [newItem].map((_, index) => {
            return <EditableRowContacto key={`new-item-${index}`} />;
          })
        : null}
      {contactos.map((contacto, index) => {
        const checked = itemsSelected.find((res) => res._id === contacto._id);
        if (newItem && !newItem.new && newItem._id === contacto._id) {
          return <EditableRowContacto key={`new-item-${index}`} />;
        } else {
          return (
            <TableRow
              role="checkbox"
              tabIndex={-1}
              key={contacto._id}
              style={getBicolorTable(theme, index)}
              sx={{ height: "48px" }}
              onDoubleClick={(e) => onClickRow(e, contacto)}
            >
              <TableCell
                padding="checkbox"
                sx={{
                  position: "sticky",
                  left: 0,
                  zIndex: 2,
                  bgcolor: getBicolorTable(theme, index),
                }}
              >
                <Checkbox
                  onChange={(e) => handleSelect(e.target.checked, contacto)}
                  checked={checked ? true : false}
                />
              </TableCell>
              <TableCell
                padding="checkbox"
                className={shadows.start}
                sx={{
                  position: "sticky",
                  left: 52,
                  zIndex: 2,
                  bgcolor: getBicolorTable(theme, index),
                }}
              >
                <ContactoDetails data={contacto}>
                  <IconButton size="small">
                    <DriveFileRenameOutline />
                  </IconButton>
                </ContactoDetails>
              </TableCell>
              <TableCell>
                <CustomAvatar size={35} fontSize={20} name={contacto.nombre} />
              </TableCell>
              <TableCell idonfocuscontactos="nombre-contacto-texfield">
                {contacto.nombre}
              </TableCell>
              <TableCell idonfocuscontactos="telefono-contacto-texfield">
                {contacto.telefono}
              </TableCell>
              <TableCell idonfocuscontactos="correo-contacto-texfield">
                {contacto.correo}
              </TableCell>
              <TableCell idonfocuscontactos="statusContacto-contacto-texfield">
                <ChipStatusContacto
                  contacto={contacto}
                  refetch={queryRefetch}
                  query={queryCats.data?.statusContactos}
                />
              </TableCell>
              <TableCell
                idonfocuscontactos="etiqueta-contacto-texfield"
                sx={{
                  minWidth: getWidth(contacto.etiqueta),
                }}
              >
                {contacto.etiqueta}
              </TableCell>
              <TableCell
                idonfocuscontactos="tipoContacto-contacto-texfield"
                sx={{
                  minWidth: getWidth(contacto.tipoContacto),
                }}
              >
                {contacto.tipoContacto}
              </TableCell>
              <TableCell idonfocuscontactos="propietario-contacto-texfield">
                {contacto.propietario}
              </TableCell>
              <TableCell idonfocuscontactos="organizacion-contacto-texfield">
                {contacto.organizacion}
              </TableCell>
              <TableCell
                idonfocuscontactos="formaAtraccion-contacto-texfield"
                sx={{
                  minWidth: getWidth(contacto.formaAtraccion),
                }}
              >
                {contacto.formaAtraccion}
              </TableCell>
              <TableCell
                idonfocuscontactos="campaign-contacto-texfield"
                sx={{
                  minWidth: getWidth(contacto.campaign),
                }}
              >
                {contacto.campaign}
              </TableCell>
              <TableCell>{formatoFechaCorta(contacto.fechaRegistro)}</TableCell>
            </TableRow>
          );
        }
      })}
    </TableBody>
  );
}
