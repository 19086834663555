import React from "react";
import { useNavigate } from "react-router-dom";

export default function ConfiguracionesIndex() {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate("/settings/productos")
  }, [navigate]);
  
  return null;
}
