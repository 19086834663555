import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import { ActividadContext } from "../../Context/ActividadContext";
import {
  formatoFechaCorta,
  formatoFechaHora,
  getBicolorTable,
  getWidth,
} from "../../../../../Config/reuserFunctions";
import { Box, useTheme } from "@mui/material";
import CustomIconComponent from "../../../../../Components/CustomIconComponent";
import EditableRowActividad from "./EditableRow";
import EditarActividad from "../../Actions/EditarActividad";
import ChipPrioridadActividad from "../../Actions/ChipPrioridadAct";
import ChipStatusAct from "../../Actions/ChipStatusAct";
import { isMobile } from "../../../../../Config/constantes";

export default function ActividadesTableBody({
  shadows,
  actividades,
  handleSelect,
}) {
  const {
    itemsSelected,
    newItem,
    setNewItem,
    queryRefetch,
    queryCats,
  } = React.useContext(ActividadContext);
  const theme = useTheme();

  const onClickRow = (e, data) => {
    const { target } = e;
    const focusOn = target.attributes["idonfocusactividad"]?.value;
    setNewItem({ ...data, focusOn });
  };

  return (
    <TableBody>
      {newItem && newItem.new
        ? [newItem].map((_, index) => {
            return <EditableRowActividad key={`new-item-${index}`} />;
          })
        : null}
      {actividades.map((actividad, index) => {
        const checked = itemsSelected.find((res) => res._id === actividad._id);
        if (newItem && !newItem.new && newItem._id === actividad._id) {
          return <EditableRowActividad key={`new-item-${index}`} />;
        } else {
          return (
            <TableRow
              role="checkbox"
              tabIndex={-1}
              key={actividad._id}
              style={getBicolorTable(theme, index)}
              sx={{ height: "48px" }}
              onDoubleClick={(e) => onClickRow(e, actividad)}
            >
              <TableCell
                padding="checkbox"
                sx={{
                  position: "sticky",
                  left: 0,
                  zIndex: 2,
                  bgcolor: getBicolorTable(theme, index),
                }}
              >
                <Checkbox
                  onChange={(e) => handleSelect(e.target.checked, actividad)}
                  checked={checked ? true : false}
                />
              </TableCell>
              <TableCell
                className={!isMobile ? "" : shadows.start}
                padding="checkbox"
                sx={{
                  position: "sticky",
                  left: 52,
                  zIndex: 2,
                  bgcolor: getBicolorTable(theme, index),
                }}
              >
                <EditarActividad edit data={actividad} />
              </TableCell>
              <TableCell
                idonfocusactividad="actividad-actividad-texfield"
                className={isMobile ? "" : shadows.start}
                sx={{
                  position: { xs: "static", sm: "sticky" },
                  left: 104,
                  zIndex: 2,
                  //minWidth: getWidth(actividad.actividad),
                }}
                style={getBicolorTable(theme, index)}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center" }}
                  idonfocusactividad="actividad-actividad-texfield"
                >
                  {actividad.tipoActividadPopulated.length ? (
                    <CustomIconComponent
                      iconName={actividad.tipoActividadPopulated[0].icono}
                      fontSize={22}
                      color="disabled"
                    />
                  ) : null}
                  <Box sx={{ mr: 1 }} />
                  {actividad.actividad}
                </Box>
              </TableCell>
              <TableCell>
                <ChipPrioridadActividad
                  actividad={actividad}
                  refetch={queryRefetch}
                />
              </TableCell>
              <TableCell
                idonfocusactividad="contacto-actividad-texfield"
                sx={{
                  minWidth: getWidth(actividad.contacto, 1),
                }}
              >
                {actividad.contacto}
              </TableCell>
              <TableCell>
                <ChipStatusAct
                  statusQuery={queryCats?.data?.statusActividad}
                  actividad={actividad}
                  refetch={queryRefetch}
                />
              </TableCell>
              <TableCell
                idonfocusactividad="fechaInicio-actividad-texfield"
                sx={{
                  minWidth: getWidth(formatoFechaHora(actividad.fechaInicio)),
                }}
                style={getBicolorTable(theme, index)}
              >
                {actividad.isDate === false
                  ? ""
                  : formatoFechaHora(actividad.fechaInicio)}
              </TableCell>
              <TableCell
                idonfocusactividad="fechaFin-actividad-texfield"
                sx={{
                  minWidth: getWidth(formatoFechaHora(actividad.fechaFin)),
                }}
              >
                {actividad.isDate === false
                  ? ""
                  : formatoFechaHora(actividad.fechaFin)}
              </TableCell>
              <TableCell />
              <TableCell
                idonfocusactividad="trato-actividad-texfield"
                sx={{
                  minWidth: getWidth(actividad.trato),
                }}
              >
                {actividad.trato}
              </TableCell>
              <TableCell
                sx={{
                  minWidth: getWidth(actividad.tipoActividad),
                }}
                style={getBicolorTable(theme, index)}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center" }}
                  idonfocusactividad="tipoActividad-actividad-texfield"
                >
                  {actividad.tipoActividad}
                </Box>
              </TableCell>
              <TableCell idonfocusactividad="notas-actividad-texfield">
                {actividad.notas}
              </TableCell>
              <TableCell idonfocusactividad="resultado-actividad-texfield">
                {actividad.resultado}
              </TableCell>
              <TableCell
                idonfocusactividad="usuario-actividad-texfield"
                sx={{
                  minWidth: getWidth(actividad.usuario),
                }}
              >
                {actividad.usuario}
              </TableCell>
              <TableCell
                idonfocusactividad="ubicacion-actividad-texfield"
                sx={{
                  minWidth: getWidth(actividad.ubicacion),
                }}
              >
                {actividad.ubicacion}
              </TableCell>
              <TableCell
                idonfocusactividad="descripcionU-actividad-texfield"
                sx={{
                  minWidth: getWidth(actividad.descripcionUbicacion),
                }}
              >
                {actividad.descripcionUbicacion}
              </TableCell>
              <TableCell
                sx={{
                  minWidth: getWidth(
                    formatoFechaCorta(actividad.fechaRegistro)
                  ),
                }}
              >
                {formatoFechaCorta(actividad.fechaRegistro)}
              </TableCell>
            </TableRow>
          );
        }
      })}
    </TableBody>
  );
}
