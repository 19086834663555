import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {
  Badge,
  AccountCircle,
  SupervisedUserCircle,
} from "@mui/icons-material";
import Usuarios from "./Usuarios";
import TiposUsuario from "../TiposUsuario";
import Areas from "../Areas";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-tratos-${index}`}
      aria-labelledby={`tab-tratos-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 1 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-tratos-${index}`,
    "aria-controls": `tabpanel-tratos-${index}`,
  };
}

export default function UsuariosIndex() {
  const [value, setValue] = React.useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: { xs: `calc(100vh - 120px)`, md: `calc(100vh - 74px)` },
      }}
    >
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            minHeight: "auto",
            "& .MuiButtonBase-root": {
              minHeight: "auto",
              py: { xs: 1.3, md: 0 },
              pb: { md: 1 },
              textTransform: "none",
            },
            borderBottom: "1px solid",
            borderColor: "divider",
          }}
        >
          <Tab
            icon={<Badge />}
            iconPosition="start"
            label="Usuarios"
            {...a11yProps(0)}
          />
          <Tab
            icon={<SupervisedUserCircle />}
            iconPosition="start"
            label="Tipos"
            {...a11yProps(1)}
          />
          <Tab
            icon={<AccountCircle />}
            iconPosition="start"
            label="Areas"
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Usuarios />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <TiposUsuario />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Areas />
      </CustomTabPanel>
    </Box>
  );
}
