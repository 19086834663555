import * as React from "react";
import { Divider, InputAdornment, TextField } from "@mui/material";
import { ReunionContext } from "../../Context/ReunionContext";
import moment from "moment-timezone";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import InvitadosForm from "./InvitadosForm";
import AutoCompleteInput from "../../../../../Components/InputsForms/AutocompleteInput";
import { Handshake } from "@mui/icons-material";
import NuevoTrato from "../../../Tratos/Actions/NuevoTrato";
import TratosCtxProvider from "../../../Tratos/Context/TratosContext";

export default function FormEvent() {
  const { evento, setEvento, queryCats } = React.useContext(ReunionContext);

  const autocompleteChange = (values) => {
    const { name, value, id, idName } = values;
    if (id && idName) {
      setEvento((st) => ({
        ...st,
        [name]: value,
        [idName]: id,
      }));
    } else {
      setEvento((st) => ({
        ...st,
        [name]: value,
      }));
    }
  };

  const handleChangeBasic = (e) => {
    const { name, value } = e.target;
    setEvento((st) => ({ ...st, [name]: value }));
  };

  const handleChangeFechas = (name, value) => {
    setEvento((st) => ({
      ...st,
      [name]: moment(value).tz("America/Mexico_City").format(),
    }));
  };

  return (
    <div>
      <TextField
        label="Nombre del evento"
        name="nombreEvento"
        size="small"
        variant="outlined"
        margin="dense"
        fullWidth
        onChange={handleChangeBasic}
        value={evento.nombreEvento}
        required
      />
      <TextField
        label="Descripción"
        name="descripcion"
        size="small"
        variant="outlined"
        margin="dense"
        fullWidth
        onChange={handleChangeBasic}
        value={evento.descripcion}
        required
      />
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DemoContainer components={["DateTimePicker"]}>
          <DemoItem label="Fecha de reunión">
            <DateTimePicker
              value={moment(evento.fechaInicio)}
              onChange={(value) => handleChangeFechas("fechaInicio", value)}
              slotProps={{
                textField: {
                  size: "small",
                  InputProps: {
                    startAdornment: (
                      <InputAdornment position="start">De: </InputAdornment>
                    ),
                  },
                },
              }}
            />
            <DateTimePicker
              value={moment(evento.fechaFin)}
              onChange={(value) => handleChangeFechas("fechaFin", value)}
              slotProps={{
                textField: {
                  size: "small",
                  InputProps: {
                    startAdornment: (
                      <InputAdornment position="start">A: </InputAdornment>
                    ),
                  },
                },
              }}
            />
          </DemoItem>
        </DemoContainer>
      </LocalizationProvider>
      <AutoCompleteInput
        renderData={queryCats.data?.tratos}
        value={evento.trato}
        label="Trato"
        icon={<Handshake />}
        loading={queryCats.loading}
        error={queryCats.error}
        required={true}
        addButton={
          <TratosCtxProvider>
            <NuevoTrato
              external={true}
              refetchExternal={queryCats.refetch}
            />
          </TratosCtxProvider>
        }
        onChange={(_, option) =>
          autocompleteChange({
            name: "trato",
            idName: "idTrato",
            value: option?.nombreTrato || "",
            id: option?._id || "",
          })
        }
        isOptionEqualToValue={({ nombreTrato }, value) => nombreTrato === value}
        getOptionLabel={(option) => option.nombreTrato || option || null}
      />
      <Divider sx={{ mt: 1 }} textAlign="left">
        Invitados
      </Divider>
      <InvitadosForm />
    </div>
  );
}
