import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import LoadingAutocomplete from "./LoadingAutocomplete";
import ErrorAutocomplete from "./ErrorAutocomplete";
import { CustomEditableTextField } from "../CustomMuiComponents";
import { Add } from "@mui/icons-material";

export default function EditableMultipleAutocomplete({
  tabIndex= null,
  placeholder = "",
  renderData = [],
  value = "",
  loading = false,
  loadingQuery = false,
  errorQuery = null,
  disabled = false,
  addButton = null,
  actionButton = null,
  onChange = null,
  isOptionEqualToValue = null,
  getOptionLabel = null,
  renderTags = null
}) {
  const [open, setOpen] = React.useState(false);
  const [focus, setFocus] = React.useState(false);
  const ref = React.useRef(null);

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  React.useEffect(() => {
    if (!loading && ref?.current) {
      setFocus(false);
      ref.current.blur();
    }
  }, [loading]);

  if (loadingQuery) return <LoadingAutocomplete editable value={value} />;
  if (errorQuery) return <ErrorAutocomplete editable value={value} />;

  return (
    <Autocomplete
      multiple
      autoHighlight
      openOnFocus
      filterSelectedOptions
      onFocus={() => setFocus(true)}
      onBlur={() => {
        setFocus(false);
        setOpen(false);
      }}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      options={renderData}
      disabled={disabled}
      value={value}
      onChange={(_, option) => onChange(option)}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      forcePopupIcon={false}
      renderInput={(params) => (
        <CustomEditableTextField
          {...params}
          size="small"
          inputRef={ref}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                {!value ? ( !focus ? (
                <InputAdornment
                  position="end"
                  sx={{ color: (theme) => theme.palette.text.disabled }}
                >
                  <Add />
                </InputAdornment>
                ) : null ) : null}
                {params.InputProps.startAdornment}
              </>
            ),
            endAdornment: (
              <React.Fragment>
                {focus ? (
                  <InputAdornment position="end">
                    <span onMouseDown={handleMouseDown}>{actionButton}</span>
                  </InputAdornment>
                ) : null}
                {focus && addButton ? (
                  <InputAdornment position="end">
                    <span onMouseDown={handleMouseDown}>{addButton}</span>
                  </InputAdornment>
                ) : null}
                {focus ? params.InputProps.endAdornment : null}
              </React.Fragment>
            ),
          }}
          inputProps={{...params.inputProps, tabIndex}}
        />
      )}
      renderTags={renderTags}
    />
  );
}
