import React, { createContext } from "react";
import { MainContext } from "../../../../Context/MainCtx";
import { useDebounce } from "use-debounce";
import { useApolloClient, useQuery } from "@apollo/client";
import { OBTENER_DATA_DASH } from "../graphql/queries";
import { OBTENER_QUERIES_CONTRATO } from "../../Tratos/graphql/queries";

export const DashboardContext = createContext();

const initial_filter = {
  searchFilter: "",
  fechaStart: "",
  fechaEnd: "",
  formaContacto: "",
  formaAtraccion: "",
  campaign: "",
  unidadNegocio: "",
  embudo: "",
  status: "",
  usuarioCierre: "",
};

const initial_query = {
  data: undefined,
  loading: true,
  error: undefined,
};

const DashboardProvider = ({ children }) => {
  const { tokenDecoded } = React.useContext(MainContext);
  const [filter, setFilter] = React.useState(initial_filter);
  const [table, setTable] = React.useState("tratos");
  const [queryCats, setQueryCats] = React.useState(initial_query);
  const [page, setPage] = React.useState(0);
  const [FILTER] = useDebounce(filter, 500);
  const client = useApolloClient();

  const dashQuery = useQuery(OBTENER_DATA_DASH, {
    variables: {
      filter: FILTER,
      idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
      limit: 10,
      page,
    },
    fetchPolicy: "network-only",
  });

  const handleSetTable = (value) => {
    setTable(value)
  }

  const getQueryCatalogos = async () => {
    try {
      const response = await client.query({
        query: OBTENER_QUERIES_CONTRATO,
        variables: { idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "" },
        fetchPolicy: "network-only",
      });
      const { loading, data, error } = response;
      setQueryCats({ loading, data: data.obtenerCatalogosTrato, error });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DashboardContext.Provider
      value={{
        filter,
        setFilter,
        page,
        setPage,
        initial_filter,
        dashQuery,
        table,
        setTable,
        handleSetTable,
        getQueryCatalogos,
        queryCats,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardProvider;
