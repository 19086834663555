import { gql } from "@apollo/client";

export const OBTENER_CAMPAIGN = gql`
  query ObtenerCampaign($idEmpresa: ID!, $filter: String) {
    obtenerCampaign(idEmpresa: $idEmpresa, filter: $filter) {
      _id
      idExterno
      nombre
      fechaInicio
      fechaFin
      redSocial
      idRedSocial
      presupuesto
      fechaRegistro
      idEmpresa
    }
  }
`;
