import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Delete } from "@mui/icons-material";
import { ELIMINAR_TRATO } from "../graphql/mutations";
import { useMutation } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { MainContext } from "../../../../Context/MainCtx";
import { TratosContext } from "../Context/TratosContext";

export default function EliminarTrato({ data, handleCloseDetail }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { snackMessage } = React.useContext(MainContext);
  const { queryRefetch } = React.useContext(TratosContext);
  const [eliminarTrato] = useMutation(ELIMINAR_TRATO);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteTrato = async () => {
    try {
      handleClose();
      setLoading(true);
      const res = await eliminarTrato({
        variables: {
          idTrato: data._id,
        },
      });
      snackMessage({
        message: res.data.eliminarTrato.message,
        variant: "success",
      });
      if(handleCloseDetail)handleCloseDetail();
      queryRefetch();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <React.Fragment>
      <Button
        color="warning"
        size="small"
        variant="contained"
        disableElevation
        sx={{ textTransform: "none" }}
        startIcon={
          loading ? <CircularProgress size={20} color="inherit" /> : <Delete />
        }
        onClick={() => handleClickOpen()}
      >
        Eliminar
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{`Eliminar ${data.nombreTrato}`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Se va a eliminar este elemento, ¿Quieres continuar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus color="inherit" size="small">
            No, cancelar
          </Button>
          <Button
            onClick={() => handleDeleteTrato()}
            color="error"
            size="small"
            variant="contained"
            disableElevation
            startIcon={<Delete />}
          >
            Si, eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
