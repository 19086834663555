import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import Select from "@mui/material/Select";
import { AssignmentInd, InfoOutlined } from "@mui/icons-material";
import { OBTENER_TIPOS_USUARIO } from "../../../TiposUsuario/graphql/queries";
import { MainContext } from "../../../../../../Context/MainCtx";
import { useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import NuevoTipoUser from "../../../TiposUsuario/Actions/NuevoTipoUser";

export default function TipoInput({ userData, setUserData }) {
  const { tokenDecoded } = React.useContext(MainContext);

  const tipoUserQuery = useQuery(OBTENER_TIPOS_USUARIO, {
    variables: {
      idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
    },
    fetchPolicy: "network-only",
  });
  const { data, loading, error, refetch } = tipoUserQuery;

  if (loading) {
    return (
      <FormControl fullWidth size="small" margin="dense">
        <InputLabel id="label-tipoUser">Tipo de usuario</InputLabel>
        <Select
          disabled
          siza="small"
          label="Tipo de usuario"
          value=""
          startAdornment={
            <InputAdornment position="start">
              <CircularProgress size={20} />
            </InputAdornment>
          }
        >
          <MenuItem value="">Seleccione uno</MenuItem>
        </Select>
      </FormControl>
    );
  }
  if (error) {
    return (
      <FormControl fullWidth size="small" margin="dense">
        <InputLabel id="label-tipoUser">Tipo de usuario</InputLabel>
        <Select
          disabled
          value=""
          size="small"
          label="Tipo de usuario"
          startAdornment={
            <InputAdornment position="start">
              <InfoOutlined />
            </InputAdornment>
          }
        >
          <MenuItem value="">Seleccione uno</MenuItem>
        </Select>
      </FormControl>
    );
  }

  const handleChange = (e, child) => {
    const { _id, tipoUsuario } = child.props.data;
    setUserData((st) => ({ ...st, tipoUsuario, idTipoUsuario: _id }));
  };

  return (
    <FormControl fullWidth size="small" margin="dense">
      <InputLabel id="label-tipoUser">Tipo de usuario</InputLabel>
      <Select
        required
        labelId="label-tipoUser"
        id="tipouser-select"
        siza="small"
        value={userData.tipoUsuario}
        label="Tipo de usuario"
        onChange={handleChange}
        startAdornment={
          <InputAdornment position="start">
            <AssignmentInd />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="start">
            <NuevoTipoUser refetch={refetch} inputMode={true} />
          </InputAdornment>
        }
      >
        <MenuItem value="">Seleccione uno</MenuItem>
        {data.obtenerTipoUsuarios.map((res) => (
          <MenuItem key={res._id} value={res.tipoUsuario} data={res}>
            {res.tipoUsuario}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
