import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Box, Checkbox, useTheme } from "@mui/material";
import {
  getBicolorTable,
  getContrastColor,
} from "../../../../../Config/reuserFunctions";
import { StatusContext } from "../Context/StatusActividadContext";
import EditableRowStatus from "./EditableRow";

export default function TableBodyStatus({ data, handleSelect }) {
  const { newItem, setNewItem, itemsSelected } = React.useContext(
    StatusContext
  );
  const theme = useTheme();

  return (
    <TableBody>
      {newItem && newItem.new
        ? [newItem].map((_, index) => {
            return <EditableRowStatus key={`new-item-${index}`} />;
          })
        : null}
      {data.map((data, index) => {
        const checked = itemsSelected.find((res) => res._id === data._id);
        if (newItem && !newItem.new && newItem._id === data._id) {
          return <EditableRowStatus key={`new-item-${index}`} edit={true} />;
        } else {
          return (
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={`${data.status}-${index}`}
              style={getBicolorTable(theme, index)}
              onDoubleClick={() => setNewItem(data)}
              sx={{ height: "48px" }}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  onChange={(e) => handleSelect(e.target.checked, data)}
                  checked={checked ? true : false}
                  disabled={data.static}
                />
              </TableCell>
              <TableCell width={250}>{data.status}</TableCell>
              <TableCell>
                <Box
                  sx={{
                    bgcolor: data.color,
                    color: getContrastColor(data.color),
                    width: 100,
                    textAlign: "center",
                    fontWeight: "bold",
                    borderRadius: 1,
                    py: 0.6,
                  }}
                >
                  {data.color}
                </Box>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          );
        }
      })}
    </TableBody>
  );
}
