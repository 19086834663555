import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import {
  AccountCircle,
  Add,
  AlternateEmail,
  ArrowBack,
  Done,
  Edit,
  Password,
  Phone,
} from "@mui/icons-material";
import TipoInput from "./FormFields/TipoInput";
import AreaInput from "./FormFields/AreaInput";
import UsuariosVer from "./FormFields/VerInput";
import FotoInput from "./FormFields/FotoInput";
import { useMutation } from "@apollo/client";
import { ACTUALIZAR_USUARIO, CREAR_USUARIO } from "../graphql/mutations";
import { MainContext } from "../../../../../Context/MainCtx";
import { CircularProgress } from "@mui/material";
import { isMobile } from "../../../../../Config/constantes";

const initial_state = {
  tipoUsuario: "",
  nombreUsuario: "",
  nombreCompleto: "",
  telefono: "",
  password: "",
  idTipoUsuario: "",
  idArea: "",
  area: "",
  correo: "",
  comision: "",
  fechaIngreso: "",
  foto: "",
  keyFoto: "",
  ver: [],
};

export default function NuevoUsuario({
  edit,
  data,
  refetch,
  external = false,
  refetchExternal = null,
}) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [changePass, setChangePass] = React.useState(false);
  const [userData, setUserData] = React.useState(initial_state);
  const [userVer, setUserVer] = React.useState([]);
  const { tokenDecoded, snackMessage } = React.useContext(MainContext);
  const [preview, setPreview] = React.useState("");
  const [crearUsuario] = useMutation(CREAR_USUARIO);
  const [actualizarUsuario] = useMutation(ACTUALIZAR_USUARIO);

  const handleOpen = () => {
    setOpen(true);
    if (edit) {
      setUserData(data);
      let arryVer = [];
      data.ver.forEach((name) => arryVer.push(name.nombreCompleto));
      setUserVer(arryVer);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setUserData(initial_state);
    setPreview("");
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    if (name === "nombreUsuario") {
      let newValue = value.replace(" ", "_");
      setUserData((st) => ({ ...st, [name]: newValue }));
      return;
    }
    setUserData((st) => ({ ...st, [name]: value }));
  };

  const handleSaveUsuario = async (e) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      setLoading(true);
      let input = { ...userData };
      if (!input.foto) delete input.foto;
      if (!edit) {
        //si es nuevo
        const res = await crearUsuario({
          variables: {
            idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
            input,
          },
        });
        snackMessage({
          message: res.data.crearUsuario.message,
          variant: "success",
        });
      } else {
        //si va a actualizar
        const {
          whatsappId,
          _id,
          idEmpresa,
          fechaRegistro,
          foto,
          ...newInput
        } = input;
        if (!input.password) delete newInput.password;
        const res = await actualizarUsuario({
          variables: {
            idUsuario: data._id,
            input: newInput,
          },
        });
        snackMessage({
          message: res.data.actualizarUsuario.message,
          variant: "success",
        });
      }
      if (external && refetchExternal) refetchExternal();
      if (refetch) refetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log(err);
      console.log(err.networkError?.result);
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      {edit ? (
        <IconButton onClick={handleOpen}>
          <Edit />
        </IconButton>
      ) : external ? (
        <IconButton onClick={handleOpen} size="small" color="primary">
          <Add />
        </IconButton>
      ) : (
        <Button
          onClick={handleOpen}
          color="primary"
          size="small"
          disableElevation
          variant="contained"
          sx={{ textTransform: "none", minWidth: 40 }}
          startIcon={isMobile ? null : <Add />}
        >
          {isMobile ? <Add fontSize="small" /> : "Nuevo"}
        </Button>
      )}
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        ModalProps={{
          componentsProps: {
            backdrop: {
              sx: { background: "none" },
            },
          },
        }}
        PaperProps={{
          elevation: 1,
          sx: { width: { xs: "100vw", md: "40vw" } },
        }}
      >
        <Toolbar variant="dense" />
        <DialogTitle>
          <Stack spacing={1} direction="row" alignItems="center">
            <IconButton onClick={() => handleClose()}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h6">
              {edit ? `Editar` : "Nuevo"}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              startIcon={
                loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <Done />
                )
              }
              variant="contained"
              disableElevation
              sx={{ textTransform: "none" }}
              size="small"
              type="submit"
              form="user-form"
            >
              Guardar
            </Button>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ py: 0 }}>
          <form id="user-form" onSubmit={handleSaveUsuario}>
            <FotoInput
              preview={preview}
              setPreview={setPreview}
              userData={userData}
              setUserData={setUserData}
              edit={edit}
            />
            <TextField
              label="Usuario"
              name="nombreUsuario"
              onChange={handleChangeInput}
              value={userData.nombreUsuario}
              size="small"
              variant="outlined"
              margin="dense"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
              required
            />
            <TextField
              label="Nombre completo"
              size="small"
              variant="outlined"
              margin="dense"
              name="nombreCompleto"
              onChange={handleChangeInput}
              value={userData.nombreCompleto}
              fullWidth
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Telefono"
              type="tel"
              size="small"
              variant="outlined"
              margin="dense"
              name="telefono"
              onChange={handleChangeInput}
              value={userData.telefono ? userData.telefono : ""}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Phone />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Correo"
              type="email"
              size="small"
              variant="outlined"
              margin="dense"
              name="correo"
              required
              onChange={handleChangeInput}
              value={userData.correo ? userData.correo : ""}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmail />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Contraseña"
              type="text"
              size="small"
              variant="outlined"
              margin="dense"
              name="password"
              required
              onChange={handleChangeInput}
              value={userData.password ? userData.password : ""}
              fullWidth
              disabled={edit && !changePass}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Password />
                  </InputAdornment>
                ),
                endAdornment: !edit ? null : (
                  <InputAdornment position="end">
                    <Button onClick={() => setChangePass((st) => !st)}>
                      {changePass ? "Cancelar" : "Modificar"}
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
            <TipoInput userData={userData} setUserData={setUserData} />
            <AreaInput userData={userData} setUserData={setUserData} />
            <UsuariosVer
              userData={userData}
              setUserData={setUserData}
              userVer={userVer}
              setUserVer={setUserVer}
            />
            <TextField
              type="number"
              label="Comisión"
              size="small"
              variant="outlined"
              margin="dense"
              name="comision"
              onChange={handleChangeInput}
              value={userData.comision ? userData.comision : ""}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
            />
            <TextField
              type="date"
              label="Fecha de ingreso"
              size="small"
              variant="outlined"
              margin="dense"
              fullWidth
              name="fechaIngreso"
              onChange={handleChangeInput}
              value={userData.fechaIngreso ? userData.fechaIngreso : ""}
            />
          </form>
        </DialogContent>
      </Drawer>
    </div>
  );
}
