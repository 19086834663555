import { useTheme } from "@mui/material";
import React from "react";

const ComodyVerticalIcon = ({
  size = 10,
  color = "default",
  className = "",
  style = {},
}) => {
  let hexcolor1;
  let hexcolor2;
  const theme = useTheme();

  switch (color) {
    case "primary":
      hexcolor1 = theme.palette.primary.main;
      hexcolor2 = theme.palette.primary.main;
      break;
    case "secondary":
      hexcolor1 = theme.palette.secondary.main;
      hexcolor2 = theme.palette.secondary.main;
      break;
    case "comodyBlue":
      hexcolor1 = theme.palette.comodyBlue;
      hexcolor2 = theme.palette.comodyBlue;
      break;
    case "twoColor":
      hexcolor1 = theme.palette.primary.main;
      hexcolor2 = theme.palette.secondary.main;
      break;
    default:
      hexcolor1 = "#FFFFFF";
      hexcolor2 = "#FFFFFF";
      break;
  }

  return (
    <svg
      style={style}
      strokeMiterlimit="10"
      version="1.1"
      viewBox="0 0 1024 760"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsvectornator="http://vectornator.io"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={`${size}rem`}
      className={className}
    >
      <defs />
      <clipPath id="ArtboardFrame">
        <rect height="760" width="1024" x="0" y="0" />
      </clipPath>
      <g
        clipPath="url(#ArtboardFrame)"
        id="Layer-1"
        vectornatorlayername="Layer 1"
      >
        <clipPath clipRule="nonzero" id="ClipPath">
          <path d="M-0.297945-2.54621e-14L1023.7-2.54621e-14L1023.7 760L-0.297945 760L-0.297945-2.54621e-14Z" />
        </clipPath>
        <g clipPath="url(#ClipPath)">
          <path
            d="M747.849 332.531L653.535 306.815C646.707 304.953 639.603 308.523 637.049 315.02C618.711 361.661 572.74 394.785 518.869 394.98C448.806 395.23 391.357 339.086 391.223 270.212C391.088 201.056 448.076 144.953 518.394 144.953C571.918 144.953 617.712 177.46 636.477 223.484C639.09 229.895 646.272 233.264 653.016 231.328L746.969 204.362C754.562 202.182 758.692 194.169 756.04 186.843C721.67 91.8906 630.096 23.6034 522.077 22.0844C383.789 20.1386 267.778 131.17 266.223 267.116C265.713 311.64 277.163 353.487 297.588 389.774L267.004 479.969C259.566 501.904 280.795 522.772 303.109 515.461L394.04 485.665C430.739 506.158 473.171 517.875 518.394 517.875C629.231 517.875 723.355 447.58 757.179 349.873C759.72 342.535 755.448 334.602 747.849 332.531"
            fill={hexcolor2}
            fillRule="nonzero"
            opacity="1"
            stroke="none"
            vectornatorlayername="path 1"
          />
          <path
            d="M565.122 269.966C565.122 295.439 544.115 316.089 518.203 316.089C492.29 316.089 471.284 295.439 471.284 269.966C471.284 244.494 492.29 223.844 518.203 223.844C544.115 223.844 565.122 244.494 565.122 269.966"
            fill={hexcolor1}
            fillRule="nonzero"
            opacity="1"
            stroke="none"
            vectornatorlayername="path 2"
          />
          <path
            d="M166.699 659.322L142.596 652.75C140.852 652.274 139.036 653.186 138.383 654.846C133.697 666.767 121.949 675.232 108.182 675.281C90.2769 675.345 75.5948 660.997 75.561 643.396C75.5262 625.723 90.0905 611.384 108.06 611.384C121.739 611.384 133.442 619.692 138.237 631.454C138.905 633.092 140.74 633.953 142.464 633.459L166.474 626.567C168.415 626.01 169.47 623.962 168.792 622.09C160.009 597.824 136.606 580.373 109.001 579.985C73.6614 579.488 44.0126 607.862 43.6163 642.605C43.4862 653.983 46.4118 664.677 51.6321 673.951L43.8159 697.001C41.9154 702.607 47.3395 707.94 53.0422 706.07L76.2808 698.456C85.6586 703.694 96.5028 706.687 108.06 706.687C136.385 706.687 160.439 688.723 169.084 663.753C169.733 661.879 168.641 659.851 166.699 659.322"
            fill={hexcolor1}
            fillRule="nonzero"
            opacity="1"
            stroke="none"
            vectornatorlayername="path 3"
            className="letter-animate"
          />
          <path
            d="M246.201 675.735C228.194 675.735 213.597 661.386 213.597 643.684C213.597 625.983 228.194 611.633 246.201 611.633C264.208 611.633 278.805 625.983 278.805 643.684C278.805 661.386 264.208 675.735 246.201 675.735M246.201 580.126C210.493 580.126 181.545 608.582 181.545 643.684C181.545 678.787 210.493 707.242 246.201 707.242C281.91 707.242 310.857 678.787 310.857 643.684C310.857 608.582 281.91 580.126 246.201 580.126"
            fill={hexcolor1}
            fillRule="nonzero"
            opacity="1"
            stroke="none"
            vectornatorlayername="path 4"
            className="letter-animate"
          />
          <path
            d="M636.541 675.735C618.534 675.735 603.936 661.386 603.936 643.684C603.936 625.983 618.534 611.633 636.541 611.633C654.547 611.633 669.145 625.983 669.145 643.684C669.145 661.386 654.547 675.735 636.541 675.735M636.541 580.126C600.832 580.126 571.885 608.582 571.885 643.684C571.885 678.787 600.832 707.242 636.541 707.242C672.249 707.242 701.196 678.787 701.196 643.684C701.196 608.582 672.249 580.126 636.541 580.126"
            fill={hexcolor1}
            fillRule="nonzero"
            opacity="1"
            stroke="none"
            vectornatorlayername="path 5"
            className="letter-animate"
          />
          <path
            d="M522.467 588.788C512.896 583.282 501.755 580.126 489.863 580.126C477.97 580.126 466.83 583.282 457.258 588.788C451.288 592.224 445.924 596.572 441.371 601.643C436.748 596.496 431.291 592.087 425.208 588.627C415.696 583.219 404.655 580.126 392.879 580.126C380.987 580.126 369.846 583.282 360.275 588.788C341.102 599.815 328.223 620.271 328.223 643.685L328.223 702.941C328.223 705.317 330.182 707.243 332.599 707.243L355.899 707.243C358.316 707.243 360.275 705.317 360.275 702.941L360.275 643.685C360.275 625.983 374.872 611.633 392.879 611.633C402.04 611.633 411.152 615.856 417.367 622.4C423.088 628.423 425.034 635.418 425.034 643.461L425.034 683.278C425.034 688.662 425.113 697.677 425.165 702.981C425.187 705.341 427.14 707.243 429.541 707.242L453.099 707.237C455.516 707.236 457.474 705.31 457.474 702.934L457.474 674.481C457.474 664.548 457.37 654.607 457.527 644.673C457.549 643.331 457.482 641.993 457.474 640.651C457.384 624.387 474.446 611.633 489.863 611.633C507.87 611.633 522.467 625.983 522.467 643.685L522.467 702.941C522.467 705.317 524.426 707.243 526.843 707.243L550.143 707.243C552.559 707.243 554.519 705.317 554.519 702.941L554.519 643.685C554.519 620.271 541.64 599.815 522.467 588.788"
            fill={hexcolor1}
            fillRule="nonzero"
            opacity="1"
            stroke="none"
            vectornatorlayername="path 6"
            className="letter-animate"
          />
          <path
            d="M793.092 674.962C789.771 675.69 785.821 675.203 782.421 675.203L769.818 675.203L753.868 675.203L753.868 611.904L781.708 611.904C781.974 611.87 782.241 611.84 782.509 611.813C784.752 611.579 787.389 611.661 789.628 611.836C789.824 611.851 790.02 611.868 790.215 611.887C806.229 613.465 818.565 628.176 818.565 643.685C818.565 658.495 807.81 671.737 793.092 674.962M785.961 580.125L725.681 580.125C723.264 580.125 721.306 582.052 721.306 584.428L721.306 702.94C721.306 705.317 723.264 707.243 725.681 707.243L785.961 707.243C809.892 707.243 830.789 694.461 841.968 675.464C847.469 666.114 850.617 655.261 850.617 643.685C850.617 630.169 846.326 617.641 839.012 607.341C827.324 590.889 807.922 580.125 785.961 580.125"
            fill={hexcolor1}
            fillRule="nonzero"
            opacity="1"
            stroke="none"
            vectornatorlayername="path 7"
            className="letter-animate"
          />
          <path
            d="M952.97 581.07L922.477 624.671L892.198 581.076C891.785 580.481 891.099 580.126 890.367 580.126L860.537 580.126C858.775 580.126 857.717 582.048 858.681 583.497L906.451 655.364L906.451 703.213C906.451 705.588 908.41 707.514 910.827 707.514L934.127 707.514C936.544 707.514 938.503 705.588 938.503 703.213L938.503 654.278L986.753 583.519C987.741 582.071 986.685 580.126 984.912 580.126L954.797 580.126C954.066 580.126 953.383 580.479 952.97 581.07"
            fill={hexcolor1}
            fillRule="nonzero"
            opacity="1"
            stroke="none"
            vectornatorlayername="path 8"
            className="letter-animate"
          />
        </g>
      </g>
    </svg>
  );
};

export default ComodyVerticalIcon;
