import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import Select from "@mui/material/Select";
import { AssignmentInd, InfoOutlined } from "@mui/icons-material";
import { OBTENER_AREAS } from "../../../Areas/graphql/queries";
import { MainContext } from "../../../../../../Context/MainCtx";
import { useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import NuevaAreaDrawer from "../../../Areas/Actions/NuevaAreaDrawer";

export default function AreaInput({ userData, setUserData }) {
  const { tokenDecoded } = React.useContext(MainContext);
  const obtenerAreas = useQuery(OBTENER_AREAS, {
    variables: {
      idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
    },
    fetchPolicy: "network-only",
  });
  const { data, loading, error, refetch } = obtenerAreas;

  if (loading) {
    return (
      <FormControl fullWidth size="small" margin="dense">
        <InputLabel>Area</InputLabel>
        <Select
          disabled
          siza="small"
          label="Area"
          value=""
          startAdornment={
            <InputAdornment position="start">
              <CircularProgress size={20} />
            </InputAdornment>
          }
        >
          <MenuItem value="">Seleccione uno</MenuItem>
        </Select>
      </FormControl>
    );
  }
  if (error) {
    return (
      <FormControl fullWidth size="small" margin="dense">
        <InputLabel>Area</InputLabel>
        <Select
          disabled
          size="small"
          label="Area"
          value=""
          startAdornment={
            <InputAdornment position="start">
              <InfoOutlined />
            </InputAdornment>
          }
        >
          <MenuItem value="">Seleccione uno</MenuItem>
        </Select>
      </FormControl>
    );
  }

  const handleChange = (e, child) => {
    const { _id, area } = child.props.data;
    setUserData((st) => ({ ...st, area, idArea: _id }));
  };

  return (
    <FormControl fullWidth size="small" margin="dense">
      <InputLabel id="label-area">Area</InputLabel>
      <Select
        required
        labelId="label-area"
        id="area-select"
        siza="small"
        value={userData.area}
        label="Area"
        onChange={handleChange}
        startAdornment={
          <InputAdornment position="start">
            <AssignmentInd />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="start">
            <NuevaAreaDrawer refetch={refetch} inputMode={true} />
          </InputAdornment>
        }
      >
        <MenuItem value="">Seleccione uno</MenuItem>
        {data.obtenerAreas.map((res) => (
          <MenuItem key={res._id} value={res.area} data={res}>
            {res.area}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
