import { Paper, useTheme } from "@mui/material";
import React, { useState, useRef } from "react";

const HorizontalScrollDragSpecificDiv = ({ children }) => {
  const specificDivRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const theme = useTheme();

  const handleMouseDown = (e) => {
    if (e.target === specificDivRef.current) {
      setIsDragging(true);
      setStartX(e.pageX - specificDivRef.current.offsetLeft);
      setScrollLeft(specificDivRef.current.scrollLeft);
    }
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.pageX - specificDivRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Ajusta la velocidad de desplazamiento aquí
    specificDivRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <Paper
      variant="outlined"
      ref={specificDivRef}
      className="specific-scrollable-div"
      sx={{
        display: "flex",
        transition: "width .3s",
        height: { xs: `calc(100vh - 232px)`, sm: `calc(100vh - 140px)` },
        overflowX: "auto",
        whiteSpace: "nowrap",
        py: 1,
        backgroundColor: theme.palette.customPaper,
        borderRadius: 2,
      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      {children}
    </Paper>
  );
};

export default HorizontalScrollDragSpecificDiv;
