import * as React from "react";
import { InputAdornment } from "@mui/material";
import { CustomEditableTextField } from "../CustomMuiComponents";
import { Add } from "@mui/icons-material";

export default function EditableTextInput({
  tabIndex = null,
  value = "",
  name = "",
  placeholder = "",
  sx = null,
  actionButton = null,
  onChange = null,
  loading = false,
  type = "text",
  fullWidth = false,
  required = false,
  startAdorment = "",
}) {
  const [focus, setFocus] = React.useState(false);
  const ref = React.useRef(null);

  const handleMouseDown = (event) => {
    // Evita que pierda el foco al hacer clic en el botón
    event.preventDefault();
  };

  React.useEffect(() => {
    if (!loading && ref) {
      setFocus(false);
      ref.current.blur();
    }
  }, [loading]);

  return (
    <CustomEditableTextField
      fullWidth={fullWidth}
      inputRef={ref}
      type={type}
      placeholder={placeholder}
      name={name}
      variant="outlined"
      size="small"
      value={value}
      required={required}
      sx={sx}
      onChange={onChange}
      InputProps={{
        startAdornment: !value ? (
          !focus ? (
            <InputAdornment
              position="end"
              sx={{ color: (theme) => theme.palette.text.disabled }}
            >
              <Add />
            </InputAdornment>
          ) : null
        ) : startAdorment ? (
          <InputAdornment
            position="start"
            sx={{ color: (theme) => theme.palette.text.disabled }}
          >
            {startAdorment}
          </InputAdornment>
        ) : null,
        endAdornment: actionButton ? (
          focus ? (
            <InputAdornment position="end">
              <span onMouseDown={handleMouseDown}>{actionButton}</span>
            </InputAdornment>
          ) : null
        ) : null,
        style: {
          border: "none",
        },
      }}
      inputProps={{tabIndex}}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
    />
  );
}
