import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Delete } from "@mui/icons-material";
import { ELIMINAR_REUNION } from "../graphql/mutations";
import { useMutation } from "@apollo/client";
import { CircularProgress, IconButton } from "@mui/material";
import { MainContext } from "../../../../Context/MainCtx";
import { ReunionContext } from "../Context/ReunionContext";

export default function EliminarReunion({ data }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { snackMessage } = React.useContext(MainContext);
  const { queryRefetch, googleCalendarLogged } = React.useContext(
    ReunionContext
  );
  const [eliminarReunion] = useMutation(ELIMINAR_REUNION);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteEventoGoogle = async () => {
    try {
      if (!googleCalendarLogged) {
        snackMessage({
          message: "Necesita iniciar sesión en Google para realizar esta acción",
          variant: "warning",
        });
        return;
      }
      setLoading(true);
      const { calendar } = window.gapi.client;
      await calendar.events.delete({
        calendarId: "primary",
        eventId: data.idGoogleMeet,
      });
      handleDeleteReunion();
    } catch (error) {
      console.log(error);
      handleDeleteReunion();
      snackMessage({
        message:
          "Error eliminado evento de Google Calendar, puedes eliminarlo directamente en tu cuenta de Google.",
        variant: "info",
      });
    }
  };

  const handleDeleteReunion = async () => {
    try {
      const res = await eliminarReunion({
        variables: {
          idReunion: data._id,
        },
      });
      snackMessage({
        message: res.data.eliminarReunion.message,
        variant: "success",
      });
      queryRefetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log(err);
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      <IconButton onClick={() => handleClickOpen()} size="small">
        <Delete />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{`Eliminar ${data.nombreEvento}`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Se va a eliminar este elemento, ¿Quiere continuar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus color="inherit">
            No, cancelar
          </Button>
          <Button
            onClick={() => deleteEventoGoogle()}
            color="error"
            variant="contained"
            disableElevation
            startIcon={
              loading ? <CircularProgress size={20} color="inherit" /> : null
            }
          >
            Si, eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
