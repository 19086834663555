import * as React from "react";
import Table from "@mui/material/Table";
import { ContactosContext } from "../Context/ContactoContext";
import ContactosTableBody from "./TableBody";
import {
  getComparator,
  stableSort,
} from "../../../../../Config/reuserFunctions";
import ContactosTableHead from "./TableHead";
import CustomTableContainer from "../../../Tratos/CardFases/TableMode/CustomTableContainer";
import { Paper } from "@mui/material";

export default function ContactosTable() {
  const {
    itemsSelected,
    setItemsSelected,
    setIndeterminated,
    handleSaveContacto,
    contactos,
    newItem,
    setNewItem,
  } = React.useContext(ContactosContext);
  const [shadows, setShadows] = React.useState({
    start: "",
    end: "leftShadowTable",
  });
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("nombre");
  const tableEl = React.useRef();

  const renderContactosSorted = React.useMemo(() => {
    return stableSort(contactos, getComparator(order, orderBy));
  }, [order, orderBy, contactos]);

  const changeClassName = React.useCallback((scrollLeft, realWidth) => {
    if (scrollLeft === 0) {
      setShadows({ start: "removeShadowTable", end: "leftShadowTable" });
      return;
    }
    if (scrollLeft === realWidth) {
      setShadows({ start: "rightShadowTable", end: "removeShadowTable" });
      return;
    }
    setShadows({ start: "rightShadowTable", end: "leftShadowTable" });
  }, []);

  const handleOnScroll = (ev) => {
    const { scrollLeft, scrollWidth, clientWidth } = ev.target;
    const realWidth = scrollWidth - clientWidth;
    changeClassName(scrollLeft, realWidth);
  };
  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelect = (checked, item) => {
    const items = [...itemsSelected];
    if (checked) items.push(item);
    if (!checked) {
      const indx = items.findIndex((elm) => elm._id === item._id);
      items.splice(indx, 1);
    }
    setItemsSelected(items);
    //revisar el checkAll
    if (items.length > 0 && items.length !== contactos.length) {
      setIndeterminated(true);
      return;
    }
    setIndeterminated(false);
  };

  const handleCheckAll = (checked) => {
    setIndeterminated(false);
    if (checked) {
      setItemsSelected(contactos);
      return;
    }
    setItemsSelected([]);
  };

  return (
    <Paper variant="outlined">
      <CustomTableContainer
        tableRef={tableEl}
        onScroll={handleOnScroll}
        onFocusInput={() =>
          newItem &&
          setNewItem((st) => {
            const { focusOn, ...state } = st;
            return state;
          })
        }
      >
        <form
          id="form-contacto-table"
          onSubmit={handleSaveContacto}
          style={{ height: "10px" }}
        >
          <Table stickyHeader size="small">
            <ContactosTableHead
              handleSort={handleSort}
              order={order}
              orderBy={orderBy}
              handleCheckAll={handleCheckAll}
            />
            <ContactosTableBody
              shadows={shadows}
              contactos={renderContactosSorted}
              handleSelect={handleSelect}
            />
          </Table>
        </form>
      </CustomTableContainer>
    </Paper>
  );
}
