import { gql } from "@apollo/client";

export const CREAR_UNIDAD_NEGOCIO = gql`
  mutation CrearUnidadNegocio($idEmpresa: ID!, $nombreUnidad: String!) {
    crearUnidadNegocio(idEmpresa: $idEmpresa, nombreUnidad: $nombreUnidad) {
      message
    }
  }
`;
export const ACTUALIZAR_UNIDAD_NEGOCIO = gql`
  mutation ActualizarUnidadNegocio(
    $idEmpresa: ID!
    $idUnidad: ID!
    $nombreUnidad: String!
  ) {
    actualizarUnidadNegocio(
      idEmpresa: $idEmpresa
      idUnidad: $idUnidad
      nombreUnidad: $nombreUnidad
    ) {
      message
    }
  }
`;
export const ELIMINAR_UNIDAD_NEGOCIO = gql`
  mutation EliminarUnidadNegocio($idUnidad: ID!) {
    eliminarUnidadNegocio(idUnidad: $idUnidad) {
      message
    }
  }
`;
