import { gql } from "@apollo/client";

export const OBTENER_ETIQUETAS = gql`
  query ObtenerEtiquetas($idEmpresa: ID!) {
    obtenerEtiquetas(idEmpresa: $idEmpresa) {
      _id
      etiqueta
      idEmpresa
    }
  }
`;
