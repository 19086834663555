import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Done, ExpandMore } from "@mui/icons-material";
import { CircularProgress, Grid, Rating } from "@mui/material";
import EditableTextInput from "../../../../../Components/InputsForms/EditableTextInput";
import { useMutation } from "@apollo/client";
import { MainContext } from "../../../../../Context/MainCtx";
import EditableAutoComplete from "../../../../../Components/InputsForms/EditableAutoComlete";
import EditableDateInput from "../../../../../Components/InputsForms/EditableDateInput";
import moment from "moment-timezone";
import NuevoUsuario from "../../../Configuraciones/Usuarios/Actions/NuevoUsuario";
import { ACTUALIZAR_TRATO } from "../../../Tratos/graphql/mutations";
import { TratosContext } from "../../../Tratos/Context/TratosContext";
import ProductoCtxProvider from "../../../Configuraciones/Productos/Context/ProductoContext";
import NuevoProducto from "../../../Configuraciones/Productos/Actions/NuevoProducto";
import NuevoEmbudo from "../../../Configuraciones/Embudos/Actions/NuevoEmbudo";
import ChipStatus from "../../../Tratos/CardFases/BoardMode/ChipStatus";
import ChipPrioridad from "../../../Tratos/CardFases/BoardMode/ChipPrioridad";
import NuevaUnidadN from "../../../Configuraciones/UnidadesNegocios/Actions/NuevaUnidadN";
import NuevaCampaign from "../../../Configuraciones/Campaigns/Actions/NuevaCampaign";
import NuevaFormaContacto from "../../../Configuraciones/FormasContacto/Actions/NuevaFormaContacto";
import NuevaMoneda from "../../../Configuraciones/Monedas/Actions/NuevaMoneda";

export default function ExtCompTratoForm({ data, refetch }) {
  const { snackMessage, tokenDecoded } = React.useContext(MainContext);
  const { queryCats } = React.useContext(TratosContext);
  const [loading, setLoading] = React.useState(false);
  const [trato, setTrato] = React.useState({ ...data });
  const [actualizarTrato] = useMutation(ACTUALIZAR_TRATO);

  const handleChangeDate = async (name, value) => {
    const tratoUpdated = {
      ...trato,
      [name]: moment(value).tz("America/Mexico_City").format(),
    };
    setTrato(tratoUpdated);
    await handleSaveTrato(tratoUpdated);
  };

  const autocompleteChange = (values) => {
    const { name, value, id, idName } = values;
    let tratoUpdated = {
      ...trato,
      [name]: value,
    };
    if (id && idName) {
      tratoUpdated = {
        ...trato,
        [name]: value,
        [idName]: id,
      };
    }
    setTrato(tratoUpdated);
    handleSaveTrato(tratoUpdated);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTrato((state) => ({
      ...state,
      [name]: name === "valor" ? parseFloat(value) : value,
    }));
  };

  const handleChangeInteres = (e, interes) => {
    const tratoUpdated = {
      ...trato,
      interes,
    };
    setTrato(tratoUpdated);
    handleSaveTrato(tratoUpdated);
  };

  const handleSaveTrato = async (tratoUpdated = trato) => {
    try {
      if (loading) return;
      setLoading(true);
      let {
        _id,
        fechaRegistro,
        idEmpresa,
        actividades,
        contactoPopulated,
        ...input
      } = tratoUpdated;

      input.valor = parseFloat(input.valor);
      input.probabilidadCierre = parseFloat(input.probabilidadCierre);
      await actualizarTrato({
        variables: {
          idTrato: trato._id,
          idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
          input,
        },
      });
      refetch();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <Accordion
      elevation={0}
      sx={{
        "& .MuiAccordionSummary-root": {
          backgroundColor: (theme) =>
            `${theme.palette.background.paper}!important`,
        },
        "& .MuiAccordionSummary-content": {
          my: 0,
        },
      }}
    >
      <AccordionSummary expandIcon={<ExpandMore />} component="div">
        <Box onClick={(e) => e.stopPropagation()} sx={{ width: "100%" }}>
          <Grid container spacing={1}>
            <Grid item>
              <Typography variant="body2" color="gray">
                Status
              </Typography>
              <ChipStatus
                trato={trato}
                setTrato={setTrato}
                refetch={refetch}
              />
            </Grid>
            <Grid item>
              <Typography variant="body2" color="gray">
                Prioridad
              </Typography>
              <ChipPrioridad
                trato={trato}
                setTrato={setTrato}
                refetch={refetch}
              />
            </Grid>
            <Grid item sx={{ flexGrow: 1 }}>
              <Typography variant="body2" color="gray">
                Trato
              </Typography>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSaveTrato();
                }}
              >
                <EditableTextInput
                  fullWidth
                  required
                  placeholder="Nombre del trato"
                  name="nombreTrato"
                  value={trato.nombreTrato}
                  loading={loading}
                  onChange={handleChange}
                  actionButton={
                    <IconButton size="small" color="primary" type="submit">
                      {loading ? (
                        <CircularProgress size={20} color="primary" />
                      ) : (
                        <Done />
                      )}
                    </IconButton>
                  }
                />
              </form>
            </Grid>
            <Grid item md={2.5}>
              <Typography variant="body2" color="gray">
                Fecha
              </Typography>
              <EditableDateInput
                name="fechaTrato"
                value={trato.fechaTrato}
                loading={loading}
                onChange={handleChangeDate}
                loadingIcon={<CircularProgress size={20} color="primary" />}
              />
            </Grid>
          </Grid>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1} sx={{ mb: 1.5 }}>
          <Grid item xs={12} md={6}>
            <Typography variant="body2" color="gray">
              Fase del embudo
            </Typography>
            <EditableAutoComplete
              placeholder="Añadir fase de embudo"
              renderData={queryCats.data?.embudos}
              value={trato.embudo}
              loading={loading}
              loadingQuery={queryCats.loading}
              errorQuery={queryCats.error}
              required={true}
              addButton={
                <NuevoEmbudo
                  external={true}
                  refetchExternal={queryCats.refetch}
                />
              }
              loadingIcon={<CircularProgress size={20} color="primary" />}
              onChange={(_, option) =>
                autocompleteChange({
                  name: "embudo",
                  idName: "idEmbudo",
                  value: option?.fase || "",
                  id: option?._id || "",
                })
              }
              isOptionEqualToValue={({ fase }, value) => fase === value}
              getOptionLabel={(option) => option.fase || option || null}
            />
          </Grid>          
          <Grid item xs={12} md={6}>
            <Typography variant="body2" color="gray">
              <span style={{ color: !trato.nombreTrato ? "red" : "gray" }}>
                *
              </span>
              Producto
            </Typography>
            <EditableAutoComplete
              fullWidth
              placeholder="Añadir producto"
              renderData={queryCats.data?.productos}
              value={trato.producto}
              loadingQuery={queryCats.loading}
              errorQuery={queryCats.error}
              required
              addButton={
                <ProductoCtxProvider>
                  <NuevoProducto external refetchExternal={queryCats.refetch} />
                </ProductoCtxProvider>
              }
              onChange={(_, option) =>
                autocompleteChange({
                  name: "producto",
                  idName: "idProducto",
                  value: option?.nombre || "",
                  id: option?._id || "",
                })
              }
              isOptionEqualToValue={({ nombre }, value) => nombre === value}
              getOptionLabel={(option) => option.nombre || option || null}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body2" color="gray">
              Unidad de negocio
            </Typography>
            <EditableAutoComplete
              placeholder="Añadir unidad de negocio"
              renderData={queryCats.data?.unidades}
              value={trato.unidadNegocio}
              loading={loading}
              loadingQuery={queryCats.loading}
              errorQuery={queryCats.error}
              addButton={
                <NuevaUnidadN
                  external={true}
                  refetchExternal={queryCats.refetch}
                />
              }
              loadingIcon={<CircularProgress size={20} color="primary" />}
              onChange={(_, option) =>
                autocompleteChange({
                  name: "unidadNegocio",
                  idName: "idUnidadNegocio",
                  value: option?.nombreUnidad || "",
                  id: option?._id || "",
                })
              }
              isOptionEqualToValue={({ nombreUnidad }, value) =>
                nombreUnidad === value
              }
              getOptionLabel={(option) => option.nombreUnidad || option || null}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body2" color="gray">
              Campaña
            </Typography>
            <EditableAutoComplete
              placeholder="Añadir campaña"
              renderData={queryCats.data?.campaigns}
              value={trato.campaign}
              loading={loading}
              loadingQuery={queryCats.loading}
              errorQuery={queryCats.error}
              addButton={
                <NuevaCampaign
                  external={true}
                  refetchExternal={queryCats.refetch}
                />
              }
              loadingIcon={<CircularProgress size={20} color="primary" />}
              onChange={(_, option) =>
                autocompleteChange({
                  name: "campaign",
                  idName: "idCampaign",
                  value: option?.nombre || "",
                  id: option?._id || "",
                })
              }
              isOptionEqualToValue={({ nombre }, value) => nombre === value}
              getOptionLabel={(option) => option.nombre || option || null}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body2" color="gray">
              ¿Cómo se contactó?
            </Typography>
            <EditableAutoComplete
              placeholder="Añadir forma de contacto"
              renderData={queryCats.data?.formasContacto}
              value={trato.formaContacto}
              loading={loading}
              loadingQuery={queryCats.loading}
              errorQuery={queryCats.error}
              required={true}
              addButton={
                <NuevaFormaContacto
                  external={true}
                  refetchExternal={queryCats.refetch}
                />
              }
              loadingIcon={<CircularProgress size={20} color="primary" />}
              onChange={(_, option) =>
                autocompleteChange({
                  name: "formaContacto",
                  idName: "idFormaContacto",
                  value: option?.formaContacto || "",
                  id: option?._id || "",
                })
              }
              isOptionEqualToValue={({ formaContacto }, value) =>
                formaContacto === value
              }
              getOptionLabel={(option) =>
                option.formaContacto || option || null
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body2" color="gray">
              Interés
            </Typography>
            <Rating
              size="medium"
              value={trato.interes}
              onChange={handleChangeInteres}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="body2" color="gray">
              Valor
            </Typography>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSaveTrato();
              }}
            >
              <EditableTextInput
                fullWidth
                placeholder="Añadir valor"
                name="valor"
                required
                value={trato.valor}
                loading={loading}
                onChange={handleChange}
                startAdorment="$"
                actionButton={
                  <IconButton size="small" color="primary" type="submit">
                    {loading ? (
                      <CircularProgress size={20} color="primary" />
                    ) : (
                      <Done />
                    )}
                  </IconButton>
                }
              />
            </form>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="body2" color="gray">
              Moneda
            </Typography>
            <EditableAutoComplete
              fullWidth
              placeholder="Añadir moneda"
              renderData={queryCats.data?.monedas}
              value={trato.moneda}
              loading={loading}
              loadingQuery={queryCats.loading}
              errorQuery={queryCats.error}
              required={true}
              addButton={
                <NuevaMoneda
                  external={true}
                  refetchExternal={queryCats.refetch}
                />
              }
              loadingIcon={<CircularProgress size={20} color="primary" />}
              onChange={(_, option) =>
                autocompleteChange({
                  name: "moneda",
                  idName: "idMoneda",
                  value: option?.moneda || "",
                  id: option?._id || "",
                })
              }
              isOptionEqualToValue={({ moneda }, value) => moneda === value}
              getOptionLabel={(option) => option.moneda || option || null}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="body2" color="gray">
              % de cierre
            </Typography>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSaveTrato();
              }}
            >
              <EditableTextInput
                fullWidth
                placeholder="Añadir probabilidad"
                name="probabilidadCierre"
                value={trato.probabilidadCierre}
                loading={loading}
                onChange={handleChange}
                startAdorment="%"
                actionButton={
                  <IconButton size="small" color="primary" type="submit">
                    {loading ? (
                      <CircularProgress size={20} color="primary" />
                    ) : (
                      <Done />
                    )}
                  </IconButton>
                }
              />
            </form>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="body2" color="gray">
              Fecha estimada
            </Typography>
            <EditableDateInput
              name="fechaEstimadaCierre"
              value={trato.fechaEstimadaCierre}
              loading={loading}
              onChange={handleChangeDate}
              actionButton={
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => handleSaveTrato()}
                >
                  {loading ? (
                    <CircularProgress size={20} color="primary" />
                  ) : (
                    <Done />
                  )}
                </IconButton>
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="body2" color="gray">
              Propietario
            </Typography>
            <EditableAutoComplete
              placeholder="Añadir propietario"
              renderData={queryCats.data?.usuarios}
              value={trato.propietario}
              loading={loading}
              loadingQuery={queryCats.loading}
              errorQuery={queryCats.error}
              required={true}
              addButton={
                <NuevoUsuario
                  external={true}
                  refetchExternal={queryCats.refetch}
                />
              }
              loadingIcon={<CircularProgress size={20} color="primary" />}
              onChange={(_, option) =>
                autocompleteChange({
                  name: "propietario",
                  idName: "idPropietario",
                  value: option?.nombreCompleto || "",
                  id: option?._id || "",
                })
              }
              isOptionEqualToValue={({ nombreCompleto }, value) =>
                nombreCompleto === value
              }
              getOptionLabel={(option) =>
                option.nombreCompleto || option || null
              }
            />
          </Grid>
          <Grid item xs={12} md={4} hidden={!trato.fechaCierre}>
            <Typography variant="body2" color="gray">
              Fecha de cierre
            </Typography>
            <Typography>{trato.fechaCierre}</Typography>
          </Grid>
          <Grid item xs={12} md={4} hidden={!trato.usuarioCierre}>
            <Typography variant="body2" color="gray">
              Usuario que cerró el trato
            </Typography>
            <Typography>{trato.usuarioCierre}</Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
