import { withSnackbar } from "notistack";
import React, { createContext } from "react";
import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";

export const MainContext = createContext();

const MainCtxProvider = ({ children, enqueueSnackbar }) => {
  const cookies = new Cookies();
  const tokenSesion = cookies.get("ComodySesion");
  const decoded = tokenSesion ? jwtDecode(tokenSesion) : null;
  const [tokenDecoded, setTokenDecoded] = React.useState(decoded);

  const snackMessage = React.useCallback(
    ({
      message = "No Message",
      variant = "default",
      preventDuplicate = true,
      anchorOrigin = { horizontal: "right", vertical: "top" },
    }) => {
      enqueueSnackbar(message, {
        variant,
        preventDuplicate,
        anchorOrigin,
        style: {
          top: 35,
        },
      });
    },
    [enqueueSnackbar]
  );

  const handleCloseSesion = () => {
    cookies.remove("ComodySesion", { path: "/" });
    setTokenDecoded(null);
  };

  return (
    <MainContext.Provider
      value={{
        snackMessage,
        tokenDecoded,
        handleCloseSesion,
        setTokenDecoded,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

export default withSnackbar(MainCtxProvider);
