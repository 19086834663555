import React, { createContext } from "react";
import { MainContext } from "../../../../Context/MainCtx";
import { useDebounce } from "use-debounce";
import { useMutation, useQuery } from "@apollo/client";
import {
  OBTENER_QUERIES_ACTIVIDAD,
  OBTENER_ACTIVIDADES,
} from "../graphql/queries";
import moment from "moment-timezone";
import { useTheme } from "@mui/material";
import { ACTUALIZAR_ACTIVIDAD, CREAR_ACTIVIDAD } from "../graphql/mutations";
import { getContrastColor } from "../../../../Config/reuserFunctions";

export const ActividadContext = createContext();

const initial_actividad = {
  new: true,
  contacto: "",
  actividad: "",
  descripcionUbicacion: "",
  isDate: true,
  expires: true,
  fechaFin: moment().tz("America/Mexico_City").format(),
  fechaInicio: moment().tz("America/Mexico_City").format(),
  notas: "",
  resultado: "",
  prioridad: "BAJA",
  status: "",
  tipoActividad: "",
  trato: "",
  ubicacion: "",
  usuario: "",
};

const initial_query = {
  data: undefined,
  loading: true,
  error: undefined,
};

const initial_filter = {
  fechaEnd: "",
  fechaStart: "",
  searchFilter: "",
  status: "",
  tipoActividad: "",
  usuario: "",
};

const ActividadCtxProvider = ({ children }) => {
  const { tokenDecoded, snackMessage } = React.useContext(MainContext);
  const [filter, setFilter] = React.useState(initial_filter);
  const [actividad, setActividad] = React.useState(initial_actividad);
  const [actividades, setActividades] = React.useState([]);
  const [events, setEvents] = React.useState([]);
  const [queryCats, setQueryCats] = React.useState(initial_query);
  const [mode, setMode] = React.useState("calendar");
  const [itemsSelected, setItemsSelected] = React.useState([]);
  const [loadingSave, setLoadingSave] = React.useState(false);
  const [newItem, setNewItem] = React.useState(null);
  const [indeterminated, setIndeterminated] = React.useState(false);
  const [FILTER] = useDebounce(filter, 500);
  const theme = useTheme();
  const [crearActividad] = useMutation(CREAR_ACTIVIDAD);
  const [actualizarActividad] = useMutation(ACTUALIZAR_ACTIVIDAD);

  const actividadQuery = useQuery(OBTENER_ACTIVIDADES, {
    variables: {
      filter: FILTER,
      idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
    },
    fetchPolicy: "network-only",
  });

  const formQuery = useQuery(OBTENER_QUERIES_ACTIVIDAD, {
    variables: {
      idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
    },
    fetchPolicy: "network-only",
  });
  const { data, loading, error, refetch } = actividadQuery;

  React.useEffect(() => {
    if (formQuery.data) {
      setQueryCats({
        loading: formQuery.loading,
        data: formQuery.data.obtenerCatalogosActividades,
        error: formQuery.error,
        refetch: formQuery.refetch,
      });
    }
  }, [formQuery]);

  /* React.useEffect(() => {
    if (queryCats.data?.statusActividad.length > 0) {
      setActividad((st) => ({
        ...st,
        status: queryCats.data.statusActividad[0].status,
        idStatus: queryCats.data.statusActividad[0]._id,
      }));
    }
  }, [queryCats]); */

  React.useEffect(() => {
    if (data) {
      const eventsFormated = data.obtenerActividades.map((act) => {
        if (act.isDate === false) return [];
        return {
          title: act.actividad,
          start: act.fechaInicio,
          end: act.fechaFin,
          extendedProps: { data: { ...act } },
          color: act.statusData[0]?.color,
          textColor: getContrastColor(act.statusData[0]?.color),
        };
      });
      setEvents(eventsFormated);
      setActividades(data.obtenerActividades);
    }
  }, [data, theme]);

  const handleSaveActividad = async (e) => {
    try {
      e.preventDefault();
      setLoadingSave(true);
      let input = { ...newItem };
      if (input.new) {
        delete input.new;
        await crearActividad({
          variables: {
            idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
            input,
          },
        });
      } else {
        delete input._id;
        delete input.fechaRegistro;
        delete input.idEmpresa;
        delete input.tipoActividadPopulated;
        delete input.contactoPopulated;
        delete input.statusData;
        delete input.focusOn;
        await actualizarActividad({
          variables: {
            idActividad: newItem._id,
            idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
            input,
          },
        });
      }
      refetch();
      setLoadingSave(false);
      setNewItem(null);
    } catch (err) {
      setLoadingSave(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <ActividadContext.Provider
      value={{
        filter,
        setFilter,
        initial_filter,
        queryData: data,
        queryLoading: loading,
        queryError: error,
        queryRefetch: refetch,
        actividad,
        setActividad,
        initial_actividad,
        queryCats,
        mode,
        setMode,
        itemsSelected,
        setItemsSelected,
        newItem,
        setNewItem,
        loadingSave,
        setLoadingSave,
        indeterminated,
        setIndeterminated,
        actividades,
        events,
        handleSaveActividad,
      }}
    >
      {children}
    </ActividadContext.Provider>
  );
};

export default ActividadCtxProvider;
