import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ContactosContext } from "../../Context/ContactoContext";
import NuevoTrato from "../../../../Tratos/Actions/NuevoTrato";
import TratosCtxProvider from "../../../../Tratos/Context/TratosContext";
import ExtCompTratoForm from "../../../../Tratos/Actions/ExtCompTratoForm";

export default function TratosContactoDetailIndex() {
  const { contacto, setContacto, queryRefetch } = React.useContext(
    ContactosContext
  );

  const setToArray = (data) => {
    if (!data) return;
    const tratos = [...contacto.tratos];
    tratos.splice(0, 0, data);
    setContacto({ ...contacto, tratos });
  };

  return (
    <TratosCtxProvider>
      <Stack direction="row" spacing={2}>
        <Typography variant="h6">Tratos</Typography>
        <NuevoTrato
          external
          refetchExternal={queryRefetch}
          fromDetail
          setToArray={setToArray}
          fromContacto={contacto}
        />
      </Stack>
      {!contacto?.tratos?.length ? (
        <Typography align="center" color="grey" sx={{ my: 5 }}>
          No Hay Tratos
        </Typography>
      ) : (
        contacto.tratos.map((trato) => (
          <ExtCompTratoForm
            data={trato}
            refetch={queryRefetch}
            key={trato._id}
          />
        ))
      )}
    </TratosCtxProvider>
  );
}
