import React, { createContext } from "react";
import { useDebounce } from "use-debounce";
import { useQuery } from "@apollo/client";
import { OBTENER_AREAS } from "../graphql/queries";
import { MainContext } from "../../../../../Context/MainCtx";

export const AreaContext = createContext();

const initial_area = {
  area: "",
  new: true,
};

const AreaCtxProvider = ({ children }) => {
  const { tokenDecoded } = React.useContext(MainContext);
  const [filter, setFilter] = React.useState("");
  const [itemsSelected, setItemsSelected] = React.useState([]);
  const [loadingSave, setLoadingSave] = React.useState(false);
  const [newItem, setNewItem] = React.useState(null);
  const [indeterminated, setIndeterminated] = React.useState(false);
  const [FILTER] = useDebounce(filter, 500);

  const query = useQuery(OBTENER_AREAS, {
    variables: {
      filter: FILTER,
      idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
    },
    fetchPolicy: "network-only",
  });

  return (
    <AreaContext.Provider
      value={{
        filter,
        setFilter,
        query,
        initial_area,
        itemsSelected,
        setItemsSelected,
        newItem,
        setNewItem,
        loadingSave,
        setLoadingSave,
        indeterminated,
        setIndeterminated,
      }}
    >
      {children}
    </AreaContext.Provider>
  );
};

export default AreaCtxProvider;
