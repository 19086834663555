import { gql } from "@apollo/client";

export const CREAR_MONEDA = gql`
  mutation CrearMoneda($idEmpresa: ID!, $moneda: String!) {
    crearMoneda(idEmpresa: $idEmpresa, moneda: $moneda) {
      message
    }
  }
`;
export const ACTUALIZAR_MONEDA = gql`
  mutation ActualizarMoneda($idEmpresa: ID!, $idMoneda: ID!, $moneda: String!) {
    actualizarMoneda(
      idEmpresa: $idEmpresa
      idMoneda: $idMoneda
      moneda: $moneda
    ) {
      message
    }
  }
`;
export const ELIMINAR_MONEDA = gql`
  mutation EliminarMoneda($idMoneda: ID!) {
    eliminarMoneda(idMoneda: $idMoneda) {
      message
    }
  }
`;
