import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Add, ArrowBack, Done, Edit } from "@mui/icons-material";
import { ACTUALIZAR_AREA, CREAR_AREA } from "../graphql/mutations";
import { useMutation } from "@apollo/client";
import { MainContext } from "../../../../../Context/MainCtx";
import { CircularProgress } from "@mui/material";
import { isMobile } from "../../../../../Config/constantes";

export default function NuevaAreaDrawer({
  edit,
  data,
  refetch,
  inputMode = false,
}) {
  const [open, setOpen] = React.useState(false);
  const [area, setArea] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const { snackMessage, tokenDecoded } = React.useContext(MainContext);
  const [crearArea] = useMutation(CREAR_AREA);
  const [actualizarArea] = useMutation(ACTUALIZAR_AREA);

  const handleOpen = () => {
    setOpen(true);
    if (edit) setArea(data.area);
  };
  const handleClose = () => {
    setOpen(false);
    setArea("");
  };

  const handleSaveArea = async (e) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      if (!area) return;
      setLoading(true);
      if (!edit) {
        //si es nuevo
        const res = await crearArea({
          variables: {
            idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
            area,
          },
        });
        snackMessage({
          message: res.data.crearArea.message,
          variant: "success",
        });
      } else {
        //si va a actualizar
        const res = await actualizarArea({
          variables: {
            idArea: data._id,
            idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
            area,
          },
        });
        snackMessage({
          message: res.data.actualizarArea.message,
          variant: "success",
        });
      }
      refetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      {edit ? (
        <IconButton onClick={handleOpen}>
          {loading ? <CircularProgress size={20} color="inherit" /> : <Edit />}
        </IconButton>
      ) : inputMode ? (
        <IconButton onClick={handleOpen} color="primary" sx={{ mx: 1 }}>
          <Add />
        </IconButton>
      ) : (
        <Button
          onClick={handleOpen}
          color="primary"
          size="small"
          disableElevation
          variant="contained"
          sx={{ textTransform: "none", minWidth: 40 }}
          startIcon={isMobile ? null : <Add />}
        >
          {isMobile ? <Add fontSize="small" /> : "Nuevo"}
        </Button>
      )}
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        ModalProps={{
          componentsProps: {
            backdrop: {
              sx: { background: "none" },
            },
          },
        }}
        PaperProps={{
          elevation: 1,
          sx: { width: { xs: "100vw", md: "40vw" } },
        }}
      >
        <Toolbar variant="dense" />
        <DialogTitle>
          <Stack spacing={1} direction="row" alignItems="center">
            <IconButton onClick={() => handleClose()}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h6">{edit ? `Editar` : "Nuevo"}</Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              startIcon={
                loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <Done />
                )
              }
              variant="contained"
              disableElevation
              sx={{ textTransform: "none" }}
              size="small"
              type="submit"
              form="formArea"
            >
              Guardar
            </Button>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ py: 0, width: { xs: "100vw", md: "40vw" } }}>
          <form id="formArea" onSubmit={handleSaveArea}>
            <TextField
              label="Nombre área"
              size="small"
              variant="outlined"
              margin="dense"
              fullWidth
              onChange={(e) => setArea(e.target.value)}
              value={area}
              required
            />
          </form>
        </DialogContent>
      </Drawer>
    </div>
  );
}
