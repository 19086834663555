import { gql } from "@apollo/client";

export const OBTENER_REDES = gql`
  query ObtenerRedSociales($idEmpresa: ID!) {
    obtenerRedSociales(idEmpresa: $idEmpresa) {
      _id
      redSocial
      enlace
      unidadNegocio
      idUnidadNegocio
      idEmpresa
    }
  }
`;
