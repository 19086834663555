import React from "react";
import TratosTable from "./CardFases";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import SearchTratos from "./Actions/Search";
import FilterTrato from "./Actions/FilterTrato";
import TratosCtxProvider, { TratosContext } from "./Context/TratosContext";
import { ViewColumn, ViewList } from "@mui/icons-material";
import DeleteManyOportunities from "./CardFases/DeleteMany";
import AddNewItem from "./Actions/NuevoTrato/AddNewItem";

export default function Tratos() {
  return (
    <TratosCtxProvider>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ mb: 1, position: { sm: "absolute" }, top: 60, right: 24 }}
        bgcolor="background.paper"
      >
        <ChangeModeView />
        <SearchTratos />
        <AddNewItem />
        <FilterTrato />
        <DeleteManyOportunities />
      </Stack>
      <TratosTable />
    </TratosCtxProvider>
  );
}

const ChangeModeView = () => {
  const { mode, setMode, newItem, setNewItem } = React.useContext(
    TratosContext
  );

  const handleSetMode = () => {
    setMode(mode === "table" ? "board" : "table");
    if (newItem) setNewItem(null);
  };
  return (
    <>
      <Button
        color="inherit"
        size="small"
        variant="outlined"
        disableElevation
        onClick={handleSetMode}
        sx={{
          minWidth: 40,
          color: (theme) => theme.palette.action.active,
          borderColor: (theme) => theme.palette.action.disabled,
        }}
      >
        {mode === "table" ? <ViewColumn /> : <ViewList />}
      </Button>
    </>
  );
};
