import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Add, ArrowBack, Done, Edit } from "@mui/icons-material";
import FormProducto from "./Formulario/ProductoForm";
import { MainContext } from "../../../../../Context/MainCtx";
import { ACTUALIZAR_PRODUCTO, CREAR_PRODUCTO } from "../graphql/mutations";
import { useMutation } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { ProductoContext } from "../Context/ProductoContext";
import { generateCodeNumerico } from "../../../../../Config/reuserFunctions";
import { isMobile } from "../../../../../Config/constantes";

export default function NuevoProducto({
  edit,
  data,
  external = false,
  refetchExternal = null,
}) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { tokenDecoded, snackMessage } = React.useContext(MainContext);
  const {
    queryRefetch,
    productData,
    setProductData,
    initial_state,
    setImagenes,
    imagenes,
  } = React.useContext(ProductoContext);

  const [crearProducto] = useMutation(CREAR_PRODUCTO);
  const [actualizarProducto] = useMutation(ACTUALIZAR_PRODUCTO);

  const handleOpen = () => {
    setOpen(true);
    setProductData((st) => ({ ...st, skuCodigo: generateCodeNumerico(8) }));
    if (edit) {
      setProductData(data);
      setImagenes(data.imagenes);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setProductData(initial_state);
    setImagenes([]);
  };

  const handleSaveProducto = async (e) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      setLoading(true);
      let input = { ...productData };
      input.precioFinal = input.precio;
      if (input.valorDescuento) input.precioFinal = input.valorDescuento;
      if (!edit) {
        //si es nuevo
        input.imagenes = imagenes;
        const res = await crearProducto({
          variables: {
            idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
            input,
          },
        });
        snackMessage({
          message: res.data.crearProducto.message,
          variant: "success",
        });
      } else {
        //si va a actualizar
        if (imagenes.length) {
          input.newImgs = imagenes.filter((img) => !img.keyImagen);
        }
        const { _id, idEmpresa, fechaRegistro, ...newInput } = input;
        const res = await actualizarProducto({
          variables: {
            idProducto: data._id,
            input: newInput,
          },
        });
        snackMessage({
          message: res.data.actualizarProducto.message,
          variant: "success",
        });
      }
      if (external && refetchExternal) refetchExternal();
      queryRefetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log(err);
      console.log(err.networkError?.result);
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      {edit ? (
        <IconButton onClick={handleOpen}>
          <Edit />
        </IconButton>
      ) : external ? (
        <IconButton
          onClick={handleOpen}
          size="small"
          color="primary"
          sx={{ padding: "1.5px" }}
        >
          <Add sx={{ fontSize: 20 }} />
        </IconButton>
      ) : (
        <Button
          startIcon={isMobile ? null : <Add />}
          color="primary"
          variant="contained"
          onClick={handleOpen}
          size="small"
          disableElevation
          sx={{ textTransform: "none", minWidth: 40 }}
        >
          {isMobile ? <Add fontSize="small" /> : "Nuevo"}
        </Button>
      )}
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        ModalProps={{
          componentsProps: {
            backdrop: {
              sx: { background: "none" },
            },
          },
        }}
        PaperProps={{
          elevation: 1,
          sx: { width: { xs: "100vw", md: "40vw" } },
        }}
      >
        <Toolbar variant="dense" />
        <DialogTitle component="div">
          <Stack spacing={1} direction="row" alignItems="center">
            <IconButton onClick={() => handleClose()}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h6">
              {edit ? `Editar` : "Nuevo Producto"}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              startIcon={
                loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <Done />
                )
              }
              variant="contained"
              disableElevation
              sx={{ textTransform: "none" }}
              size="small"
              form="producto-form"
              type="submit"
            >
              Guardar
            </Button>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ py: 0 }}>
          <FormProducto handleSaveProducto={handleSaveProducto} />
        </DialogContent>
      </Drawer>
    </div>
  );
}
