import React from "react";
import FullCalendar from "@fullcalendar/react";
import { Box, useTheme } from "@mui/material";
import { config, customStyles } from "./configCalendar";
import { ActividadContext } from "../Context/ActividadContext";
import moment from "moment-timezone";

export default function ActividadesCalendar() {
  const { events, setActividad, initial_actividad } = React.useContext(
    ActividadContext
  );
  const theme = useTheme();

  const handleOpenDetail = ({ event }) => {
    if (!event) return;
    setActividad({
      ...event.extendedProps.data,
      edit: true,
      fromCalendar: true,
    });
  };

  /* const handleClickEvent = ({ event }) => {
    if (!event) return;
    setActividad({
      ...event.extendedProps.data,
      fromCalendar: true,
      edit: true
    });
  }; */

  const handleDateSelect = (data) => {
    if (!data) return;
    const fechaInicio = moment(data.start).tz("America/Mexico_City").format();
    const fechaFin = moment(data.end).tz("America/Mexico_City");
    if (data.view.type === "dayGridMonth") {
      //restarle un minuto a la fecha fin
      setActividad({
        ...initial_actividad,
        fechaInicio,
        fechaFin: fechaFin.subtract(1, "minutes").format(),
        fromCalendar: true,
        new: true,
      });
      return;
    }
    setActividad({
      ...initial_actividad,
      fechaInicio,
      fechaFin: fechaFin.format(),
      fromCalendar: true,
      new: true,
    });
  };

  return (
    <Box
      sx={{
        ...customStyles(theme),
        height: { xs: `calc(100vh - 262px)`, sm: `calc(100vh - 150px)` },
        backgroundColor: theme.palette.paper,
      }}
    >
      <FullCalendar
        plugins={config.plugins}
        timeZone={config.timeZone}
        initialView={config.initialView}
        headerToolbar={config.headerToolbar}
        buttonText={config.buttonText}
        events={events}
        locale={config.locale}
        height="100%"
        eventClick={handleOpenDetail}
        selectable={true}
        select={handleDateSelect}
      />
    </Box>
  );
}
