import React from "react";
import { Stack } from "@mui/material";
/* import SearchEmbudo from "./Actions/Search"; */
import NuevoEmbudo from "./Actions/NuevoEmbudo";
import EmbudosTable from "./Table/Table";
import { MainContext } from "../../../../Context/MainCtx";
import { OBTENER_EMBUDOS } from "./graphql/queries";
import { useQuery } from "@apollo/client";

export default function Embudos() {
  const { tokenDecoded } = React.useContext(MainContext);

  const embudoQuery = useQuery(OBTENER_EMBUDOS, {
    variables: {
      idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
    },
    fetchPolicy: "network-only",
  });

  return (
    <div>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="flex-end"
        alignItems="center"
        sx={{ mb: 1, position: { sm: "absolute" }, top: 60, right: 24 }}
      >
        <NuevoEmbudo refetch={embudoQuery.refetch} />
      </Stack>
      <EmbudosTable embudoQuery={embudoQuery} />
    </div>
  );
}
