import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { Outlet, useMatches, useNavigate } from "react-router-dom";
import MenuSideComponent from "./Navegacion/MenuSideComponent";
import NavbarDashboard from "./Navegacion/NavbarDashboard";
import { MainContext } from "../../Context/MainCtx";
import { Toolbar } from "@mui/material";
import BottomMenuResponsive from "./Navegacion/ResponsiveNav";

export default function DashboardIndex() {
  const [view, setView] = React.useState("");
  const navigate = useNavigate();
  const matches = useMatches();
  const pathname = matches && matches.length > 1 ? matches[1].pathname : "";
  const { tokenDecoded } = React.useContext(MainContext);
  const [open, setOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    if (!tokenDecoded) {
      navigate("/login");
      return;
    }
    pathname ? navigate(pathname) : navigate("/contactos"); //chat
    if (!view) setView(localStorage.getItem("lastview"));
  }, [navigate, pathname, tokenDecoded, view]);

  const width = open ? "300px" : "114px"; //112px

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <CssBaseline />
      <NavbarDashboard handleDrawerToggle={handleDrawerToggle} view={view} />
      <MenuSideComponent
        open={open}
        setOpen={setOpen}
        setView={setView}
        handleClose={handleDrawerToggle}
      />
      <Box sx={{ flex: 1, px: { xs: 1, md: 3 } }}>
        <Toolbar variant="dense" />
        <Box
          sx={{
            mt: { xs: 0, md: 2 },
            width: {
              xs: `calc(100vw - 20px)`,
              sm: `calc(100vw - auto)`,
              md: `calc(100vw - ${width})`,
            },
          }}
        >
          <Outlet />
        </Box>
      </Box>
      <BottomMenuResponsive setView={setView} />
    </Box>
  );
}
