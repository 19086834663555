import React from "react";
import { Stack } from "@mui/material";
import ReunionesTable from "./ReunionesTable";
import SearchReunion from "./Actions/SearchReunion";
import ReunionCtxProvider from "./Context/ReunionContext";
import FilterReunion from "./Actions/FilterReunion";
import AuthGoogleCalendar from "./GoogleAuth";
import NuevaReunion from "./Actions/NuevaReunion";

export default function Reuniones() {
  return (
    <ReunionCtxProvider>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ mb: 1, mt: 2 }}
        bgcolor="background.paper"
      >
        <NuevaReunion />
        <AuthGoogleCalendar />
        <SearchReunion />
        <FilterReunion />
      </Stack>
      <ReunionesTable />
    </ReunionCtxProvider>
  );
}
