import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import Select from "@mui/material/Select";
import { AssignmentInd, InfoOutlined } from "@mui/icons-material";
import { useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { OBTENER_UNIDADES_NEGOCIO } from "../../UnidadesNegocios/graphql/queries";
import { MainContext } from "../../../../../Context/MainCtx";
import NuevaUnidadN from "../../UnidadesNegocios/Actions/NuevaUnidadN";

export default function GetUnidadesField({ redSocial, setRedSocial }) {
  const { tokenDecoded } = React.useContext(MainContext);

  const unidadesQuery = useQuery(OBTENER_UNIDADES_NEGOCIO, {
    variables: {
      idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
      filter: "",
    },
    fetchPolicy: "network-only",
  });
  const { data, loading, error, refetch } = unidadesQuery;

  if (loading) {
    return (
      <FormControl fullWidth size="small" margin="dense">
        <InputLabel>Unidad de negocio</InputLabel>
        <Select
          disabled
          siza="small"
          label="Unidad de negocio"
          value=""
          startAdornment={
            <InputAdornment position="start">
              <CircularProgress size={20} />
            </InputAdornment>
          }
        >
          <MenuItem value="">Seleccione uno</MenuItem>
        </Select>
      </FormControl>
    );
  }
  if (error) {
    return (
      <FormControl fullWidth size="small" margin="dense">
        <InputLabel>Unidad de negocio</InputLabel>
        <Select
          disabled
          size="small"
          label="Unidad de negocio"
          value=""
          startAdornment={
            <InputAdornment position="start">
              <InfoOutlined />
            </InputAdornment>
          }
        >
          <MenuItem value="">Seleccione uno</MenuItem>
        </Select>
      </FormControl>
    );
  }

  const handleChange = (e, child) => {
    const { _id, nombreUnidad } = child.props.data;
    setRedSocial((st) => ({
      ...st,
      unidadNegocio: nombreUnidad,
      idUnidadNegocio: _id,
    }));
  };

  return (
    <FormControl fullWidth size="small" margin="dense">
      <InputLabel id="label-unidadNegocio">Unidad de negocio</InputLabel>
      <Select
        required
        labelId="label-unidadNegocio"
        id="unidadNegocio-select"
        siza="small"
        value={redSocial.unidadNegocio}
        label="Unidad de negocio"
        onChange={handleChange}
        startAdornment={
          <InputAdornment position="start">
            <AssignmentInd />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="start">
            <NuevaUnidadN refetchExternal={refetch} external={true} />
          </InputAdornment>
        }
      >
        <MenuItem value="">Seleccione uno</MenuItem>
        {data.obtenerUnidadNegocios.map((res) => (
          <MenuItem key={res._id} value={res.nombreUnidad} data={res}>
            {res.nombreUnidad}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
