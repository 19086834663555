import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Actividades from "./Actividades";
import { EditCalendar, Event, ManageHistory } from "@mui/icons-material";
import TiposActividad from "../Configuraciones/TiposActividades";
import StatusActividad from "../Configuraciones/StatusActividad";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-actividades-${index}`}
      aria-labelledby={`tab-actividades-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 1 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-actividades-${index}`,
    "aria-controls": `tabpanel-actividades-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        //border: "1px solid red",
        height: { xs: `calc(100vh - 120px)`, md: `calc(100vh - 74px)` },
      }}
    >
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            minHeight: "auto",
            "& .MuiButtonBase-root": {
              minHeight: "auto",
              py: { xs: 1.3, md: 0 },
              pb: { md: 1 },
              textTransform: "none",
            },
            borderBottom: "1px solid",
            borderColor: "divider"
          }}
        >
          <Tab
            icon={<Event />}
            iconPosition="start"
            label="Actividades"
            {...a11yProps(0)}
          />
          <Tab
            icon={<EditCalendar />}
            iconPosition="start"
            label="Status"
            {...a11yProps(1)}
          />
          <Tab
            icon={<ManageHistory />}
            iconPosition="start"
            label="Tipos"
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Actividades />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <StatusActividad />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <TiposActividad />
      </CustomTabPanel>
    </Box>
  );
}
