import React from "react";
import NuevoTipoC from "./Actions/NuevoTipoC";
//import FilterTipoC from './Actions/FilterTipoC'
import { Box, Stack } from "@mui/material";
import TiposContactoTable from "./Table/Table";
import { OBTENER_TIPOS_CONTACTO } from "./graphql/queries";
import { useQuery } from "@apollo/client";
import { MainContext } from "../../../../Context/MainCtx";

export default function TiposContacto() {
  const { tokenDecoded } = React.useContext(MainContext);

  const tiposCQuery = useQuery(OBTENER_TIPOS_CONTACTO, {
    variables: {
      idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
    },
    fetchPolicy: "network-only",
  });

  return (
    <Box>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="flex-end"
        alignItems="center"
        sx={{ mb: 1, position: { sm: "absolute" }, top: 60, right: 24 }}
      >
        <NuevoTipoC refetch={tiposCQuery.refetch} />
      </Stack>
      <TiposContactoTable tiposCQuery={tiposCQuery} />
    </Box>
  );
}
