import React from "react";
import { Box, Button, Paper, Typography } from "@mui/material";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Cell,
  Tooltip,
} from "recharts";
import { DashboardContext } from "../Context/DashboardContext";
import { SOCIAL_NETWORKS } from "../../../../Config/constantes";
import { Forum } from "@mui/icons-material";

function getColorSocialMedia(name) {
  const index = SOCIAL_NETWORKS.findIndex((res) => res.name === name);
  return SOCIAL_NETWORKS[index].color;
}

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Box
        bgcolor="background.paper"
        sx={{ p: 1, borderRadius: 2, opacity: 0.7 }}
      >
        {payload.map(({ name, value }, index) => {
          return (
            <Typography key={index} variant="caption">
              <b>{`${name}: ${value}`}</b>
            </Typography>
          );
        })}
      </Box>
    );
  }
  return null;
};

export default function Prospectos() {
  const { dashQuery, handleSetTable, table } = React.useContext(
    DashboardContext
  );
  const { prospectos } = dashQuery.data.obtenerDataDashboard;

  return (
    <Paper variant="outlined">
      <Button
        fullWidth
        disableElevation
        size="small"
        sx={{ textTransform: "none" }}
        startIcon={<Forum />}
        onClick={() => handleSetTable("prospectos")}
        variant={table === "prospectos" ? "contained" : "text"}
      >
        Prospectos en forma de atracción
      </Button>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          minHeight: "35vh",
        }}
      >
        <ResponsiveContainer width="100%" height={230}>
          <ComposedChart
            layout="vertical"
            data={prospectos}
            margin={{
              top: 0,
              right: 10,
              left: 30,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" />
            <YAxis dataKey="formaAtraccion" type="category" fontSize={11} />
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey="contactos.length" barSize={30} name="PROSPECTOS">
              {prospectos.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={getColorSocialMedia(entry.formaAtraccion)}
                />
              ))}
            </Bar>
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </Paper>
  );
}
