import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import {
  CustomEditableTextField,
  CustomTableAutocomplete,
  CustomTableTextField,
} from "../CustomMuiComponents";

export default function LoadingAutocomplete({
  table,
  editable,
  label,
  value = "Cargando...",
}) {
  if (table) {
    return (
      <CustomTableAutocomplete
        disabled
        renderInput={(params) => (
          <CustomTableTextField
            {...params}
            label={label}
            InputProps={{
              ...params.InputProps,
              startAdornment: <CircularProgress size={14} sx={{ mr: 1 }} />,
            }}
          />
        )}
      />
    );
  } else if (editable) {
    return (
      <Autocomplete
        freeSolo
        value={value}
        options={[]}
        readOnly
        renderInput={(params) => (
          <CustomEditableTextField
            {...params}
            size="small"
            InputProps={{
              ...params.InputProps,
              startAdornment: <CircularProgress size={18} sx={{ mx: 1 }} />,
              sx: {
                "&.Mui-focused fieldset": {
                  border: "none!important",
                },
              },
            }}
          />
        )}
      />
    );
  } else {
    return (
      <Autocomplete
        disabled
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            margin="dense"
            label={label}
            InputProps={{
              ...params.InputProps,
              startAdornment: <CircularProgress size={18} sx={{ mx: 1 }} />,
            }}
          />
        )}
      />
    );
  }
}
