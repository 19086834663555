import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { CustomTableTextField } from "../../../../../Components/CustomMuiComponents";
import { TratosContext } from "../../Context/TratosContext";
import moment from "moment-timezone";
import { Rating, Typography } from "@mui/material";
import UsuariosMultipleTable from "../../Actions/NuevoTrato/UsuariosMultipleTable";
import AutoCompleteInputTable from "../../../../../Components/InputsForms/AutocompleteInputTable";
import ProductoCtxProvider from "../../../Configuraciones/Productos/Context/ProductoContext";
import NuevoProducto from "../../../Configuraciones/Productos/Actions/NuevoProducto";
import NuevoUsuario from "../../../Configuraciones/Usuarios/Actions/NuevoUsuario";
import NuevoContacto from "../../../Contactos/Contactos/Actions/NuevoContacto";
import NuevaMoneda from "../../../Configuraciones/Monedas/Actions/NuevaMoneda";
import NuevoEmbudo from "../../../Configuraciones/Embudos/Actions/NuevoEmbudo";
import { STATUS_TRATO } from "../../../../../Config/constantes";
import NuevaCampaign from "../../../Configuraciones/Campaigns/Actions/NuevaCampaign";
import NuevaUnidadN from "../../../Configuraciones/UnidadesNegocios/Actions/NuevaUnidadN";
import NuevaFormaContacto from "../../../Configuraciones/FormasContacto/Actions/NuevaFormaContacto";
import ContactosCtxProvider from "../../../Contactos/Contactos/Context/ContactoContext";

export default function EditableRowTrato() {
  const {
    setNewItem,
    newItem,
    loadingSave,
    queryCats,
    visiblePara,
    setVisiblePara,
    tableEl,
  } = React.useContext(TratosContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewItem((st) => ({ ...st, [name]: value }));
  };

  const autocompleteChange = (values) => {
    const { name, value, id, idName } = values;
    if (id && idName) {
      setNewItem((st) => ({
        ...st,
        [name]: value,
        [idName]: id,
      }));
    } else {
      setNewItem((st) => ({
        ...st,
        [name]: value,
      }));
    }
  };
  const autocompleteMultipleChange = (values) => {
    const array = values.map((res) => ({
      idUsuario: res._id,
      nombreCompleto: res.nombreCompleto,
    }));
    setVisiblePara(values);
    setNewItem((st) => ({
      ...st,
      visiblePara: array,
    }));
    if (tableEl.current) {
      setTimeout(() => {
        const container = tableEl.current;
        const scrollWidth = container.scrollWidth;
        const visibleWidth = container.clientWidth;
        const targetScrollLeft = scrollWidth - visibleWidth;
        container.scrollTo({
          left: targetScrollLeft,
          behavior: "smooth", // Agrega una transición suave
        });
      }, 300);
    }
  };

  setTimeout(() => {
    document.getElementById(newItem.focusOn)?.focus();
  }, 500);

  return (
    <TableRow role="checkbox" tabIndex={-1}>
      <TableCell padding="checkbox" />
      <TableCell padding="checkbox" />
      <TableCell>
        <CustomTableTextField
          id="nombreTrato-trato-texfield"
          name="nombreTrato"
          size="small"
          variant="outlined"
          fullWidth
          onChange={handleChange}
          value={newItem.nombreTrato}
          disabled={loadingSave}
          required
          InputProps={{ startAdornment: "*" }}
        />
      </TableCell>
      <TableCell>
        <AutoCompleteInputTable
          id="producto-trato-texfield"
          renderData={queryCats.data?.productos}
          value={newItem.producto}
          loading={queryCats.loading}
          error={queryCats.error}
          required={true}
          addButton={
            <ProductoCtxProvider>
              <NuevoProducto
                external={true}
                refetchExternal={queryCats.refetch}
              />
            </ProductoCtxProvider>
          }
          onChange={(_, option) =>
            autocompleteChange({
              name: "producto",
              idName: "idProducto",
              value: option?.nombre || "",
              id: option?._id || "",
            })
          }
          isOptionEqualToValue={({ nombre }, value) => nombre === value}
          getOptionLabel={(option) => option.nombre || option || null}
        />
      </TableCell>
      <TableCell>
        <AutoCompleteInputTable
          id="contacto-trato-texfield"
          renderData={queryCats.data?.contactos}
          value={newItem.contacto}
          loading={queryCats.loading}
          error={queryCats.error}
          disabled={loadingSave}
          addButton={
            <ContactosCtxProvider>
              <NuevoContacto
                external={true}
                refetchExternal={queryCats.refetch}
              />
            </ContactosCtxProvider>
          }
          onChange={(_, option) =>
            autocompleteChange({
              name: "contacto",
              idName: "idContacto",
              value: option?.nombre || "",
              id: option?._id || "",
            })
          }
          isOptionEqualToValue={({ nombre }, value) => nombre === value}
          getOptionLabel={(option) => option.nombre || option || null}
        />
      </TableCell>
      <TableCell>
        <AutoCompleteInputTable
          id="embudo-trato-texfield"
          renderData={queryCats.data?.embudos}
          value={newItem.embudo}
          loading={queryCats.loading}
          error={queryCats.error}
          disabled={loadingSave}
          addButton={
            <NuevoEmbudo external={true} refetchExternal={queryCats.refetch} />
          }
          onChange={(_, option) =>
            autocompleteChange({
              name: "embudo",
              idName: "idEmbudo",
              value: option?.fase || "",
              id: option?._id || "",
            })
          }
          isOptionEqualToValue={({ fase }, value) => fase === value}
          getOptionLabel={(option) => option.fase || option || null}
        />
      </TableCell>
      <TableCell>
        <CustomTableTextField
          id="probabilidadCierre-trato-texfield"
          name="probabilidadCierre"
          size="small"
          variant="outlined"
          fullWidth
          onChange={(e) => {
            if (
              parseFloat(e.target.value) > 100 ||
              parseFloat(e.target.value) < 0
            )
              return;
            handleChange(e);
          }}
          value={newItem.probabilidadCierre}
          required
          disabled={loadingSave}
          type="number"
          inputProps={{
            min: 0,
            max: 100,
          }}
        />
      </TableCell>
      <TableCell>
        <AutoCompleteInputTable
          id="prioridad-trato-texfield"
          renderData={["BAJA", "MEDIA", "ALTA"]}
          value={newItem.prioridad}
          disabled={loadingSave}
          onChange={(_, option) =>
            autocompleteChange({
              name: "prioridad",
              value: option || "",
            })
          }
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option) => option || option || null}
        />
      </TableCell>
      <TableCell>
        <AutoCompleteInputTable
          id="status-trato-texfield"
          renderData={STATUS_TRATO}
          value={newItem.status}
          disabled={loadingSave}
          onChange={(_, option) =>
            autocompleteChange({
              name: "status",
              value: option || "",
            })
          }
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option) => option || option || null}
        />
      </TableCell>
      <TableCell>
        <AutoCompleteInputTable
          id="formaContacto-trato-texfield"
          renderData={queryCats.data?.formasContacto}
          value={newItem.formaContacto}
          loading={queryCats.loading}
          error={queryCats.error}
          disabled={loadingSave}
          required
          addButton={
            <NuevaFormaContacto
              external={true}
              refetchExternal={queryCats.refetch}
            />
          }
          onChange={(_, option) =>
            autocompleteChange({
              name: "formaContacto",
              idName: "idFormaContacto",
              value: option?.formaContacto || "",
              id: option?._id || "",
            })
          }
          isOptionEqualToValue={({ formaContacto }, value) =>
            formaContacto === value
          }
          getOptionLabel={(option) => option.formaContacto || option || null}
        />
      </TableCell>
      <TableCell>
        <CustomTableTextField
          id="valor-trato-texfield"
          name="valor"
          size="small"
          variant="outlined"
          fullWidth
          onChange={handleChange}
          value={newItem.valor}
          required
          disabled={loadingSave}
          InputProps={{ startAdornment: "*" }}
        />
      </TableCell>
      <TableCell>
        <AutoCompleteInputTable
          id="moneda-trato-texfield"
          renderData={queryCats.data?.monedas}
          value={newItem.moneda}
          loading={queryCats.loading}
          error={queryCats.error}
          disabled={loadingSave}
          addButton={
            <NuevaMoneda external={true} refetchExternal={queryCats.refetch} />
          }
          onChange={(_, option) =>
            autocompleteChange({
              name: "moneda",
              idName: "idMoneda",
              value: option?.moneda || "",
              id: option?._id || "",
            })
          }
          isOptionEqualToValue={({ moneda }, value) => moneda === value}
          getOptionLabel={(option) => option.moneda || option || null}
        />
      </TableCell>
      <TableCell>
        <Rating
          id="interes-trato-texfield"
          size="small"
          value={newItem.interes}
          onChange={(_, interes) => {
            setNewItem((st) => ({
              ...st,
              interes,
            }));
          }}
        />
      </TableCell>
      <TableCell>
        <AutoCompleteInputTable
          id="campaign-trato-texfield"
          renderData={queryCats.data?.campaigns}
          value={newItem.campaign}
          loading={queryCats.loading}
          error={queryCats.error}
          disabled={loadingSave}
          addButton={
            <NuevaCampaign
              external={true}
              refetchExternal={queryCats.refetch}
            />
          }
          onChange={(_, option) =>
            autocompleteChange({
              name: "campaign",
              idName: "idCampaign",
              value: option?.nombre || "",
              id: option?._id || "",
            })
          }
          isOptionEqualToValue={({ nombre }, value) => nombre === value}
          getOptionLabel={(option) => option.nombre || option || null}
        />
      </TableCell>
      <TableCell>
        <AutoCompleteInputTable
          id="unidadNegocio-trato-texfield"
          renderData={queryCats.data?.unidades}
          value={newItem.unidadNegocio}
          loading={queryCats.loading}
          error={queryCats.error}
          disabled={loadingSave}
          addButton={
            <NuevaUnidadN external={true} refetchExternal={queryCats.refetch} />
          }
          onChange={(_, option) =>
            autocompleteChange({
              name: "unidadNegocio",
              idName: "idUnidadNegocio",
              value: option?.nombreUnidad || "",
              id: option?._id || "",
            })
          }
          isOptionEqualToValue={({ nombreUnidad }, value) =>
            nombreUnidad === value
          }
          getOptionLabel={(option) => option.nombreUnidad || option || null}
        />
      </TableCell>
      <TableCell>
        <AutoCompleteInputTable
          id="propietario-trato-texfield"
          renderData={queryCats.data?.usuarios}
          value={newItem.propietario}
          loading={queryCats.loading}
          error={queryCats.error}
          disabled={loadingSave || newItem.new}
          addButton={
            <NuevoUsuario external={true} refetchExternal={queryCats.refetch} />
          }
          onChange={(_, option) =>
            autocompleteChange({
              name: "propietario",
              idName: "idPropietario",
              value: option?.nombreCompleto || "",
              id: option?._id || "",
            })
          }
          isOptionEqualToValue={({ nombreCompleto }, value) =>
            nombreCompleto === value
          }
          getOptionLabel={(option) => option.nombreCompleto || option || null}
        />
      </TableCell>
      <TableCell>
        {newItem.new ? (
          <Typography variant="body2">{newItem.usuarioCierre}</Typography>
        ) : (
          <AutoCompleteInputTable
            id="usuarioCierre-trato-texfield"
            renderData={queryCats.data?.usuarios}
            value={newItem.usuarioCierre}
            loading={queryCats.loading}
            error={queryCats.error}
            disabled={loadingSave}
            addButton={
              <NuevoUsuario
                external={true}
                refetchExternal={queryCats.refetch}
              />
            }
            onChange={(_, option) =>
              autocompleteChange({
                name: "usuarioCierre",
                idName: "idUsuarioCierre",
                value: option?.nombreCompleto || "",
                id: option?._id || "",
              })
            }
            isOptionEqualToValue={({ nombreCompleto }, value) =>
              nombreCompleto === value
            }
            getOptionLabel={(option) => option.nombreCompleto || option || null}
          />
        )}
      </TableCell>
      <TableCell>
        <CustomTableTextField
          id="fechaEstimadaCierre-trato-texfield"
          name="fechaEstimadaCierre"
          size="small"
          variant="outlined"
          fullWidth
          onChange={handleChange}
          value={newItem.fechaEstimadaCierre}
          required
          type="date"
          disabled={loadingSave}
        />
      </TableCell>
      <TableCell>
        <CustomTableTextField
          id="fechaTrato-trato-texfield"
          name="fechaTrato"
          size="small"
          variant="outlined"
          fullWidth
          onChange={handleChange}
          value={moment(newItem.fechaTrato)
            .tz("America/Mexico_City")
            .format("yyyy-MM-DD")}
          required
          type="date"
          disabled={loadingSave}
          InputProps={{ startAdornment: "*" }}
        />
      </TableCell>
      <TableCell>
        <UsuariosMultipleTable
          id="visiblePara-trato-texfield"
          value={visiblePara}
          handleChange={autocompleteMultipleChange}
          query={queryCats}
          disabled={loadingSave}
        />
      </TableCell>
    </TableRow>
  );
}
