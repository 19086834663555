import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import InputAdornment from "@mui/material/InputAdornment";
import { Info, SupervisedUserCircle } from "@mui/icons-material";
import { OBTENER_USUARIOS } from "../../graphql/queries";
import { useQuery } from "@apollo/client";
import { MainContext } from "../../../../../../Context/MainCtx";
import { CircularProgress } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, userVer, theme) {
  return {
    fontWeight:
      userVer.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function UsuariosVer({
  userData,
  setUserData,
  userVer,
  setUserVer,
}) {
  const theme = useTheme();
  const { tokenDecoded } = React.useContext(MainContext);

  const userQuery = useQuery(OBTENER_USUARIOS, {
    variables: {
      idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
    },
    fetchPolicy: "network-only",
  });
  const { data, loading, error } = userQuery;

  if (loading) {
    return (
      <FormControl fullWidth size="small" margin="dense">
        <InputLabel id="ver-label">Ver</InputLabel>
        <Select
          labelId="ver-label"
          value=""
          input={<OutlinedInput />}
          size="small"
          disabled
          startAdornment={
            <InputAdornment position="start">
              <CircularProgress size={20} />
            </InputAdornment>
          }
        />
      </FormControl>
    );
  }
  if (error) {
    return (
      <FormControl fullWidth size="small" margin="dense">
        <InputLabel id="ver-label">Ver</InputLabel>
        <Select
          labelId="ver-label"
          value=""
          input={<OutlinedInput />}
          size="small"
          disabled
          startAdornment={
            <InputAdornment position="start">
              <Info size={20} />
            </InputAdornment>
          }
        />
      </FormControl>
    );
  }

  const handleChange = (event, child) => {
    const { value } = event.target;
    const { data } = child.props;
    let ver = [...userData.ver];
    const existe = ver.filter(
      (res) => res.nombreCompleto === data.nombreCompleto
    );
    if (existe.length > 0) {
      //si existe, quitarlo
      ver = ver.filter((name) => name.nombreCompleto !== data.nombreCompleto);
    } else {
      //si no existe, ponerlo
      ver.push({ idUsuario: data._id, nombreCompleto: data.nombreCompleto });
    }
    setUserVer(typeof value === "string" ? value.split(",") : value);
    setUserData((st) => ({
      ...st,
      ver,
    }));
  };

  return (
    <FormControl fullWidth size="small" margin="dense">
      <InputLabel id="ver-label">Ver</InputLabel>
      <Select
        labelId="ver-label"
        id="ver-select"
        multiple
        value={userVer}
        onChange={handleChange}
        input={<OutlinedInput id="select-ver-chip" label="Ver" />}
        size="small"
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        startAdornment={
          <InputAdornment position="start">
            <SupervisedUserCircle />
          </InputAdornment>
        }
        MenuProps={MenuProps}
      >
        {data.obtenerUsuarios.map((res) => (
          <MenuItem
            key={`item-${res._id}`}
            value={res.nombreCompleto}
            style={getStyles(res.nombreCompleto, userVer, theme)}
            data={res}
          >
            {res.nombreCompleto}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
