import { gql } from "@apollo/client";

export const CREAR_AREA = gql`
  mutation CrearArea($idEmpresa: ID!, $area: String!) {
    crearArea(idEmpresa: $idEmpresa, area: $area) {
      message
    }
  }
`;
export const ACTUALIZAR_AREA = gql`
  mutation ActualizarArea($idEmpresa: ID!, $idArea: ID!, $area: String!) {
    actualizarArea(idEmpresa: $idEmpresa, idArea: $idArea, area: $area) {
      message
    }
  }
`;
export const ELIMINAR_AREA = gql`
  mutation EliminarArea($idArea: ID!) {
    eliminarArea(idArea: $idArea) {
      message
    }
  }
`;
export const ELIMINAR_AREAS = gql`
  mutation EliminarAreas($areas: [AreasDeleted]) {
    eliminarAreas(areas: $areas) {
      message
    }
  }
`;