import * as React from "react";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { useDebounce } from "use-debounce";

export default function Descuento({ productData, setProductData }) {
  const [percent, setPercent] = React.useState();
  const [price, setPrice] = React.useState();

  const [PERCENT] = useDebounce(percent, 500);
  const [PRICE] = useDebounce(price, 500);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData((st) => ({
      ...st,
      [name]: value,
    }));
  };

  const calcularPrecio = React.useCallback(() => {
    if (PERCENT > 100 || !PERCENT) return;
    const OLD_PRICE = parseFloat(productData.precio);
    const PORCENTAJE = parseFloat(100 - PERCENT);
    const NEW_PRICE = parseFloat((OLD_PRICE * PORCENTAJE) / 100);
    const DESCONTADO = parseFloat(OLD_PRICE - NEW_PRICE);
    setProductData((st) => ({
      ...st,
      valorDescuento: DESCONTADO,
    }));
  }, [PERCENT, productData.precio, setProductData]);

  const calcularPercent = React.useCallback(() => {
    const OLD_PRICE = parseFloat(productData.precio);
    if (PRICE > OLD_PRICE || !PRICE) return;
    const PORCENTAJE = parseFloat(100 - (PRICE / OLD_PRICE) * 100);
    setProductData((st) => ({
      ...st,
      promocion: PORCENTAJE,
    }));
  }, [PRICE, productData.precio, setProductData]);

  const verifyNoEmpty = (e) => {
    const { value } = e.target;
    if (!value) {
      setProductData((st) => ({ ...st, valorDescuento: 0, promocion: 0 }));
    }
  };

  React.useEffect(() => {
    calcularPrecio();
  }, [PERCENT, calcularPrecio]);

  React.useEffect(() => {
    calcularPercent();
  }, [PRICE, calcularPercent]);

  return (
    <Grid container spacing={1}>
      <Grid item md={5}>
        <TextField
          label="Tipo descuento"
          size="small"
          variant="outlined"
          margin="dense"
          fullWidth
          name="tipoDescuento"
          value={productData.tipoDescuento}
          onChange={handleChange}
        />
      </Grid>
      <Grid item md={4}>
        <TextField
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          name="valorDescuento"
          value={productData.valorDescuento}
          onChange={(e) => {
            setProductData((st) => ({
              ...st,
              valorDescuento: parseFloat(e.target.value),
            }));
            setPrice(e.target.value);
          }}
          onBlur={verifyNoEmpty}
          type="number"
          label="Valor Descuento"
          size="small"
          variant="outlined"
          margin="dense"
          fullWidth
        />
      </Grid>
      <Grid item md={3}>
        <TextField
          InputProps={{
            startAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          name="promocion"
          value={productData.promocion}
          onChange={(e) => {
            setProductData((st) => ({
              ...st,
              promocion: parseFloat(e.target.value),
            }));
            setPercent(e.target.value);
          }}
          onBlur={verifyNoEmpty}
          size="small"
          variant="outlined"
          margin="dense"
          fullWidth
        />
      </Grid>
    </Grid>
  );
}
