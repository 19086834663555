import React from "react";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import Rating from "@mui/material/Rating";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  formatoFechaCorta,
  formatoMexico,
  getBicolorTable,
  getColorTratoPrioridades,
  getColorTratoStatus,
  getContrastColor,
  getWidth,
} from "../../../../Config/reuserFunctions";
import { DashboardContext } from "../Context/DashboardContext";
import { Box, Pagination, Typography, useTheme } from "@mui/material";

export default function TableTratos() {
  const { dashQuery, setPage } = React.useContext(DashboardContext);
  const { tratos } = dashQuery.data.obtenerDataDashboard.tratos;
  const theme = useTheme();

  const { page, totalPages, docs } = tratos;

  const handleChangePage = (event, newPage) => setPage(newPage - 1);

  return (
    <Paper
      variant="outlined"
      sx={{
        my: 2,
        //borderColor: theme.palette.primary.main,
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          backgroundColor: theme.palette.primary.main,
          zIndex: 1,
          top: -25,
          left: 0,
          px: 2,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
        }}
      >
        <Typography variant="subtitle1" color="white">
          <b>Tratos</b>
        </Typography>
      </Box>
      <TableContainer sx={{ height: "60vh" }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  minWidth: 150,
                }}
              >
                Trato
              </TableCell>
              <TableCell sx={{ minWidth: 150 }}>Producto</TableCell>
              <TableCell sx={{ minWidth: 150 }}>Nombre Contacto</TableCell>
              <TableCell padding="checkbox" sx={{ minWidth: 120 }}>
                Fecha trato
              </TableCell>
              <TableCell padding="checkbox" align="center">
                Prioridad
              </TableCell>
              <TableCell padding="checkbox" align="center">
                Status
              </TableCell>
              <TableCell padding="checkbox" align="center">
                Interés
              </TableCell>
              <TableCell sx={{ minWidth: 110 }}>Probabilidad</TableCell>
              <TableCell sx={{ minWidth: 140 }}>Forma contacto</TableCell>
              <TableCell sx={{ minWidth: 140 }}>Forma atracción</TableCell>
              <TableCell sx={{ minWidth: 100 }}>Campaña</TableCell>
              <TableCell sx={{ minWidth: 140 }}>Unidad negocio</TableCell>
              <TableCell sx={{ minWidth: 100 }}>Valor</TableCell>
              <TableCell sx={{ minWidth: 100 }}>Propietario</TableCell>
              <TableCell sx={{ minWidth: 130 }}>Usuario Cierre</TableCell>
              <TableCell sx={{ minWidth: 180 }}>
                Fecha estimada cierre
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {docs.map((trato, index) => (
              <TableRow key={trato._id} style={getBicolorTable(theme, index)}>
                <TableCell
                  sx={{
                    minWidth: getWidth(trato.nombreTrato),
                  }}
                >
                  {trato.nombreTrato}
                </TableCell>
                <TableCell sx={{ minWidth: getWidth(trato.producto) }}>
                  {trato.producto}
                </TableCell>
                <TableCell sx={{ minWidth: getWidth(trato.contacto) }}>
                  {trato.contacto}
                </TableCell>
                <TableCell>{formatoFechaCorta(trato.fechaTrato)}</TableCell>
                <TableCell align="center">
                  <Chip
                    label={trato.prioridad}
                    sx={{
                      height: 20,
                      fontSize: 11,
                      fontWeight: "bold",
                      bgcolor: (theme) => getColorTratoPrioridades(trato.prioridad, theme),
                      color: (theme) => getContrastColor(getColorTratoPrioridades(trato.prioridad, theme))
                    }}
                    size="small"
                  />
                </TableCell>
                <TableCell align="center">
                  {trato.status ? (
                    <Chip
                      label={trato.status}
                      sx={{
                        height: 20,
                        fontSize: 11,
                        fontWeight: "bold",
                        bgcolor: (theme) => getColorTratoStatus(trato.status, theme),
                        color: (theme) => getContrastColor(getColorTratoStatus(trato.status, theme))
                      }}
                      size="small"
                    />
                  ) : null}
                </TableCell>
                <TableCell>
                  <Rating size="small" value={trato.interes} readOnly />
                </TableCell>
                <TableCell align="center">
                  {trato.probabilidadCierre}%
                </TableCell>
                <TableCell sx={{ minWidth: getWidth(trato.formaContacto) }}>
                  {trato.formaContacto}
                </TableCell>
                <TableCell sx={{ minWidth: getWidth(trato.formaAtraccion) }}>
                  {trato.formaAtraccion}
                </TableCell>
                <TableCell sx={{ minWidth: getWidth(trato.campaign) }}>
                  {trato.campaign}
                </TableCell>
                <TableCell sx={{ minWidth: getWidth(trato.unidadNegocio) }}>
                  {trato.unidadNegocio}
                </TableCell>
                <TableCell
                  sx={{
                    minWidth: getWidth(
                      `$${formatoMexico(trato.valor)} ${trato.moneda}`
                    ),
                  }}
                >{`$${formatoMexico(trato.valor)} ${trato.moneda}`}</TableCell>
                <TableCell sx={{ minWidth: getWidth(trato.propietario) }}>
                  {trato.propietario}
                </TableCell>
                <TableCell sx={{ minWidth: getWidth(trato.usuarioCierre) }}>
                  {trato.usuarioCierre}
                </TableCell>
                <TableCell
                  sx={{ minWidth: getWidth(trato.fechaEstimadaCierre) }}
                >
                  {formatoFechaCorta(trato.fechaEstimadaCierre)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          color="primary"
          page={page}
          count={totalPages}
          onChange={handleChangePage}
        />
      </Box>
    </Paper>
  );
}
