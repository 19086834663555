import React from "react";
import { Stack } from "@mui/material";
import NuevoTipoActividad from "./Actions/NuevoTipoActividad";
import TipoActividadTable from "./Table/Table";
/* import SearchTipoActividad from "./Actions/Search"; */
import { MainContext } from "../../../../Context/MainCtx";
import { OBTENER_TIPO_ACTIVIDAD } from "./graphql/queries";
import { useQuery } from "@apollo/client";

export default function TiposActividad() {
  const { tokenDecoded } = React.useContext(MainContext);

  const tipoQuery = useQuery(OBTENER_TIPO_ACTIVIDAD, {
    variables: {
      idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
    },
    fetchPolicy: "network-only",
  });

  return (
    <div>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="flex-end"
        alignItems="center"
        sx={{
          mb: 1,
          position: { sm: "absolute" },
          top: 60,
          right: 24,
        }}
      >
        <NuevoTipoActividad refetch={tipoQuery.refetch} />
      </Stack>
      <TipoActividadTable tipoQuery={tipoQuery} />
    </div>
  );
}
