import { gql } from "@apollo/client";

export const OBTENER_REUNIONES = gql`
  query ObtenerReuniones($idEmpresa: ID!, $filter: ReunionFiltrosInput) {
    obtenerReuniones(idEmpresa: $idEmpresa, filter: $filter) {
      _id
      nombreEvento
      descripcion
      fechaInicio
      fechaFin
      trato
      idTrato
      linkMeeting
      idGoogleMeet
      invitados {
        idUsuario
        correo
        nombreCompleto
      }
      creadoPor {
        email
        idUsuario
        nombre
      }
      fechaRegistro
      idEmpresa
      invitados_data {
        _id
        correo
        foto
        nombreCompleto
        nombreUsuario
        telefono
      }
    }
  }
`;

export const OBTENER_QUERIES_REUNIONES = gql`
  query ObtenerCatalogosReuniones($idEmpresa: ID!) {
    obtenerCatalogosReuniones(idEmpresa: $idEmpresa) {
      contactos {
        _id
        nombre
        correo
      }
      tratos {
        _id
        nombreTrato
      }
      usuarios {
        _id
        correo
        nombreCompleto
      }
    }
  }
`;
