import { gql } from "@apollo/client";

export const CREAR_USUARIO = gql`
  mutation CrearUsuario($idEmpresa: ID!, $input: crearUsuarioInput) {
    crearUsuario(idEmpresa: $idEmpresa, input: $input) {
      message
    }
  }
`;
export const ACTUALIZAR_USUARIO = gql`
  mutation ActualizarUsuario($idUsuario: ID!, $input: crearUsuarioInput) {
    actualizarUsuario(idUsuario: $idUsuario, input: $input) {
      message
    }
  }
`;
export const ELIMINAR_USUARIO = gql`
  mutation EliminarUsuario($idUsuario: ID!) {
    eliminarUsuario(idUsuario: $idUsuario) {
      message
    }
  }
`;
