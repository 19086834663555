import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import { Add, Done, WhatsApp } from "@mui/icons-material";
import CustomAvatar from "../../../../../../Components/Avatar/CustomAvatar";
import { CircularProgress } from "@mui/material";
import EditableTextInput from "../../../../../../Components/InputsForms/EditableTextInput";
import ChipStatusContacto from "./ChipStatusContacto";
import { TratosContext } from "../../../Context/TratosContext";
import { ACTUALIZAR_CONTACTO } from "../../../../Contactos/Contactos/graphql/mutations";
import { useMutation } from "@apollo/client";
import { MainContext } from "../../../../../../Context/MainCtx";
import SelectContactList from "./ContactoList";

export default function ContactoEdit({ trato }) {
  const { snackMessage, tokenDecoded } = React.useContext(MainContext);
  const { queryRefetch, queryCats, setTrato } = React.useContext(TratosContext);
  const [loading, setLoading] = React.useState(false);
  const [contacto, setContacto] = React.useState({
    ...trato.contactoPopulated[0],
  });
  const [actualizarContacto] = useMutation(ACTUALIZAR_CONTACTO);

  const handleChangeContacto = (e) => {
    const { name, value } = e.target;
    setContacto((state) => ({ ...state, [name]: value }));
  };

  const sendWhatsAppMessage = (contacto) => {
    const phoneNumber = contacto.telefono.replace(/[^\d]/g, "");
    const message = `Hola ${contacto.nombre}, `;
    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
    window.open(url, "_blank");
  };

  React.useEffect(() => {
    if (trato) {
      setContacto({
        ...trato.contactoPopulated[0],
      });
    }
  }, [trato]);

  const updateContacto = async () => {
    try {
      setLoading(true);
      const { statusContactoPopulated, _id, ...input } = contacto;
      await actualizarContacto({
        variables: {
          idContacto: contacto._id,
          idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
          input,
        },
      });
      setTrato((st) => ({
        ...st,
        contacto: input.nombre,
        contactoPopulated: [
          { ...st.contactoPopulated[0], nombre: input.nombre },
        ],
      }));
      queryCats.refetch();
      queryRefetch();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
      console.log(err.networkError?.result);
    }
  };

  return (
    <Box sx={{ position: { xs: "initial", md: "sticky" }, top: 0 }}>
      <Stack direction="row" spacing={1}>
        <CustomAvatar name={contacto.nombre} size={50} />
        <Box sx={{ width: "100%" }}>
          <Box sx={{ display: "flex" }}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                updateContacto();
              }}
            >
              <EditableTextInput
                fullWidth
                placeholder="Nombre contacto"
                name="nombre"
                value={contacto.nombre}
                loading={loading}
                onChange={handleChangeContacto}
                actionButton={
                  <IconButton size="small" color="primary" type="submit">
                    {loading ? (
                      <CircularProgress size={20} color="primary" />
                    ) : (
                      <Done />
                    )}
                  </IconButton>
                }
              />
            </form>

            <SelectContactList />
          </Box>
          <Chip
            label={contacto.etiqueta}
            size="small"
            sx={{
              fontWeight: "bold",
            }}
          />
        </Box>
      </Stack>
      <Stack direction="row" sx={{ my: 2 }} spacing={3} alignItems="flex-end">
        <Box>
          <Typography color="gray" variant="body2" sx={{ display: "block" }}>
            Status
          </Typography>
          {queryCats.data?.statusContactos ? (
            <ChipStatusContacto
              contacto={contacto}
              setContacto={setContacto}
              refetch={queryRefetch}
              query={queryCats.data?.statusContactos}
            />
          ) : (
            <Chip
              label={contacto.statusContacto}
              color="info"
              sx={{
                fontWeight: "bold",
              }}
            />
          )}
        </Box>
        <Box>
          <Typography color="gray" variant="body2" sx={{ display: "block" }}>
            Mensaje
          </Typography>
          <IconButton
            sx={{ p: 0 }}
            onClick={() => sendWhatsAppMessage(contacto)}
          >
            <WhatsApp sx={{ fontSize: 24 }} />
          </IconButton>
        </Box>
      </Stack>
      <Box sx={{ mb: 2 }}>
        <Typography variant="body2" color="gray">
          Telefono
        </Typography>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            updateContacto();
          }}
        >
          <EditableTextInput
            fullWidth
            placeholder="Agregar telefono"
            name="telefono"
            value={contacto.telefono}
            icon={<Add />}
            loading={loading}
            onChange={handleChangeContacto}
            actionButton={
              <IconButton size="small" color="primary" type="submit">
                {loading ? (
                  <CircularProgress size={20} color="primary" />
                ) : (
                  <Done />
                )}
              </IconButton>
            }
          />
        </form>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="body2" color="gray">
          Correo
        </Typography>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            updateContacto();
          }}
        >
          <EditableTextInput
            fullWidth
            placeholder="Agregar Correo"
            name="correo"
            value={contacto.correo}
            icon={<Add />}
            loading={loading}
            onChange={handleChangeContacto}
            actionButton={
              <IconButton size="small" color="primary" type="submit">
                {loading ? (
                  <CircularProgress size={20} color="primary" />
                ) : (
                  <Done />
                )}
              </IconButton>
            }
          />
        </form>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="body2" color="gray">
          Organización
        </Typography>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            updateContacto();
          }}
        >
          <EditableTextInput
            fullWidth
            placeholder="Agregar Organización"
            name="organizacion"
            value={contacto.organizacion}
            icon={<Add />}
            loading={loading}
            onChange={handleChangeContacto}
            actionButton={
              <IconButton size="small" color="primary" type="submit">
                {loading ? (
                  <CircularProgress size={20} color="primary" />
                ) : (
                  <Done />
                )}
              </IconButton>
            }
          />
        </form>
      </Box>
    </Box>
  );
}
