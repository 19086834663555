import React, { createContext } from "react";
import { MainContext } from "../../../../Context/MainCtx";
import { useDebounce } from "use-debounce";
import { useMutation, useQuery } from "@apollo/client";
import {
  OBTENER_QUERIES_CONTRATO,
  OBTENER_TRATOS_BY_FASE,
} from "../graphql/queries";
import moment from "moment-timezone";
import { ACTUALIZAR_TRATO, CREAR_TRATO } from "../graphql/mutations";
import { STATUS_TRATO } from "../../../../Config/constantes";

export const TratosContext = createContext();

const initial_state_trato = {
  new: true,
  producto: "",
  unidadNegocio: "",
  fechaTrato: moment().tz("America/Mexico_City").format(),
  contacto: "",
  nombreTrato: "",
  moneda: "",
  valor: "",
  formaContacto: "",
  embudo: "",
  status: STATUS_TRATO[2],
  visiblePara: [],
  propietario: "",
  interes: 0,
  campaign: "",
  prioridad: "MEDIA",
  probabilidadCierre: 0,
  fechaEstimadaCierre: moment().tz("America/Mexico_City").format("yyyy-MM-DD"),
  usuarioCierre: "",
};

const initial_query = {
  data: undefined,
  loading: true,
  error: undefined,
};

const initial_filter = {
  searchFilter: "",
  fechaStart: "",
  fechaEnd: "",
  formaContacto: "",
  campaign: "",
  unidadNegocio: "",
  moneda: "",
  embudo: "",
  prioridad: "",
  status: "",
  propietario: "",
  usuarioCierre: "",
};

const TratosCtxProvider = ({ children }) => {
  const { tokenDecoded, snackMessage } = React.useContext(MainContext);
  const [filter, setFilter] = React.useState(initial_filter);
  const [trato, setTrato] = React.useState(initial_state_trato);
  const [fases, setFases] = React.useState([]);
  const [tratos, setTratos] = React.useState([]);
  const [visiblePara, setVisiblePara] = React.useState([]);
  const [queryCats, setQueryCats] = React.useState(initial_query);
  const [mode, setMode] = React.useState("table");
  const [isSelecting, setIsSelecting] = React.useState(false);
  const [itemsSelected, setItemsSelected] = React.useState([]);
  const [loadingSave, setLoadingSave] = React.useState(false);
  const [newItem, setNewItem] = React.useState(null);
  const [indeterminated, setIndeterminated] = React.useState(false);
  const [FILTER] = useDebounce(filter, 500);
  const tableEl = React.useRef(null);
  const [crearTrato] = useMutation(CREAR_TRATO);
  const [actualizarTrato] = useMutation(ACTUALIZAR_TRATO);

  const tratoQuery = useQuery(OBTENER_TRATOS_BY_FASE, {
    variables: {
      filter: FILTER,
      idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
    },
    fetchPolicy: "network-only",
  });

  const formQuery = useQuery(OBTENER_QUERIES_CONTRATO, {
    variables: {
      idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
    },
    fetchPolicy: "network-only",
  });
  const { data, loading, error, refetch } = tratoQuery;

  React.useEffect(() => {
    if (data) {
      setFases(data.obtenerTratos.tratoByFase);
      setTratos(data.obtenerTratos.tratos);
    }
  }, [data]);

  React.useEffect(() => {
    if (formQuery.data) {
      setQueryCats({
        loading: formQuery.loading,
        data: formQuery.data.obtenerCatalogosTrato,
        error: formQuery.error,
        refetch: formQuery.refetch,
      });
    }
  }, [formQuery]);

  const handleSaveTrato = async (e) => {
    try {
      e.preventDefault();
      if (loadingSave) return;
      setLoadingSave(true);
      let input = { ...newItem };
      input.valor = parseFloat(input.valor);
      input.probabilidadCierre = parseFloat(input.probabilidadCierre);
      if (input.new) {
        delete input.new;
        await crearTrato({
          variables: {
            idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
            input,
          },
        });
      } else {
        delete input._id;
        delete input.idEmpresa;
        delete input.actividades;
        delete input.fechaRegistro;
        delete input.focusOn;
        delete input.contactoPopulated;
        await actualizarTrato({
          variables: {
            idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
            idTrato: newItem._id,
            input,
          },
        });
      }
      refetch();
      setLoadingSave(false);
      setNewItem(null);
    } catch (err) {
      console.log(err);
      console.log(err.networkError?.result);
      setLoadingSave(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <TratosContext.Provider
      value={{
        filter,
        setFilter,
        initial_filter,
        queryData: data,
        queryLoading: loading,
        queryError: error,
        queryRefetch: refetch,
        trato,
        setTrato,
        fases,
        setFases,
        tratos,
        setTratos,
        visiblePara,
        setVisiblePara,
        initial_state_trato,
        queryCats,
        mode,
        setMode,
        tokenDecoded,
        itemsSelected,
        setItemsSelected,
        newItem,
        setNewItem,
        loadingSave,
        setLoadingSave,
        indeterminated,
        setIndeterminated,
        handleSaveTrato,
        tableEl,
        isSelecting,
        setIsSelecting,
      }}
    >
      {children}
    </TratosContext.Provider>
  );
};

export default TratosCtxProvider;
