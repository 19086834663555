import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Checkbox, TableSortLabel } from "@mui/material";
import { AreaContext } from "../Context/AreaContext";

const headCells = [
  {
    id: "area",
    numeric: false,
    disablePadding: true,
    label: "Área",
  },
];

export default function TableHeadAreas({
  handleCheckAll,
  handleSort,
  order,
  orderBy,
}) {
  const { itemsSelected, indeterminated } = React.useContext(AreaContext);

  return (
    <TableHead sx={{ height: 42 }}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={indeterminated}
            checked={itemsSelected.length > 0 && !indeterminated}
            onChange={(e) => handleCheckAll(e.target.checked)}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={{ fontWeight: "bold" }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={() => handleSort(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell padding="checkbox" />
      </TableRow>
    </TableHead>
  );
}
