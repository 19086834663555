import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CustomLoader from "../../../../../Components/CustomLoader";
import ErrorMessage from "../../../../../Components/ErrorMessage";
import EliminarUsuario from "./EliminarUsuario";
import NuevoUsuario from "../Actions/NuevoUsuario";
import {
  formatoFechaCorta,
  getBicolorTable,
} from "../../../../../Config/reuserFunctions";
import CustomAvatar from "../../../../../Components/Avatar/CustomAvatar";
import { useTheme } from "@mui/material";

export default function TablaUsuarios({ usuariosQuery }) {
  const { loading, data, error, refetch } = usuariosQuery;
  const theme = useTheme();

  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage />;

  return (
    <Paper variant="outlined" sx={{ overflow: "hidden" }}>
      <TableContainer
        sx={{
          transition: "width .3s",
          height: { xs: `calc(100vh - 232px)`, sm: `calc(100vh - 130px)` },
          overflowX: "auto",
        }}
      >
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Usuario</TableCell>
              <TableCell>Nombre completo</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Área</TableCell>
              <TableCell>Fecha ingreso</TableCell>
              <TableCell>Fecha registro</TableCell>
              <TableCell padding="checkbox" />
              <TableCell padding="checkbox" />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.obtenerUsuarios.map((data, index) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={data._id}
                  style={getBicolorTable(theme, index)}
                >
                  <TableCell>
                    <CustomAvatar
                      name={data.nombreCompleto}
                      size={36}
                      img={data.foto}
                    />
                  </TableCell>
                  <TableCell>{data.nombreUsuario}</TableCell>
                  <TableCell>{data.nombreCompleto}</TableCell>
                  <TableCell>{data.tipoUsuario}</TableCell>
                  <TableCell>{data.area}</TableCell>
                  <TableCell>{formatoFechaCorta(data.fechaIngreso)}</TableCell>
                  <TableCell>{formatoFechaCorta(data.fechaRegistro)}</TableCell>
                  <TableCell padding="checkbox">
                    <NuevoUsuario edit={true} data={data} refetch={refetch} />
                  </TableCell>
                  <TableCell padding="checkbox">
                    <EliminarUsuario data={data} refetch={refetch} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
