import React from "react";
import { Chip, useTheme } from "@mui/material";
import { Cancel } from "@mui/icons-material";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 14)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */
  return color;
}

const getContrastMui = (bgColor = "#ffffff") => {
  // Convertir el color de fondo a RGB
  const r = parseInt(bgColor.slice(1, 3), 16);
  const g = parseInt(bgColor.slice(3, 5), 16);
  const b = parseInt(bgColor.slice(5, 7), 16);

  // Calcular el valor de luminosidad (L)
  //const L = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  const L = 0.1412 * r + 0.5652 * g + 0.0722 * b;

  // Elegir el color de texto en función del contraste
  const muiColor = L > 128 ? 'default' : 'primary';
  return muiColor;
}


export default function ChipUsuario({ label, mx = 0.2, fontSize = 13, idonfocus, onDelete = null }) {
  const theme = useTheme();

  if (!label) return <Chip />;

  return (
    <Chip
      label={label.split(" ")[0]}
      idonfocus={idonfocus}
      color={getContrastMui(stringToColor(label))}
      sx={{
        height: "26px",
        mx,
        backgroundColor: stringToColor(label),
        color: theme.palette.getContrastText(stringToColor(label)),
        fontSize,
      }}
      onDelete={onDelete}
      deleteIcon={<Cancel color="inherit" />}
    />
  );
}
