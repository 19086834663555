import { gql } from "@apollo/client";

export const CREAR_TIPO_USUARIO = gql`
  mutation CrearTipoUsuario($idEmpresa: ID!, $tipoUsuario: String!) {
    crearTipoUsuario(idEmpresa: $idEmpresa, tipoUsuario: $tipoUsuario) {
      message
    }
  }
`;
export const ACTUALIZAR_TIPO_USUARIO = gql`
  mutation ActualizarTipoUsuario(
    $idEmpresa: ID!
    $idTipoUsuario: ID!
    $tipoUsuario: String!
  ) {
    actualizarTipoUsuario(
      idEmpresa: $idEmpresa
      idTipoUsuario: $idTipoUsuario
      tipoUsuario: $tipoUsuario
    ) {
      message
    }
  }
`;
export const ELIMINAR_TIPO_USUARIO = gql`
  mutation EliminarTipoUsuario($idTipoUsuario: ID!) {
    eliminarTipoUsuario(idTipoUsuario: $idTipoUsuario) {
      message
    }
  }
`;
