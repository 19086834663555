import React from "react";
import { Stack } from "@mui/material";
import NuevoFormaContacto from "./Actions/NuevaFormaContacto";
import SearchFormasContacto from "./Actions/Search";
import FormasContactoTable from "./Table/Table";
import { OBTENER_FORMAS_CONTACTO } from "./graphql/queries";
import { useQuery } from "@apollo/client";
import { MainContext } from "../../../../Context/MainCtx";
import { useDebounce } from "use-debounce";

export default function FormasContacto() {
  const [filter, setFilter] = React.useState("");
  const { tokenDecoded } = React.useContext(MainContext);
  const [FILTER] = useDebounce(filter, 800);

  const formasQuery = useQuery(OBTENER_FORMAS_CONTACTO, {
    variables: {
      filter: FILTER,
      idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
    },
    fetchPolicy: "network-only",
  });

  return (
    <div>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ mb: 1, mt: 2, width: { xs: "100%", md: "50%" } }}
        bgcolor="background.paper"
      >
        <SearchFormasContacto filter={filter} setFilter={setFilter} />
        <NuevoFormaContacto refetch={formasQuery.refetch} />
      </Stack>
      <FormasContactoTable formasQuery={formasQuery} />
    </div>
  );
}
