import React from 'react'
import NuevoStatus from './Actions/NuevoStatus'
//import FilterTipoC from './Actions/FilterTipoC'
import { Box, Stack } from '@mui/material'
import TiposContactoTable from './Table/Table'
import { OBTENER_STATUS } from './graphql/queries'
import { useQuery } from "@apollo/client";
import { MainContext } from '../../../../Context/MainCtx'

export default function StatusContacto() {
  const {tokenDecoded} = React.useContext(MainContext);

  const statusQuery = useQuery(OBTENER_STATUS, {
    variables: {
      idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : ""
    },
    fetchPolicy: "network-only"
  });

  return (
    <Box>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="flex-end"
        alignItems="center"
        sx={{ mb: 1, position: { sm: "absolute" }, top: 60, right: 24 }}
      >
        <NuevoStatus refetch={statusQuery.refetch} />
      </Stack>
      <TiposContactoTable statusQuery={statusQuery} />
    </Box>
  )
}
