import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { Close, PhotoCamera } from "@mui/icons-material";
import { Grid, Paper } from "@mui/material";
import { ProductoContext } from "../../Context/ProductoContext";

export default function ImagenesProducto() {
  const { setImagenes, imagenes } = React.useContext(ProductoContext);

  const handleGetImage = (files) => {
    setImagenes((st) => [...st, files[0]]);
  };

  return (
    <Grid container spacing={1} sx={{ justifyContent: "center" }}>
      <Grid item xs={2}>
        <Box
          sx={{
            mt: 3,
            mb: 2,
            height: 80,
            width: 80,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="user-foto-upload"
            type="file"
            onChange={(e) => handleGetImage(e.target.files)}
          />
          <label htmlFor="user-foto-upload">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              size="large"
            >
              <PhotoCamera />
            </IconButton>
          </label>
        </Box>
      </Grid>
      {imagenes.map((res, index) => (
        <Grid key={index} item>
          <ImgPrevComponent imgData={res} index={index} />
        </Grid>
      ))}
    </Grid>
  );
}

const ImgPrevComponent = ({ imgData, index }) => {
  const {
    imagenes,
    setImagenes,
    setProductData,
    productData,
  } = React.useContext(ProductoContext);

  const image = () => {
    return imgData.keyImagen
      ? imgData.imagen
      : window.URL.createObjectURL(imgData);
  };

  const handleDeleteImg = () => {
    if (imgData.keyImagen) {
      //poner en array de deletedImgs
      const deletedImgs = productData.deletedImgs
        ? [...productData.deletedImgs]
        : [];
      deletedImgs.push(imgData);
      const imagenes = productData.imagenes.filter(
        (res) => res.keyImagen !== imgData.keyImagen
      );

      setProductData((st) => ({
        ...st,
        deletedImgs,
        imagenes,
      }));
    }
    const copyImgs = [...imagenes];
    copyImgs.splice(index, 1);
    setImagenes(copyImgs);
  };

  return (
    <Paper sx={{ position: "relative", height: 80, width: 80 }}>
      <Box
        sx={{
          mt: 3,
          mb: 2,
          height: 80,
          width: 80,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          alt="producto"
          src={image()}
          loading="lazy"
          style={{
            maxHeight: "100%",
            maxWidth: "100%",
          }}
        />
      </Box>
      <IconButton
        size="small"
        sx={{ position: "absolute", top: -26, right: -15 }}
        onClick={() => handleDeleteImg()}
      >
        <Close />
      </IconButton>
    </Paper>
  );
};
