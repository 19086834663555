import React from "react";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { Close, Delete } from "@mui/icons-material";
import { useMutation } from "@apollo/client";
import { TratosContext } from "../Context/TratosContext";
import { ELIMINAR_TRATOS } from "../graphql/mutations";
import { MainContext } from "../../../../Context/MainCtx";
import { ButtonGroup } from "@mui/material";
import { isMobile } from "../../../../Config/constantes";

export default function DeleteManyOportunities() {
  const [loading, setLoading] = React.useState(false);
  const { snackMessage } = React.useContext(MainContext);
  const {
    isSelecting,
    queryRefetch,
    setIsSelecting,
    itemsSelected,
    setItemsSelected,
    setIndeterminated,
  } = React.useContext(TratosContext);
  const [eliminarTratos] = useMutation(ELIMINAR_TRATOS);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "popover-delete-trato" : undefined;

  const handleRemove = () => {
    setItemsSelected([]);
    setIndeterminated(false);
    setIsSelecting(false);
  };

  const handleDeleteTratos = async () => {
    try {
      if (!itemsSelected.length) return;
      const tratosToDelete = itemsSelected.map(({ _id }) => _id);
      handleClose();
      setLoading(true);
      await eliminarTratos({
        variables: {
          tratos: tratosToDelete,
        },
      });
      queryRefetch();
      setLoading(false);
      setItemsSelected([]);
      setIndeterminated(false);
    } catch (err) {
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        color="warning"
        size="small"
        disableElevation
      >
        <Button
          color="warning"
          size="small"
          variant="contained"
          disabled={!itemsSelected.length}
          startIcon={
            isMobile ? null : loading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              <Delete />
            )
          }
          disableElevation
          sx={{ textTransform: "none", minWidth: 40 }}
          onClick={handleClick}
        >
          {isMobile ? (
            loading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              <Delete fontSize="small" />
            )
          ) : (
            "Eliminar"
          )}
        </Button>
        {isSelecting ? (
          <Button
            hidden={isSelecting}
            size="small"
            onClick={handleRemove}
            sx={{ minWidth: "35px!important", padding: 0 }}
          >
            <Close sx={{ fontSize: 16 }} />
          </Button>
        ) : null}
      </ButtonGroup>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              p: 1,
            },
          },
        }}
      >
        <Typography>¿Continuar con la acción?</Typography>
        <Button
          sx={{ textTransform: "none" }}
          color="error"
          size="small"
          onClick={handleDeleteTratos}
          variant="contained"
          fullWidth
          disableElevation
        >
          Si, eliminar
        </Button>
      </Popover>
    </>
  );
}
