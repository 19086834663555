import React from "react";
import { Stack } from "@mui/material";
import SearchActividad from "./Actions/SearchActividad";
import FilterActividad from "./Actions/FilterActividad";
import ActividadCtxProvider, {
  ActividadContext,
} from "./Context/ActividadContext";
import ActividadesIndex from "./Calendar";
import ColorIndicatorStatus from "./ColorIndicatorStatus";
import Button from "@mui/material/Button";
import { Event, ViewList } from "@mui/icons-material";
import AddNewItem from "./Actions/AddNewItem";
import DeleteManyActividades from "./Actions/DeleteManyActividades";
import EditarActividad from "./Actions/EditarActividad";

export default function Actividades() {
  return (
    <ActividadCtxProvider>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ mb: 1, position: { sm: "absolute" }, top: {xs: 60, md: 60}, right: 24 }}
        bgcolor="background.paper"
      >
        <ChangeModeView />
        <SearchActividad />
        <AddNewItem />
        <FilterActividad />
        <DeleteManyActividades />
      </Stack>
      <EditarActividad />
      <ColorIndicatorStatus />
      <ActividadesIndex />
    </ActividadCtxProvider>
  );
}

const ChangeModeView = () => {
  const { mode, setMode, setNewItem } = React.useContext(ActividadContext);

  const handleSetMode = () => {
    setMode(mode === "table" ? "calendar" : "table");
    setNewItem(null);
  };
  return (
    <Button
      color="inherit"
      size="small"
      variant="outlined"
      disableElevation
      onClick={handleSetMode}
      sx={{
        minWidth: 40,
        color: (theme) => theme.palette.action.active,
        borderColor: (theme) => theme.palette.action.disabled,
      }}
    >
      {mode === "table" ? <Event /> : <ViewList />}
    </Button>
  );
};
