import * as React from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Clear } from "@mui/icons-material";
import { ELIMINAR_USUARIO } from "../graphql/mutations";
import { MainContext } from "../../../../../Context/MainCtx";
import { useMutation } from "@apollo/client";
import { CircularProgress } from "@mui/material";

export default function EliminarUsuario({ data, refetch }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { snackMessage } = React.useContext(MainContext);
  const [eliminarUsuario] = useMutation(ELIMINAR_USUARIO);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteUser = async () => {
    try {
      handleClose();
      setLoading(true);
      const res = await eliminarUsuario({
        variables: {
          idUsuario: data._id,
        },
      });
      snackMessage({
        message: res.data.eliminarUsuario.message,
        variant: "success",
      });
      refetch();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      <IconButton onClick={() => handleClickOpen()}>
        {loading ? <CircularProgress size={20} color="inherit" /> : <Clear />}
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{`Eliminar ${data.nombreUsuario}`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Se va a eliminar este elemento, ¿Quiere continuar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus color="inherit">
            No, cancelar
          </Button>
          <Button
            onClick={() => handleDeleteUser()}
            color="error"
            variant="contained"
            disableElevation
          >
            Si, eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
