import { gql } from "@apollo/client";

export const CREAR_TRATO = gql`
  mutation CrearTrato($idEmpresa: ID!, $input: crearTratoInput) {
    crearTrato(idEmpresa: $idEmpresa, input: $input) {
      _id
      unidadNegocio
      idUnidadNegocio
      producto
      idProducto
      fechaTrato
      contacto
      idContacto
      nombreTrato
      moneda
      idMoneda
      valor
      formaContacto
      idFormaContacto
      embudo
      idEmbudo
      visiblePara {
        nombreCompleto
        idUsuario
      }
      propietario
      idPropietario
      interes
      status
      campaign
      idCampaign
      prioridad
      probabilidadCierre
      fechaEstimadaCierre
      fechaCierre
      usuarioCierre
      fechaRegistro
      idUsuarioCierre
      idEmpresa
      contactoPopulated {
        _id
        tipoContacto
        idTipoContacto
        nombre
        organizacion
        etiqueta
        idEtiqueta
        telefono
        correo
        propietario
        idPropietario
        formaAtraccion
        idFormaAtraccion
        campaign
        idCampaign
        recomendadoPor
        statusContacto
        idStatusContacto
        statusContactoPopulated {
          _id
          status
          factor
          color
          idEmpresa
        }
        idEmpresa
        fechaRegistro
        customFields {
          nombre
          contenido
        }
      }
      actividades {
        _id
        actividad
        tipoActividad
        idTipoActividad
        tipoActividadPopulated {
          _id
          tipoActividad
          accion
          icono
          idEmpresa
        }
        isDate
        fechaInicio
        fechaFin
        notas
        contacto
        idContacto
        trato
        idTrato
        ubicacion
        descripcionUbicacion
        usuario
        idUsuario
        prioridad
        status
        statusData {
          _id
          status
          color
          static
          idEmpresa
        }
        idStatus
        expires
        resultado
        fechaRegistro
        idResultado
        idEmpresa
      }
    }
  }
`;
export const ACTUALIZAR_TRATO = gql`
  mutation ActualizarTrato($idTrato: ID!, $input: crearTratoInput) {
    actualizarTrato(idTrato: $idTrato, input: $input) {
      message
    }
  }
`;
export const ACTUALIZAR_FASE_TRATO = gql`
  mutation ActualizarFaseTrato($idTrato: ID!, $idFase: ID!) {
    actualizarFaseTrato(idTrato: $idTrato, idFase: $idFase) {
      message
    }
  }
`;
export const ACTUALIZAR_STATUS_TRATO = gql`
  mutation ActualizarStatusTrato($idTrato: ID!, $input: updateStatus) {
    actualizarStatusTrato(idTrato: $idTrato, input: $input) {
      message
    }
  }
`;
export const ACTUALIZAR_PRIORIDAD_TRATO = gql`
  mutation ActualizarPrioridadTrato($idTrato: ID!, $prioridad: String!) {
    actualizarPrioridadTrato(idTrato: $idTrato, prioridad: $prioridad) {
      message
    }
  }
`;
export const ELIMINAR_TRATO = gql`
  mutation EliminarTrato($idTrato: ID!) {
    eliminarTrato(idTrato: $idTrato) {
      message
    }
  }
`;
export const ELIMINAR_TRATOS = gql`
  mutation EliminarTratos($tratos: [ID!]!) {
    eliminarTratos(tratos: $tratos) {
      message
    }
  }
`;
export const ARCHIVAR_TRATO = gql`
  mutation ArchivarTrato($idTrato: ID!) {
    archivarTrato(idTrato: $idTrato) {
      message
    }
  }
`;
