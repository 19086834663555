import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import {
  formatoFechaCorta,
  formatoFechaHora,
  getBicolorTable,
  getWidth,
} from "../../../../Config/reuserFunctions";
import { Videocam } from "@mui/icons-material";
import CustomLoader from "../../../../Components/CustomLoader";
import ErrorMessage from "../../../../Components/ErrorMessage";
import { ReunionContext } from "../Context/ReunionContext";
import NuevaReunion from "../Actions/NuevaReunion";
import EliminarReunion from "../Actions/EliminarReunion";
import Invitados from "./Invitados";
import { useTheme } from "@mui/material";

export default function ReunionesTable() {
  const { queryData, queryLoading, queryError } = React.useContext(
    ReunionContext
  );
  const theme = useTheme();

  if (queryLoading) return <CustomLoader />;
  if (queryError) return <ErrorMessage />;

  return (
    <Paper variant="outlined" sx={{ overflow: "hidden", mt: 1 }}>
      <TableContainer
        sx={{
          transition: "width .3s",
          height: { xs: `calc(100vh - 190px)`, sm: `calc(100vh - 120px)` },
          overflowX: "auto",
        }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>Nombre evento</TableCell>
              <TableCell>Descripción</TableCell>
              <TableCell>Trato</TableCell>
              <TableCell>De</TableCell>
              <TableCell>A</TableCell>
              <TableCell>Creado por</TableCell>
              <TableCell>Creado el</TableCell>
              <TableCell>Invitados</TableCell>
              <TableCell>Link</TableCell>
              <TableCell padding="checkbox" />
              <TableCell padding="checkbox" />
            </TableRow>
          </TableHead>
          <TableBody>
            {queryData.obtenerReuniones.map((reunion, index) => {
              return (
                <TableRow
                  role="checkbox"
                  tabIndex={-1}
                  key={reunion._id}
                  style={getBicolorTable(theme, index)}
                >
                  <TableCell
                    sx={{
                      minWidth: getWidth(reunion.nombreEvento),
                    }}
                  >
                    {reunion.nombreEvento}
                  </TableCell>
                  <TableCell
                    sx={{
                      minWidth: getWidth(reunion.descripcion),
                    }}
                  >
                    {reunion.descripcion}
                  </TableCell>
                  <TableCell sx={{ minWidth: getWidth(reunion.trato) }}>
                    {reunion.trato}
                  </TableCell>
                  <TableCell
                    sx={{
                      minWidth: getWidth(formatoFechaHora(reunion.fechaInicio)),
                    }}
                  >
                    {formatoFechaHora(reunion.fechaInicio)}
                  </TableCell>
                  <TableCell
                    sx={{
                      minWidth: getWidth(formatoFechaHora(reunion.fechaFin)),
                    }}
                  >
                    {formatoFechaHora(reunion.fechaFin)}
                  </TableCell>
                  <TableCell
                    sx={{ minWidth: getWidth(reunion.creadoPor?.nombre) }}
                  >
                    {reunion.creadoPor?.nombre}
                  </TableCell>
                  <TableCell
                    sx={{
                      minWidth: getWidth(
                        formatoFechaCorta(reunion.fechaRegistro)
                      ),
                    }}
                  >
                    {formatoFechaCorta(reunion.fechaRegistro)}
                  </TableCell>
                  <TableCell align="center" width={80}>
                    <Invitados data={reunion} />
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      color="primary"
                      size="small"
                      href={reunion.linkMeeting}
                      target="_blank"
                    >
                      <Videocam />
                    </IconButton>
                  </TableCell>
                  <TableCell padding="checkbox">
                    <NuevaReunion edit={true} data={reunion} />
                  </TableCell>
                  <TableCell padding="checkbox">
                    <EliminarReunion data={reunion} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
