import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import CustomLoader from "../../../../../Components/CustomLoader";
import ErrorMessage from "../../../../../Components/ErrorMessage";
import { StatusContext } from "../Context/StatusActividadContext";
import TableBodyStatus from "./TableBody";
import TableHeadStatus from "./TableHead";
import {
  getComparator,
  stableSort,
} from "../../../../../Config/reuserFunctions";

export default function StatusTable() {
  const {
    query,
    setItemsSelected,
    itemsSelected,
    setIndeterminated,
  } = React.useContext(StatusContext);
  const { loading, data, error } = query;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("status");

  const renderData = React.useMemo(
    () =>
      data
        ? stableSort(
            data?.obtenerStatusActividades,
            getComparator(order, orderBy)
          )
        : null,
    [order, orderBy, data]
  );

  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage />;

  const handleSelect = (checked, item) => {
    const items = [...itemsSelected];
    if (checked) items.push(item);
    if (!checked) {
      const indx = items.findIndex((elm) => elm._id === item._id);
      items.splice(indx, 1);
    }
    setItemsSelected(items);
    //revisar el checkAll
    const filterdata = data.obtenerStatusActividades.filter(
      (status) => !status.static
    );
    if (items.length > 0 && items.length !== filterdata.length) {
      setIndeterminated(true);
      return;
    }
    setIndeterminated(false);
  };

  const handleCheckAll = (checked) => {
    setIndeterminated(false);
    if (checked) {
      console.log(checked);
      const filterdata = data.obtenerStatusActividades.filter(
        (status) => !status.static
      );
      setItemsSelected(filterdata);
      return;
    }
    setItemsSelected([]);
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Paper variant="outlined" sx={{ overflow: "hidden" }}>
      <TableContainer
        sx={{
          height: { xs: `calc(100vh - 232px)`, sm: `calc(100vh - 124px)` },
        }}
      >
        <Table stickyHeader size="small" aria-label="sticky table">
          <TableHeadStatus
            handleCheckAll={handleCheckAll}
            handleSort={handleSort}
            order={order}
            orderBy={orderBy}
          />
          <TableBodyStatus data={renderData} handleSelect={handleSelect} />
        </Table>
      </TableContainer>
    </Paper>
  );
}
