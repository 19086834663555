import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CustomLoader from "../../../../../Components/CustomLoader";
import ErrorMessage from "../../../../../Components/ErrorMessage";
import EliminarStatus from "./EliminarStatus";
import NuevoStatus from "../Actions/NuevoStatus";
import { Box, useTheme } from "@mui/material";
import {
  getBicolorTable,
  getContrastColor,
} from "../../../../../Config/reuserFunctions";

export default function StatusTable({ statusQuery }) {
  const { loading, data, error, refetch } = statusQuery;
  const theme = useTheme();

  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage />;

  return (
    <Paper variant="outlined" sx={{ overflow: "hidden" }}>
      <TableContainer
        sx={{
          height: { xs: `calc(100vh - 232px)`, sm: `calc(100vh - 124px)` },
          flex: 1,
          overflowX: "auto",
        }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>Factor</TableCell>
              <TableCell>Color</TableCell>
              <TableCell padding="checkbox" />
              <TableCell padding="checkbox" />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.obtenerStatusContactos.map((data, index) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={`status-${data._id}`}
                  style={getBicolorTable(theme, index)}
                  sx={{ minWidth: "48px" }}
                >
                  <TableCell>{data.status}</TableCell>
                  <TableCell>{data.factor}</TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        bgcolor: data.color,
                        color: getContrastColor(data.color),
                        width: 130,
                        height: 24,
                        display: "grid",
                        placeItems: "center",
                        borderRadius: 1,
                      }}
                    >
                      <b>{data.color}</b>
                    </Box>
                  </TableCell>
                  <TableCell padding="checkbox">
                    <NuevoStatus edit={true} data={data} refetch={refetch} />
                  </TableCell>
                  <TableCell padding="checkbox">
                    <EliminarStatus data={data} refetch={refetch} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
