import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import LoadingAutocomplete from "./LoadingAutocomplete";
import ErrorAutocomplete from "./ErrorAutocomplete";
import { CustomEditableTextField } from "../CustomMuiComponents";
import { Add } from "@mui/icons-material";

export default function EditableAutoComplete({
  tabIndex = null,
  placeholder = "",
  renderData = [],
  value = "",
  loading = false,
  loadingQuery = false,
  errorQuery = null,
  required = false,
  disabled = false,
  addButton = null,
  actionButton = null,
  onChange = null,
  isOptionEqualToValue = null,
  getOptionLabel = null,
  loadingIcon = null,
}) {
  const [open, setOpen] = React.useState(false);
  const [focus, setFocus] = React.useState(false);
  const ref = React.useRef(null);

  /* const handleMouseDown = (event) => {
    event.preventDefault();
  }; */

  React.useEffect(() => {
    if (!loading && ref?.current) {
      setFocus(false);
      ref.current.blur();
    }
  }, [loading]);

  if (loadingQuery) return <LoadingAutocomplete editable value={value} />;
  if (errorQuery) return <ErrorAutocomplete editable value={value} />;

  return (
    <Autocomplete
      autoHighlight
      openOnFocus
      fullWidth
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      options={renderData}
      disabled={disabled}
      value={value || null}
      open={open}
      onFocus={() => setFocus(true)}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      forcePopupIcon={false}
      renderInput={(params) => (
        <CustomEditableTextField
          {...params}
          size="small"
          fullWidth
          onBlur={() => {
            setFocus(false);
            setOpen(false);
          }}
          inputRef={ref}
          required={required}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            startAdornment: !value ? (
              !focus ? (
                <InputAdornment
                  position="end"
                  sx={{ color: (theme) => theme.palette.text.disabled }}
                >
                  <Add />
                </InputAdornment>
              ) : null
            ) : null,
            endAdornment:
              loadingIcon && loading && focus ? (
                <InputAdornment position="end">{loadingIcon}</InputAdornment>
              ) : (
                <React.Fragment>
                  {focus ? (
                    <InputAdornment
                      position="end"
                      //onMouseDown={handleMouseDown}
                    >
                      {actionButton}
                    </InputAdornment>
                  ) : null}
                  {focus && addButton ? (
                    <InputAdornment
                      position="end"
                     //onMouseDown={handleMouseDown}
                    >
                      {addButton}
                    </InputAdornment>
                  ) : null}
                  {/* {focus ? params.InputProps.endAdornment : null} */}
                </React.Fragment>
              ),
            sx: {
              padding: "2px 4px 2px 2px!important",
            },
          }}
          inputProps={{ ...params.inputProps, tabIndex }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option._id}>
          {props.key}
        </li>
      )}
      onChange={onChange}
    />
  );
}
