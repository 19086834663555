import { gql } from "@apollo/client";

export const CREAR_FORMA = gql`
  mutation CrearForma($idEmpresa: ID!, $formaAtraccion: String!) {
    crearForma(idEmpresa: $idEmpresa, formaAtraccion: $formaAtraccion) {
      message
    }
  }
`;
export const ACTUALIZAR_FORMA = gql`
  mutation ActualizarForma(
    $idEmpresa: ID!
    $idForma: ID!
    $formaAtraccion: String!
  ) {
    actualizarForma(
      idEmpresa: $idEmpresa
      idForma: $idForma
      formaAtraccion: $formaAtraccion
    ) {
      message
    }
  }
`;
export const ELIMINAR_FORMA = gql`
  mutation EliminarForma($idForma: ID!) {
    eliminarForma(idForma: $idForma) {
      message
    }
  }
`;
