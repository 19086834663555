import React from "react";
import { Grid, Toolbar } from "@mui/material";
import Segmentador from "./Segmentador/Segmentador";
import GanadosPerdidos from "./GanadosPerdidos/GanadosPerdidos";
import TratosPorFase from "./TratosPorFase/TratosPorFase";
import Prospectos from "./Prospectos/Prospectos";
import HistorialTratos from "./HistorialTratos";
import DashboardProvider, { DashboardContext } from "./Context/DashboardContext";
import CustomLoader from "../../../Components/CustomLoader";
import ErrorMessage from "../../../Components/ErrorMessage";

export default function Dashboard() {
  return (
    <DashboardProvider>
      <Segmentador />
      <RenderGraficas />
    </DashboardProvider>
  );
}

const RenderGraficas = () => {
  const { dashQuery } = React.useContext(DashboardContext);
  const {loading, error} = dashQuery;

  if(loading) return <CustomLoader height="60vh" />
  if(error) return <ErrorMessage />

  return (
    <Grid container spacing={2} sx={{ mt: 1}}>
      <Grid item xs={12} md={3}>
        <GanadosPerdidos />
      </Grid>
      <Grid item xs={12} md={4.5}>
        <TratosPorFase />
      </Grid>
      <Grid item xs={12} md={4.5}>
        <Prospectos />
      </Grid>
      <Grid item xs={12} sx={{mt: 1}}>
        <HistorialTratos />
        <Toolbar sx={{ my: 1}} />
      </Grid>
    </Grid>
  );
};
