import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CustomAvatar from "../../../../../../Components/Avatar/CustomAvatar";
import { Delete, PhotoCamera } from "@mui/icons-material";

export default function FotoInput({
  setPreview,
  userData,
  setUserData,
  edit,
  preview,
}) {
  const handleGetImage = (files) => {
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      let image = reader.result;
      setPreview(image);
    };
    if (edit) {
      setUserData((st) => ({ ...st, newFoto: files[0] }));
      return;
    }
    setUserData((st) => ({ ...st, foto: files[0] }));
  };

  const handleDeletePhoto = () => {
    if (userData.keyFoto) {
      setUserData((st) => ({
        ...st,
        foto: "",
        keyFoto: "",
        deletedFotoKey: st.keyFoto,
      }));
    } else {
      setUserData((st) => ({ ...st, foto: "", newFoto: "" }));
      setPreview("");
    }
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", ml: 5 }}>
      <CustomAvatar
        name={userData.nombreCompleto}
        img={preview ? preview : userData.foto}
        fontSize={56}
        size={128}
      />
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="user-foto-upload"
        type="file"
        onChange={(e) => handleGetImage(e.target.files)}
      />
      <label htmlFor="user-foto-upload">
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
        >
          <PhotoCamera />
        </IconButton>
      </label>
      {userData.foto || userData.newFoto ? (
        <Box>
          <IconButton onClick={handleDeletePhoto}>
            <Delete />
          </IconButton>
        </Box>
      ) : null}
    </Box>
  );
}
