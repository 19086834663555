import React from "react";
import { Stack } from "@mui/material";
import NuevaCampaign from "./Actions/NuevaCampaign";
import SearchCampaign from "./Actions/Search";
import RedSocialTable from "./Table/Table";
import { OBTENER_CAMPAIGN } from "./graphql/queries";
import { useQuery } from "@apollo/client";
import { MainContext } from "../../../../Context/MainCtx";
import { useDebounce } from "use-debounce";

export default function Campaigns() {
  const [filter, setFilter] = React.useState("");
  const { tokenDecoded } = React.useContext(MainContext);
  const [FILTER] = useDebounce(filter, 800);

  const campaignQuery = useQuery(OBTENER_CAMPAIGN, {
    variables: {
      idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
      filter: FILTER,
    },
    fetchPolicy: "network-only",
  });

  return (
    <div>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ mb: 1, mt: 2, width: { xs: "100%", md: "50%" } }}
        bgcolor="background.paper"
      >
        <SearchCampaign setFilter={setFilter} filter={filter} />
        <NuevaCampaign refetch={campaignQuery.refetch} />
      </Stack>
      <RedSocialTable campaignQuery={campaignQuery} />
    </div>
  );
}
