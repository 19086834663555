import React from "react";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ListItemButton from "@mui/material/ListItemButton";
import { MainContext } from "../../../../Context/MainCtx";
import { useMutation } from "@apollo/client";
import { Box, CircularProgress, List, ListItemText } from "@mui/material";
import { ACTUALIZAR_STATUS_ACTIVIDAD } from "../graphql/mutations";
import { getContrastColor } from "../../../../Config/reuserFunctions";
import { ACCIONES_STATUS_ACTIVIDADES } from "../../../../Config/constantes";

export default function ChipStatusAct({
  statusQuery = [],
  actividad = null,
  setActividad = null,
  refetch,
  tabIndex = null,
}) {
  const { snackMessage } = React.useContext(MainContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [data, setData] = React.useState({
    status: null,
    resultado: actividad?.resultado || "",
  });
  const open = Boolean(anchorEl);
  const [updateStatusActividad] = useMutation(ACTUALIZAR_STATUS_ACTIVIDAD);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setData({ status: null, resultado: actividad?.resultado || "" });
  };
  const handleOpenDialog = (status) => {
    setOpenDialog(true);
    setData({ status, resultado: "" });
  };

  const handleClickNew = (status) => {
    if (setActividad) {
      setActividad((st) => ({
        ...st,
        statusData: [
          {
            ...st.statusData,
            _id: status._id,
            status: status.status,
            color: status.color,
          },
        ],
        idStatus: status._id,
        status: status.status,
        color: status.color,
      }));
      handleClose();
    }
  };

  const handleClickMenuItem = (status) => {
    if (status.accion === ACCIONES_STATUS_ACTIVIDADES[0]) {
      handleOpenDialog(status);
      return;
    }
    if (setActividad) {
      setActividad((st) => ({
        ...st,
        statusData: [
          {
            ...st.statusData,
            _id: status._id,
            status: status.status,
            color: status.color,
          },
        ],
        idStatus: status._id,
        status: status.status,
        color: status.color,
      }));
    }
    updateStatus(status);
  };

  const updateStatus = async ({ status, _id }, resultado = "") => {
    try {
      handleClose();
      setLoading(true);
      await updateStatusActividad({
        variables: {
          idActividad: actividad._id,
          idStatus: _id,
          status,
          resultado,
        },
      });
      refetch();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
      console.log(err.networkError?.result);
    }
  };

  if (actividad.new) {
    return (
      <>
        <Chip
          tabIndex={tabIndex}
          id="custom-chip-act"
          aria-controls={open ? "status-menu-act" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          component={"span"}
          label={actividad?.status || "Asignar status"}
          size="small"
          icon={
            loading ? (
              <CircularProgress sx={{ color: "white!important" }} size={16} />
            ) : null
          }
          sx={{
            m: 0,
            fontWeight: "600",
            bgcolor: actividad?.color,
            ":hover": {
              bgcolor: actividad?.color,
            },
            color: getContrastColor(actividad?.color),
          }}
          onClick={handleClick}
        />
        <Menu
          id="status-menu-act"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "custom-chip-act",
          }}
        >
          <List disablePadding dense>
            {statusQuery.map((status, index) => (
              <ListItemButton
                key={index}
                onClick={() => handleClickNew(status)}
                selected={status._id === actividad?._id}
              >
                <ListItemText>{status.status}</ListItemText>
              </ListItemButton>
            ))}
          </List>
        </Menu>
      </>
    );
  }

  if (!statusQuery || !statusQuery.length || !actividad?.statusData?.length) {
    return (
      <Chip
        label={actividad.status}
        color="default"
        size="small"
        sx={{
          fontWeight: "bold",
        }}
      />
    );
  }

  return (
    <>
      <Chip
        tabIndex={tabIndex}
        id="custom-chip-act"
        aria-controls={open ? "status-menu-act" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        component={"span"}
        label={actividad?.statusData[0]?.status || actividad?.status}
        size="small"
        icon={
          loading ? (
            <CircularProgress sx={{ color: "white!important" }} size={16} />
          ) : null
        }
        sx={{
          m: 0,
          fontWeight: "600",
          bgcolor: actividad?.statusData[0]?.color,
          ":hover": {
            bgcolor: actividad?.statusData[0]?.color,
          },
          color: getContrastColor(actividad?.statusData[0]?.color),
        }}
        onClick={handleClick}
      />
      <Menu
        id="status-menu-act"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "custom-chip-act",
        }}
      >
        <List disablePadding dense>
          {statusQuery.map((status, index) => (
            <ListItemButton
              key={index}
              onClick={() => handleClickMenuItem(status)}
              selected={status._id === actividad?.statusData[0]?._id}
            >
              <ListItemText>{status.status}</ListItemText>
            </ListItemButton>
          ))}
        </List>
      </Menu>
      <ResultadoDialog
        handleCloseDialog={handleCloseDialog}
        openDialog={openDialog}
        data={data}
        setData={setData}
        setActividad={setActividad}
        updateStatus={updateStatus}
      />
    </>
  );
}

const ResultadoDialog = ({
  handleCloseDialog,
  openDialog,
  data,
  setData,
  setActividad,
  updateStatus,
}) => {
  const handleChange = (resultado) => {
    setData((st) => ({ ...st, resultado }));
  };

  const handleSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();
    handleCloseDialog();
    if (setActividad) {
      setActividad((st) => ({
        ...st,
        statusData: [
          {
            ...st.statusData,
            _id: data.status._id,
            status: data.status.status,
            color: data.status.color,
          },
        ],
        idStatus: data.status._id,
        status: data.status.status,
        color: data.status.color,
        resultado: data.resultado,
      }));
    }
    updateStatus(data.status, data.resultado);
  };

  return (
    <Dialog
      onClose={handleCloseDialog}
      open={openDialog}
      fullWidth
      maxWidth="xs"
    >
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Box sx={{ display: "block" }}>
            <TextField
              fullWidth
              label="Resultado actividad"
              variant="outlined"
              size="small"
              margin="normal"
              value={data.resultado}
              onChange={(e) => handleChange(e.target.value)}
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="submit"
                size="small"
                disableElevation
                variant="contained"
              >
                Guardar
              </Button>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};
