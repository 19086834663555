import React from "react";
import VentasTable from "./VentasTable";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import SearchVentas from "./Actions/SearchVentas";
import FilterVenta from "./Actions/FilterVenta";
import { MainContext } from "../../../Context/MainCtx";
import { useDebounce } from "use-debounce";
import { useQuery } from "@apollo/client";
import { OBTENER_VENTAS } from "../Tratos/graphql/queries";

const initial_filter = {
  searchFilter: "",
  fechaStart: "",
  fechaEnd: "",
  formaContacto: "",
  campaign: "",
  unidadNegocio: "",
  moneda: "",
  embudo: "",
  prioridad: "",
  status: "",
  propietario: "",
  usuarioCierre: "",
};

export default function Ventas() {
  const [filter, setFilter] = React.useState(initial_filter);
  const { tokenDecoded } = React.useContext(MainContext);
  const [page, setPage] = React.useState(0);
  const [FILTER] = useDebounce(filter, 500);

  const ventasQuery = useQuery(OBTENER_VENTAS, {
    variables: {
      filter: FILTER,
      idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
      page: page,
      limit: 20,
    },
    fetchPolicy: "network-only",
  });

  React.useEffect(() => {
    setPage(0);
  }, [FILTER]);

  return (
    <Box>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ mb: 1, mt: 2, justifyContent: {xs: "center", sm: "flex-start"} }}
        bgcolor="background.paper"
      >
        <SearchVentas filter={filter} setFilter={setFilter} />
        <FilterVenta setFilter={setFilter} initialFilter={initial_filter} />
      </Stack>
      <VentasTable ventasQuery={ventasQuery} setPage={setPage} />
    </Box>
  );
}
