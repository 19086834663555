import React from "react";
import CustomLoader from "../../../../Components/CustomLoader";
import ErrorMessage from "../../../../Components/ErrorMessage";
import TratosBoardMode from "./BoardMode";
import TratosTableMode from "./TableMode";
import { TratosContext } from "../Context/TratosContext";

export default function TratosComponent() {
  const { queryLoading, queryError, mode } = React.useContext(TratosContext);

  if (queryLoading) return <CustomLoader />;
  if (queryError) return <ErrorMessage />;

  return mode === "board" ? <TratosBoardMode /> : <TratosTableMode />;
}
