import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CustomLoader from "../../../../../Components/CustomLoader";
import ErrorMessage from "../../../../../Components/ErrorMessage";
import EliminarProducto from "./EliminarProducto";
import NuevoProducto from "../Actions/NuevoProducto";
import { Box, Pagination, useTheme } from "@mui/material";
import {
  formatoFechaCorta,
  formatoMexico,
  getBicolorTable,
} from "../../../../../Config/reuserFunctions";
import { ProductoContext } from "../Context/ProductoContext";

export default function ProductosTable() {
  const { queryLoading, queryData, queryError, setPage } = React.useContext(
    ProductoContext
  );
  const theme = useTheme();

  if (queryLoading) return <CustomLoader />;
  if (queryError) return <ErrorMessage />;

  const { page, totalPages } = queryData.obtenerProductos;

  const handleChangePage = (event, newPage) => setPage(newPage - 1);

  return (
    <Paper variant="outlined" sx={{ overflow: "hidden" }}>
      <TableContainer
        sx={{
          transition: "width .3s",
          height: { xs: `calc(100vh - 220px)`, sm: `calc(100vh - 156px)` },
          overflowX: "scroll",
        }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{minWidth: 150}}>Sku</TableCell>
              <TableCell sx={{minWidth: 230}}>Nombre</TableCell>
              <TableCell sx={{minWidth: 100}}>Unidad</TableCell>
              <TableCell sx={{minWidth: 200}}>Categoría</TableCell>
              <TableCell sx={{minWidth: 100}}>Costo</TableCell>
              <TableCell sx={{minWidth: 150}}>Promoción</TableCell>
              <TableCell sx={{minWidth: 150}}>Precio final</TableCell>
              <TableCell sx={{minWidth: 200}}>Fecha registro</TableCell>
              <TableCell padding="checkbox" />
              <TableCell padding="checkbox" />
            </TableRow>
          </TableHead>
          <TableBody>
            {queryData.obtenerProductos.docs.map((data, index) => {
              return (
                <TableRow
                  role="checkbox"
                  tabIndex={-1}
                  key={data._id}
                  style={getBicolorTable(theme, index)}
                >
                  <TableCell>{data.skuCodigo}</TableCell>
                  <TableCell>{data.nombre}</TableCell>
                  <TableCell>{data.unidadMedida}</TableCell>
                  <TableCell>{data.categoria}</TableCell>
                  <TableCell>${formatoMexico(data.costo)}</TableCell>
                  <TableCell>{`${data.promocion}% - $${data.valorDescuento}`}</TableCell>
                  <TableCell>${formatoMexico(data.precioFinal)}</TableCell>
                  <TableCell>{formatoFechaCorta(data.fechaRegistro)}</TableCell>
                  <TableCell padding="checkbox">
                    <NuevoProducto edit={true} data={data} />
                  </TableCell>
                  <TableCell padding="checkbox">
                    <EliminarProducto data={data} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          color="primary"
          page={page}
          count={totalPages}
          onChange={handleChangePage}
        />
      </Box>
    </Paper>
  );
}
