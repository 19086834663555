import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import {
  ArrowBack,
  DriveFileRenameOutline,
  PersonSearch,
} from "@mui/icons-material";
import ContactoEdit from "./Contacto/ContactoEdit";
import ActividadDetail from "./ActividadDetail";
import EliminarActividadDetail from "./EliminarActividadDetail";
import { ActividadContext } from "../../Context/ActividadContext";
import { DialogContent } from "@mui/material";

export default function EditarActividad({
  edit = false,
  data,
  refetchTratos = null,
  sxButton = { p: 0 },
}) {
  const { initial_actividad, actividad, setActividad } = React.useContext(
    ActividadContext
  );
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
    setActividad(data);
  };

  const handleClose = (event) => {
    setOpen(false);
    setActividad(initial_actividad);
  };

  React.useEffect(() => {
    if (actividad && actividad.fromCalendar && actividad.edit) {
      setOpen(true);
    }
  }, [actividad]);

  return (
    <div>
      {actividad?.edit || edit ? (
        <IconButton size="small" onClick={handleOpen} sx={sxButton}>
          <DriveFileRenameOutline />
        </IconButton>
      ) : null}
      <Drawer
        anchor={"right"}
        open={open}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
        ModalProps={{
          componentsProps: {
            backdrop: {
              sx: { background: "none" },
            },
          },
        }}
        PaperProps={{
          elevation: 1,
          sx: { width: { xs: "100vw", md: "75vw" }, overflowY: "auto" },
        }}
      >
        <Toolbar variant="dense" />
        <DialogTitle component="div">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <IconButton onClick={handleClose}>
              <ArrowBack />
            </IconButton>
            <EliminarActividadDetail
              data={actividad}
              handleCloseDetail={handleClose}
              refetchTratos={refetchTratos}
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          {actividad ? (
            <Grid container sx={{ my: 1 }}>
              <Grid
                item
                md={4}
                xs={12}
                sx={{
                  borderRight: { xs: 0, md: 1 },
                  borderColor: { xs: null, md: "divider" },
                  p: 1,
                }}
              >
                {!actividad?.contactoPopulated?.length ? (
                  <Box
                    sx={{
                      mt: 3,
                      display: "grid",
                      direction: "column",
                      placeItems: "center",
                      position: { xs: "initial", md: "sticky" },
                      top: 0,
                    }}
                  >
                    <PersonSearch color="action" sx={{ fontSize: 50 }} />
                    <Typography color="GrayText">
                      No se encontro este contacto
                    </Typography>
                  </Box>
                ) : (
                  <ContactoEdit refetchTratos={refetchTratos} />
                )}
              </Grid>
              <Grid item md={8} xs={12}>
                <ActividadDetail refetchTratos={refetchTratos} />
              </Grid>
            </Grid>
          ) : null}
        </DialogContent>
      </Drawer>
    </div>
  );
}
