export const light = {
  palette: {
    mode: "light",
    primary: {
      main: "#6573ef",
    },
    secondary: {
      main: "#2cc2bc",
    },
    comodyPurple: "#6573ef",
    comodyGreen: "#2cc2bc",
    comodyBlue: "#48a2f0",
    customPaper: "#f6f8fb",
    borderCalendar: "#0000001f",
  },
};
export const dark = {
  palette: {
    mode: "dark",
    primary: {
      main: "#707ff0",
    },
    secondary: {
      main: "#48a2f0",
    },
    background: {
      default: "#1a203b",
      paper: "#242b47",
    },
    customPaper: "#1a203b",
    comodyPurple: "#6573ef",
    comodyGreen: "#2cc2bc",
    comodyBlue: "#48a2f0",
    borderCalendar: "#ffffff1f",
  },
};
