import React from "react";
import FilterStatusActividad from "./Actions/FilterStatusActividad";
import TableStatusActividad from "./Table";
import { Button, CircularProgress, Stack } from "@mui/material";
import { Add, Done } from "@mui/icons-material";
import EliminarStatus from "./Actions/EliminarStatus";
import StatusActividadCtxProvider, {
  StatusContext,
} from "./Context/StatusActividadContext";
import { isMobile } from "../../../../Config/constantes";

export default function StatusActividad() {
  return (
    <div>
      <StatusActividadCtxProvider>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="center"
          sx={{ mb: 1, position: { sm: "absolute" }, top: 60, right: 24 }}
          bgcolor="background.paper"
        >
          <FilterStatusActividad />
          <AddNewItemToTable />
          <EliminarStatus />
        </Stack>
        <TableStatusActividad />
      </StatusActividadCtxProvider>
    </div>
  );
}

const AddNewItemToTable = () => {
  const { initial_status, setNewItem, newItem, loadingSave } = React.useContext(
    StatusContext
  );

  React.useEffect(() => {
    window.addEventListener("keydown", KeyDownFunction);
    function KeyDownFunction(e) {
      const tecla_escape = e.keyCode;
      if (tecla_escape === 27) {
        if (!newItem) {
          setNewItem(initial_status);
        } else {
          setNewItem(null);
        }
      }
    }
    return () => window.removeEventListener("keydown", KeyDownFunction);
  }, [newItem, initial_status, setNewItem]);

  return !newItem ? (
    <Button
      color="primary"
      size="small"
      variant="contained"
      startIcon={ isMobile ? null : <Add />}
      disableElevation
      onClick={() => setNewItem(initial_status)}
      sx={{ textTransform: "none", minWidth: 40 }}
    >
      {isMobile ? <Add fontSize="small" /> : "Nuevo"}
    </Button>
  ) : (
    <Button
      color="primary"
      size="small"
      variant="contained"
      startIcon={
        loadingSave ? <CircularProgress color="inherit" size={20} /> : <Done />
      }
      disableElevation
      form="form-statusActividad-table"
      type="submit"
      sx={{ textTransform: "none" }}
    >
      Guardar
    </Button>
  );
};
