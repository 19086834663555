import * as React from "react";
import { InputAdornment } from "@mui/material";
import moment from "moment-timezone";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function EditableDateInput({
  tabIndex= null,
  name = "",
  value = "",
  sx = null,
  onChange = null,
  loading = false,
  disabled = false,
  loadingIcon = null,
}) {
  const [focus, setFocus] = React.useState(false);
  const inputRef = React.useRef(null);

  const handleMouseDown = (event) => {
    // Evita que pierda el foco al hacer clic en el botón
    event.preventDefault();
  };

  React.useEffect(() => {
    if (!loading && inputRef) {
      setFocus(false);
      inputRef.current.blur();
    }
  }, [loading]);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DemoContainer
          components={["DatePicker"]}
          sx={{ padding: 0, overflow: "none" }}
        >
          <DemoItem>
            <DatePicker
              inputRef={inputRef}
              value={moment(value)}
              onChange={(val) => onChange(name, val)}
              disabled={disabled}
              slotProps={{
                textField: {
                  tabIndex,
                  size: "small",
                  onFocus: () => setFocus(true),
                  onBlur: () => setFocus(false),
                  sx: {
                    ...sx,
                    "& .MuiOutlinedInput-root": {
                      cursor: "pointer",
                      "& fieldset": {
                        border: "none",
                      },
                      "& .MuiInputBase-input": {
                        cursor: "pointer",
                        padding: "4px",
                        fontSize: 18,
                      },
                      "& .MuiInputBase-input:focus": {
                        cursor: "text",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1px solid #B2BAC2",
                      },
                    },
                  },
                  InputProps: {
                    startAdornment: loadingIcon && loading ? (
                      focus ? (
                        <InputAdornment position="start">
                          {loadingIcon}
                        </InputAdornment>
                      ) : null
                    ) : null,
                  },
                },
                inputAdornment: {
                  sx: { display: focus ? "flex" : "none", m: 0 },
                },
                openPickerButton: {
                  onMouseDown: (e) => handleMouseDown(e),
                  sx: { p: 0.5 },
                },
              }}
            />
          </DemoItem>
        </DemoContainer>
      </LocalizationProvider>
    </>
  );
}
