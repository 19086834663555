import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { ArrowBack, PermContactCalendar } from "@mui/icons-material";
import LoadingAutocomplete from "../../../../../../Components/InputsForms/LoadingAutocomplete";
import ErrorAutocomplete from "../../../../../../Components/InputsForms/ErrorAutocomplete";
import { ACTUALIZAR_TRATO } from "../../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { TratosContext } from "../../../Context/TratosContext";
import { MainContext } from "../../../../../../Context/MainCtx";
import NuevoContacto from "../../../../Contactos/Contactos/Actions/NuevoContacto";
import CustomAvatar from "../../../../../../Components/Avatar/CustomAvatar";
import ContactosCtxProvider from "../../../../Contactos/Contactos/Context/ContactoContext";

export default function SelectContactList() {
  const [open, setOpen] = React.useState(false);
  const {
    trato,
    setTrato,
    queryCats,
    loadingQuery,
    errorQuery,
    queryRefetch,
  } = React.useContext(TratosContext);
  const { snackMessage, tokenDecoded } = React.useContext(MainContext);
  const [loading, setLoading] = React.useState(false);
  const [actualizarTrato] = useMutation(ACTUALIZAR_TRATO);

  if (loadingQuery) return <LoadingAutocomplete />;
  if (errorQuery) return <ErrorAutocomplete />;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const contactoAutocompleteChange = (values) => {
    const { value, id } = values;
    if (!value || !id) return;
    let tratoUpdated = {
      ...trato,
      contacto: value,
      idContacto: id,
      contactoPopulated: [{ ...trato.contactoPopulated[0], nombre: value }],
    };
    setTrato(tratoUpdated);
    handleSaveTrato(tratoUpdated);
  };

  const handleSaveTrato = async (tratoUpdated = trato) => {
    try {
      if (loading) return;
      setLoading(true);
      let {
        _id,
        fechaRegistro,
        idEmpresa,
        actividades,
        contactoPopulated,
        ...input
      } = tratoUpdated;

      input.valor = parseFloat(input.valor);
      input.probabilidadCierre = parseFloat(input.probabilidadCierre);
      handleClose();
      await actualizarTrato({
        variables: {
          idTrato: trato._id,
          idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
          input,
        },
      });
      queryRefetch();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      <IconButton size="small" onClick={handleClickOpen}>
        {loading ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          <PermContactCalendar />
        )}
      </IconButton>
      <Dialog
        onClose={handleClose}
        open={open}
        fullWidth
        maxWidth="sm"
        sx={{
          zIndex: 1200,
        }}
      >
        <DialogTitle component="div">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton sx={{ mr: 1 }} onClick={handleClose}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h6">Lista de contactos</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ height: "50vh" }}>
            <Autocomplete
              autoHighlight
              fullWidth
              isOptionEqualToValue={({ nombre }, value) => nombre === value}
              getOptionLabel={(option) => option.nombre || option || null}
              options={queryCats.data?.contactos}
              value={
                trato?.contactoPopulated?.length > 0
                  ? trato.contactoPopulated[0].nombre
                  : trato.contacto
              }
              open={open}
              forcePopupIcon={false}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        <InputAdornment position="end">
                          <ContactosCtxProvider>
                            <NuevoContacto
                              external={true}
                              refetchExternal={queryCats.refetch}
                              openFromDialog
                            />
                          </ContactosCtxProvider>
                        </InputAdornment>
                      </React.Fragment>
                    ),
                  }}
                />
              )}
              onChange={(_, option) =>
                contactoAutocompleteChange({
                  value: option?.nombre || "",
                  id: option?._id || "",
                })
              }
              renderOption={(props, option) => {
                return (
                  <ListItem {...props} key={option._id}>
                    <ListItemAvatar>
                      <CustomAvatar name={option.nombre} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={option?.nombre}
                      secondary={option?.telefono}
                    />
                  </ListItem>
                );
              }}
              slotProps={{
                paper: {
                  variant: "outlined",
                },
                popper: {
                  sx: {
                    zIndex: 1200,
                  },
                },
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
