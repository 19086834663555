import * as React from "react";
import LibraryAddCheckOutlined from "@mui/icons-material/LibraryAddCheckOutlined";
import { IconButton } from "@mui/material";
import { TratosContext } from "../../../Context/TratosContext";

export default function SelectingOption({ trato, handleSelect, handleClose }) {
  const {
    isSelecting,
    setIsSelecting,
    setItemsSelected,
    setIndeterminated,
  } = React.useContext(TratosContext);

  const onSelect = (e) => {
    handleSelect(true, trato);
    setIsSelecting(true);
    if (handleClose) handleClose(e);
  };
  const onUnselect = (e) => {
    setItemsSelected([]);
    setIndeterminated(false);
    setIsSelecting(false);
    if (handleClose) handleClose(e);
  };

  return (
    <IconButton onClick={isSelecting ? onUnselect : onSelect}>
      <LibraryAddCheckOutlined fontSize="small" />
    </IconButton>
  );
}
