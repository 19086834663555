import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CustomLoader from "../../../../../Components/CustomLoader";
import ErrorMessage from "../../../../../Components/ErrorMessage";
import NuevoTipoActividad from "../Actions/NuevoTipoActividad";
import EliminarTipoActividad from "./EliminarTipoActividad";
import CustomIconComponent from "../../../../../Components/CustomIconComponent";
import { useTheme } from "@mui/material";
import { getBicolorTable } from "../../../../../Config/reuserFunctions";

export default function AreasTable({ tipoQuery }) {
  const { loading, data, error, refetch } = tipoQuery;
  const theme = useTheme();

  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage />;

  return (
    <Paper variant="outlined" sx={{ overflow: "hidden" }}>
      <TableContainer
        sx={{
          transition: "width .3s",
          height: { xs: `calc(100vh - 232px)`, sm: `calc(100vh - 140px)` },
          overflowX: "auto",
        }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>Icono</TableCell>
              <TableCell>Tipo actividad</TableCell>
              <TableCell>Acción</TableCell>
              <TableCell padding="checkbox" />
              <TableCell padding="checkbox" />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.obtenerTipoActividades.map((data, index) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={`tipo-${data._id}`}
                  style={getBicolorTable(theme, index)}
                  sx={{ height: "48px" }}
                >
                  <TableCell padding="checkbox" align="center">
                    <CustomIconComponent
                      iconName={data.icono}
                      color="action"
                      fontSize={26}
                    />
                  </TableCell>
                  <TableCell>{data.tipoActividad}</TableCell>
                  <TableCell>{data.accion}</TableCell>
                  <TableCell padding="checkbox">
                    <NuevoTipoActividad
                      edit={true}
                      data={data}
                      refetch={refetch}
                    />
                  </TableCell>
                  <TableCell padding="checkbox">
                    <EliminarTipoActividad data={data} refetch={refetch} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
