import { gql } from "@apollo/client";

export const CREAR_PRODUCTO = gql`
  mutation CrearProducto($idEmpresa: ID!, $input: productoInput) {
    crearProducto(idEmpresa: $idEmpresa, input: $input) {
      message
    }
  }
`;
export const ACTUALIZAR_PRODUCTO = gql`
  mutation ActualizarProducto($idProducto: ID!, $input: productoUpdateInput) {
    actualizarProducto(idProducto: $idProducto, input: $input) {
      message
    }
  }
`;
export const ELIMINAR_PRODUCTO = gql`
  mutation EliminarProducto($idProducto: ID!) {
    eliminarProducto(idProducto: $idProducto) {
      message
    }
  }
`;
