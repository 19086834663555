import React from "react";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import ListItemButton from "@mui/material/ListItemButton";
import { MainContext } from "../../../../../Context/MainCtx";
import { ACTUALIZAR_PRIORIDAD_TRATO } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { getColorTratoPrioridades } from "../../../../../Config/reuserFunctions";
import {
  NotificationImportant,
  NotificationsActive,
  NotificationsNone,
} from "@mui/icons-material";
import {
  CircularProgress,
  List,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

const PRIORIDADES = ["BAJA", "MEDIA", "ALTA"];

export default function ChipPrioridad({ trato,  setTrato = null, refetch, onchange = null, tabIndex=null }) {
  const { snackMessage } = React.useContext(MainContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(null);
  const open = Boolean(anchorEl);
  const [actualizarPrioridadTrato] = useMutation(ACTUALIZAR_PRIORIDAD_TRATO);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const updatePrioridad = async (prioridad) => {
    try {
      handleClose();
      setLoading(true);
      if(setTrato)setTrato(st => ({...st, prioridad}))
      await actualizarPrioridadTrato({
        variables: {
          idTrato: trato._id,
          prioridad,
        },
      });
      refetch();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
      console.log(err.networkError?.result);
    }
  };

  const getIconPrioridad = ({ prioridad, sx = null }) => {
    switch (prioridad) {
      case "BAJA":
        return (
          <NotificationsNone
            color={prioridad === trato.prioridad ? "primary" : "inherit"}
            sx={sx}
          />
        );
      case "MEDIA":
        return (
          <NotificationsActive
            color={prioridad === trato.prioridad ? "primary" : "inherit"}
            sx={sx}
          />
        );
      case "ALTA":
        return (
          <NotificationImportant
            color={prioridad === trato.prioridad ? "primary" : "inherit"}
            sx={sx}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Chip
      tabIndex={tabIndex}
        id="custom-chip-prioridad"
        aria-controls={open ? "prioridad-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        component={"span"}
        label={trato.prioridad}
        size="small"
        icon={
          loading ? (
            <CircularProgress sx={{ color: "white!important" }} size={16} />
          ) : (
            getIconPrioridad({
              prioridad: trato.prioridad,
              sx: { color: "white" },
            })
          )
        }
        sx={{
          mr: 0.5,
          //height: 20,
          fontWeight: "600",
          bgcolor: (theme) => getColorTratoPrioridades(trato.prioridad, theme),
          color: 'white',
          ":hover": {
            bgcolor: (theme) =>
              getColorTratoPrioridades(trato.prioridad, theme),
          },
        }}
        onClick={handleClick}
      />
      <Menu
        id="prioridad-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "custom-chip-prioridad",
        }}
      >
        <List disablePadding dense>
          {PRIORIDADES.map((prioridad, index) => (
            <ListItemButton
              key={index}
              onClick={() => onchange ? onchange('prioridad', prioridad) : updatePrioridad(prioridad)}
              selected={prioridad === trato.prioridad}
            >
              <ListItemIcon>{getIconPrioridad({ prioridad })}</ListItemIcon>
              <ListItemText>{prioridad}</ListItemText>
            </ListItemButton>
          ))}
        </List>
      </Menu>
    </>
  );
}
