import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import { Search } from "@mui/icons-material";
import CountUp from "react-countup";
import { DashboardContext } from "../Context/DashboardContext";
import { Stack, useTheme } from "@mui/material";
import * as Muicon from "@mui/icons-material";
import FilterDashboard from "./FilterDashboard";
import { CustomSearchTextField } from "../../../../Components/CustomMuiComponents";

export default function Segmentador() {
  const { dashQuery, filter, setFilter } = React.useContext(DashboardContext);
  const theme = useTheme();
  const { data } = dashQuery;

  const dataLeyend = [
    {
      name: "En trato",
      value: data ? data.obtenerDataDashboard.tratos.byGroup?.enTrato : 0,
      color: theme.palette.primary.light,
      icon: "Handshake",
    },
    {
      name: "Perdidos",
      value: data ? data.obtenerDataDashboard.tratos.byGroup?.perdidos : 0,
      color: theme.palette.error.light,
      icon: "DoDisturbOn",
    },
    {
      name: "Olvidados",
      value: data ? data.obtenerDataDashboard.tratos.byGroup?.olvidadas : 0,
      color: theme.palette.warning.light,
      icon: "EventBusy",
    },
    {
      name: "Ganados",
      value: data ? data.obtenerDataDashboard.tratos.byGroup?.ganados : 0,
      color: theme.palette.secondary.light,
      icon: "RocketLaunch",
    },
    {
      name: "Ventas",
      value: data ? data.obtenerDataDashboard.tratos.byGroup?.ventas : 0,
      color: theme.palette.success.light,
      icon: "Paid",
    },
  ];

  return (
    <Grid container spacing={2} sx={{ alignItems: "center" }}>
      <Grid item xs={12} lg={4}>
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          sx={{ mb: { xs: 1, md: 0 }, mt: { xs: 2, md: 0 } }}
        >
          <CustomSearchTextField
            placeholder="Buscar por tratos o contactos"
            size="small"
            fullWidth
            variant="outlined"
            value={filter.searchFilter}
            onChange={(e) =>
              setFilter((st) => ({ ...st, searchFilter: e.target.value }))
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <FilterDashboard />
        </Stack>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Grid container spacing={2}>
          {dataLeyend.map((res, index) => (
            <Grid
              item
              xs={6}
              sm={2.4}
              key={index}
              sx={{ display: "flex", alignItems: "start" }}
            >
              <Box
                sx={{
                  backgroundColor: res.color,
                  borderRadius: 2,
                  mr: 0.5,
                  p: 0.5,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CustomIcon iconName={res.icon} color={res.color} />
              </Box>
              <Box>
                <Typography
                  sx={{ lineHeight: 1, fontSize: { xs: 20, md: 18 } }}
                >
                  {res.name}
                </Typography>
                <CountUp
                  start={0}
                  end={res.value}
                  duration={1.5}
                  prefix={res.name === "Ventas" ? "$ " : ""}
                >
                  {({ countUpRef }) => (
                    <Typography
                      sx={{
                        fontSize: { xs: 20, md: 18 },
                        fontWeight: "bolder",
                      }}
                    >
                      <span ref={countUpRef} />
                    </Typography>
                  )}
                </CountUp>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

const CustomIcon = ({ iconName, color }) => {
  const Icon = Muicon[iconName];
  return <Icon sx={{ fontSize: { xs: 40, sm: 24 } }} htmlColor="white" />;
};
