import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableRow from "@mui/material/TableRow";
import CustomIconComponent from "../../../../../Components/CustomIconComponent";
import {
  formatoFechaCorta,
  formatoFechaHora,
  getWidth,
} from "../../../../../Config/reuserFunctions";
import NuevaActividad from "../../../Actividades/Actions/NuevaActividad/index";
import { TratosContext } from "../../Context/TratosContext";
import EliminarActividad from "../../../Actividades/Actions/EliminarActividad";
import { TableContainer } from "@mui/material";
import EditarActividad from "../../../Actividades/Actions/EditarActividad";
import ChipPrioridadActividad from "../../../Actividades/Actions/ChipPrioridadAct";
import ChipStatusAct from "../../../Actividades/Actions/ChipStatusAct";
import { ActividadContext } from "../../../Actividades/Context/ActividadContext";

export default function RenderActividades({ trato }) {
  const { queryRefetch } = React.useContext(TratosContext);
  const { queryCats } = React.useContext(ActividadContext);

  return (
    <Box sx={{ margin: 1 }}>
      <Box sx={{ display: "flex" }}>
        <Typography variant="h6" gutterBottom component="div" sx={{ mr: 2 }}>
          Actividades
        </Typography>
        <NuevaActividad
          size="small"
          external={true}
          tratoData={trato}
          refetchExternal={queryRefetch}
        />
      </Box>
      <TableContainer sx={{ overflowX: "scroll" }}>
        <Table size="small" aria-label="activities">
          <TableHead>
            <TableRow>
              <TableCell width={400}>Actividad</TableCell>
              <TableCell width={200}>Importancia</TableCell>
              <TableCell width={250}>Contacto</TableCell>
              <TableCell width={150}>Tefefono</TableCell>
              <TableCell width={150}>Status</TableCell>
              <TableCell width={300}>Recordar el</TableCell>
              <TableCell width={300}>Vence el</TableCell>
              <TableCell width={300}>Acción</TableCell>
              <TableCell>Asigando a</TableCell>
              <TableCell>Notas</TableCell>
              <TableCell>Ubicación</TableCell>
              <TableCell width={200}>Descripción ubicación</TableCell>
              <TableCell>Creada el</TableCell>
              <TableCell padding="checkbox" />
              <TableCell padding="checkbox" />
            </TableRow>
          </TableHead>
          <TableBody>
            {trato.actividades.map((actividad) => (
              <TableRow key={actividad._id}>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {actividad.tipoActividadPopulated.length ? (
                      <CustomIconComponent
                        color="disabled"
                        iconName={actividad.tipoActividadPopulated[0].icono}
                        fontSize={20}
                      />
                    ) : null}
                    <Box sx={{ mr: 1 }} />
                    {actividad.actividad}
                  </Box>
                </TableCell>
                <TableCell>
                  <ChipPrioridadActividad
                    actividad={actividad}
                    refetch={queryRefetch}
                  />
                </TableCell>
                <TableCell sx={{ minWidth: getWidth(actividad.contacto) }}>
                  {actividad.contacto}
                </TableCell>
                <TableCell>
                  {actividad.contactoPopulated.length > 0
                    ? actividad.contactoPopulated[0].telefono
                    : ""}
                </TableCell>
                <TableCell>
                  <ChipStatusAct
                    statusQuery={queryCats?.data?.statusActividad}
                    actividad={actividad}
                    refetch={queryRefetch}
                  />
                </TableCell>
                <TableCell sx={{ minWidth: 190 }}>
                  {formatoFechaHora(actividad.fechaInicio)}
                </TableCell>
                <TableCell sx={{ minWidth: 190 }}>
                  {formatoFechaHora(actividad.fechaFin)}
                </TableCell>
                <TableCell sx={{ minWidth: getWidth(actividad.tipoActividad) }}>
                  {actividad.tipoActividad}
                </TableCell>
                <TableCell sx={{ minWidth: getWidth(actividad.usuario) }}>
                  {actividad.usuario}
                </TableCell>
                <TableCell sx={{ minWidth: getWidth(actividad.notas) }}>
                  {actividad.notas}
                </TableCell>
                <TableCell sx={{ minWidth: getWidth(actividad.ubicacion) }}>
                  {actividad.ubicacion}
                </TableCell>
                <TableCell
                  sx={{ minWidth: getWidth(actividad.descripcionUbicacion) }}
                >
                  {actividad.descripcionUbicacion}
                </TableCell>
                <TableCell sx={{ minWidth: 120 }}>
                  {formatoFechaCorta(actividad.fechaRegistro)}
                </TableCell>
                <TableCell>
                  <EditarActividad
                    edit
                    data={actividad}
                    refetchTratos={queryRefetch}
                  />
                </TableCell>
                <TableCell>
                  <EliminarActividad
                    data={actividad}
                    refetchTratos={queryRefetch}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!trato.actividades.length ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            py: 2,
          }}
        >
          <Typography color="InactiveCaptionText">
            No hay actividades en este trato
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
}
