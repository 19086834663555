import * as React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

import { useMatches, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import CustomIconComponent from "../../../Components/CustomIconComponent";
import { isMobile } from "../../../Config/constantes";

export default function ListContent({ items, setView, handleClose }) {
  const { title, url, icon, expand, subItems } = items;
  const navigate = useNavigate();
  const matches = useMatches();

  const pathname = matches && matches.length > 1 ? matches[1].pathname : "";

  const handleClick = () => {
    navigate(url);
    setView(title);
    localStorage.setItem("lastview", title);
    if(isMobile) handleClose()
  };

  return !expand ? (
    <ListItem disablePadding sx={{ display: "block" }}>
      <ListItemButton
        selected={url === pathname}
        onClick={() => handleClick()}
        sx={{
          minHeight: 38,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: 2.6,
            justifyContent: "center",
          }}
        >
          <CustomIconComponent
            iconName={icon}
            fontSize={24}
            color={url === pathname ? "primary" : "inherit"}
          />
        </ListItemIcon>
        <ListItemText primary={title} />
      </ListItemButton>
    </ListItem>
  ) : (
    <>
      <Divider sx={{ my: 1 }} />
      {subItems.map((subtiems, index) => {
        const { icon, url } = subtiems;
        const subtitle = subtiems.title;
        return (
          <ListItem component="div" disablePadding key={index}>
            <ListItemButton
              selected={url === pathname}
              onClick={() => {
                setView(`${title} » ${subtitle}`);
                localStorage.setItem("lastview", `${title} » ${subtitle}`);
              }}
              component={Link}
              to={url}
              sx={{
                height: 38,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 2.6,
                  justifyContent: "center",
                }}
              >
                <CustomIconComponent
                  iconName={icon}
                  fontSize={24}
                  color={url === pathname ? "primary" : "inherit"}
                />
              </ListItemIcon>
              <ListItemText primary={subtitle} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </>
  );
}
