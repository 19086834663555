import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  ArrowBack,
  FilterAltOff,
  Close,
  Done,
  FilterAlt,
} from "@mui/icons-material";
import { ProductoContext } from "../Context/ProductoContext";
import { ButtonGroup, Stack } from "@mui/material";
import { isMobile } from "../../../../../Config/constantes";

const initial_filter = {
  categoria: "",
  unidadMedida: "",
};

export default function FilterProducto() {
  const [open, setOpen] = React.useState(false);
  const [countFilter, setCountFilter] = React.useState(0);
  const { setFilter, units } = React.useContext(ProductoContext);
  const [localFilter, setLocalFilter] = React.useState(initial_filter);
  const { categoria, unidadMedida } = localFilter;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onFilterChange = (e) => {
    const { name, value } = e.target;
    setLocalFilter((st) => ({ ...st, [name]: value }));
  };

  const handleFilterOk = (e) => {
    e.preventDefault();
    setFilter((st) => ({
      ...st,
      categoria,
      unidadMedida,
    }));
    const arrValues = Object.values(localFilter);
    let count = 0;
    arrValues.forEach((res) => (res ? count++ : null));
    setCountFilter(count);
    handleClose();
  };

  const clearFilter = (e) => {
    setLocalFilter(initial_filter);
    setFilter((st) => ({
      ...st,
      categoria: "",
      unidadMedida: "",
    }));
    setCountFilter(0);
    handleClose();
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        color="inherit"
        size="small"
        disableElevation
      >
        <Button
          size="small"
          startIcon={
            isMobile ? null : countFilter ? (
              countFilter
            ) : (
              <FilterAlt color="action" />
            )
          }
          onClick={handleOpen}
          sx={{ textTransform: "none", minWidth: 40 }}
        >
          {isMobile ? (
            countFilter ? (
              countFilter
            ) : (
              <FilterAlt fontSize="small" color="action" />
            )
          ) : (
            "Filtrar"
          )}
        </Button>
        {countFilter ? (
          <Button
            size="small"
            onClick={clearFilter}
            disabled={!countFilter}
            sx={{ minWidth: "25px!important", padding: 0 }}
          >
            <Close sx={{ fontSize: 16 }} />
          </Button>
        ) : null}
      </ButtonGroup>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        ModalProps={{
          componentsProps: {
            backdrop: {
              sx: { background: "none" /* backdropFilter: "blur(2px)" */ },
            },
          },
        }}
        PaperProps={{
          elevation: 1,
          sx: { width: { xs: "100vw", md: "30vw" } },
        }}
      >
        <Toolbar />
        <DialogTitle>
          <Stack spacing={1} direction="row" alignItems="center">
            <IconButton onClick={() => handleClose()}>
              <ArrowBack />
            </IconButton>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              startIcon={<FilterAltOff />}
              disableElevation
              onClick={clearFilter}
              size="small"
            >
              Limpiar
            </Button>
            <Button
              startIcon={<Done />}
              variant="contained"
              disableElevation
              type="submit"
              size="small"
              form="filter-producto"
            >
              Filtrar
            </Button>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <form id="filter-producto" onSubmit={handleFilterOk}>
            <FormControl fullWidth size="small" margin="dense">
              <InputLabel id="label-unidad">Unidad</InputLabel>
              <Select
                labelId="label-unidad"
                siza="small"
                name="unidadMedida"
                value={unidadMedida}
                onChange={onFilterChange}
                label="Unidad"
              >
                <MenuItem value="">Seleccione uno</MenuItem>
                {units.map((res, index) => (
                  <MenuItem key={`${res}-${index}`} value={res}>
                    {res}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Categoría"
              size="small"
              variant="outlined"
              margin="dense"
              name="categoria"
              value={categoria}
              onChange={onFilterChange}
              fullWidth
            />
          </form>
        </DialogContent>
      </Drawer>
    </>
  );
}
