import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Checkbox, useTheme } from "@mui/material";
import { getBicolorTable } from "../../../../../Config/reuserFunctions";
import { AreaContext } from "../Context/AreaContext";
import EditableRowArea from "./EditableRow";

export default function TableBodyAreas({ data, handleSelect }) {
  const { newItem, setNewItem, itemsSelected } = React.useContext(AreaContext);
  const theme = useTheme();

  return (
    <TableBody>
      {newItem && newItem.new
        ? [newItem].map((_, index) => {
            return <EditableRowArea key={`new-item-${index}`} />;
          })
        : null}
      {data.map((data, index) => {
        const checked = itemsSelected.find((res) => res._id === data._id);
        if (newItem && !newItem.new && newItem._id === data._id) {
          return <EditableRowArea key={`new-item-${index}`} edit={true} />;
        } else {
          return (
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={`${data.area}-${index}`}
              style={getBicolorTable(theme, index)}
              onDoubleClick={() => setNewItem(data)}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  onChange={(e) => handleSelect(e.target.checked, data)}
                  checked={checked ? true : false}
                />
              </TableCell>
              <TableCell>{data.area}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          );
        }
      })}
    </TableBody>
  );
}
