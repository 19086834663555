import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ContactosContext } from "../../Context/ContactoContext";
import NuevaActividad from "../../../../Actividades/Actions/NuevaActividad/index";
import ActividadCtxProvider from "../../../../Actividades/Context/ActividadContext";
import ExtCompActForm from "../../../../Actividades/Actions/ExtCompActForm";

export default function ActividadesContactoDetailIndex() {
  const { contacto, setContacto, queryRefetch } = React.useContext(
    ContactosContext
  );

  const setToArray = (data) => {
    if (!data) return;
    const actividades = [...contacto.actividades];
    actividades.splice(0, 0, data);
    setContacto({ ...contacto, actividades });
  };

  return (
    <ActividadCtxProvider>
      <Stack direction="row" spacing={2} sx={{ mb: 1 }}>
        <Typography variant="h6">Actividades</Typography>
        <NuevaActividad
          external
          refetchExternal={queryRefetch}
          setToArray={setToArray}
          fromContacto={contacto}
        />
      </Stack>
      {!contacto?.actividades?.length ? (
        <Typography align="center" color="grey" sx={{ my: 5 }}>
          No Hay Actividades
        </Typography>
      ) : (
        contacto.actividades.map((actividad) => (
          <ExtCompActForm
            data={actividad}
            refetch={queryRefetch}
            key={actividad._id}
          />
        ))
      )}
    </ActividadCtxProvider>
  );
}
