import { gql } from "@apollo/client";

export const OBTENER_AREAS = gql`
  query ObtenerAreas($idEmpresa: ID!, $filter: String) {
    obtenerAreas(idEmpresa: $idEmpresa, filter: $filter ) {
      _id
      area
      idEmpresa
    }
  }
`;
