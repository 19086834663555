import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Add, ArrowBack, Done, Edit } from "@mui/icons-material";
import { ACTUALIZAR_STATUS, CREAR_STATUS } from "../graphql/mutations";
import { useMutation } from "@apollo/client";
import { MainContext } from "../../../../../Context/MainCtx";
import { CircularProgress, Stack } from "@mui/material";
import ColorSelect from "./ColorSelect";

export default function NuevoStatus({
  edit,
  data,
  refetch,
  external = false,
  refetchExternal = null,
}) {
  const [open, setOpen] = React.useState(false);
  const [statusC, setStatus] = React.useState({
    status: "",
    factor: "",
    color: "",
  });
  const [loading, setLoading] = React.useState(false);
  const { snackMessage, tokenDecoded } = React.useContext(MainContext);
  const [crearStatusContacto] = useMutation(CREAR_STATUS);
  const [actualizarStatusContacto] = useMutation(ACTUALIZAR_STATUS);
  const { status, factor } = statusC;

  const handleOpen = () => {
    setOpen(true);
    if (edit) {
      const { _id, idEmpresa, ...newData } = data;
      setStatus(newData);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setStatus({ status: "", factor: "", color: "" });
  };

  const handleChangeStatus = (e) => {
    const { name, value } = e.target;
    setStatus((st) => ({ ...st, [name]: value }));
  };

  const handleSaveStatus = async (e) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      if (!status) return;
      setLoading(true);
      if (!edit) {
        //si es nuevo
        const res = await crearStatusContacto({
          variables: {
            idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
            input: statusC,
          },
        });
        snackMessage({
          message: res.data.crearStatusContacto.message,
          variant: "success",
        });
      } else {
        //si va a actualizar
        const res = await actualizarStatusContacto({
          variables: {
            idStatusContacto: data._id,
            idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
            input: statusC,
          },
        });
        snackMessage({
          message: res.data.actualizarStatusContacto.message,
          variant: "success",
        });
      }
      if (refetchExternal) refetchExternal();
      if (refetch) refetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log(err.networkError?.result);
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      {edit ? (
        <IconButton onClick={handleOpen}>
          {loading ? <CircularProgress size={20} color="inherit" /> : <Edit />}
        </IconButton>
      ) : external ? (
        <IconButton onClick={handleOpen} size="small" color="primary">
          <Add />
        </IconButton>
      ) : (
        <Button
          onClick={handleOpen}
          color="primary"
          size="small"
          disableElevation
          sx={{ textTransform: "none" }}
          variant="contained"
          startIcon={<Add />}
        >
          Nuevo
        </Button>
      )}

      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        ModalProps={{
          componentsProps: {
            backdrop: {
              sx: { background: "none" },
            },
          },
        }}
        PaperProps={{
          elevation: 1,
          sx: { width: { xs: "100vw", sm: "40vw" } },
        }}
      >
        <Toolbar variant="dense" />
        <DialogTitle component="div">
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton onClick={() => handleClose()}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h6" color="GrayText">
              {edit ? `Editar` : "Nuevo status"}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              startIcon={
                loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <Done />
                )
              }
              size="small"
              disableElevation
              sx={{ textTransform: "none" }}
              variant="contained"
              type="submit"
              form="formStatusC"
            >
              Guardar
            </Button>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ py: 0 }}>
          <form id="formStatusC" onSubmit={handleSaveStatus}>
            <TextField
              label="Status"
              size="small"
              variant="outlined"
              margin="dense"
              name="status"
              fullWidth
              onChange={handleChangeStatus}
              value={status}
              required
            />
            <TextField
              label="Factor"
              size="small"
              variant="outlined"
              margin="dense"
              name="factor"
              fullWidth
              onChange={handleChangeStatus}
              value={factor}
            />
            <ColorSelect statusC={statusC} setStatus={setStatus} />
          </form>
        </DialogContent>
      </Drawer>
    </div>
  );
}
