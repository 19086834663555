import React from "react";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable } from "../../../../../Config/StrictModeDroppable";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Checkbox, Collapse, IconButton, ListItemSecondaryAction, alpha } from "@mui/material";
import { MainContext } from "../../../../../Context/MainCtx";
import { ACTUALIZAR_FASE_TRATO } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import HorizontalScrollDrag from "./HorizontalScrollDrag";
import { TratosContext } from "../../Context/TratosContext";
import ChipStatus from "./ChipStatus";
import ChipPrioridad from "./ChipPrioridad";
import TratoDetails from "../Details";
import {
  MoreVert,
  PermContactCalendar,
} from "@mui/icons-material";
import MenuOptionsTrato from "./ListOptions";

export default function TratosBoardMode() {
  const { snackMessage } = React.useContext(MainContext);
  const [actualizarFaseTrato] = useMutation(ACTUALIZAR_FASE_TRATO);
  const {
    fases,
    setFases,
    queryRefetch,
  } = React.useContext(TratosContext);
  const [dragginID, setDragginID] = React.useState("");

  const onDragStart = (result) => {
    setDragginID(result.destination?.droppableId);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const { source, destination, draggableId } = result;
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    const FASES = [...fases];
    const sourceFase = FASES.filter((fase) => fase._id === source.droppableId);
    const destinFase = FASES.filter(
      (fase) => fase._id === destination.droppableId
    );

    if (!sourceFase.length && !destinFase.length) return;

    const draggedItem = sourceFase[0].tratos.find(
      (item) => item._id === draggableId
    );

    // Move within the same list
    if (source.droppableId !== destination.droppableId) {
      sourceFase[0].tratos.splice(source.index, 1);
      destinFase[0].tratos.splice(destination.index, 0, draggedItem);

      /* el siguiente codigo si funciona, pero sin el tambien, por si llega a falla
      descomentar, es para remplazar los arrays

      FASES.splice(sourceFase[0].posicion-1, 1, sourceFase[0]);
      FASES.splice(destinFase[0].posicion-1, 1, destinFase[0]); */
      setDragginID("");
      setFases(FASES);
      updateList(draggedItem._id, destinFase[0]._id);
    }
  };

  const updateList = async (idTrato, idFase) => {
    try {
      await actualizarFaseTrato({
        variables: {
          idTrato,
          idFase,
        },
      });
      queryRefetch();
    } catch (err) {
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragStart}>
      <HorizontalScrollDrag>
        {fases.map((embudo, k) => {
          let divContainer = document.getElementById(`contenedor-tratos-${k}`);
          const actHeight = divContainer?.offsetHeight || 70;
          const minHeight = dragginID === embudo._id ? actHeight + 90 : 70;

          return (
            <StrictModeDroppable
              key={`${embudo._id}-${k}`}
              droppableId={embudo._id}
            >
              {(provided) => (
                <Box sx={{ height: 80 }}>
                  <Box sx={{ mx: 1, width: "360px" }}>
                    <Box
                      sx={{
                        maxHeight: "100%",
                        borderRadius: 3,
                        bgcolor: (theme) =>
                          alpha(theme.palette.comodyBlue, 0.07),
                      }}
                    >
                      <Typography variant="body2" sx={{ px: 2, pt: 1 }}>
                        <b>{embudo.fase}</b>
                      </Typography>
                      <List
                        dense
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        <Box
                          sx={{
                            px: 1.5,
                            minHeight,
                            transition: ".3s",
                            maxHeight: "72vh",
                            overflowY: "auto",
                          }}
                          id={`contenedor-tratos-${k}`}
                        >
                          {embudo.tratos.map((trato, index) => {
                            return (
                              <Draggable
                                key={`${trato._id}-${index}`}
                                draggableId={trato._id}
                                index={index}
                              >
                                {(provided) => (
                                  <Box
                                    {...provided.draggableProps}
                                    ref={provided.innerRef}
                                    sx={{
                                      bgcolor: "background.paper",
                                      borderRadius: 2,
                                      mb: 1.5,
                                      cursor: "grab",
                                    }}
                                    component={Paper}
                                    variant="outlined"
                                  >
                                    <ListItemsComponent
                                      trato={trato}
                                      provided={provided}
                                    />
                                  </Box>
                                )}
                              </Draggable>
                            );
                          })}
                        </Box>
                      </List>
                    </Box>
                    {provided.placeholder}
                  </Box>
                </Box>
              )}
            </StrictModeDroppable>
          );
        })}
      </HorizontalScrollDrag>
    </DragDropContext>
  );
}

const ListItemsComponent = ({ trato, provided }) => {
  const [expanded, setExpanded] = React.useState(false);
  const {
    isSelecting,
    queryRefetch,
    itemsSelected,
    setItemsSelected,
    setIndeterminated,
    tratos,
  } = React.useContext(TratosContext);
  const checked = itemsSelected.find((res) => res._id === trato._id);

  const handleExpandClick = (event) => {
    event.stopPropagation();
    setExpanded(!expanded);
  };

  const handleSelect = (checked, item) => {
    const items = [...itemsSelected];
    if (checked) items.push(item);
    if (!checked) {
      const indx = items.findIndex((elm) => elm._id === item._id);
      items.splice(indx, 1);
    }
    setItemsSelected(items);
    //revisar el checkAll
    if (items.length > 0 && items.length !== tratos.length) {
      setIndeterminated(true);
      return;
    }
    setIndeterminated(false);
  };

  return (
    <>
      <TratoDetails data={trato}>
        <ListItem sx={{ py: 1, pr: 1, pl: 0 }}>
          <ListItemIcon sx={{ minWidth: "30px" }}>
            {isSelecting ? (
              <Checkbox
                onChange={(e) => handleSelect(e.target.checked, trato)}
                onClick={(e) => e.stopPropagation()}
                checked={checked ? true : false}
                size="small"
              />
            ) : null}
          </ListItemIcon>
          <ListItemText
            {...provided.dragHandleProps}
            primary={
              <Box
                component="span"
                sx={{
                  display: "inline-block",
                  wordWrap: "break-word",
                  pb: 0.5,
                  whiteSpace: "normal",
                  width: "100%",
                }}
              >
                <Typography>{trato.nombreTrato}</Typography>
              </Box>
            }
            secondary={
              <Box component="span" sx={{ display: "block" }}>
                <Box
                  component="span"
                  sx={{
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PermContactCalendar
                    color="disabled"
                    sx={{ fontSize: 19, mr: 0.5 }}
                  />
                  <Typography variant="body2" component="span">
                    {trato?.contactoPopulated?.length > 0
                      ? trato.contactoPopulated[0].nombre
                      : trato.contacto}
                  </Typography>
                </Box>
                <span onClick={(e) => e.stopPropagation()}>
                  <ChipPrioridad trato={trato} refetch={queryRefetch} />
                  {trato.status ? (
                    <ChipStatus trato={trato} refetch={queryRefetch} />
                  ) : null}
                </span>
              </Box>
            }
          />
          <ListItemSecondaryAction>
            <IconButton onClick={handleExpandClick}>
              <MoreVert />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </TratoDetails>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <MenuOptionsTrato trato={trato} handleSelect={handleSelect} />
      </Collapse>
    </>
  );
};
