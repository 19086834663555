import React from "react";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import ListItemButton from "@mui/material/ListItemButton";
import { useMutation } from "@apollo/client";
import {
  NotificationImportant,
  NotificationsActive,
  NotificationsNone,
} from "@mui/icons-material";
import {
  CircularProgress,
  List,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { ACTUALIZAR_PRIORIDAD_ACTIVIDAD } from "../graphql/mutations";
import { MainContext } from "../../../../Context/MainCtx";
import { getColorTratoPrioridades } from "../../../../Config/reuserFunctions";

const PRIORIDADES = ["BAJA", "MEDIA", "ALTA"];

export default function ChipPrioridadActividad({
  actividad,
  setActividad = null,
  refetch,
  onchange = null,
  tabIndex = null,
}) {
  const { snackMessage } = React.useContext(MainContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(null);
  const open = Boolean(anchorEl);
  const [updatePrioridadActividad] = useMutation(
    ACTUALIZAR_PRIORIDAD_ACTIVIDAD
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const updatePrioridad = async (prioridad) => {
    try {
      handleClose();
      setLoading(true);
      if (setActividad) setActividad((st) => ({ ...st, prioridad }));
      await updatePrioridadActividad({
        variables: {
          idActividad: actividad._id,
          prioridad,
        },
      });
      refetch();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
      console.log(err.networkError?.result);
    }
  };

  const getIconPrioridad = ({ prioridad, sx = null }) => {
    switch (prioridad) {
      case "BAJA":
        return (
          <NotificationsNone
            color={prioridad === actividad.prioridad ? "primary" : "inherit"}
            sx={sx}
          />
        );
      case "MEDIA":
        return (
          <NotificationsActive
            color={prioridad === actividad.prioridad ? "primary" : "inherit"}
            sx={sx}
          />
        );
      case "ALTA":
        return (
          <NotificationImportant
            color={prioridad === actividad.prioridad ? "primary" : "inherit"}
            sx={sx}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Chip
        tabIndex={tabIndex}
        id="custom-chip-prioridad-act"
        aria-controls={open ? "prioridad-menu-act" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        component={"span"}
        label={actividad.prioridad}
        size="small"
        icon={
          loading ? (
            <CircularProgress sx={{ color: "white!important" }} size={16} />
          ) : (
            getIconPrioridad({
              prioridad: actividad.prioridad,
              sx: { color: "white" },
            })
          )
        }
        sx={{
          mr: 0.5,
          //height: 20,
          fontWeight: "600",
          bgcolor: (theme) =>
            getColorTratoPrioridades(actividad.prioridad, theme),
          color: "white",
          ":hover": {
            bgcolor: (theme) =>
              getColorTratoPrioridades(actividad.prioridad, theme),
          },
        }}
        onClick={handleClick}
      />
      <Menu
        id="prioridad-menu-act"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "custom-chip-prioridad-act",
        }}
      >
        <List disablePadding dense>
          {PRIORIDADES.map((prioridad, index) => (
            <ListItemButton
              key={index}
              onClick={() =>
                onchange
                  ? onchange({
                      name: "prioridad",
                      value: prioridad,
                    })
                  : updatePrioridad(prioridad)
              }
              selected={prioridad === actividad.prioridad}
            >
              <ListItemIcon>{getIconPrioridad({ prioridad })}</ListItemIcon>
              <ListItemText>{prioridad}</ListItemText>
            </ListItemButton>
          ))}
        </List>
      </Menu>
    </>
  );
}
