import React from "react";
import {
  TextField,
  Box,
  Button,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import {
  AccountCircle,
  LockOpen,
  Password,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { Navigate, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { LOGEAR_USUARIO } from "./mutation";
import { useMutation } from "@apollo/client";
import { MainContext } from "../../Context/MainCtx";
import jwtDecode from "jwt-decode";

export default function LoginForm() {
  const cookies = new Cookies();
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({
    username: "",
    password: "",
  });
  const { username, password } = data;
  const navigate = useNavigate();
  const [logearUsuario] = useMutation(LOGEAR_USUARIO);
  const { snackMessage, tokenDecoded, setTokenDecoded } = React.useContext(MainContext);

  if (tokenDecoded) return <Navigate to="/" />;

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const res = await logearUsuario({
        variables: {
          username,
          password,
        },
      });
      cookies.set("ComodySesion", res.data.logearUsuario.token);
      const decoded = jwtDecode(res.data.logearUsuario.token);
      setTokenDecoded(decoded);
      setLoading(false);
      snackMessage({
        message: "Listo!",
        variant: "success",
      });
      navigate("/");
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <Box sx={{ width: 370 }}>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Usuario o correo electrónico"
          variant="outlined"
          margin="dense"
          required
          value={username}
          onChange={(e) => setData({ ...data, username: e.target.value })}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          fullWidth
          label="Password"
          variant="outlined"
          margin="dense"
          required
          type={show ? "text" : "password"}
          value={password}
          onChange={(e) => setData({ ...data, password: e.target.value })}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Password />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShow(!show)}>
                  {show ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          sx={{ mt: 1 }}
          fullWidth
          variant="contained"
          disableElevation
          type="submit"
          startIcon={
            loading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <LockOpen />
            )
          }
        >
          Entrar
        </Button>
      </form>
    </Box>
  );
}
