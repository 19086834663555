import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { Add, ArrowBack, Done, Edit } from "@mui/icons-material";
import { CREAR_REUNION, ACTUALIZAR_REUNION } from "../graphql/mutations";
import { MainContext } from "../../../../Context/MainCtx";
import { useMutation } from "@apollo/client";
import FormEvent from "./Forms/FormEvent";
import { ReunionContext } from "../Context/ReunionContext";
import { isMobile } from "../../../../Config/constantes";
import { Stack } from "@mui/material";

export default function NuevaReunion({ data = null, edit = false }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { snackMessage, tokenDecoded } = React.useContext(MainContext);
  const [crearReunion] = useMutation(CREAR_REUNION);
  const [actualizarReunion] = useMutation(ACTUALIZAR_REUNION);

  const {
    initial_evento,
    getQueryCatalogos,
    queryRefetch,
    evento,
    setEvento,
    googleCalendarLogged,
    setGoogleCalendarLogged,
    setInvitados,
  } = React.useContext(ReunionContext);

  const handleOpen = () => {
    setOpen(true);
    getQueryCatalogos();
    if (edit) {
      const { _id, fechaRegistro, idEmpresa, ...newData } = data;
      setEvento(newData);
      let invitados = [];
      data.invitados.forEach((name) => invitados.push(name.nombreCompleto));
      setInvitados(invitados);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setEvento(initial_evento);
    setInvitados([]);
  };

  const createEventoGoogleMeet = async (e) => {
    try {
      e.preventDefault();
      if (!googleCalendarLogged) {
        snackMessage({
          message:
            "Necesita iniciar sesión en Google para realizar esta acción",
          variant: "warning",
        });
        return;
      }
      const { calendar } = window.gapi.client;
      setLoading(true);
      const attendees = [];
      await evento.invitados.forEach(({ correo }) =>
        attendees.push({ email: correo })
      );
      let request = {
        calendarId: "primary",
        resource: {
          summary: evento.nombreEvento,
          description: evento.descripcion,
          start: {
            dateTime: evento.fechaInicio,
            timeZone: "America/Mexico_City",
          },
          end: {
            dateTime: evento.fechaFin,
            timeZone: "America/Mexico_City",
          },
          attendees,
        },
      };
      let googleResponse = null;
      if (edit) {
        request.eventId = evento.idGoogleMeet;
        googleResponse = await calendar.events.update(request);
      } else {
        request.conferenceDataVersion = 1;
        request.resource.conferenceData = {
          createRequest: {
            requestId: "opcional",
          },
        };
        googleResponse = await calendar.events.insert(request);
      }
      if (googleResponse.result) await handleSaveEvento(googleResponse.result);
    } catch (err) {
      setLoading(false);
      console.log(err);
      if (err.result && err.result.error.code === 401) {
        snackMessage({
          message:
            "Expiró la sesion de Google, por favor vuelve a inicial sesión",
          variant: "warning",
        });
        // quitar sesion expirada de google
        const token = window.gapi.client.getToken();
        if (token !== null) {
          window.google.accounts.oauth2.revoke(token.access_token);
          window.gapi.client.setToken("");
          setGoogleCalendarLogged(null);
        }
        return;
      }
      if (err.result && err.result.error.code === 404) {
        snackMessage({
          message: "No se encontró evento en Google Meet",
          variant: "error",
        });
        return;
      }
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  const handleSaveEvento = async (googleResponse) => {
    try {
      if (!edit) {
        //si es nuevo
        const input = {
          ...evento,
          creadoPor: {
            idUsuario: tokenDecoded._id,
            nombre: tokenDecoded.nombreCompleto,
            email: tokenDecoded.correo,
          },
          linkMeeting: googleResponse.conferenceData.entryPoints[0]?.uri,
          idGoogleMeet: googleResponse.id,
        };
        const res = await crearReunion({
          variables: {
            idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
            input,
          },
        });
        snackMessage({
          message: res.data.crearReunion.message,
          variant: "success",
        });
      } else {
        //si va a actualizar
        const res = await actualizarReunion({
          variables: {
            idReunion: data._id,
            idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
            input: evento,
          },
        });
        snackMessage({
          message: res.data.actualizarReunion.message,
          variant: "success",
        });
      }
      queryRefetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      {edit ? (
        <IconButton onClick={handleOpen} size="small">
          <Edit />
        </IconButton>
      ) : (
        <Button
          startIcon={isMobile ? null : <Add />}
          color="primary"
          variant="contained"
          onClick={handleOpen}
          size="small"
          disableElevation
          sx={{ textTransform: "none", minWidth: 40 }}
        >
          {isMobile ? <Add fontSize="small" /> : "Nuevo"}
        </Button>
      )}
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        ModalProps={{
          componentsProps: {
            backdrop: {
              sx: { background: "none" },
            },
          },
        }}
        PaperProps={{
          elevation: 1,
          sx: { width: { xs: "100vw", md: "40vw" } },
        }}
      >
        <Toolbar variant="dense" />
        <DialogTitle>
          <Stack spacing={1} direction="row" alignItems="center">
            <IconButton onClick={() => handleClose()}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h6">
              {edit ? `Editar` : "Nueva reunión"}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              startIcon={
                loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <Done />
                )
              }
              variant="contained"
              disableElevation
              sx={{ textTransform: "none" }}
              size="small"
              type="submit"
              form="formEvento"
            >
              Guardar
            </Button>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <form id="formEvento" onSubmit={createEventoGoogleMeet}>
            <FormEvent />
          </form>
        </DialogContent>
      </Drawer>
    </div>
  );
}
