import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Add, ArrowBack, Done, Edit } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { MainContext } from "../../../../../Context/MainCtx";
import { ACTUALIZAR_EMBUDO, CREAR_EMBUDO } from "../graphql/mutations";
import { useMutation } from "@apollo/client";

export default function NuevoEmbudo({
  edit,
  data,
  refetch,
  external = false,
  refetchExternal = null,
}) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [fase, setFase] = React.useState("");
  const { snackMessage, tokenDecoded } = React.useContext(MainContext);
  const [crearEmbudo] = useMutation(CREAR_EMBUDO);
  const [actualizarEmbudo] = useMutation(ACTUALIZAR_EMBUDO);

  const handleOpen = () => {
    setOpen(true);
    if (edit) setFase(data.fase);
  };
  const handleClose = () => {
    setOpen(false);
    setFase("");
  };

  const handleSaveEmbudo = async (e) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      if (!fase) return;
      setLoading(true);
      if (!edit) {
        //si es nuevo
        await crearEmbudo({
          variables: {
            idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
            fase,
          },
        });
      } else {
        //si va a actualizar
        await actualizarEmbudo({
          variables: {
            idEmbudo: data._id,
            idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
            fase,
          },
        });
      }
      if (external && refetchExternal) refetchExternal();
      if (refetch) refetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      {edit ? (
        <IconButton onClick={handleOpen}>
          <Edit />
        </IconButton>
      ) : external ? (
        <IconButton onClick={handleOpen} size="small" color="primary">
          <Add />
        </IconButton>
      ) : (
        <Button
          onClick={handleOpen}
          color="primary"
          size="small"
          disableElevation
          sx={{ textTransform: "none" }}
          variant="contained"
          startIcon={<Add />}
        >
          Nuevo
        </Button>
      )}
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        ModalProps={{
          componentsProps: {
            backdrop: {
              sx: { background: "none" },
            },
          },
        }}
        PaperProps={{
          elevation: 1,
          sx: { width: { xs: "100vw", md: "40vw" } },
        }}
      >
        <Toolbar variant="dense" />
        <DialogTitle>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={() => handleClose()}>
              <ArrowBack />
            </IconButton>
            <Box sx={{ ml: 1 }} />
            <Typography variant="h6">
              {edit ? `Editar` : "Nuevo embudo"}
            </Typography>
            <Box sx={{flexGrow: 1}} />
            <Button
              startIcon={
                loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <Done />
                )
              }
              variant="contained"
              size="small"
              sx={{textTransform: "none"}}
              disableElevation
              type="submit"
              form="embudo-form"
            >
              Guardar
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ py: 0 }}>
          <form id="embudo-form" onSubmit={handleSaveEmbudo}>
            <TextField
              label="Fase"
              size="small"
              variant="outlined"
              margin="dense"
              fullWidth
              onChange={(e) => setFase(e.target.value)}
              value={fase}
              required
            />
          </form>
        </DialogContent>
      </Drawer>
    </div>
  );
}
