import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Done from "@mui/icons-material/Done";
import Add from "@mui/icons-material/Add";
import CircularProgress from "@mui/material/CircularProgress";
import EditableTextInput from "../../../../../Components/InputsForms/EditableTextInput";
import EditableAutoComplete from "../../../../../Components/InputsForms/EditableAutoComlete";
import NuevoUsuario from "../../../Configuraciones/Usuarios/Actions/NuevoUsuario";
import NuevoTipoActividad from "../../../Configuraciones/TiposActividades/Actions/NuevoTipoActividad";
import { ACTUALIZAR_ACTIVIDAD } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { MainContext } from "../../../../../Context/MainCtx";
import { ActividadContext } from "../../Context/ActividadContext";
import moment from "moment-timezone";
import ChipStatusAct from "../ChipStatusAct";
import ChipPrioridadActividad from "../ChipPrioridadAct";
import TratosCtxProvider from "../../../Tratos/Context/TratosContext";
import NuevoTrato from "../../../Tratos/Actions/NuevoTrato";
import EditableDateTimeInput from "../../../../../Components/InputsForms/EditableDateTimeInput";

export default function ActividadDetail({ refetchTratos }) {
  const { queryCats, queryRefetch, actividad, setActividad } = React.useContext(
    ActividadContext
  );
  const { snackMessage } = React.useContext(MainContext);
  const [loading, setLoading] = React.useState(false);
  const [actualizarActividad] = useMutation(ACTUALIZAR_ACTIVIDAD);

  const handleChangeActividad = (e) => {
    const { name, value } = e.target;
    setActividad((state) => ({ ...state, [name]: value }));
  };
  const handleSetFecha = (name, value) => {
    const dataUpdated = {
      ...actividad,
      [name]: value,
    };
    setActividad(dataUpdated);
    updateActividad(dataUpdated);
  };
  const handleChangeDate = async (name, value) => {
    const dataUpdated = {
      ...actividad,
      [name]: moment(value).tz("America/Mexico_City").format(),
    };
    setActividad(dataUpdated);
    await updateActividad(dataUpdated);
  };
  const autocompleteChange = (values) => {
    const { name, value, id, idName } = values;
    let dataUpdated = {
      ...actividad,
      [name]: value,
    };
    if (id && idName) {
      dataUpdated = {
        ...actividad,
        [name]: value,
        [idName]: id,
      };
    }
    setActividad(dataUpdated);
    updateActividad(dataUpdated);
  };
  const updateActividad = async (dataUpdated = actividad) => {
    try {
      if (loading) return;
      setLoading(true);
      let {
        _id,
        fechaRegistro,
        idEmpresa,
        contactoPopulated,
        tipoActividadPopulated,
        statusData,
        fromCalendar,
        edit,
        ...input
      } = dataUpdated;
      await actualizarActividad({
        variables: {
          idActividad: actividad._id,
          input,
        },
      });
      if (refetchTratos) refetchTratos();
      queryRefetch();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <Box
      sx={{
        height: "80vh",
        position: { xs: "initial", md: "sticky" },
        top: 0,
        ml: 2,
      }}
    >
      <Grid container spacing={1} onClick={(e) => e.stopPropagation()}>
        <Grid item xs={12} md={7}>
          <Typography variant="body2" color="gray">
            Tipo Actividad
          </Typography>
          <EditableAutoComplete
            tabIndex={1}
            placeholder="Añadir tipo actividad"
            renderData={queryCats.data?.tipoActividades}
            value={actividad.tipoActividad}
            loading={loading}
            loadingQuery={queryCats.loading}
            errorQuery={queryCats.error}
            required={true}
            addButton={
              <NuevoTipoActividad
                external={true}
                refetchExternal={queryCats.refetch}
              />
            }
            loadingIcon={<CircularProgress size={20} color="primary" />}
            onChange={(_, option) =>
              autocompleteChange({
                name: "tipoActividad",
                idName: "idTipoActividad",
                value: option?.tipoActividad || "",
                id: option?._id || "",
              })
            }
            isOptionEqualToValue={({ tipoActividad }, value) =>
              tipoActividad === value
            }
            getOptionLabel={(option) => option.tipoActividad || option || null}
          />
        </Grid>
        <Grid item xs={6} md={2.5}>
          <Typography variant="body2" color="gray">
            Status
          </Typography>
          <ChipStatusAct
            tabIndex={2}
            actividad={actividad}
            setActividad={setActividad}
            refetch={queryRefetch}
            statusQuery={queryCats.data?.statusActividad}
          />
        </Grid>
        <Grid item xs={6} md={2.5}>
          <Typography variant="body2" color="gray">
            Importancia
          </Typography>
          <ChipPrioridadActividad
            tabIndex={3}
            actividad={actividad}
            setActividad={setActividad}
            refetch={queryRefetch}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="gray">
            Actividad
          </Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              updateActividad();
            }}
          >
            <EditableTextInput
              tabIndex={4}
              fullWidth
              placeholder="Agregar nombre a la actividad"
              name="actividad"
              value={actividad.actividad}
              icon={<Add />}
              loading={loading}
              onChange={handleChangeActividad}
              actionButton={
                <IconButton size="small" color="primary" type="submit">
                  {loading ? (
                    <CircularProgress size={20} color="primary" />
                  ) : (
                    <Done />
                  )}
                </IconButton>
              }
            />
          </form>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="gray">
            Trato
          </Typography>
          <EditableAutoComplete
            tabIndex={5}
            placeholder="Asignar trato"
            renderData={queryCats.data?.tratos}
            value={actividad.trato}
            loading={loading}
            loadingQuery={queryCats.loading}
            errorQuery={queryCats.error}
            required={true}
            addButton={
              <TratosCtxProvider>
                <NuevoTrato
                  external={true}
                  refetchExternal={queryCats.refetch}
                />
              </TratosCtxProvider>
            }
            loadingIcon={<CircularProgress size={20} color="primary" />}
            onChange={(_, option) =>
              autocompleteChange({
                name: "trato",
                idName: "idTrato",
                value: option?.nombreTrato || "",
                id: option?._id || "",
              })
            }
            isOptionEqualToValue={({ nombreTrato }, value) =>
              nombreTrato === value
            }
            getOptionLabel={(option) => option.nombreTrato || option || null}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body2" color="gray">
            Fecha Recordatorio
          </Typography>
          <Box sx={{ display: "flex" }}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  sx={{ p: 0 }}
                  onChange={(e) => handleSetFecha("isDate", e.target.checked)}
                  checked={actividad.isDate}
                />
              }
              label={
                <Typography sx={{ ml: 1 }}>
                  {actividad.isDate ? "" : "Asignar Fecha"}
                </Typography>
              }
              sx={{ m: 0 }}
            />
            <Box hidden={!actividad.isDate}>
              <EditableDateTimeInput
                tabIndex={6}
                name="fechaInicio"
                value={actividad.fechaInicio}
                loading={loading}
                onChange={handleChangeDate}
                actionButton={
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => updateActividad()}
                  >
                    {loading ? (
                      <CircularProgress size={20} color="primary" />
                    ) : (
                      <Done />
                    )}
                  </IconButton>
                }
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body2" color="gray">
            Vence el
          </Typography>
          <Box sx={{ display: "flex" }}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  sx={{ p: 0 }}
                  onChange={(e) => handleSetFecha("expires", e.target.checked)}
                  checked={actividad.expires}
                />
              }
              label={
                <Typography sx={{ ml: 1 }}>
                  {actividad.expires ? "" : "Asignar Fecha"}
                </Typography>
              }
              sx={{ m: 0 }}
            />
            <Box hidden={!actividad.expires}>
              <EditableDateTimeInput
                tabIndex={7}
                name="fechaFin"
                value={actividad.fechaFin}
                loading={loading}
                onChange={handleChangeDate}
                actionButton={
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => updateActividad()}
                  >
                    {loading ? (
                      <CircularProgress size={20} color="primary" />
                    ) : (
                      <Done />
                    )}
                  </IconButton>
                }
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="gray">
            Usuario asignado
          </Typography>
          <EditableAutoComplete
            tabIndex={9}
            placeholder="Añadir usuario asignado"
            renderData={queryCats.data?.usuarios}
            value={actividad.usuario}
            loading={loading}
            loadingQuery={queryCats.loading}
            errorQuery={queryCats.error}
            required={true}
            addButton={
              <NuevoUsuario
                external={true}
                refetchExternal={queryCats.refetch}
              />
            }
            loadingIcon={<CircularProgress size={20} color="primary" />}
            onChange={(_, option) =>
              autocompleteChange({
                name: "usuario",
                idName: "idUsuario",
                value: option?.nombreCompleto || "",
                id: option?._id || "",
              })
            }
            isOptionEqualToValue={({ nombreCompleto }, value) =>
              nombreCompleto === value
            }
            getOptionLabel={(option) => option.nombreCompleto || option || null}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="gray">
            Notas
          </Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              updateActividad();
            }}
          >
            <EditableTextInput
              tabIndex={10}
              fullWidth
              placeholder="Agregar una nota"
              name="notas"
              value={actividad.notas}
              icon={<Add />}
              loading={loading}
              onChange={handleChangeActividad}
              actionButton={
                <IconButton size="small" color="primary" type="submit">
                  {loading ? (
                    <CircularProgress size={20} color="primary" />
                  ) : (
                    <Done />
                  )}
                </IconButton>
              }
            />
          </form>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: actividad.resultado ? "block" : "none" }}
        >
          <Typography variant="body2" color="gray">
            Resultado actividad
          </Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              updateActividad();
            }}
          >
            <EditableTextInput
              tabIndex={10}
              fullWidth
              placeholder="cual fue el resultado de la actividad"
              name="resultado"
              value={actividad.resultado}
              icon={<Add />}
              loading={loading}
              onChange={handleChangeActividad}
              actionButton={
                <IconButton size="small" color="primary" type="submit">
                  {loading ? (
                    <CircularProgress size={20} color="primary" />
                  ) : (
                    <Done />
                  )}
                </IconButton>
              }
            />
          </form>
        </Grid>
      </Grid>
    </Box>
  );
}
