import { gql } from "@apollo/client";

export const OBTENER_MONEDAS = gql`
  query ObtenerMonedas($idEmpresa: ID!) {
    obtenerMonedas(idEmpresa: $idEmpresa) {
      _id
      moneda
      idEmpresa
    }
  }
`;
