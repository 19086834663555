import { gql } from "@apollo/client";

export const CREAR_RED = gql`
  mutation CrearRedSocial($idEmpresa: ID!, $input: redSocialInput) {
    crearRedSocial(idEmpresa: $idEmpresa, input: $input) {
      message
    }
  }
`;
export const ACTUALIZAR_RED = gql`
  mutation ActualizarRedSocial(
    $idEmpresa: ID!
    $idRedSocial: ID!
    $input: redSocialInput
  ) {
    actualizarRedSocial(
      idEmpresa: $idEmpresa
      idRedSocial: $idRedSocial
      input: $input
    ) {
      message
    }
  }
`;
export const ELIMINAR_RED = gql`
  mutation EliminarRedSocial($idRedSocial: ID!) {
    eliminarRedSocial(idRedSocial: $idRedSocial) {
      message
    }
  }
`;
