import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Checkbox, TableSortLabel } from "@mui/material";
import { TratosContext } from "../../Context/TratosContext";

const headCells = [
  {
    id: "edit",
    padding: "checkbox",
    align: "left",
    label: "",
    sx: null,
  },
  {
    id: "nombreTrato",
    padding: "normal",
    align: "left",
    label: "Trato",
    sortable: true,
    sx: {
      /* position: { xs: "relative", sm: "sticky" },
      left: 52, */
      minWidth: 400,
      //zIndex: 3
    },
  },
  {
    id: "producto",
    padding: "normal",
    align: "left",
    label: "Producto",
    sortable: true,
    sx: {
      minWidth: 250,
    },
  },
  {
    id: "contacto",
    padding: "normal",
    align: "left",
    label: "Contacto",
    sortable: true,
    sx: {
      minWidth: 230,
    },
  },
  {
    id: "embudo",
    padding: "normal",
    align: "left",
    label: "Fase del embudo",
    sortable: true,
    sx: {
      minWidth: 250,
    },
  },
  {
    id: "probabilidad",
    padding: "normal",
    align: "left",
    label: "% Cierre",
    sortable: true,
    sx: {
      minWidth: 120,
    },
  },
  {
    id: "prioridad",
    padding: "normal",
    align: "center",
    label: "Prioridad",
    sortable: false,
    sx: {
      minWidth: 135,
    },
  },
  {
    id: "status",
    padding: "normal",
    align: "center",
    label: "Status",
    sortable: false,
    sx: {
      minWidth: 135,
    },
  },
  {
    id: "formaContacto",
    padding: "normal",
    align: "left",
    label: "¿Cómo nos contactó?",
    sortable: true,
    sx: {
      minWidth: 200,
    },
  },
  {
    id: "valor",
    padding: "normal",
    align: "left",
    label: "Valor",
    sortable: true,
    sx: {
      minWidth: 130,
    },
  },
  {
    id: "moneda",
    padding: "normal",
    align: "left",
    label: "Moneda",
    sortable: false,
    sx: {
      minWidth: 130,
    },
  },
  {
    id: "interes",
    padding: "normal",
    align: "center",
    label: "Interés",
    sortable: false,
    sx: null,
  },
  {
    id: "campaign",
    padding: "normal",
    align: "left",
    label: "Campaña",
    sortable: true,
    sx: {
      minWidth: 100,
    },
  },
  {
    id: "unidadNegocio",
    padding: "normal",
    align: "left",
    label: "Unidad negocio",
    sortable: true,
    sx: {
      minWidth: 170,
    },
  },
  {
    id: "propietario",
    padding: "normal",
    align: "left",
    label: "Propietario",
    sortable: true,
    sx: {
      minWidth: 220,
    },
  },
  {
    id: "usuarioCierre",
    padding: "normal",
    align: "left",
    label: "Usuario Cierre",
    sortable: true,
    sx: {
      minWidth: 220,
    },
  },
  {
    id: "fechaEstimadaCierre",
    padding: "normal",
    align: "left",
    label: "Fecha estimada cierre",
    sortable: true,
    sx: {
      minWidth: 200,
    },
  },
  {
    id: "fechaTrato",
    padding: "checkbox",
    align: "left",
    label: "Fecha trato",
    sortable: true,
    sx: {
      minWidth: 190,
    },
  },
  {
    id: "visiblePara",
    padding: "normal",
    align: "left",
    label: "Visible para",
    sortable: false,
    sx: {
      minWidth: 250,
    },
  },
];

export default function TableHeadTrato({
  handleSort,
  order,
  orderBy,
  handleCheckAll,
}) {
  const { itemsSelected, indeterminated } = React.useContext(TratosContext);

  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          sx={{
            position: { xs: "relative", sm: "sticky" },
            left: 0,
            zIndex: 3,
          }}
        >
          <Checkbox
            indeterminate={indeterminated}
            checked={itemsSelected.length > 0 && !indeterminated}
            onChange={(e) => handleCheckAll(e.target.checked)}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={headCell.sx}
            sortDirection={orderBy === headCell.id ? order : false}
            padding={headCell.padding}
            align={headCell.align}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => handleSort(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
