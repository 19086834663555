import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ReunionContext } from "../Context/ReunionContext";
import { Google } from "@mui/icons-material";
import CustomAvatar from "../../../../Components/Avatar/CustomAvatar";
import { Avatar } from "@mui/material";
import { isMobile } from "../../../../Config/constantes";

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const DISCOVERY_DOC =
  "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest";
const SCOPES = "profile https://www.googleapis.com/auth/calendar";

function TestCalendar() {
  const [gapiInited, setGapiInited] = React.useState(false);
  const [gisInited, setGisInited] = React.useState(false);
  const { googleCalendarLogged, setGoogleCalendarLogged } = React.useContext(
    ReunionContext
  );

  React.useEffect(() => {
    const gapiLoaded = () => {
      const script = document.createElement("script");
      script.src = "https://apis.google.com/js/api.js";
      script.onload = () => {
        window.gapi.load("client", async () => {
          await window.gapi.client.init({
            apiKey: API_KEY,
            discoveryDocs: [DISCOVERY_DOC],
          });
          setGapiInited(true);
        });
      };
      document.body.appendChild(script);
    };
    const gisLoaded = () => {
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.onload = () => {
        window.tokenClient = window.google.accounts.oauth2.initTokenClient({
          client_id: CLIENT_ID,
          scope: SCOPES,
          callback: "", // defined later
        });
        setGisInited(true);
      };
      document.body.appendChild(script);
    };
    gapiLoaded();
    gisLoaded();
  }, []);

  const handleAuthClick = () => {
    window.tokenClient.callback = async (resp) => {
      if (resp.error !== undefined) {
        throw resp;
      }
      window.gapi.client.load("oauth2", "v2", function () {
        window.gapi.client.oauth2.userinfo.get().execute(function (resp) {
          setGoogleCalendarLogged(resp);
        });
      });
      await listUpcomingEvents();
    };

    if (window.gapi.client.getToken() === null) {
      window.tokenClient.requestAccessToken({ prompt: "consent" });
    } else {
      window.tokenClient.requestAccessToken({ prompt: "" });
    }
  };

  const handleSignoutClick = () => {
    const token = window.gapi.client.getToken();
    if (token !== null) {
      window.google.accounts.oauth2.revoke(token.access_token);
      window.gapi.client.setToken("");
      setGoogleCalendarLogged(null);
    }
  };

  const listUpcomingEvents = async () => {
    let response;
    try {
      const request = {
        calendarId: "primary",
        //timeMin: new Date().toISOString(),
        showDeleted: false,
        singleEvents: true,
        maxResults: 10,
        orderBy: "startTime",
      };
      response = await window.gapi.client.calendar.events.list(request);
    } catch (err) {
      console.log(err);
      return;
    }

    const events = response.result.items;
    if (!events || events.length === 0) {
      console.log("No events found.");
      return;
    }
  };

  return (
    <>
      {gapiInited && gisInited ? (
        !googleCalendarLogged ? (
          <Button
            variant="contained"
            color="info"
            size="small"
            disableElevation
            sx={{
              textTransform: "none",
              minWidth: 40,
              bgcolor: "#4285F4",
              color: "white",
            }}
            startIcon={isMobile ? null : <Google />}
            onClick={handleAuthClick}
          >
            {isMobile ? <Google fontSize="small" /> : "Iniciar Sesión"}
          </Button>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              border: "1px solid rgb(0,0,0, 0.1)",
              borderRadius: 10,
              p: 0.5,
            }}
          >
            <Avatar sx={{ opacity: 0.3, mr: 2, width: 32, height: 32 }}>
              <Google />
            </Avatar>
            <Box sx={{ mr: 1, textAlign: "end" }}>
              <Typography
                variant="subtitle2"
                sx={{ lineHeight: 0.5 }}
              >{`${googleCalendarLogged.name}`}</Typography>
              <Button
                sx={{ m: 0, p: 0, textTransform: "none" }}
                color="info"
                size="small"
                onClick={handleSignoutClick}
              >
                Cerrar sesión
              </Button>
            </Box>
            <CustomAvatar
              size={32}
              name={googleCalendarLogged.name}
              img={googleCalendarLogged.picture}
              fontSize={18}
            />
          </Box>
        )
      ) : null}
    </>
  );
}

export default TestCalendar;
