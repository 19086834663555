import React from "react";
import SearchArea from "./Actions/FilterArea";
import TableAreas from "./Table";
import { Button, CircularProgress, Stack } from "@mui/material";
import { Add, Done } from "@mui/icons-material";
import EliminarAreas from "./Actions/EliminarArea";
import AreaCtxProvider, { AreaContext } from "./Context/AreaContext";
import { isMobile } from "../../../../Config/constantes";

export default function Areas() {
  return (
    <div>
      <AreaCtxProvider>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-end"
          alignItems="center"
          sx={{
            mb: 1,
            position: { sm: "absolute" },
            top: 60,
            right: 24,
          }}
        >
          <SearchArea />
          <AddNewItemToTable />
          <EliminarAreas />
        </Stack>
        <TableAreas />
      </AreaCtxProvider>
    </div>
  );
}

const AddNewItemToTable = () => {
  const { initial_area, setNewItem, newItem, loadingSave } = React.useContext(
    AreaContext
  );

  React.useEffect(() => {
    window.addEventListener("keydown", KeyDownFunction);
    function KeyDownFunction(e) {
      const tecla_escape = e.keyCode;
      if (tecla_escape === 27) {
        if (!newItem) {
          setNewItem(initial_area);
        } else {
          setNewItem(null);
        }
      }
    }
    return () => window.removeEventListener("keydown", KeyDownFunction);
  }, [newItem, initial_area, setNewItem]);

  return !newItem ? (
    <Button
      color="primary"
      size="small"
      variant="contained"
      disableElevation
      onClick={() => setNewItem(initial_area)}
      sx={{ textTransform: "none", minWidth: 40 }}
      startIcon={isMobile ? null : <Add />}
    >
      {isMobile ? <Add fontSize="small" /> : "Nuevo"}
    </Button>
  ) : (
    <Button
      color="primary"
      size="small"
      variant="contained"
      startIcon={
        loadingSave ? <CircularProgress color="inherit" size={20} /> : <Done />
      }
      disableElevation
      form="form-area-table"
      type="submit"
      sx={{ textTransform: "none" }}
    >
      Guardar
    </Button>
  );
};
