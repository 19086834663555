import moment from "moment-timezone";
import { STATUS_TRATO } from "./constantes";

export const generateCode = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
export const generateCodeNumerico = (length = 10) => {
  var result = "";
  var characters = "0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
export const formatoHora = (hora) => {
  if (!hora) {
    return null;
  } else {
    var newtime = new Date(hora);
    return newtime.toLocaleTimeString("en-US", { hour12: "false" });
  }
};
export const formatoFecha = (fecha) => {
  if (!fecha) {
    return null;
  } else {
    var newdate = new Date(fecha);
    return newdate.toLocaleDateString("es-MX", {
      weekday: "short",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }
};
export const formatoFechaCorta = (fecha) => {
  if (!fecha) return null;
  return moment(fecha).tz("America/Mexico_City").format("ll");
};
export const formatoFechaHora = (fecha) => {
  if (!fecha) return null;
  return moment(fecha).locale("es-mx").tz("America/Mexico_City").format("lll");
};
export const formatoMexico = (number, decimal = 2) => {
  if (!number) return 0;
  let nueva;
  if (number % 1 === 0) {
    nueva = parseFloat(number).toFixed(decimal);
  } else {
    nueva = parseFloat(number).toFixed(decimal);
  }
  const exp = /(\d)(?=(\d{3})+(?!\d))/g;
  const rep = "$1,";
  return nueva.toString().replace(exp, rep);
};

export const getWidth = (value, more = 0) => {
  if (!value) return "0px";
  let size = value.replace(" ", "_").length;
  return `${size / 1.25 + more}em`;
};

export const getColorTratoStatus = (status, theme) => {
  if (!theme) return "#f9f9f9";
  switch (status) {
    case STATUS_TRATO[0]:
      return theme.palette.error.light;
    case STATUS_TRATO[1]:
      return theme.palette.success.light;
    case STATUS_TRATO[2]:
      return theme.palette.info.light;
    case STATUS_TRATO[3]:
      return theme.palette.warning.light;
    default:
      return theme.palette.grey[400];
  }
};
export const getColorTratoPrioridades = (prioridad, theme) => {
  if (!theme) return "#f9f9f9";
  switch (prioridad) {
    case "BAJA":
      return theme.palette.grey[400];
    case "MEDIA":
      return theme.palette.info.light;
    case "ALTA":
      return theme.palette.warning.light;
    default:
      return theme.palette.grey[400];
  }
};

export const getContrastColor = (bgColor = "#ffffff") => {

  if(!bgColor) return null
  // Convertir el color de fondo a RGB
  const r = parseInt(bgColor.slice(1, 3), 16);
  const g = parseInt(bgColor.slice(3, 5), 16);
  const b = parseInt(bgColor.slice(5, 7), 16);

  // Calcular el valor de luminosidad (L)
  //const L = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  const L = 0.1412 * r + 0.5652 * g + 0.0722 * b;

  // Elegir el color de texto en función del contraste
  const textColor = L > 128 ? '#000000' : '#ffffff';
  return textColor;
}

export const getBicolorTable = (theme, index) => {
  return index % 2 === 0
    ? {
        backgroundColor: theme.palette.customPaper,
      }
    : {
        backgroundColor: theme.palette.background.paper,
      };
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
