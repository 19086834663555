import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { indigo } from "@mui/material/colors";

const BorderLinearProgress = styled(LinearProgress)(({ theme, value }) => {
  let bgColor = theme.palette.warning.main;

  if (value <= 30) {
    bgColor = theme.palette.grey[300];
  } else if (value > 30 && value <= 70) {
    bgColor = theme.palette.warning.light;
  } else {
    bgColor = "#1a90ff";
  }

  return {
    height: 24,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: indigo[theme.palette.mode === "light" ? 50 : 300],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: bgColor,
    },
  };
});

export default function CustomProgress({ value }) {
  return (
    <Box sx={{ flexGrow: 1, position: "relative" }}>
      <BorderLinearProgress variant="determinate" value={value} />
      <Box
        idonfocus="probabilidadCierre-trato-texfield"
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{ color: value > 60 ? "white" : "#404040" }}
        >
          <b idonfocus="probabilidadCierre-trato-texfield">{value}%</b>
        </Typography>
      </Box>
    </Box>
  );
}
