import { alpha } from "@mui/material";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import interactionPlugin from '@fullcalendar/interaction';

export const customStyles = (theme) => ({
  "& .fc-theme-standard th": {
    borderColor: theme.palette.borderCalendar
  },
  "& .fc-theme-standard td": {
    borderColor: theme.palette.borderCalendar
  },
  "& .fc-theme-standard .fc-list": {
    border: 'none!important'
  },
  "& .fc-theme-standard table": {
    border: 'none!important'
  },
  "& .fc .fc-toolbar.fc-header-toolbar": {
    marginBottom: 0,
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  "& .fc .fc-toolbar-title": {
    fontSize: 18,
    marginLeft: 1,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  "& .fc .fc-toolbar-chunk": {
    [theme.breakpoints.down("sm")]: {
      display :"flex",
      justifyContent: "center"
    },
  },
  "& .fc-button": {
    bgcolor: "transparent",
    border: "none",
    color: "primary.main",
  },
  "& .fc-button:disabled": {
    bgcolor: "transparent",
    border: theme.palette.action.disabled,
    color: theme.palette.action.disabled,
  },
  "& .fc-button:not(:disabled).fc-button-active, .fc-button:not(:disabled).fc-button-active:focus": {
    bgcolor: theme.palette.primary.main,
    border: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    boxShadow: "none",
  },
  "& .fc-button:not(:disabled):hover": {
    bgcolor: alpha(theme.palette.primary.main, 0.1),
    border: "none",
    color: theme.palette.text.secondary,
  },
  "& .fc-button-primary:not(:disabled):active:focus, .fc-button-primary:focus": {
    bgcolor: "transparent",
    border: "none",
    color: "primary.main",
    boxShadow: "none",
  },
  "& .fc-list-day-cushion": {
    bgcolor: theme.palette.customPaper
  },
  "& .fc-list-event:hover": {
    bgcolor: `${theme.palette.customPaper}!important`
  }
});

export const config = {
  timeZone: "America/Mexico_City",
  locale: "es-mx",
  initialView: "timeGridWeek",
  headerToolbar: {
    left: "title",
    center: "",
    right: "prev,today,next timeGridDay,timeGridWeek,dayGridMonth,listWeek",
  },
  buttonText: {
    today: "Hoy",
    month: "Mes",
    week: "Semana",
    day: "Día",
    list: "Lista",
  },
  plugins: [
    momentTimezonePlugin,
    listPlugin,
    timeGridPlugin,
    dayGridPlugin,
    interactionPlugin,
  ],
};
