import React, { createContext } from "react";
import { MainContext } from "../../../../Context/MainCtx";
import { useDebounce } from "use-debounce";
import { useApolloClient, useQuery } from "@apollo/client";
import {
  OBTENER_QUERIES_REUNIONES,
  OBTENER_REUNIONES,
} from "../graphql/queries";
import moment from "moment-timezone";

export const ReunionContext = createContext();

const initial_evento = {
  nombreEvento: "",
  descripcion: "",
  fechaFin: moment().tz("America/Mexico_City").format(),
  fechaInicio: moment().tz("America/Mexico_City").format(),
  trato: "",
  idTrato: "",
  linkMeeting: "",
  invitados: [],
  creadoPor: null,
};

const initial_query = {
  data: undefined,
  loading: true,
  error: undefined,
};

const initial_filter = {
  searchFilter: "",
  idTrato: "",
  fechaStart: "",
  fechaEnd: "",
  idUsuario: "",
};

const ReunionCtxProvider = ({ children }) => {
  const { tokenDecoded } = React.useContext(MainContext);
  const [filter, setFilter] = React.useState(initial_filter);
  const [evento, setEvento] = React.useState(initial_evento);
  const [queryCats, setQueryCats] = React.useState(initial_query);
  const [invitados, setInvitados] = React.useState([]);
  const [googleCalendarLogged, setGoogleCalendarLogged] = React.useState(null);
  const [FILTER] = useDebounce(filter, 500);
  const client = useApolloClient();

  const reunionesQuery = useQuery(OBTENER_REUNIONES, {
    variables: {
      filter: FILTER,
      idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
    },
    fetchPolicy: "network-only",
  });
  const { data, loading, error, refetch } = reunionesQuery;

  const getQueryCatalogos = async () => {
    try {
      const response = await client.query({
        query: OBTENER_QUERIES_REUNIONES,
        variables: { idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "" },
        fetchPolicy: "network-only",
      });
      const { loading, data, error } = response;
      setQueryCats({ loading, data: data.obtenerCatalogosReuniones, error });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ReunionContext.Provider
      value={{
        filter,
        setFilter,
        initial_filter,
        queryData: data,
        queryLoading: loading,
        queryError: error,
        queryRefetch: refetch,
        evento,
        setEvento,
        initial_evento,
        getQueryCatalogos,
        queryCats,
        googleCalendarLogged,
        setGoogleCalendarLogged,
        invitados,
        setInvitados,
      }}
    >
      {children}
    </ReunionContext.Provider>
  );
};

export default ReunionCtxProvider;
