import { gql } from "@apollo/client";

export const OBTENER_STATUS = gql`
  query ObtenerStatusContactos($idEmpresa: ID!) {
    obtenerStatusContactos(idEmpresa: $idEmpresa) {
      _id
      status
      factor
      color
      idEmpresa
    }
  }
`;
