import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {
  AccountCircle,
  ArrowBack,
  Close,
  Done,
  FilterAlt,
  FilterAltOff,
  Handshake,
} from "@mui/icons-material";
import { InputAdornment, Stack } from "@mui/material";
import { ReunionContext } from "../Context/ReunionContext";
import AutoCompleteInput from "../../../../Components/InputsForms/AutocompleteInput";
import { isMobile } from "../../../../Config/constantes";

export default function FilterReunion() {
  const [open, setOpen] = React.useState(false);
  const [countFilter, setCountFilter] = React.useState(0);
  const {
    setFilter,
    initial_filter,
    getQueryCatalogos,
    queryCats,
  } = React.useContext(ReunionContext);
  const [localFilter, setLocalFilter] = React.useState(initial_filter);

  const handleOpen = () => {
    setOpen(true);
    getQueryCatalogos();
  };
  const handleClose = () => setOpen(false);

  const onFilterChange = (e, child) => {
    const { name, value } = e.target;
    const { id, idName } = child.props;
    setLocalFilter((st) => ({ ...st, [name]: value, [idName]: id }));
  };

  const autocompleteChange = (values) => {
    const { name, value, idName, id } = values;
    setLocalFilter((st) => ({
      ...st,
      [name]: value,
      [idName]: id,
    }));
  };

  const handleFilterOk = (e) => {
    e.preventDefault();
    const { trato, usuario, ...filtros } = localFilter;
    setFilter((st) => ({
      ...st,
      ...filtros,
    }));
    const arrValues = Object.values(filtros);
    let count = 0;
    arrValues.forEach((res) => (res ? count++ : null));
    setCountFilter(count);
    handleClose();
  };

  const clearFilter = () => {
    const { searchFilter, ...initial } = initial_filter;
    setLocalFilter(initial);
    setFilter((st) => ({
      ...st,
      ...initial,
    }));
    setCountFilter(0);
    handleClose();
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        color="inherit"
        size="small"
        disableElevation
      >
        <Button
          size="small"
          startIcon={
            isMobile ? null : countFilter ? (
              countFilter
            ) : (
              <FilterAlt color="action" />
            )
          }
          onClick={handleOpen}
          sx={{ textTransform: "none", minWidth: 40 }}
        >
          {isMobile ? (
            countFilter ? (
              countFilter
            ) : (
              <FilterAlt fontSize="small" color="action" />
            )
          ) : (
            "Filtrar"
          )}
        </Button>
        {countFilter ? (
          <Button
            size="small"
            onClick={clearFilter}
            disabled={!countFilter}
            sx={{ minWidth: "25px!important", padding: 0 }}
          >
            <Close sx={{ fontSize: 16 }} />
          </Button>
        ) : null}
      </ButtonGroup>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        ModalProps={{
          componentsProps: {
            backdrop: {
              sx: { background: "none" /* backdropFilter: "blur(2px)" */ },
            },
          },
        }}
        PaperProps={{
          elevation: 1,
          sx: { width: { xs: "100vw", md: "30vw" } },
        }}
      >
        <Toolbar />
        <DialogTitle sx={{ py: 0 }}>
          <Stack spacing={1} direction="row" alignItems="center">
            <IconButton onClick={() => handleClose()}>
              <ArrowBack />
            </IconButton>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              startIcon={<FilterAltOff />}
              disableElevation
              onClick={clearFilter}
              size="small"
            >
              Limpiar
            </Button>
            <Button
              startIcon={<Done />}
              variant="contained"
              size="small"
              sz={{ textTransform: "none" }}
              disableElevation
              type="submit"
              form="filter-tratos"
            >
              Filtrar
            </Button>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <form id="filter-tratos" onSubmit={handleFilterOk}>
            <Typography>Por fecha:</Typography>
            <Divider />
            <TextField
              size="small"
              variant="outlined"
              margin="dense"
              name="fechaStart"
              value={localFilter.fechaStart}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">De: </InputAdornment>
                ),
              }}
              onChange={onFilterChange}
              fullWidth
              type="date"
            />
            <TextField
              size="small"
              variant="outlined"
              margin="dense"
              name="fechaEnd"
              value={localFilter.fechaEnd}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">A: </InputAdornment>
                ),
              }}
              onChange={onFilterChange}
              fullWidth
              type="date"
            />
            <Box sx={{ my: 1 }}>
              <Divider />
            </Box>
            <AutoCompleteInput
              renderData={queryCats.data?.tratos}
              value={localFilter.trato}
              label="Trato"
              icon={<Handshake />}
              loading={queryCats.loading}
              error={queryCats.error}
              onChange={(_, option) =>
                autocompleteChange({
                  name: "trato",
                  idName: "idTrato",
                  value: option?.nombreTrato || "",
                  id: option?._id || "",
                })
              }
              isOptionEqualToValue={({ nombreTrato }, value) =>
                nombreTrato === value
              }
              getOptionLabel={(option) => option.nombreTrato || option || null}
            />
            <AutoCompleteInput
              renderData={queryCats.data?.usuarios}
              value={localFilter.usuario}
              label="Usuario creador"
              icon={<AccountCircle />}
              loading={queryCats.loading}
              error={queryCats.error}
              onChange={(_, option) =>
                autocompleteChange({
                  name: "usuario",
                  idName: "idUsuario",
                  value: option?.nombreCompleto || "",
                  id: option?._id || "",
                })
              }
              isOptionEqualToValue={({ nombreCompleto }, value) =>
                nombreCompleto === value
              }
              getOptionLabel={(option) =>
                option.nombreCompleto || option || null
              }
            />
          </form>
        </DialogContent>
      </Drawer>
    </>
  );
}
