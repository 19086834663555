import { gql } from "@apollo/client";

export const OBTENER_FORMAS = gql`
  query ObtenerFormas($idEmpresa: ID!, $filter: String) {
    obtenerFormas(idEmpresa: $idEmpresa, filter: $filter) {
      _id
      formaAtraccion
      idEmpresa
    }
  }
`;