import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CustomLoader from "../../../../Components/CustomLoader";
import ErrorMessage from "../../../../Components/ErrorMessage";
import EliminarVenta from "./EliminarVenta";
import {
  formatoFechaCorta,
  formatoMexico,
  getBicolorTable,
  getWidth,
} from "../../../../Config/reuserFunctions";
import { Pagination, useTheme } from "@mui/material";
import ChipPrioridad from "../../Tratos/CardFases/BoardMode/ChipPrioridad";
import ChipStatus from "../../Tratos/CardFases/BoardMode/ChipStatus";
import { isMobile } from "../../../../Config/constantes";

export default function TableVentas({ ventasQuery, setPage }) {
  const { data, loading, error, refetch } = ventasQuery;
  const [shadows, setShadows] = React.useState({
    start: "",
    end: "leftShadowTable",
  });
  const tableEl = React.useRef();
  const theme = useTheme();

  const changeClassName = React.useCallback((scrollLeft, realWidth) => {
    if (scrollLeft === 0) {
      setShadows({ start: "removeShadowTable", end: "leftShadowTable" });
      return;
    }
    if (scrollLeft === realWidth) {
      setShadows({ start: "rightShadowTable", end: "removeShadowTable" });
      return;
    }
    setShadows({ start: "rightShadowTable", end: "leftShadowTable" });
  }, []);

  const handleOnScroll = (ev) => {
    const { scrollLeft, scrollWidth, clientWidth } = ev.target;
    const realWidth = scrollWidth - clientWidth;
    changeClassName(scrollLeft, realWidth);
  };

  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage />;

  const { page, totalPages } = data.obtenerVentas;

  const handleChangePage = (event, newPage) => setPage(newPage - 1);

  return (
    <Paper variant="outlined">
      <TableContainer
        sx={{
          transition: "width .3s",
          height: { xs: `calc(100vh - 232px)`, sm: `calc(100vh - 140px)` },
          overflowX: "auto",
        }}
        ref={tableEl}
        onScroll={handleOnScroll}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  position: { xs: "relative", sm: "sticky" },
                  left: 0,
                  minWidth: 150,
                }}
              >
                Trato
              </TableCell>
              <TableCell sx={{ minWidth: 150 }}>Producto</TableCell>
              <TableCell sx={{ minWidth: 150 }}>Nombre Contacto</TableCell>
              <TableCell padding="checkbox" sx={{ minWidth: 120 }}>
                Fecha trato
              </TableCell>
              <TableCell padding="checkbox" align="center">
                Prioridad
              </TableCell>
              <TableCell padding="checkbox" align="center">
                Status
              </TableCell>
              <TableCell padding="checkbox" align="center">
                Interés
              </TableCell>
              <TableCell sx={{ minWidth: 110 }}>Probabilidad</TableCell>
              <TableCell sx={{ minWidth: 140 }}>Forma contacto</TableCell>
              <TableCell sx={{ minWidth: 100 }}>Campaña</TableCell>
              <TableCell sx={{ minWidth: 140 }}>Unidad negocio</TableCell>
              <TableCell sx={{ minWidth: 100 }}>Valor</TableCell>
              <TableCell sx={{ minWidth: 100 }}>Propietario</TableCell>
              <TableCell sx={{ minWidth: 130 }}>Usuario Cierre</TableCell>
              <TableCell sx={{ minWidth: 180 }}>
                Fecha estimada cierre
              </TableCell>
              <TableCell padding="checkbox" />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.obtenerVentas.docs.map((trato, index) => (
              <TableRow key={trato._id} style={getBicolorTable(theme, index)}>
                <TableCell
                  className={!isMobile ? shadows.start : ""}
                  sx={{
                    position: { xs: "relative", sm: "sticky" },
                    left: 0,
                    zIndex: 1,
                    minWidth: getWidth(trato.nombreTrato),
                    bgcolor: "background.paper",
                    //padding: 0,
                  }}
                  style={getBicolorTable(theme, index)}
                >
                  {trato.nombreTrato}
                </TableCell>
                <TableCell sx={{ minWidth: getWidth(trato.producto) }}>
                  {trato.producto}
                </TableCell>
                <TableCell sx={{ minWidth: getWidth(trato.contacto) }}>
                  {trato.contacto}
                </TableCell>
                <TableCell>{formatoFechaCorta(trato.fechaTrato)}</TableCell>
                <TableCell align="center">
                  <ChipPrioridad trato={trato} refetch={refetch} />
                </TableCell>
                <TableCell align="center">
                  {trato.status ? (
                    <ChipStatus trato={trato} refetch={refetch} />
                  ) : null}
                </TableCell>
                <TableCell>
                  <Rating size="small" value={trato.interes} readOnly />
                </TableCell>
                <TableCell align="center">
                  {trato.probabilidadCierre}%
                </TableCell>
                <TableCell sx={{ minWidth: getWidth(trato.formaContacto) }}>
                  {trato.formaContacto}
                </TableCell>
                <TableCell sx={{ minWidth: getWidth(trato.campaign) }}>
                  {trato.campaign}
                </TableCell>
                <TableCell sx={{ minWidth: getWidth(trato.unidadNegocio) }}>
                  {trato.unidadNegocio}
                </TableCell>
                <TableCell
                  sx={{
                    minWidth: getWidth(
                      `$${formatoMexico(trato.valor)} ${trato.moneda}`
                    ),
                  }}
                >{`$${formatoMexico(trato.valor)} ${trato.moneda}`}</TableCell>
                <TableCell sx={{ minWidth: getWidth(trato.propietario) }}>
                  {trato.propietario}
                </TableCell>
                <TableCell sx={{ minWidth: getWidth(trato.usuarioCierre) }}>
                  {trato.usuarioCierre}
                </TableCell>
                <TableCell
                  sx={{ minWidth: getWidth(trato.fechaEstimadaCierre) }}
                >
                  {formatoFechaCorta(trato.fechaEstimadaCierre)}
                </TableCell>
                <TableCell
                  sx={{
                    position: { xs: "relative", sm: "sticky" },
                    right: 0,
                    bgcolor: "background.paper",
                    p: 0,
                  }}
                >
                  <EliminarVenta data={trato} refetch={refetch} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          color="primary"
          page={page}
          count={totalPages}
          onChange={handleChangePage}
        />
      </Box>
    </Paper>
  );
}
