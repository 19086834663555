import { gql } from "@apollo/client";

export const OBTENER_TRATOS_BY_FASE = gql`
  query ObtenerTratos($idEmpresa: ID!, $filter: FiltrosInput) {
    obtenerTratos(idEmpresa: $idEmpresa, filter: $filter) {
      tratoByFase {
        tratos {
          probabilidadCierre
          _id
          producto
          idProducto
          unidadNegocio
          idUnidadNegocio
          fechaTrato
          contacto
          idContacto
          nombreTrato
          moneda
          idMoneda
          valor
          formaContacto
          idFormaContacto
          embudo
          idEmbudo
          visiblePara {
            nombreCompleto
            idUsuario
          }
          status
          propietario
          idPropietario
          interes
          campaign
          idCampaign
          prioridad
          fechaEstimadaCierre
          usuarioCierre
          fechaRegistro
          idUsuarioCierre
          idEmpresa
          contactoPopulated {
            _id
            correo
            nombre
            campaign
            propietario
            organizacion
            recomendadoPor
            statusContacto
            idStatusContacto
            telefono
            tipoContacto
            idTipoContacto
            etiqueta
            idEtiqueta
            statusContactoPopulated {
              _id
              status
              factor
              color
            }
          }
          actividades {
            _id
            actividad
            tipoActividad
            idTipoActividad
            isDate
            fechaInicio
            fechaFin
            notas
            contacto
            idContacto
            trato
            idTrato
            ubicacion
            descripcionUbicacion
            usuario
            idUsuario
            status
            fechaRegistro
            resultado
            prioridad
            statusData {
              _id
              status
              color
            }
            contactoPopulated {
              _id
              correo
              nombre
              campaign
              propietario
              organizacion
              recomendadoPor
              statusContacto
              telefono
              tipoContacto
              etiqueta
              idStatusContacto
              idTipoContacto
              idEtiqueta
              statusContactoPopulated {
                _id
                status
                factor
                color
              }
            }
            tipoActividadPopulated {
              _id
              accion
              icono
              tipoActividad
            }
          }
        }
        fase
        posicion
        _id
      }
      tratos {
        probabilidadCierre
        _id
        producto
        idProducto
        unidadNegocio
        idUnidadNegocio
        fechaTrato
        contacto
        idContacto
        nombreTrato
        moneda
        idMoneda
        valor
        formaContacto
        idFormaContacto
        embudo
        idEmbudo
        visiblePara {
          nombreCompleto
          idUsuario
        }
        status
        propietario
        idPropietario
        interes
        campaign
        idCampaign
        prioridad
        fechaEstimadaCierre
        usuarioCierre
        fechaRegistro
        idUsuarioCierre
        idEmpresa
        contactoPopulated {
          _id
          correo
          nombre
          campaign
          propietario
          organizacion
          recomendadoPor
          statusContacto
          idStatusContacto
          telefono
          tipoContacto
          idTipoContacto
          etiqueta
          idEtiqueta
          statusContactoPopulated {
            _id
            status
            factor
            color
          }
        }
        actividades {
          _id
          actividad
          tipoActividad
          idTipoActividad
          isDate
          fechaInicio
          fechaFin
          notas
          contacto
          idContacto
          trato
          idTrato
          ubicacion
          descripcionUbicacion
          usuario
          idUsuario
          status
          fechaRegistro
          resultado
          prioridad
          statusData {
            _id
            status
            color
          }
          contactoPopulated {
            _id
            correo
            nombre
            campaign
            propietario
            organizacion
            recomendadoPor
            statusContacto
            telefono
            tipoContacto
            etiqueta
            idStatusContacto
            idTipoContacto
            idEtiqueta
            statusContactoPopulated {
              _id
              status
              factor
              color
            }
          }
          tipoActividadPopulated {
            _id
            accion
            icono
            tipoActividad
          }
        }
      }
    }
  }
`;

export const OBTENER_QUERIES_CONTRATO = gql`
  query ObtenerCatalogosTrato($idEmpresa: ID!) {
    obtenerCatalogosTrato(idEmpresa: $idEmpresa) {
      unidades {
        _id
        nombreUnidad
      }
      contactos {
        _id
        nombre
        telefono
      }
      monedas {
        _id
        moneda
      }
      embudos {
        _id
        fase
        posicion
      }
      usuarios {
        _id
        nombreCompleto
        nombreUsuario
      }
      formas {
        _id
        formaAtraccion
      }
      formasContacto {
        _id
        formaContacto
      }
      campaigns {
        _id
        idExterno
        nombre
        redSocial
      }
      productos {
        _id
        nombre
        skuCodigo
      }
      statusContactos {
        _id
        status
        color
      }
    }
  }
`;

export const OBTENER_TRATOS_ARCHIVADOS = gql`
  query ObtenerTratosArchivados(
    $idEmpresa: ID!
    $limit: Int
    $page: Int
    $filter: FiltrosInput
  ) {
    obtenerTratosArchivados(
      idEmpresa: $idEmpresa
      limit: $limit
      page: $page
      filter: $filter
    ) {
      totalPages
      totalDocs
      page
      docs {
        _id
        producto
        idProducto
        unidadNegocio
        fechaTrato
        contacto
        nombreTrato
        moneda
        valor
        formaContacto
        embudo
        interes
        propietario
        status
        campaign
        prioridad
        probabilidadCierre
        fechaEstimadaCierre
        usuarioCierre
        fechaRegistro
        archivado
      }
    }
  }
`;

export const OBTENER_VENTAS = gql`
  query ObtenerVentas(
    $idEmpresa: ID!
    $limit: Int
    $page: Int
    $filter: FiltrosInput
  ) {
    obtenerVentas(
      idEmpresa: $idEmpresa
      limit: $limit
      page: $page
      filter: $filter
    ) {
      totalPages
      totalDocs
      page
      docs {
        _id
        producto
        idProducto
        unidadNegocio
        fechaTrato
        contacto
        nombreTrato
        moneda
        valor
        formaContacto
        embudo
        interes
        propietario
        status
        campaign
        prioridad
        probabilidadCierre
        fechaEstimadaCierre
        usuarioCierre
        fechaRegistro
        archivado
      }
    }
  }
`;
