import React from "react";
import { InputAdornment } from "@mui/material";
import { ReunionContext } from "../Context/ReunionContext";
import { Search } from "@mui/icons-material";
import { CustomSearchTextField } from "../../../../Components/CustomMuiComponents";

export default function SearchReunion() {
  const { filter, setFilter } = React.useContext(ReunionContext);

  return (
    <CustomSearchTextField
      placeholder="Buscar por tratos o contactos"
      size="small"
      variant="outlined"
      value={filter.searchFilter}
      onChange={(e) => setFilter(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
}
