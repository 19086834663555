import { gql } from "@apollo/client";

export const OBTENER_PRODUCTOS = gql`
  query ObtenerProductos($idEmpresa: ID!, $filter: filterInput, $limit: Int, $page: Int) {
    obtenerProductos(idEmpresa: $idEmpresa, filter: $filter, limit: $limit, page: $page) {
      docs {
        _id
        skuCodigo
        nombre
        unidadMedida
        descripcion
        costo
        precio
        promocion
        tipoDescuento
        valorDescuento
        precioFinal
        imagenes {
          imagen
          keyImagen
        }
        categoria
        fechaRegistro
        idEmpresa
      }
      totalDocs
      page
      totalPages
    }
  }
`;
