import { gql } from "@apollo/client";

export const OBTENER_USUARIOS = gql`
  query ObtenerUsuarios($idEmpresa: ID!, $filter: String) {
    obtenerUsuarios(idEmpresa: $idEmpresa, filter: $filter) {
      _id
      nombreCompleto
      nombreUsuario
      tipoUsuario
      idTipoUsuario
      area
      idArea
      fechaIngreso
      fechaRegistro
      correo
      telefono
      ver {
        idUsuario
        nombreCompleto
      }
      whatsappId
      foto
      keyFoto
      comision
      idEmpresa
    }
  }
`;
