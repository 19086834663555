import React from "react";
import NuevoTipoUser from "./Actions/NuevoTipoUser";
import FilterTipoUser from "./Actions/FilterTipoUser";
import TableTipos from "./Table/Table";
import { Stack } from "@mui/material";
import { OBTENER_TIPOS_USUARIO } from "./graphql/queries";
import { useQuery } from "@apollo/client";
import { MainContext } from "../../../../Context/MainCtx";
import { useDebounce } from "use-debounce";

export default function TiposUsuario() {
  const [filter, setFilter] = React.useState("");
  const { tokenDecoded } = React.useContext(MainContext);
  const [FILTER] = useDebounce(filter, 800);

  const tipoQuery = useQuery(OBTENER_TIPOS_USUARIO, {
    variables: {
      filter: FILTER,
      idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
    },
    fetchPolicy: "network-only",
  });

  return (
    <div>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="flex-end"
        alignItems="center"
        sx={{
          mb: 1,
          position: { sm: "absolute" },
          top: 60,
          right: 24,
          width: { xs: "100%", md: "auto" }
        }}
      >
        <FilterTipoUser filter={filter} setFilter={setFilter} />
        <NuevoTipoUser refetch={tipoQuery.refetch} />
      </Stack>
      <TableTipos tipoQuery={tipoQuery} />
    </div>
  );
}
