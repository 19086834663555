import { gql } from "@apollo/client";

export const OBTENER_DATA_DASH = gql`
  query ObtenerDataDashboard(
    $idEmpresa: ID!
    $filter: FiltrosInputDashboard
    $limit: Int
    $page: Int
  ) {
    obtenerDataDashboard(
      idEmpresa: $idEmpresa
      filter: $filter
      limit: $limit
      page: $page
    ) {
      tratos {
        byGroup {
          enTrato
          ganados
          olvidadas
          perdidos
          ventas
        }
        tratos {
          docs {
            _id
            producto
            contacto
            nombreTrato
            campaign
            embudo
            fechaEstimadaCierre
            fechaRegistro
            fechaTrato
            formaContacto
            interes
            moneda
            prioridad
            probabilidadCierre
            propietario
            status
            unidadNegocio
            usuarioCierre
            visiblePara {
              idUsuario
              nombreCompleto
            }
            valor
          }
          page
          totalDocs
          totalPages
        }
        byFase {
          _id
          fase
          posicion
          tratosDashboard {
            byGroup {
              enTrato
              ganados
              olvidadas
              perdidos
              ventas
              total
            }
            tratos {
              _id
              producto
              nombreTrato
              status
              contacto
              campaign
              embudo
              fechaEstimadaCierre
              fechaRegistro
              fechaTrato
              formaContacto
              interes
              moneda
              prioridad
              probabilidadCierre
              propietario
              unidadNegocio
              usuarioCierre
              valor
              visiblePara {
                nombreCompleto
                idUsuario
              }
            }
          }
        }
      }
      prospectos {
        formaAtraccion
        contactos {
          _id
          nombre
          campaign
          correo
          etiqueta
          formaAtraccion
          organizacion
          propietario
          statusContacto
          telefono
          tipoContacto
        }
        _id
      }
    }
  }
`;
