import React from "react";
import { Brightness4 } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { changeTheme } from "../Store/Slices/theming/themingSlice";
import { IconButton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";

export default function DarkMode({ listMode = false }) {
  const theming = useSelector((state) => state.theming.mode);
  const dispatch = useDispatch();

  const handleClickTheme = () => {
    dispatch(changeTheme(theming === "dark" ? "light" : "dark"));
    localStorage.setItem("CRMtheming", theming === "dark" ? "light" : "dark");
  };

  if (listMode) {
    return (
      <MenuItem onClick={handleClickTheme}>
        <ListItemIcon>
          <Brightness4 fontSize="small" />
        </ListItemIcon>
        Tema {theming === "dark" ? "claro" : "oscuro"}
      </MenuItem>
    );
  } else {
    return (
      <IconButton color="inherit" onClick={handleClickTheme}>
        <Brightness4 />
      </IconButton>
    );
  }
}
