import * as React from "react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AvatarMenuComponent from "../../../Components/Avatar/AvatarMenuComponent";
import DarkMode from "../../../Components/DarkMode";
import ComodyHorizontalIcon from "../../../Icons/ComodyHorizontalIcon";
import ComodyIcon from "../../../Icons/ComodyIcon";

export default function NavbarDashboard({ handleDrawerToggle, view }) {
  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar variant="dense">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          edge="start"
          sx={{
            marginRight: 2,
            display: { xs: "none", sm: "flex" },
          }}
        >
          <MenuIcon />
        </IconButton>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
          }}
        >
          <Box display="flex">
            <Box sx={{ display: { xs: "flex", sm: "none" } }}>
              <ComodyIcon
                size={2}
                color="default"
                style={{ marginRight: 10 }}
              />
            </Box>
            <Box sx={{ display: { xs: "none", sm: "flex" } }}>
              <ComodyHorizontalIcon
                size={10}
                color="default"
                style={{ marginRight: 10 }}
              />
            </Box>
            <Typography variant="h6" noWrap component="div">
              {view}
            </Typography>
          </Box>
        </Box>
        <Box />
        <Box sx={{ mx: 1, display: { xs: "none", md: "flex" } }}>
          <DarkMode />
        </Box>
        <AvatarMenuComponent />
      </Toolbar>
    </AppBar>
  );
}
