import React from "react";
import NuevoFormaAtraccion from "./Actions/NuevaForma";
import SearchFormasAtraccion from "./Actions/Search";
import FormasAtraccionTable from "./Table/Table";
import { Stack } from "@mui/material";
import { OBTENER_FORMAS } from "./graphql/queries";
import { useQuery } from "@apollo/client";
import { MainContext } from "../../../../Context/MainCtx";
import { useDebounce } from "use-debounce";

export default function FormasAtraccion() {
  const [filter, setFilter] = React.useState("");
  const { tokenDecoded } = React.useContext(MainContext);
  const [FILTER] = useDebounce(filter, 800);

  const formasQuery = useQuery(OBTENER_FORMAS, {
    variables: {
      filter: FILTER,
      idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
    },
    fetchPolicy: "network-only",
  });

  return (
    <div>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ mb: 1, mt: 2, width: { xs: "100%", md: "50%" } }}
        bgcolor="background.paper"
      >
        <SearchFormasAtraccion filter={filter} setFilter={setFilter} />
        <NuevoFormaAtraccion refetch={formasQuery.refetch} />
      </Stack>
      <FormasAtraccionTable formasQuery={formasQuery} />
    </div>
  );
}
