import { TableContainer, useTheme } from "@mui/material";
import React, { useState } from "react";

const CustomContainer = ({
  children,
  tableRef,
  onScroll,
  onFocusInput,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const theme = useTheme();

  const handleMouseDown = (e) => {
    if (onFocusInput) onFocusInput();
    if (e.target === tableRef.current) {
      setIsDragging(true);
      setStartX(e.pageX - tableRef.current.offsetLeft);
      setScrollLeft(tableRef.current.scrollLeft);
    }
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.pageX - tableRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Ajusta la velocidad de desplazamiento aquí
    tableRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <TableContainer
      ref={tableRef}
      className="specific-scrollable-div"
      sx={{
        display: "flex",
        transition: "width .3s",
        height: { xs: `calc(100vh - 262px)`, sm: `calc(100vh - 170px)` },
        flex: 1,
        overflowX: "auto",
        backgroundColor: theme.palette.customPaper,
      }}
      onScroll={onScroll}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      {children}
    </TableContainer>
  );
};

export default CustomContainer;
