import React from "react";
import NuevaRedSocial from "./Actions/NuevaRedSocial";
import { Stack } from "@mui/material";
//import SearchRedSocial from './Actions/Search'
import RedSocialTable from "./Table/Table";
import { OBTENER_REDES } from "./graphql/queries";
import { useQuery } from "@apollo/client";
import { MainContext } from "../../../../Context/MainCtx";

export default function RedesSociales() {
  const { tokenDecoded } = React.useContext(MainContext);

  const redesQuery = useQuery(OBTENER_REDES, {
    variables: {
      idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
    },
    fetchPolicy: "network-only",
  });

  return (
    <div>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ mb: 1, mt: 2, width: { xs: "100%", md: "50%" } }}
        bgcolor="background.paper"
      >
        {/* <SearchRedSocial /> */}
        <NuevaRedSocial refetch={redesQuery.refetch} />
      </Stack>
      <RedSocialTable redesQuery={redesQuery} />
    </div>
  );
}
