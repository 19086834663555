import React from "react";
import { InputAdornment } from "@mui/material";
import { TratosContext } from "../Context/TratosContext";
import { CustomSearchTextField } from "../../../../Components/CustomMuiComponents";
import { Search } from "@mui/icons-material";

export default function SearchTratos() {
  const { filter, setFilter } = React.useContext(TratosContext);

  const onSearch = (searchFilter) => {
    setFilter((st) => ({
      ...st,
      searchFilter,
    }));
  };

  return (
    <CustomSearchTextField
      placeholder="Buscar por tratos o contactos"
      size="small"
      variant="outlined"
      value={filter.searchFilter}
      onChange={(e) => onSearch(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
}
