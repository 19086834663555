import * as React from "react";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import Groups from "@mui/icons-material/Groups";
import CustomAvatar from "../../../../Components/Avatar/CustomAvatar";

export default function Invitados({ data }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton color="primary" size="small" onClick={handleClickOpen}>
        <Groups />
      </IconButton>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Invitados</DialogTitle>
        <List sx={{ pt: 0 }}>
          {data.invitados_data.map(
            ({ _id, nombreCompleto, correo, foto, telefono }) => (
              <ListItem key={_id}>
                <ListItemAvatar>
                  <CustomAvatar name={nombreCompleto} img={foto}>
                    <PersonIcon />
                  </CustomAvatar>
                </ListItemAvatar>
                <ListItemText
                  primary={nombreCompleto}
                  secondary={`${correo} - ${telefono}`}
                />
              </ListItem>
            )
          )}
        </List>
      </Dialog>
    </div>
  );
}
