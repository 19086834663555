import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Divider from "@mui/material/Divider";
import {
  AccountCircle,
  Campaign,
  CurrencyExchange,
  FilterAltOff,
  Forum,
  Label,
  NotificationImportant,
  NotificationsActive,
  NotificationsNone,
  Store,
} from "@mui/icons-material";
import { ArrowBack, Close, Done, FilterAlt } from "@mui/icons-material";
import { InputAdornment, Stack } from "@mui/material";
import { TratosContext } from "../Context/TratosContext";
import moment from "moment-timezone";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AutoCompleteInput from "../../../../Components/InputsForms/AutocompleteInput";
import { isMobile } from "../../../../Config/constantes";

export default function FilterTrato({ setFilterArchived }) {
  const [open, setOpen] = React.useState(false);
  const [countFilter, setCountFilter] = React.useState(0);
  const { setFilter, initial_filter, queryCats } = React.useContext(
    TratosContext
  );
  const [localFilter, setLocalFilter] = React.useState(initial_filter);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const autocompleteChange = (values) => {
    const { name, value } = values;
    setLocalFilter((st) => ({
      ...st,
      [name]: value,
    }));
  };

  const handleChange = (name, value) => {
    setLocalFilter((st) => ({
      ...st,
      [name]: value,
    }));
  };

  const handleChangeFechas = (name, value) => {
    setLocalFilter((st) => ({
      ...st,
      [name]: moment(value).tz("America/Mexico_City").format(),
    }));
  };

  const handleFilterOk = (e) => {
    e.preventDefault();
    if (setFilterArchived) {
      setFilterArchived((st) => ({
        ...st,
        ...localFilter,
      }));
    } else {
      setFilter((st) => ({
        ...st,
        ...localFilter,
      }));
    }
    const arrValues = Object.values(localFilter);
    let count = 0;
    arrValues.forEach((res) => (res ? count++ : null));
    setCountFilter(count);
    handleClose();
  };

  const clearFilter = () => {
    const { searchFilter, ...initial } = initial_filter;
    setLocalFilter(initial);
    setCountFilter(0);
    handleClose();
    if (setFilterArchived) {
      setFilterArchived((st) => ({
        ...st,
        ...initial,
      }));
      return;
    }
    setFilter((st) => ({
      ...st,
      ...initial,
    }));
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        color="inherit"
        size="small"
        disableElevation
      >
        <Button
          size="small"
          startIcon={
            isMobile ? null : countFilter ? (
              countFilter
            ) : (
              <FilterAlt color="action" />
            )
          }
          onClick={handleOpen}
          sx={{ textTransform: "none", minWidth: 40 }}
        >
          {isMobile ? (
            countFilter ? (
              countFilter
            ) : (
              <FilterAlt fontSize="small" color="action" />
            )
          ) : (
            "Filtrar"
          )}
        </Button>
        {countFilter ? (
          <Button
            size="small"
            onClick={clearFilter}
            disabled={!countFilter}
            sx={{ minWidth: "25px!important", padding: 0 }}
          >
            <Close sx={{ fontSize: 16 }} />
          </Button>
        ) : null}
      </ButtonGroup>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        ModalProps={{
          componentsProps: {
            backdrop: {
              sx: { background: "none" },
            },
          },
        }}
        PaperProps={{
          elevation: 1,
          sx: { width: { xs: "100vw", md: "30vw" } },
        }}
      >
        <Toolbar />
        <DialogTitle sx={{ py: 0 }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton onClick={() => handleClose()}>
              <ArrowBack />
            </IconButton>
            <div style={{ flexGrow: 1 }} />
            <Button
              startIcon={<FilterAltOff />}
              size="small"
              disableElevation
              onClick={clearFilter}
            >
              Limpiar
            </Button>
            <Button
              startIcon={<Done />}
              size="small"
              variant="contained"
              disableElevation
              type="submit"
              form="filter-tratos"
            >
              Filtrar
            </Button>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <form id="filter-tratos" onSubmit={handleFilterOk}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DemoContainer components={["DatePicker"]}>
                <DemoItem label="Fecha Tato">
                  <DatePicker
                    value={moment(localFilter.fechaStart)}
                    onChange={(value) =>
                      handleChangeFechas("fechaStart", value)
                    }
                    slotProps={{
                      textField: {
                        size: "small",
                        InputProps: {
                          startAdornment: (
                            <InputAdornment position="start">
                              De:{" "}
                            </InputAdornment>
                          ),
                        },
                      },
                    }}
                  />
                  <DatePicker
                    value={moment(localFilter.fechaEnd)}
                    onChange={(value) => handleChangeFechas("fechaEnd", value)}
                    slotProps={{
                      textField: {
                        size: "small",
                        InputProps: {
                          startAdornment: (
                            <InputAdornment position="start">
                              A:{" "}
                            </InputAdornment>
                          ),
                        },
                      },
                    }}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
            <Box sx={{ my: 1 }}>
              <Divider />
            </Box>
            <AutoCompleteInput
              renderData={queryCats.data?.formasContacto}
              value={localFilter.formaContacto}
              label="Forma Contacto"
              icon={<Forum />}
              loading={queryCats.loading}
              error={queryCats.error}
              onChange={(_, option) =>
                autocompleteChange({
                  name: "formaContacto",
                  value: option?.formaContacto || "",
                })
              }
              isOptionEqualToValue={({ formaContacto }, value) =>
                formaContacto === value
              }
              getOptionLabel={(option) =>
                option.formaContacto || option || null
              }
            />
            <AutoCompleteInput
              renderData={queryCats.data?.campaigns}
              value={localFilter.campaign}
              label="Campaña"
              icon={<Campaign />}
              loading={queryCats.loading}
              error={queryCats.error}
              onChange={(_, option) =>
                autocompleteChange({
                  name: "campaign",
                  value: option?.nombre || "",
                })
              }
              isOptionEqualToValue={({ nombre }, value) => nombre === value}
              getOptionLabel={(option) => option.nombre || option || null}
            />
            <AutoCompleteInput
              renderData={queryCats.data?.unidades}
              value={localFilter.unidadNegocio}
              label="Unidad de negocio"
              icon={<Store />}
              loading={queryCats.loading}
              error={queryCats.error}
              onChange={(_, option) =>
                autocompleteChange({
                  name: "unidadNegocio",
                  value: option?.nombreUnidad || "",
                })
              }
              isOptionEqualToValue={({ nombreUnidad }, value) =>
                nombreUnidad === value
              }
              getOptionLabel={(option) => option.nombreUnidad || option || null}
            />
            <AutoCompleteInput
              renderData={queryCats.data?.monedas}
              value={localFilter.moneda}
              label="Moneda"
              icon={<CurrencyExchange />}
              loading={queryCats.loading}
              error={queryCats.error}
              onChange={(_, option) =>
                autocompleteChange({
                  name: "moneda",
                  value: option?.moneda || "",
                })
              }
              isOptionEqualToValue={({ moneda }, value) => moneda === value}
              getOptionLabel={(option) => option.moneda || option || null}
            />
            <AutoCompleteInput
              renderData={queryCats.data?.embudos}
              value={localFilter.embudo}
              label="Embudo"
              icon={<Label />}
              loading={queryCats.loading}
              error={queryCats.error}
              onChange={(_, option) =>
                autocompleteChange({
                  name: "embudo",
                  value: option?.fase || "",
                })
              }
              isOptionEqualToValue={({ fase }, value) => fase === value}
              getOptionLabel={(option) => option.fase || option || null}
            />
            <AutoCompleteInput
              renderData={queryCats.data?.usuarios}
              value={localFilter.propietario}
              label="Propietario"
              icon={<AccountCircle />}
              loading={queryCats.loading}
              error={queryCats.error}
              onChange={(_, option) =>
                autocompleteChange({
                  name: "propietario",
                  value: option?.nombreCompleto || "",
                })
              }
              isOptionEqualToValue={({ nombreCompleto }, value) =>
                nombreCompleto === value
              }
              getOptionLabel={(option) =>
                option.nombreCompleto || option || null
              }
            />
            <AutoCompleteInput
              renderData={queryCats.data?.usuarios}
              value={localFilter.usuarioCierre}
              label="Usuario cierre"
              icon={<AccountCircle />}
              loading={queryCats.loading}
              error={queryCats.error}
              onChange={(_, option) =>
                autocompleteChange({
                  name: "usuarioCierre",
                  value: option?.nombreCompleto || "",
                })
              }
              isOptionEqualToValue={({ nombreCompleto }, value) =>
                nombreCompleto === value
              }
              getOptionLabel={(option) =>
                option.nombreCompleto || option || null
              }
            />
            <Box sx={{ my: 1 }}>
              <Typography>Prioridad</Typography>
              <Divider />
              <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
                <ToggleButtonGroup
                  color="primary"
                  name="prioridad"
                  value={localFilter.prioridad}
                  exclusive
                  onChange={(e, value) => handleChange("prioridad", value)}
                  fullWidth
                >
                  <ToggleButton ic value="BAJA">
                    <NotificationsNone sx={{ mr: 1, fontSize: 20 }} />
                    Baja
                  </ToggleButton>
                  <ToggleButton value="MEDIA">
                    <NotificationsActive sx={{ mr: 1, fontSize: 20 }} />
                    Media
                  </ToggleButton>
                  <ToggleButton value="ALTA">
                    <NotificationImportant sx={{ mr: 1, fontSize: 20 }} />
                    Alta
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Box>
            {/* <Box sx={{ my: 1 }}>
              <Typography>Status trato</Typography>
              <Divider />
              <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
                <ToggleButtonGroup
                  color="primary"
                  value={localFilter.status}
                  exclusive
                  name="status"
                  onChange={(e, value) => handleChange("status", value)}
                  fullWidth
                >
                  <ToggleButton ic value={STATUS_TRATO[2]}>
                    <Grade sx={{ mr: 1, fontSize: 20 }} />
                    En trato
                  </ToggleButton>
                  <ToggleButton value={STATUS_TRATO[0]}>
                    <ThumbDown sx={{ mr: 1, fontSize: 20 }} />
                    Perdido
                  </ToggleButton>
                  <ToggleButton value={STATUS_TRATO[1]}>
                    <ThumbUp sx={{ mr: 1, fontSize: 20 }} />
                    Ganado
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Box> */}
          </form>
        </DialogContent>
      </Drawer>
    </>
  );
}
