import * as React from "react";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { StatusContext } from "../Context/StatusActividadContext";
import { Close } from "@mui/icons-material";
import { CustomTableTextField } from "../../../../../Components/CustomMuiComponents";
import { MainContext } from "../../../../../Context/MainCtx";
import {
  ACTUALIZAR_STATUS_ACTIVIDAD,
  CREAR_STATUS_ACTIVIDAD,
} from "../graphql/mutations";
import { useMutation } from "@apollo/client";
import ColorSelect from "./ColorSelect";

export default function EditableRowStatus({ edit = false }) {
  const {
    setNewItem,
    newItem,
    setLoadingSave,
    query,
    loadingSave,
  } = React.useContext(StatusContext);
  const { snackMessage, tokenDecoded } = React.useContext(MainContext);
  const [crearStatusActividad] = useMutation(CREAR_STATUS_ACTIVIDAD);
  const [actualizarStatusActividad] = useMutation(ACTUALIZAR_STATUS_ACTIVIDAD);

  const handleSaveStatus = async (e) => {
    try {
      e.preventDefault();
      if (!newItem.status || !newItem.color) return;
      setLoadingSave(true);
      if (!edit) {
        await crearStatusActividad({
          variables: {
            idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
            input: {
              status: newItem.status,
              color: newItem.color,
            },
          },
        });
      } else {
        await actualizarStatusActividad({
          variables: {
            idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
            idStatus: newItem._id,
            input: {
              status: newItem.status,
              color: newItem.color,
            },
          },
        });
      }
      query.refetch();
      setLoadingSave(false);
      setNewItem(null);
    } catch (err) {
      console.log(err.networkError?.result);
      setLoadingSave(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  const handleChange = (status) => {
    setNewItem((st) => ({ ...st, status }));
  };

  const handleRemove = () => {
    setNewItem(null);
  };

  return (
    <TableRow role="checkbox" tabIndex={-1}>
      <TableCell padding="checkbox" />
      <TableCell width={250}>
        <form
          id="form-statusActividad-table"
          onSubmit={(e) => handleSaveStatus(e)}
        >
          <CustomTableTextField
            variant="outlined"
            size="small"
            fullWidth
            value={newItem.status}
            onChange={(e) => handleChange(e.target.value)}
            autoFocus
            disabled={newItem.static || loadingSave}
          />
        </form>
      </TableCell>
      <TableCell>
        <ColorSelect />
      </TableCell>
      <TableCell padding="checkbox">
        <IconButton onClick={() => handleRemove()}>
          <Close />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
