import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Add, ArrowBack, Done, Edit } from "@mui/icons-material";
import { MainContext } from "../../../../../Context/MainCtx";
import { ACTUALIZAR_CAMPAIGN, CREAR_CAMPAIGN } from "../graphql/mutations";
import { useMutation } from "@apollo/client";
import { CircularProgress, InputAdornment } from "@mui/material";
import GetRedesSociales from "./GetRedesSociales";
import { isMobile } from "../../../../../Config/constantes";

const initial_state = {
  nombre: "",
  fechaInicio: "",
  fechaFin: "",
  redSocial: "",
  idRedSocial: "",
  presupuesto: "",
};

export default function NuevaCampaign({
  edit,
  data,
  refetch,
  external = false,
  refetchExternal = null,
}) {
  const [open, setOpen] = React.useState(false);
  const [campData, setCampData] = React.useState(initial_state);
  const [loading, setLoading] = React.useState(false);
  const { snackMessage, tokenDecoded } = React.useContext(MainContext);
  const [crearCampaign] = useMutation(CREAR_CAMPAIGN);
  const [actualizarCampaign] = useMutation(ACTUALIZAR_CAMPAIGN);

  const handleOpen = () => {
    setOpen(true);
    if (edit) {
      const { _id, idEmpresa, fechaRegistro, ...newData } = data;
      setCampData(newData);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setCampData(initial_state);
  };

  const handleChangeCampaign = (e) => {
    const { name, value } = e.target;
    setCampData((st) => ({ ...st, [name]: value }));
  };

  const handleSaveCampaign = async (e) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      const input = { ...campData };
      setLoading(true);
      if (!edit) {
        //si es nuevo
        const res = await crearCampaign({
          variables: {
            idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
            input,
          },
        });
        snackMessage({
          message: res.data.crearCampaign.message,
          variant: "success",
        });
      } else {
        //si va a actualizar
        const res = await actualizarCampaign({
          variables: {
            idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
            idCampaign: data._id,
            input,
          },
        });
        snackMessage({
          message: res.data.actualizarCampaign.message,
          variant: "success",
        });
      }
      if (external && refetchExternal) refetchExternal();
      if (refetch) refetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log(err.networkError?.result);
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      {edit ? (
        <IconButton onClick={handleOpen}>
          {loading ? <CircularProgress size={20} color="inherit" /> : <Edit />}
        </IconButton>
      ) : external ? (
        <IconButton onClick={handleOpen} size="small" color="primary">
          <Add />
        </IconButton>
      ) : (
        <Button
          startIcon={isMobile ? null : <Add />}
          color="primary"
          variant="contained"
          onClick={handleOpen}
          size="small"
          disableElevation
          sx={{ textTransform: "none", minWidth: 40 }}
        >
          {isMobile ? <Add fontSize="small" /> : "Nuevo"}
        </Button>
      )}
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        ModalProps={{
          componentsProps: {
            backdrop: {
              sx: { background: "none" },
            },
          },
        }}
        PaperProps={{
          elevation: 1,
          sx: { width: { xs: "100vw", md: "40vw" } },
        }}
      >
        <Toolbar variant="dense" />
        <DialogTitle>
          <Stack spacing={1} direction="row" alignItems="center">
            <IconButton onClick={() => handleClose()}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h6">
              {edit ? `Editar` : "Nueva"}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              startIcon={
                loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <Done />
                )
              }
              variant="contained"
              disableElevation
              sx={{ textTransform: "none" }}
              size="small"
              type="submit"
              form="campaing-form"
            >
              Guardar
            </Button>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ py: 0 }}>
          <form id="campaing-form" onSubmit={handleSaveCampaign}>
            <TextField
              label="ID Campaña"
              name="idExterno"
              size="small"
              variant="outlined"
              margin="dense"
              fullWidth
              onChange={handleChangeCampaign}
              value={campData.idExterno}
              required
            />
            <TextField
              label="Nombre campaña"
              name="nombre"
              size="small"
              variant="outlined"
              margin="dense"
              fullWidth
              onChange={handleChangeCampaign}
              value={campData.nombre}
              required
            />
            <TextField
              type="date"
              name="fechaInicio"
              size="small"
              variant="outlined"
              margin="dense"
              fullWidth
              onChange={handleChangeCampaign}
              value={campData.fechaInicio}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    Fecha inicio:
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              type="date"
              name="fechaFin"
              size="small"
              variant="outlined"
              margin="dense"
              fullWidth
              onChange={handleChangeCampaign}
              value={campData.fechaFin}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Fecha fin:</InputAdornment>
                ),
              }}
            />
            <TextField
              label="Presupuesto"
              name="presupuesto"
              type="number"
              size="small"
              variant="outlined"
              margin="dense"
              fullWidth
              onChange={handleChangeCampaign}
              value={campData.presupuesto}
            />
            <GetRedesSociales campData={campData} setCampData={setCampData} />
          </form>
        </DialogContent>
      </Drawer>
    </div>
  );
}
