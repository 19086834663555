import React from "react";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { Delete } from "@mui/icons-material";
import { ELIMINAR_STATUS_ACTIVIDADES } from "../graphql/mutations";
import { useMutation } from "@apollo/client";
import { MainContext } from "../../../../../Context/MainCtx";
import { StatusContext } from "../Context/StatusActividadContext";
import { isMobile } from "../../../../../Config/constantes";

export default function EliminarStatusActividad() {
  const [loading, setLoading] = React.useState(false);
  const { snackMessage } = React.useContext(MainContext);
  const {
    query,
    itemsSelected,
    setItemsSelected,
    setIndeterminated,
  } = React.useContext(StatusContext);
  const [eliminarStatusActividades] = useMutation(ELIMINAR_STATUS_ACTIVIDADES);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "popover-delete-statusActividad" : undefined;

  const handleDeleteStatus = async () => {
    try {
      if (!itemsSelected.length) return;
      const statusToDelete = itemsSelected.map(({ _id }) => (_id));
      handleClose();
      setLoading(true);
      await eliminarStatusActividades({
        variables: {
          status: statusToDelete,
        },
      });
      query.refetch();
      setItemsSelected([]);
      setIndeterminated(false);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <>
      <Button
        color="warning"
        size="small"
        variant="contained"
        startIcon={
          isMobile ? null : loading ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <Delete />
          )
        }
        disableElevation
        disabled={!itemsSelected.length}
        onClick={handleClick}
        sx={{ textTransform: "none", minWidth: 40 }}
      >
        {isMobile ? (
            loading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              <Delete fontSize="small" />
            )
          ) : (
            "Eliminar"
          )}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              p: 1,
            },
          },
        }}
      >
        <Typography>¿Continuar con la acción?</Typography>
        <Button
          sx={{ textTransform: "none" }}
          color="error"
          size="small"
          onClick={handleDeleteStatus }
          variant="contained"
          fullWidth
          disableElevation
        >
          Si, eliminar
        </Button>
      </Popover>
    </>
  );
}
