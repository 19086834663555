import { createBrowserRouter } from "react-router-dom";
import NoMatch from "../Components/NoMatch/NoMatch";
import ChatIndex from "../Views/Dashboard/Chat";
import LoginUser from "../Views/Login";
import DashboardIndex from "../Views/Dashboard";
import Tratos from "../Views/Dashboard/Tratos";
import Actividades from "../Views/Dashboard/Actividades";
import Contactos from "../Views/Dashboard/Contactos/Contactos";
import TiposContacto from "../Views/Dashboard/Contactos/TipoContacto";
import Reuniones from "../Views/Dashboard/Reuniones";
import ContactoIndex from "../Views/Dashboard/Contactos";
import Ventas from "../Views/Dashboard/Ventas";
import ConfiguracionesIndex from "../Views/Dashboard/Configuraciones";
import Dashboard from "../Views/Dashboard/Dashboard";
import FormasAtraccion from "../Views/Dashboard/Configuraciones/FormasAtraccion";
import FormasContacto from "../Views/Dashboard/Configuraciones/FormasContacto";
import Embudos from "../Views/Dashboard/Configuraciones/Embudos";
import Monedas from "../Views/Dashboard/Configuraciones/Monedas";
import Productos from "../Views/Dashboard/Configuraciones/Productos";
import RedesSociales from "../Views/Dashboard/Configuraciones/RedesSociales";
import Campaigns from "../Views/Dashboard/Configuraciones/Campaigns";
import TiposActividad from "../Views/Dashboard/Configuraciones/TiposActividades";
import UnidadesNegocio from "../Views/Dashboard/Configuraciones/UnidadesNegocios";
import Usuarios from "../Views/Dashboard/Configuraciones/Usuarios";
import Areas from "../Views/Dashboard/Configuraciones/Areas";
import StatusActividad from "../Views/Dashboard/Configuraciones/StatusActividad";
import TiposUsuario from "../Views/Dashboard/Configuraciones/TiposUsuario";
import StatusContacto from "../Views/Dashboard/Contactos/StatusContacto";
import EtiquetasContacto from "../Views/Dashboard/Contactos/Etiquetas";


export const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginUser />,
    errorElement: <NoMatch />,
  },
  {
    path: "/",
    element: <DashboardIndex />,
    errorElement: <NoMatch />,
    children: [
      {
        path: "chat",
        element: <ChatIndex />,
      },
      {
        path: "contactos",
        element: <ContactoIndex />,
      },
      {
        path: "tratos",
        element: <Tratos />,
      },
      {
        path: "actividades",
        element: <Actividades />,
      },
      {
        path: "contactos/lista",
        element: <Contactos />,
      },
      {
        path: "contactos/tipos",
        element: <TiposContacto />,
      },
      {
        path: "contactos/status",
        element: <StatusContacto />,
      },
      {
        path: "contactos/etiquetas",
        element: <EtiquetasContacto />,
      },
      {
        path: "reuniones",
        element: <Reuniones />,
      },
      {
        path: "ventas",
        element: <Ventas />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "settings",
        element: <ConfiguracionesIndex />,
      },
      {
        path: "settings/productos",
        element: <Productos />,
      },
      {
        path: "settings/areas",
        element: <Areas />,
      },
      {
        path: "settings/status-actividad",
        element: <StatusActividad />,
      },
      {
        path: "settings/embudos",
        element: <Embudos />,
      },
      {
        path: "settings/forma-atraccion",
        element: <FormasAtraccion />,
      },
      {
        path: "settings/forma-contacto",
        element: <FormasContacto />,
      },
      {
        path: "settings/monedas",
        element: <Monedas />,
      },
      {
        path: "settings/redes-sociales",
        element: <RedesSociales />,
      },
      {
        path: "settings/campaigns",
        element: <Campaigns />,
      },
      {
        path: "settings/tipo-actividades",
        element: <TiposActividad />,
      },
      {
        path: "settings/unidades-negocio",
        element: <UnidadesNegocio />,
      },
      {
        path: "settings/tipos_usuario",
        element: <TiposUsuario />,
      },
      {
        path: "settings/usuarios",
        element: <Usuarios />,
      },
    ],
  },
]);
