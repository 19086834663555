import * as React from "react";
import IconButton from "@mui/material/IconButton";
import DriveFileMoveOutlined from "@mui/icons-material/DriveFileMoveOutlined";
import { Event, Handshake } from "@mui/icons-material";
import SelectingOption from "./Seleccting";
import EliminarContactoOption from "./EliminarOption";
import { Box, Stack } from "@mui/material";

export default function MenuOptionsContacto({ contacto, handleSelect }) {
  return (
    <Box sx={{ mx: 1, py: 1, borderTop: "1px solid", borderColor: "divider" }}>
      <Stack direction="row" justifyContent="space-evenly">
        <SelectingOption contacto={contacto} handleSelect={handleSelect} />
        <IconButton size="small">
          <Handshake fontSize="small" />
        </IconButton>
        <IconButton size="small">
          <Event fontSize="small" />
        </IconButton>
        <IconButton size="small">
          <DriveFileMoveOutlined fontSize="small" />
        </IconButton>
        <EliminarContactoOption contacto={contacto} />
      </Stack>
    </Box>
  );
}
