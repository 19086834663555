import React from "react";
import Stack from "@mui/material/Stack";
import { Box, Typography } from "@mui/material";
import { getContrastColor } from "../../../Config/reuserFunctions";
import { useQuery } from "@apollo/client";
import { OBTENER_STATUS_GROUPED } from "./graphql/queries";
import { MainContext } from "../../../Context/MainCtx";

export default function ColorIndicatorStatus() {
  const { tokenDecoded } = React.useContext(MainContext);
  const {loading, data, error} = useQuery(OBTENER_STATUS_GROUPED, {
    variables: {
      idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
    },
    fetchPolicy: "network-only",
  });

  if (!data || loading || error) return null;

  return (
    <Stack direction="row" /* justifyContent="center" */ spacing={2} sx={{py: 1}}>
      {data?.getStatusGroup.map(({ status, color, total  }) => (
        <Box key={`indicator-${status}`} sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: color,
              height: 13,
              width: 13,
              m: 0.5,
              borderRadius: 1,
              color: getContrastColor(color),
            }}
          >
            {/* <Typography variant="caption"><b>{total}</b></Typography> */}
          </Box>
          <Typography variant="caption">{status}S</Typography>
        </Box>
      ))}
    </Stack>
  );
}
