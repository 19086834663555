import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CustomLoader from "../../../../../Components/CustomLoader";
import ErrorMessage from "../../../../../Components/ErrorMessage";
import EliminarEmbudo from "./EliminarEmbudo";
import NuevoProducto from "../Actions/NuevoEmbudo";
import { DragIndicator, PlaylistAddCheck } from "@mui/icons-material";
import { CircularProgress, IconButton, useTheme } from "@mui/material";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { MainContext } from "../../../../../Context/MainCtx";
import { useMutation } from "@apollo/client";
import { GUARDAR_ORDEN } from "../graphql/mutations";
import { StrictModeDroppable } from "../../../../../Config/StrictModeDroppable";
import { getBicolorTable } from "../../../../../Config/reuserFunctions";

export default function EmbudosTable({ embudoQuery }) {
  const { loading, data, error, refetch } = embudoQuery;
  const [fases, setFases] = React.useState([]);
  const [loadingOrden, setLoadingOrden] = React.useState(false);
  const { snackMessage } = React.useContext(MainContext);
  const [ordenEmbudo] = useMutation(GUARDAR_ORDEN);
  const theme = useTheme();

  React.useEffect(() => {
    if (data) setFases(data.obtenerEmbudo);
  }, [data]);

  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage />;

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newItems = [...fases];
    const [movedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, movedItem);
    setFases(newItems);
  };

  const handleSaveOrder = async () => {
    try {
      setLoadingOrden(true);
      const res = await ordenEmbudo({
        variables: {
          ordenArray: fases,
        },
      });
      snackMessage({
        message: res.data.ordenEmbudo.message,
        variant: "success",
      });
      setLoadingOrden(false);
    } catch (err) {
      console.log(err);
      console.log(err.networkError?.result);
      setLoadingOrden(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <Paper variant="outlined">
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="droppable-fases" direction="vertical">
          {(provided) => (
            <TableContainer
              sx={{
                height: {
                  xs: `calc(100vh - 232px)`,
                  sm: `calc(100vh - 124px)`,
                },
              }}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <IconButton color="primary" onClick={handleSaveOrder}>
                        {loadingOrden ? (
                          <CircularProgress />
                        ) : (
                          <PlaylistAddCheck />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell padding="checkbox">Orden</TableCell>
                    <TableCell>Fase del embudo</TableCell>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fases.map((res, index) => (
                    <Draggable
                      key={res._id}
                      draggableId={res._id}
                      index={index}
                    >
                      {(provided) => (
                        <TableRow
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <TableCell
                            style={getBicolorTable(theme, index)}
                            padding="checkbox"
                            sx={{px: 0}}
                          >
                            <IconButton {...provided.dragHandleProps}>
                              <DragIndicator />
                            </IconButton>
                          </TableCell>
                          <TableCell
                            style={getBicolorTable(theme, index)}
                            align="center"
                            padding="checkbox"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            style={getBicolorTable(theme, index)}
                            width={"100%"}
                          >
                            {res.fase}
                          </TableCell>
                          <TableCell
                            style={getBicolorTable(theme, index)}
                            padding="checkbox"
                          >
                            <NuevoProducto
                              edit={true}
                              data={res}
                              refetch={refetch}
                            />
                          </TableCell>
                          <TableCell
                            style={getBicolorTable(theme, index)}
                            padding="checkbox"
                          >
                            <EliminarEmbudo data={res} refetch={refetch} />
                          </TableCell>
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </Paper>
  );
}
