export const iconsMui = [
  "AddIcCall",
  "Call",
  "CallEnd",
  "CallMade",
  "CallReceived",
  "Groups",
  "Message",
  "Email",
  "ContactPhone",
  "History",
  "Close",
  "AccessTime",
  "PersonPin",
  "PersonSearch",
  "Face",
  "Face3",
  "Person",
  "Person2",
  "InsertEmoticon",
  "CalendarMonth",
  "CalendarToday",
  "EventRepeat",
  "Today",
  "TipsAndUpdates",
  "Place",
  "PushPin",
  "AlternateEmail",
  "AttachEmail",
  "LocalDining",
  "Restaurant",
  "Cake",
  "DocumentScanner",
  "Article",
  "Grading",
  "Topic",
  "AssignmentTurnedIn",
  "Payment",
  "Paid",
  "Receipt",
  "AttachMoney",
  "LocalAtm",
  "LocalMall",
  "PriceChange",
  "PriceCheck",
  "Sell",
  "RequestQuote",
  "Done",
  "DoneAll",
  "CheckCircle",
  "PublishedWithChanges",
  "HowToReg",
  "EditAttributes",
  "EventAvailable",
  "CreditScore",
  "Verified",
  "Error",
  "Warning",
  "SmsFailed",
  "DoDisturbOn",
  "DoDisturb",
  "PhonelinkErase",
  "CircleNotifications",
  "Notifications",
  "NotificationsActive",
  "NotificationsOff",
  "Campaign",
  "Snooze",
];
