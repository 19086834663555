import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import { ArrowBack } from "@mui/icons-material";
import { ContactosContext } from "../Context/ContactoContext";
import EliminarContacto from "../Actions/EliminarContacto";
import ContactoEdit from "./ContactoEdit";
import ActividadesContactoDetailIndex from "./Actividades";
import TratosContactoDetailIndex from "./Tratos";

export default function ContactoDetails({ data, children, externalClose }) {
  const { setContacto, initial_state_contacto } = React.useContext(
    ContactosContext
  );
  const [open, setOpen] = React.useState(false);

  const handleOpenDetail = (e) => {
    e.stopPropagation();
    setOpen(true);
    setContacto(data);
  };

  const handleClose = (event) => {
    const { type, key } = event;
    if (type === "keydown" && (key === "Tab" || key === "Shift")) return;
    setOpen(false);
    setContacto(initial_state_contacto);
    if (externalClose) externalClose(event);
  };

  return (
    <div onClick={handleOpenDetail}>
      {children}
      <Drawer
        anchor={"right"}
        open={open}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
        ModalProps={{
          componentsProps: {
            backdrop: {
              sx: { background: "none" },
            },
          },
        }}
        PaperProps={{
          elevation: 1,
          sx: { width: { xs: "100vw", md: "60vw" }, overflowY: "auto" },
        }}
      >
        <Toolbar variant="dense" />
        <DialogTitle component="div">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <IconButton onClick={handleClose}>
                <ArrowBack />
              </IconButton>
              <Typography variant="h6" color="gray">
                Detalles Contacto
              </Typography>
            </Stack>
            <EliminarContacto data={data} handleCloseDetail={handleClose} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <ContactoEdit />
          <br />
          <ActividadesContactoDetailIndex />
          <br />
          <TratosContactoDetailIndex />
        </DialogContent>
      </Drawer>
    </div>
  );
}
