export const STATUS_TRATO = [
  "PERDIDO",
  "GANADO",
  "EN TRATO",
  "TIEMPO EXCEDIDO",
];

export const ACCIONES_STATUS_ACTIVIDADES = ["DONE", "EXPIRED", "NO_ACTION"];

export const SOCIAL_NETWORKS = [
  { name: "FACEBOOK", color: "#4267B2", icon: "facebook" },
  { name: "YOUTUBE", color: "#FF0000", icon: "youtube" },
  { name: "WHATSAPP", color: "#25D366", icon: "whatsapp" },
  { name: "INSTAGRAM", color: "#E1306C", icon: "instagram" },
  { name: "TIKTOK", color: "#000000", icon: "tiktok" },
  { name: "WECHAT", color: "#09B83E", icon: "" },
  { name: "FACEBOOK MESSENGER", color: "#00B2FF", icon: "facebook" },
  { name: "SNAPCHAT", color: "#FFFC00", icon: "snapchat" },
  { name: "TELEGRAM", color: "##229ED9", icon: "telegram" },
  { name: "KWAI", color: "#FFAC1C", icon: "" },
  { name: "TWITTER", color: "#1DA1F2", icon: "twitter" },
  { name: "PINTEREST", color: "#E60023", icon: "pinterest" },
];

export const isMobile = window.matchMedia("only screen and (max-width: 932px)")
  .matches; //768px
