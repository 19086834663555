import { gql } from "@apollo/client";

export const CREAR_REUNION = gql`
  mutation CrearReunion($idEmpresa: ID!, $input: crearReunionInput) {
    crearReunion(idEmpresa: $idEmpresa, input: $input) {
      message
    }
  }
`;
export const ACTUALIZAR_REUNION = gql`
  mutation ActualizarReunion($idReunion: ID!, $input: crearReunionInput) {
    actualizarReunion(idReunion: $idReunion, input: $input) {
      message
    }
  }
`;
export const ELIMINAR_REUNION = gql`
  mutation EliminarReunion($idReunion: ID!) {
    eliminarReunion(idReunion: $idReunion) {
      message
    }
  }
`;
