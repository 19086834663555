import { gql } from "@apollo/client";

export const CREAR_EMBUDO = gql`
  mutation CrearEmbudo($idEmpresa: ID!, $fase: String!) {
    crearEmbudo(idEmpresa: $idEmpresa, fase: $fase) {
      message
    }
  }
`;
export const ACTUALIZAR_EMBUDO = gql`
  mutation ActualizarEmbudo($idEmpresa: ID!, $idEmbudo: ID!, $fase: String!) {
    actualizarEmbudo(idEmpresa: $idEmpresa, idEmbudo: $idEmbudo, fase: $fase) {
      message
    }
  }
`;
export const GUARDAR_ORDEN = gql`
  mutation OrdenEmbudo($ordenArray: [ordenArray]) {
    ordenEmbudo(ordenArray: $ordenArray) {
      message
    }
  }
`;
export const ELIMINAR_EMBUDO = gql`
  mutation EliminarEmbudo($idEmpresa: ID!, $idEmbudo: ID!) {
    eliminarEmbudo(idEmpresa: $idEmpresa, idEmbudo: $idEmbudo) {
      message
    }
  }
`;
