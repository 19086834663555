import React, { createContext } from "react";
import { MainContext } from "../../../../../Context/MainCtx";
import { useDebounce } from "use-debounce";
import { useMutation, useQuery } from "@apollo/client";
import {
  OBTENER_CATALOGOS,
  OBTENER_CONTACTOS_DASHBOARD,
} from "../graphql/queries";
import { ACTUALIZAR_CONTACTO, CREAR_CONTACTO } from "../graphql/mutations";

export const ContactosContext = createContext();

const initial_state_contacto = {
  new: true,
  nombre: "",
  organizacion: "",
  telefono: "",
  correo: "",
  recomendadoPor: "",
  tipoContacto: "",
  idTipoContacto: "",
  etiqueta: "",
  idEtiqueta: "",
  statusContacto: "",
  idStatusContacto: "",
  propietario: "",
  idPropietario: "",
  formaAtraccion: "",
  campaign: "",
  customFields: [],
};

const initial_query = {
  data: undefined,
  loading: true,
  error: undefined,
};

const ContactosCtxProvider = ({ children }) => {
  const { tokenDecoded, snackMessage } = React.useContext(MainContext);
  const [filter, setFilter] = React.useState("");
  const [contacto, setContacto] = React.useState(initial_state_contacto);
  const [tipos, setTipos] = React.useState([]);
  const [contactos, setContactos] = React.useState([]);
  const [queryCats, setQueryCats] = React.useState(initial_query);
  const [mode, setMode] = React.useState("board");
  const [isSelecting, setIsSelecting] = React.useState(false);
  const [itemsSelected, setItemsSelected] = React.useState([]);
  const [loadingSave, setLoadingSave] = React.useState(false);
  const [newItem, setNewItem] = React.useState(null);
  const [indeterminated, setIndeterminated] = React.useState(false);
  const [FILTER] = useDebounce(filter, 500);
  const tableEl = React.useRef(null);
  const [crearContacto] = useMutation(CREAR_CONTACTO);
  const [actualizarContacto] = useMutation(ACTUALIZAR_CONTACTO);

  const contactosQuery = useQuery(OBTENER_CONTACTOS_DASHBOARD, {
    variables: {
      filter: FILTER,
      idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
    },
    fetchPolicy: "network-only",
  });

  const catalogos = useQuery(OBTENER_CATALOGOS, {
    variables: {
      idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
    },
    fetchPolicy: "network-only",
  });

  const { data, loading, error, refetch } = contactosQuery;

  React.useEffect(() => {
    if (data) {
      setTipos(data.obtenerContactosDashboard.byTipo);
      setContactos(data.obtenerContactosDashboard.contactos);
    }
  }, [data]);

  React.useEffect(() => {
    if (catalogos.data) {
      setQueryCats({
        data: catalogos.data.obtenerCatalogos,
        loading: catalogos.loading,
        error: catalogos.error,
      });
    }
  }, [catalogos]);

  const handleSaveContacto = async (e) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      if (loading) return;
      setLoadingSave(true);
      let {
        tratos,
        actividades,
        fechaRegistro,
        idEmpresa,
        statusContactoPopulated,
        _id,
        ...input
      } = newItem;
      if (newItem.new) {
        delete input.new;
        await crearContacto({
          variables: {
            idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
            input,
          },
        });
      } else {
        await actualizarContacto({
          variables: {
            idContacto: _id,
            input,
          },
        });
      }
      refetch();
      setNewItem(null)
      setLoadingSave(false);
    } catch (err) {
      console.log(err);
      setLoadingSave(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <ContactosContext.Provider
      value={{
        filter,
        setFilter,
        queryData: data,
        queryLoading: loading,
        queryError: error,
        queryRefetch: refetch,
        contacto,
        setContacto,
        tipos,
        setTipos,
        contactos,
        setContactos,
        initial_state_contacto,
        queryCats,
        mode,
        setMode,
        isSelecting,
        setIsSelecting,
        itemsSelected,
        setItemsSelected,
        newItem,
        setNewItem,
        loadingSave,
        setLoadingSave,
        indeterminated,
        setIndeterminated,
        tableEl,
        handleSaveContacto,
      }}
    >
      {children}
    </ContactosContext.Provider>
  );
};

export default ContactosCtxProvider;
