import React from "react";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { Delete } from "@mui/icons-material";
import { useMutation } from "@apollo/client";
import { MainContext } from "../../../../Context/MainCtx";
import { ActividadContext } from "../Context/ActividadContext";
import { ELIMINAR_ACTIVIDADES } from "../graphql/mutations";
import { isMobile } from "../../../../Config/constantes";

export default function DeleteManyActividades({ isTable = false }) {
  const [loading, setLoading] = React.useState(false);
  const { snackMessage } = React.useContext(MainContext);
  const {
    mode,
    queryRefetch,
    itemsSelected,
    setItemsSelected,
    setIndeterminated,
  } = React.useContext(ActividadContext);
  const [eliminarActividades] = useMutation(ELIMINAR_ACTIVIDADES);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "popover-delete-actividad" : undefined;

  const handleDeleteActividades = async () => {
    try {
      if (!itemsSelected.length) return;
      const actividadesToDelete = itemsSelected.map(({ _id }) => _id);
      handleClose();
      setLoading(true);
      await eliminarActividades({
        variables: {
          actividadesToDelete,
        },
      });
      queryRefetch();
      setLoading(false);
      setItemsSelected([]);
      setIndeterminated(false);
    } catch (err) {
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  if (mode === "calendar" && !isTable) return null;

  return (
    <>
      <Button
        color="warning"
        size="small"
        variant="contained"
        startIcon={
          isMobile ? null : loading ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <Delete />
          )
        }
        disableElevation
        disabled={!itemsSelected.length}
        onClick={handleClick}
        sx={{ textTransform: "none", minWidth: 40 }}
      >
        {isMobile ? (
          loading ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <Delete fontSize="small" />
          )
        ) : (
          "Eliminar"
        )}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              p: 1,
            },
          },
        }}
      >
        <Typography>¿Continuar con la acción?</Typography>
        <Button
          sx={{ textTransform: "none" }}
          color="error"
          size="small"
          onClick={handleDeleteActividades}
          variant="contained"
          fullWidth
          disableElevation
        >
          Si, eliminar
        </Button>
      </Popover>
    </>
  );
}
