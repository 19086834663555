import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { TratosContext } from "../../../Context/TratosContext";
import NuevaActividad from "../../../../Actividades/Actions/NuevaActividad/index";
import ExtCompActForm from "../../../../Actividades/Actions/ExtCompActForm";

export default function ActividadesTratoDetail() {
  const { trato, setTrato, queryRefetch } = React.useContext(TratosContext);

  const setToArray = (data) => {
    if (!data) return;
    const actividades = [...trato.actividades];
    actividades.splice(0, 0, data);
    setTrato({ ...trato, actividades });
  };

  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <Typography variant="h6" gutterBottom component="div" sx={{ mr: 2 }}>
          Actividades
        </Typography>
        <NuevaActividad
          external={true}
          tratoData={trato}
          refetchExternal={queryRefetch}
          setToArray={setToArray}
        />
      </Box>
      {!trato?.actividades?.length ? (
        <Typography align="center" color="grey" sx={{ py: 5 }}>
          No Hay Actividades
        </Typography>
      ) : (
        trato.actividades.map((actividad) => (
          <ExtCompActForm
            data={actividad}
            refetch={queryRefetch}
            key={actividad._id}
          />
        ))
      )}
    </Box>
  );
}
