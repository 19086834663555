import * as React from "react";
import moment from "moment-timezone";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

export default function FechaInputTable({
  id = null,
  name = "",
  value = "",
  setState = null,
  disabled = false,
  sx = null,
  hidden = false,
}) {
  const handleChange = (val) => {
    setState((st) => ({
      ...st,
      [name]: moment(val).tz("America/Mexico_City").format(),
    }));
  };

  return (
    <div hidden={hidden}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DemoContainer
          components={["DateTimePicker"]}
          sx={{ padding: 0, overflow: "none" }}
        >
          <DemoItem>
            <DateTimePicker
              id={id}
              value={moment(value)}
              onChange={(val) => handleChange(val)}
              disabled={disabled}
              slotProps={{
                textField: {
                  size: "small",
                  sx: {
                    ...sx,
                    "& .MuiOutlinedInput-root": {
                      fontSize: 14,
                      "& .MuiInputBase-input": {
                        padding: "2px 0px 2px 4px",
                      },
                      "& fieldset": {
                        borderColor: "#F3F3F3",
                      },
                      "&:hover fieldset": {
                        borderColor: "#F3F3F3",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1px solid #F3F3F3",
                      },
                    },
                  },
                },
                inputAdornment: {
                  sx: {m: 0}
                },
                openPickerButton: {
                  size: 'small',
                  sx: { p: 0.3}
                },
                openPickerIcon: {
                  sx: { fontSize: 18}
                }
              }}
            />
          </DemoItem>
        </DemoContainer>
      </LocalizationProvider>
    </div>
  );
}
