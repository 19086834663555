import React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import CircularProgress from "@mui/material/CircularProgress";
import { Add, Close, Done } from "@mui/icons-material";
import { ContactosContext } from "../Context/ContactoContext";
import NuevoContacto from "./NuevoContacto";
import { MainContext } from "../../../../../Context/MainCtx";
import { isMobile } from "../../../../../Config/constantes";

export default function AddNewItem() {
  const {
    refetchQuery,
    initial_state_contacto,
    setNewItem,
    newItem,
    loadingSave,
    mode,
  } = React.useContext(ContactosContext);
  const { tokenDecoded } = React.useContext(MainContext);

  const handleRemove = () => {
    setNewItem(null);
  };

  const handleSetNewItem = () => {
    setNewItem({
      ...initial_state_contacto,
      propietario: tokenDecoded.nombreCompleto,
      idPropietario: tokenDecoded._id,
    });
  };

  React.useEffect(() => {
    window.addEventListener("keydown", KeyDownFunction);
    function KeyDownFunction(e) {
      const tecla_escape = e.keyCode;
      if (tecla_escape === 27 && mode !== "board") {
        if (!newItem) {
          setNewItem(initial_state_contacto);
        } else {
          setNewItem(null);
        }
      }
    }
    return () => window.removeEventListener("keydown", KeyDownFunction);
  }, [newItem, initial_state_contacto, setNewItem, mode]);

  return !newItem ? (
    mode === "board" ? (
      <NuevoContacto refetch={refetchQuery} />
    ) : (
      <Button
        color="primary"
        size="small"
        variant="contained"
        startIcon={ isMobile ? null : <Add />}
        disableElevation
        onClick={() => handleSetNewItem()}
        sx={{ textTransform: "none", minWidth: 40 }}
      >
        {isMobile ? <Add fontSize="small" /> : "Nuevo"}
      </Button>
    )
  ) : (
    <ButtonGroup
      variant="contained"
      color="primary"
      size="small"
      disableElevation
    >
      <Button
        color="primary"
        size="small"
        variant="contained"
        startIcon={
          loadingSave ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <Done />
          )
        }
        disableElevation
        form="form-contacto-table"
        type="submit"
        sx={{ textTransform: "none" }}
      >
        Guardar
      </Button>
      <Button
        size="small"
        onClick={handleRemove}
        sx={{ minWidth: "35px!important", padding: 0 }}
      >
        <Close sx={{ fontSize: 16 }} />
      </Button>
    </ButtonGroup>
  );
}
