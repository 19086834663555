import * as React from "react";
import LibraryAddCheckOutlined from "@mui/icons-material/LibraryAddCheckOutlined";
import { ContactosContext } from "../../Context/ContactoContext";
import { IconButton } from "@mui/material";

export default function SelectingOption({
  contacto,
  handleSelect,
  handleClose,
}) {
  const {
    isSelecting,
    setIsSelecting,
    setItemsSelected,
    setIndeterminated,
  } = React.useContext(ContactosContext);

  const onSelect = (e) => {
    handleSelect(true, contacto);
    setIsSelecting(true);
    if(handleClose)handleClose(e);
  };
  const onUnselect = (e) => {
    setItemsSelected([]);
    setIndeterminated(false);
    setIsSelecting(false);
    if(handleClose)handleClose(e);
  };

  return (
    <IconButton onClick={isSelecting ? onUnselect : onSelect}>
      <LibraryAddCheckOutlined fontSize="small" />
    </IconButton>
  );
}
