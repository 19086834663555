import { gql } from "@apollo/client";

export const OBTENER_CONTACTOS = gql`
  query ObtenerContactos($idEmpresa: ID!, $filter: String) {
    obtenerContactos(idEmpresa: $idEmpresa, filter: $filter) {
      _id
      tipoContacto
      idTipoContacto
      nombre
      organizacion
      etiqueta
      idEtiqueta
      telefono
      correo
      propietario
      idPropietario
      formaAtraccion
      idFormaAtraccion
      campaign
      idCampaign
      recomendadoPor
      statusContacto
      idStatusContacto
      fechaRegistro
      statusContactoPopulated {
        _id
        status
        factor
        color
      }
      customFields {
        nombre
        contenido
      }
    }
  }
`;
export const OBTENER_CONTACTOS_BY_ALPH = gql`
  query ObtenerContactos($idEmpresa: ID!, $filter: String) {
    obtenerContactos(idEmpresa: $idEmpresa, filter: $filter) {
      letter
      contactos {
        _id
        tipoContacto
        idTipoContacto
        nombre
        organizacion
        etiqueta
        idEtiqueta
        telefono
        correo
        propietario
        idPropietario
        formaAtraccion
        idFormaAtraccion
        campaign
        idCampaign
        recomendadoPor
        statusContacto
        idStatusContacto
        fechaRegistro
        customFields {
          nombre
          contenido
        }
      }
    }
  }
`;
export const OBTENER_CONTACTOS_DASHBOARD = gql`
  query ObtenerContactosDashboard($idEmpresa: ID!, $filter: String) {
    obtenerContactosDashboard(idEmpresa: $idEmpresa, filter: $filter) {
      contactos {
        _id
        tipoContacto
        idTipoContacto
        nombre
        organizacion
        etiqueta
        idEtiqueta
        telefono
        correo
        propietario
        idPropietario
        formaAtraccion
        idFormaAtraccion
        campaign
        idCampaign
        recomendadoPor
        statusContacto
        idStatusContacto
        statusContactoPopulated {
          _id
          status
          factor
          color
        }

        fechaRegistro
        customFields {
          nombre
          contenido
        }
        actividades {
          _id
          actividad
          tipoActividad
          idTipoActividad
          tipoActividadPopulated {
            _id
            tipoActividad
            accion
            icono
          }
          contactoPopulated {
            _id
            tipoContacto
            idTipoContacto
            nombre
            organizacion
            etiqueta
            idEtiqueta
            telefono
            correo
            propietario
            idPropietario
            formaAtraccion
            idFormaAtraccion
            campaign
            idCampaign
            recomendadoPor
            statusContacto
            idStatusContacto

            fechaRegistro
          }
          isDate
          fechaInicio
          fechaFin
          notas
          contacto
          idContacto
          trato
          idTrato
          ubicacion
          descripcionUbicacion
          usuario
          idUsuario
          prioridad
          status
          statusData {
            _id
            status
            color
            static
          }
          idStatus
          expires
          resultado
          fechaRegistro
          idResultado
        }
        tratos {
          _id
          unidadNegocio
          idUnidadNegocio
          producto
          idProducto
          fechaTrato
          contacto
          idContacto
          nombreTrato
          moneda
          idMoneda
          valor
          formaContacto
          idFormaContacto
          embudo
          idEmbudo
          visiblePara {
            nombreCompleto
            idUsuario
          }
          propietario
          idPropietario
          interes
          status
          campaign
          idCampaign
          prioridad
          probabilidadCierre
          fechaEstimadaCierre
          fechaCierre
          usuarioCierre
          fechaRegistro
          idUsuarioCierre
        }
      }
      byTipo {
        _id
        tipoContacto
        contactos {
          _id
          tipoContacto
          idTipoContacto
          nombre
          organizacion
          etiqueta
          idEtiqueta
          telefono
          correo
          propietario
          idPropietario
          formaAtraccion
          idFormaAtraccion
          campaign
          idCampaign
          recomendadoPor
          statusContacto
          idStatusContacto
          statusContactoPopulated {
            _id
            status
            factor
            color
          }

          fechaRegistro
          customFields {
            nombre
            contenido
          }
          actividades {
            _id
            actividad
            tipoActividad
            idTipoActividad
            tipoActividadPopulated {
              _id
              tipoActividad
              accion
              icono
            }
            contactoPopulated {
              _id
              tipoContacto
              idTipoContacto
              nombre
              organizacion
              etiqueta
              idEtiqueta
              telefono
              correo
              propietario
              idPropietario
              formaAtraccion
              idFormaAtraccion
              campaign
              idCampaign
              recomendadoPor
              statusContacto
              idStatusContacto

              fechaRegistro
            }
            isDate
            fechaInicio
            fechaFin
            notas
            contacto
            idContacto
            trato
            idTrato
            ubicacion
            descripcionUbicacion
            usuario
            idUsuario
            prioridad
            status
            statusData {
              _id
              status
              color
              static
            }
            idStatus
            expires
            resultado
            fechaRegistro
            idResultado
          }
          tratos {
            _id
            unidadNegocio
            idUnidadNegocio
            producto
            idProducto
            fechaTrato
            contacto
            idContacto
            nombreTrato
            moneda
            idMoneda
            valor
            formaContacto
            idFormaContacto
            embudo
            idEmbudo
            visiblePara {
              nombreCompleto
              idUsuario
            }
            propietario
            idPropietario
            interes
            status
            campaign
            idCampaign
            prioridad
            probabilidadCierre
            fechaEstimadaCierre
            fechaCierre
            usuarioCierre
            fechaRegistro
            idUsuarioCierre
          }
        }
      }
    }
  }
`;
export const OBTENER_CATALOGOS = gql`
  query ObtenerCatalogos($idEmpresa: ID!) {
    obtenerCatalogos(idEmpresa: $idEmpresa) {
      campaigns {
        _id
        idExterno
        nombre
        idRedSocial
        redSocial
      }
      contactos {
        _id
        nombre
        telefono
      }
      embudos {
        _id
        fase
        posicion
      }
      formasAtraccion {
        _id
        formaAtraccion
      }
      formasContacto {
        _id
        formaContacto
      }
      monedas {
        _id
        moneda
      }
      productos {
        _id
        nombre
        precio
        skuCodigo
      }
      statusActividad {
        _id
        static
        color
        status
      }
      statusContactos {
        _id
        color
        factor
        status
      }
      tipoActividades {
        _id
        accion
        icono
        tipoActividad
      }
      tratos {
        _id
        nombreTrato
        status
        valor
      }
      unidades {
        _id
        nombreUnidad
      }
      usuarios {
        _id
        nombreCompleto
        nombreUsuario
        telefono
      }
      tiposContactos {
        _id
        tipoContacto
      }
      etiquetas {
        _id
        etiqueta
      }
    }
  }
`;
