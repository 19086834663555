import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import { ELIMINAR_CONTACTO } from "../../graphql/mutations";
import { MainContext } from "../../../../../../Context/MainCtx";
import { useMutation } from "@apollo/client";
import { CircularProgress, IconButton } from "@mui/material";
import { ContactosContext } from "../../Context/ContactoContext";

export default function EliminarContactoOption({ contacto, externalClose }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { snackMessage } = React.useContext(MainContext);
  const { queryRefetch } = React.useContext(ContactosContext);
  const [eliminarContacto] = useMutation(ELIMINAR_CONTACTO);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
    if (externalClose) externalClose(e);
  };

  const handleDeleteContacto = async (e) => {
    try {
      handleClose(e);
      setLoading(true);
      const res = await eliminarContacto({
        variables: {
          idContacto: contacto._id,
        },
      });
      snackMessage({
        message: res.data.eliminarContacto.message,
        variant: "success",
      });
      queryRefetch();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      <IconButton onClick={() => handleClickOpen()}>
        <DeleteOutline fontSize="small" />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{`Eliminar ${contacto.nombre}`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Se va a eliminar este elemento, ¿Quiere continuar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus color="inherit">
            No, cancelar
          </Button>
          <Button
            onClick={(e) => handleDeleteContacto(e)}
            color="error"
            variant="contained"
            disableElevation
            startIcon={
              loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <DeleteOutline fontSize="small" />
              )
            }
          >
            Si, eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
