import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import Select from "@mui/material/Select";
import { InfoOutlined, Interests } from "@mui/icons-material";
import { useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { OBTENER_REDES } from "../../RedesSociales/graphql/queries";
import { MainContext } from "../../../../../Context/MainCtx";
import NuevaRedSocial from "../../RedesSociales/Actions/NuevaRedSocial";

export default function GetRedesSociales({ campData, setCampData }) {
  const { tokenDecoded } = React.useContext(MainContext);

  const redesQuery = useQuery(OBTENER_REDES, {
    variables: {
      idEmpresa: tokenDecoded ? tokenDecoded.idEmpresa : "",
    },
    fetchPolicy: "network-only",
  });
  const { data, loading, error, refetch } = redesQuery;

  if (loading) {
    return (
      <FormControl fullWidth size="small" margin="dense">
        <InputLabel>Red social</InputLabel>
        <Select
          disabled
          siza="small"
          label="Red social"
          value=""
          startAdornment={
            <InputAdornment position="start">
              <CircularProgress size={20} />
            </InputAdornment>
          }
        >
          <MenuItem value="">Seleccione uno</MenuItem>
        </Select>
      </FormControl>
    );
  }
  if (error) {
    return (
      <FormControl fullWidth size="small" margin="dense">
        <InputLabel>Red social</InputLabel>
        <Select
          disabled
          size="small"
          label="Red social"
          value=""
          startAdornment={
            <InputAdornment position="start">
              <InfoOutlined />
            </InputAdornment>
          }
        >
          <MenuItem value="">Seleccione uno</MenuItem>
        </Select>
      </FormControl>
    );
  }

  const handleChange = (e, child) => {
    const { _id, redSocial } = child.props.data;
    setCampData((st) => ({ ...st, redSocial, idRedSocial: _id }));
  };

  return (
    <FormControl fullWidth size="small" margin="dense">
      <InputLabel id="label-redSocial">Red social</InputLabel>
      <Select
        required
        labelId="label-redSocial"
        id="redSocial-select"
        siza="small"
        value={campData.redSocial}
        label="Red social"
        onChange={handleChange}
        startAdornment={
          <InputAdornment position="start">
            <Interests />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="start">
            <NuevaRedSocial refetch={refetch} inputMode={true} />
          </InputAdornment>
        }
      >
        <MenuItem value="">Seleccione uno</MenuItem>
        {data.obtenerRedSociales.map((res) => (
          <MenuItem key={res._id} value={res.redSocial} data={res}>
            {res.redSocial}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
